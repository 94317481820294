<div class="contactus_page landing__other_sections bg-page">
  <app-header></app-header>
  <div class="contactus_page_container">
    <div class="conatctus_banner p-relative">
      <img src="assets/images/banners/static-banner.webp" alt="" class="fixed-banner">
      <div class="banner-text">
        <h1>Privacy Policy</h1>
        <h2 class="text-normal">Everything else you want to know</h2>
      </div>
    </div>

    <div class="container policy_contents_layout mt-4">
      <p>PRIVACY POLICY</p>

      <p>

        At GSharp Media Private Limited having its registered office at A-1203, Serenity Complex, Off Link Road,
        Oshiwara West Mumbai 400102 (referred to as “Company,” “us” or “we”), we recognize that your privacy is
        important. This Policy discloses the privacy practices for the Company’s family of applications and/or Websites
        (collectively, the “Site”), as well as related products and services we may offer to you (collectively referred
        to as the “Services”). This Policy also covers how personal and other information that we receive or collect
        about you is treated. Please read the information below to learn the following regarding your use of this
        Site.<br><br>

        You acknowledge that this Privacy Policy is designed to be read in connection with the Site Terms of Use
        (https://hoopr.ai/terms-and-conditions), and that by accessing or using our Site, you agree to be bound by the
        Site’s terms and conditions, as well as this Privacy Policy.<br><br>

        We reserve the right to change this Privacy Policy from time to time. Any significant changes we may make to our
        Privacy Policy in the future will be promptly notified to users by posting the relevant terms in a prominent
        position on the webpage. The new terms may be displayed on the webpage and you will be required to read and
        accept them to continue your use of the Services. Your continued use of the Site and or Services available
        through this Site after such modifications will constitute your:
        <span class="d-flex">
          <span>(a)</span>
          <span class="pl-2">
            acknowledgment of the modified Policy; and
          </span>
        </span>
        <span class="d-flex">
          <span>(b)</span>
          <span class="pl-2">
            your agreement to abide and be bound by that Policy.
          </span>
        </span>
        <br>

        If you have any questions about this Policy, please feel free to contact us at Support@Hoopr.Ai;
        IMPORTANT: BY USING THE SITE AND/OR OFFERINGS AVAILABLE ON THIS SITE, YOU GIVE YOUR CONSENT THAT ALL PERSONAL
        DATA YOU SUBMIT MAY BE PROCESSED BY US IN THE MANNER AND FOR THE PURPOSES DESCRIBED BELOW. IF YOU DO NOT AGREE
        TO THESE TERMS AND CONDITIONS, DO NOT USE THE SITE.<br><br>

        1. Types of Information We Collect<br>
        We collect two types of information about our users: Personally Identifiable Information (“PII”) and Aggregate
        Information.<br><br>

        Personally Identifiable Information: This refers to information that lets us know the specifics of who you are.
        When you engage in certain activities on this Site, such as registering for an account, downloading or
        purchasing a product or service, submitting content and/or posting content, or sending us feedback, we may ask
        you to provide or collect certain information about yourself including without limitation your first and last
        name, age, email address, billing address (including ZIP code), IP address, payment details, preferences, and
        other identifying information.<br><br>

        Aggregate Information: This refers to information that does not by itself identify a specific individual. We
        gather certain information about you based upon where you visit on our Site and what other sites may have
        directed you to us. This information, which is collected in a variety of different ways, is compiled and
        analyzed on both a personal and an aggregated basis. This information may include the Website’s Uniform Resource
        Locator (“URL”) that points to the site you just came from, which URL you go to after visiting our Site, what
        browser you are using, and your Internet Protocol (“IP”) address.<br><br>

        2. How We Collect and Use Information<br>
        We do not collect any PII about you unless you voluntarily provide it to us. However, you may be required to
        provide certain PII to us when you elect to use certain products or services available on the Site. These may
        include:
        <span class="d-flex">
          <span>(a)</span>
          <span class="pl-2">
            registering for an account on our Site;
          </span>
        </span>
        <span class="d-flex">
          <span>(b)</span>
          <span class="pl-2">
            to help facilitate in-app purchases & withdrawals and
            make subscribed and paid content available to you;
          </span>
        </span>
        <span class="d-flex">
          <span>(c)</span>
          <span class="pl-2">
            to provide you with marketing communications,
            advertising, news, special offers, and general information about the products and services;
          </span>
        </span>
        <span class="d-flex">
          <span>(d)</span>
          <span class="pl-2">
            to communicate
            with you or sending us an email message; or
          </span>
        </span>
        <span class="d-flex">
          <span>(e)</span>
          <span class="pl-2">
            submitting a form or transmitting other information by telephone
            or letter;
          </span>
        </span>
        <span class="d-flex">
          <span>(f)</span>
          <span class="pl-2">
            to enhance the quality of the Services including gaming related services that we provide and to
            improve the effectiveness to better tailor our Services to your interests and needs;
          </span>
        </span>
        <span class="d-flex">
          <span>(g)</span>
          <span class="pl-2">
            to validate, authorize
            and map a specific profile with an authorized user;
          </span>
        </span>
        <span class="d-flex">
          <span>(h)</span>
          <span class="pl-2">
            for the performance of a contractual obligation;
          </span>
        </span>
        <span class="d-flex">
          <span>(i)</span>
          <span class="pl-2">
            to
            provide and process service requests initiated by you;
          </span>
        </span>
        <span class="d-flex">
          <span>(j)</span>
          <span class="pl-2">
            to facilitate your participation in various contests
            or programs conducted by the Company, affiliates, subsidiaries, or agents on the Platform. When processing
            certain information, we encrypt the transaction, using Secure Socket Layer (SSL) encryption technology, in
            order
            to prevent your PII from being stolen or intercepted.
          </span>
        </span>
        <br>
        We will primarily use your PII to provide products or services to you. We will also use certain forms of PII to
        enhance the operation of our Site, improve our internal marketing and promotional efforts, statistically analyze
        Site use, improve our product and service offerings, and customize our Site’s content, layout, and services. We
        may use PII to deliver information to you and to contact you regarding administrative notices. Finally, we may
        use your PII to resolve disputes, troubleshoot problems and enforce our agreements with you, including our Site
        Terms of Use, and this Privacy Policy.
        We may also collect certain Aggregate Information. For example, we may use your IP address to diagnose problems
        with our servers, software, to administer our Site and to gather demographic information.<br><br>

        3. Cookies<br>
        Depending on how you use our Site, we may store cookies on your computer in order to collect certain aggregate
        data about our users and to customize certain aspects of your specific user experience. A cookie is a small data
        text file which is stored on your computer that uniquely identifies your browser. Cookies may also include more
        personalized information, such as your IP address, browser type, the server your computer is logged onto, the
        area code and ZIP code associated with your server, and your first name to welcome you back to our Site. We use
        cookies to monitor how people use our website, including estimating audience size, consumer behavior, user
        navigation and patterns of use, to help us provide you with a better service. Cookies also make it easier for
        you to use the website on future visits and allow us to personalize the content of the website to you by
        recording information about your preferences. We use both persistent cookies (which expire on a specified date)
        and session cookies (which expire when you close your browser). Please note that third parties (including, for
        example providers of external services like web traffic analysis services such as Google Analytics, or
        advertising services and vendors) may also use cookies, over which we have no control. These cookies are likely
        to be analytical/performance cookies or targeting cookies.<br><br>

        We may use cookies to perform tasks such as: monitoring aggregate site usage metrics, storing and remembering
        your passwords (if you allow us to do so), storing account and advertising preferences that you have set, and
        personalizing the Services we make available to you. Most browsers are initially set up to accept cookies, but
        you can reset your browser to refuse all cookies or to indicate when a cookie is being sent. However, some
        aspects of the Site may not function properly if you elect to disable cookies.<br><br>

        4. Release of Information<br>
        We do provide some of our products and services through contractual arrangements made with affiliates, service
        providers, partners and other third parties (“Service Partners”). We and our Service Partners may need to use
        some PII in order to perform tasks between our respective sites, or to deliver products or services to you. We
        will encourage our Service Partners to adopt and promote strong privacy policies. However, the use of your PII
        by our Service Partners is governed by the respective privacy policies of those providers, and is not subject to
        our control. Except as otherwise discussed in this Privacy Policy, this document only addresses the use and
        disclosure of information we collect from you. Other Sites accessible through this Site, including our
        Advertising and Service Partners, have their own privacy policies and data collection, use and disclosure
        practices. We are not responsible for the policies or practices of third parties.<br><br>

        Occasionally, we may be required by law enforcement or judicial authorities to provide PII to the appropriate
        governmental authorities. In such cases, we will disclose PII upon receipt of a statutory regulation, court
        order, subpoena, or to cooperate with a law enforcement investigation. We fully cooperate with law enforcement
        agencies in identifying those who use our services for illegal activities. We reserve the right to report to law
        enforcement agencies any activities that we in good faith believe to be unlawful.<br><br>

        We may also provide Aggregate Information about our customers’ sales, traffic patterns, and related Site
        information to third party advertisers, but these statistics do not include any PII.<br>
        We may share the PII (and other information) with our affiliates and/or third-parties when necessary, to provide
        you with the service infrastructure and the related, to provide advertising and promotional services, to provide
        search results and links (including paid listings and links), to process financial transactions, to provide
        customer service, and any such relevant and necessary purposes which are essential for us making our Services
        available to you. We will never display, rent, or sell your personally identifiable information to
        others.<br><br>

        We may have to disclose your Personal Information for the following purposes including without limitation:
        <span class="d-flex">
          <span>(a)</span>
          <span class="pl-2">
            As required by law, such as to comply with a judicial order, executive orders, requirement by a law
            enforcement
            authority, or by other legal processes;
          </span>
        </span>
        <span class="d-flex">
          <span>(b)</span>
          <span class="pl-2">
            To protect and guard the rights and interests of the Company;
          </span>
        </span>
        <span class="d-flex">
          <span>(c)</span>
          <span class="pl-2">
            When we are required to provide information to the companies that provide products or services on our
            behalf;
            (unless we notify you otherwise, these companies do not have the right to use your personally identifiable
            information);
          </span>
        </span>

        <span class="d-flex">
          <span>(d)</span>
          <span class="pl-2">
            When we believe in good faith that disclosure is necessary to protect our rights, protect your
            safety or the safety of others, or, investigate fraud or crime;
          </span>
        </span>
        <span class="d-flex">
          <span>(e)</span>
          <span class="pl-2">
            If we (or our affiliates) are involved in a
            merger, acquisition, or sale of all or substantially all of its assets or equity.
          </span>
        </span>
        <br>

        5. Updating and Correcting Information<br>
        We believe you should have the ability to access and edit the PII that you have provided to us. You may change
        any of your PII in your account online at any time by linking to your account in accordance with instructions
        posted elsewhere on this Site. You may also access and correct your personal information and privacy preferences
        by emailing us at Support@Hoopr.Ai; or writing to us at:<br>
        Attn: Privacy Compliance Officer<br>
        Support@Hoopr.Ai;<br><br>

        Please include your name, address, and/or email address when you contact us.<br>
        We encourage you to promptly update your PII if it changes. Also, depending on your location, you may have
        certain additional rights with respect to your information such as deletion (including the right to have the
        Company delete your personal information, except information we are required to retain, by contacting us at the
        address listed above), or we may delete your data if we are instructed to do so by our customers who export your
        personal information to us, except for information we are required to retain; and withdrawal of consent or
        object to processing (including, in limited circumstances, the right to ask the Company to stop processing your
        personal data, by contacting us at the address listed above).<br><br>

        6. User Rights, Choices on Collection and Use of Information<br>
        If you indicated upon registration that you are interested in receiving offers or information from us and our
        partners, then we may from time to time, send you emails, call you or send you direct mail about products and
        services that may be of interest to you. Only Company (or agents working on behalf of Company and under
        confidentiality agreements) will send you these solicitations, and only if you have previously indicated that
        you wish to receive them. If you change your mind after giving consent to receive these solicitations, you can
        “opt-out” by accessing your account online editing your account information to no longer receive such offers and
        mailings.
        <br><br>
        You also have choices with respect to cookies, as described above. By modifying your browser preferences, you
        have the choice to accept all cookies, to be notified when a cookie is set, or to reject all cookies. If you
        choose to reject all cookies some parts of our Site may not work properly in your case.<br><br>

        You have a number of rights in relation to the information (including PII) we hold about you. These rights
        include:
        <span class="d-flex">
          <span>(a)</span>
          <span class="pl-2">
            The right to access the information we hold about you, and to obtain details of processing;
          </span>
        </span>
        <span class="d-flex">
          <span>(b)</span>
          <span class="pl-2">
            The
            right to withdraw your consent to our continued processing of the information, at any point of time, subject
            only to any legitimate reason for continued processing in certain circumstances;
          </span>
        </span>
        <span class="d-flex">
          <span>(c)</span>
          <span class="pl-2">
            The right to edit your
            account information and preferences available with us;
          </span>
        </span>
        <span class="d-flex">
          <span>(d)</span>
          <span class="pl-2">
            The right to seek from us your personal data to be
            provided to another company;
          </span>
        </span>
        <span class="d-flex">
          <span>(e)</span>
          <span class="pl-2">
            The right to erasure of any personal data that we process, in certain
            circumstances, to the extent permissible in applicable law, and feasible; and,
          </span>
        </span>
        <span class="d-flex">
          <span>(f)</span>
          <span class="pl-2">
            The right to move your
            information to another service provider, in a compatible format. This will only cover such information which
            we
            have received from you.
          </span>
        </span>
        <br>

        7. Security of Your PII<br>
        At our Site you can be assured that your PII is secure, consistent with current industry standards. We strive to
        take appropriate security measures to protect against unauthorized access to or unauthorized alteration,
        disclosure or destruction of your PII. For example:<br><br>

        We work hard to ensure that the data we collect is reliable, accurate, complete and current. We encrypt all PII,
        in order to prevent unauthorized parties from viewing such information when it is transmitted to us. We also
        only keep collected information indefinitely and use it only for the purposes for which it was collected or to
        comply with any applicable legal or ethical reporting or document retention requirements.
        We limit access to PII only to specific employees, contractors and agents who have a reasonable need to come
        into contact with your information. For example, we may provide members of our technical support team with
        limited access to your account in order to allow them to troubleshoot problems you may be having with the Site.
        We also employ a number of physical, electronic, and procedural safeguards to protect PII. Our secure servers
        and our data centers are protected by industry-standard encryption, and our servers reside behind firewalls and
        employ high-level password protection.<br>
        Finally, access by you to your PII is available through a password and unique customer ID selected by you. This
        password is encrypted. We recommend that you do not divulge your password to anyone.<br><br>

        As a result, while we strive to protect your PII, you acknowledge that: 
        <span class="d-flex">
          <span>(a)</span>
          <span class="pl-2">
            there are security and privacy
            limitations inherent to the Internet which are beyond our control; and 
          </span>
        </span>
        <span class="d-flex">
          <span>(b)</span>
          <span class="pl-2">
            the security, integrity and privacy
            of any and all information and data exchanged between you and us through this Site cannot be guaranteed.
          </span>
        </span>
      <br>

        8. Third Party Services<br>
        We may allow you to connect a YouTube channel to your account on the Site. In this case, we will access certain
        information from YouTube about your channel by using YouTube API Services. We may store your channel ID for
        reference when creating licenses, which will be treated in accordance with this Privacy Policy. We may share
        your channel id to our content ID licensing partner who facilitates our content ID licensing. If you decide to
        connect a YouTube channel to your Hoopr account, you will also be subject to Google’s privacy policy available
        here: http://www.google.com/policies/privacy. When disconnecting a YouTube channel on our Site, We will delete
        the stored data associated with that YouTube channel. You can also revoke access to the data we collect via the
        YouTube API Services by changing your security settings on Google here:
        https://security.google.com/settings/security/permissions.<br><br>

        9. Miscellaneous<br>
        You must be at least 18 years old to have our permission to use this Site. Our policy is that we do not
        knowingly collect, use or disclose PII about children under the age of 18. You must have attained the age of
        majority to be able to use and access our Services. As a parent or legal guardian, please do not allow your
        minors under your care to submit Personal Information to us. In the event that such PII of minor is disclosed to
        us, you hereby consent to the processing of the minor’s PII and accept and agree to be bound by this Privacy
        Policy and take responsibility for his or her actions.<br><br>

        You should also be aware that when PII is voluntarily disclosed (i.e., your name, email address, etc.) in the
        discussion forums or other public areas on this Site, that information, along with any information disclosed in
        your communication, can be collected and used by third parties and may result in unsolicited messages (including
        unwanted spam messages) from third parties. Such activities are beyond our control and this Policy does not
        apply to such information. Please consult our Site Terms of Use for our Site conduct policies.<br><br>

        If you have any questions, concerns or inquiries about our Privacy Policy, or our use of your PII, or our
        privacy practices, please contact us at Support@Hoopr.Ai.You can also reach our Privacy Compliance Officer by
        sending written correspondence to:<br>
        Attn: Privacy Compliance Officer<br>
        Support@Hoopr.Ai;
        <br><br>

      </p>

    </div>

  </div>
  <section class="contact">
    <div class="pageContainer p-3 px-md-4 px-xl-5">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6">
          <h3 class="sub-title">We’re happy to answer questions and get you acquainted with Hoopr, Didn't find your
            answers here?</h3>
          <div class="divider"></div>
          <a routerLink="/contactus" id="privacy_policy_contact_us" class="btn btn-brand">Contact us</a>
        </div><!-- col-6 -->
      </div><!-- row -->
    </div><!-- page container -->
  </section><!-- contact -->
</div>
<footer></footer>