import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BackendApiService } from './hoopr.services';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  deviceInfo
  constructor(
    private deviceService: DeviceDetectorService,
    private apiService: BackendApiService,
  ) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  getUserMetaData() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const anon_id = localStorage.getItem('anon_id')

    return {
      user_id: userData?.id ? userData.id : anon_id,
      anon_id: null,
      login_status: localStorage.getItem('authorizationToken') ? true : false,
      plan_category: userData?.subscription?.subscriptionPlan?.name || null,
      plan_type: userData?.subscription?.subscriptionPlan?.type || null,
      device: this.deviceInfo?.device?.substring(0, 125) || null,
      deviceType: this.deviceInfo?.deviceType?.substring(0, 125) || null,
      browser: this.deviceInfo?.browser?.substring(0, 125) || null,
      browser_version: this.deviceInfo?.browser?.substring(0, 125) || null,
      os: this.deviceInfo?.os || null,
      os_version: this.deviceInfo?.os_version?.substring(0, 125) || null,
      userAgent: this.deviceInfo?.userAgent?.substring(0, 125) || null,
      make: null,
      model: null,
      city: userData?.address?.town || userData?.address?.line1?.split('-')[0] || null,
      state: userData?.address?.state || null,
      country: userData?.address?.country || userData?.address?.line1?.split('-').at[-1] || null,
      current_city: JSON.parse(localStorage.getItem('current_address'))?.city?.substring(0, 125) || null,
      current_state: JSON.parse(localStorage.getItem('current_address'))?.state?.substring(0, 125) || null,
      current_country: JSON.parse(localStorage.getItem('current_address'))?.country?.substring(0, 125) || null,
      signup_date: userData?.signup_date,
      subs_startdate: userData?.subscription?.createdAt || null,
      subs_expdate: userData?.subscription?.expiryDate || null,
      subs_status: userData?.subscription?.status || null,
      acq_referral_utm_source: userData?.acq_referral_utm_source?.substring(0, 125) || null,
      acq_referral_utm_medium: null,
      acq_referral_utm_campaign: userData?.acq_referral_utm_campaign?.substring(0, 125) || null,
    }
  }

  logData(metaData: any) {
    let userData: any = this.getUserMetaData();
    this.apiService.logEvent({ ...metaData, ...userData }).subscribe(() => { }, err => { })
  }
}
