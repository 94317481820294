import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  templateUrl: './terms_conditions.component.html',
  styleUrls: ['./terms_conditions.component.scss']
})
export class TermsConditionsComponent {



  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }



  ngOnInit() {

    this.titleService.setTitle("Terms and Conditions | Hoopr - India ka Sound");

    this.metaTagService.updateTag(
      { name: 'description', content: 'Terms and conditions about using Hoopr - A platform that gives you access to royal free music for a subscription. ' },

    );
  }

}
