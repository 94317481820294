<div class="contactus_page landing__other_sections bg-page">
  <app-header></app-header>
  <div class="contactus_page_container row m-0 d-flex justify-content-center">
   
    <div class="container conatct_us_content col-md-8">
      <h1 class="title-color title text-center">
        Contact <span class="brand-pink-color">Form</span>
        <!-- <small class="text-normal mt-1">Select The Plan That’s Right For You</small> -->
      </h1>
      <!-- <h1 class="contact-us-title sub-title my-4"> Contact <span>Form</span></h1> -->
      <!-- <p class="conatct_tag_line_text">
        Contact <span>customer support</span> for technical issues and questions about the Personal or Commercial Plan.
      </p> -->
      <div class="contactus__page_fields form-section">
        <form class="" [formGroup]='contactusForm' (ngSubmit)='onSubmit()'>
          <div class="d-flex flex-wrap justify-content-between">
            <div class="hoopr_form_field_group field_width_50">
              <div class="hoopr_form_fields">
                <input  type="text" inputmode="text" class="inputText" formControlName="firstName" required/>
                <span class="floating-label">First Name*</span>
                <p><i class="glyphicon glyphicon-exclamation-sign error_info_highlight"> </i></p>
              </div>
              <p class="form_errors text-left" *ngIf="formErrors.firstName">{{formErrors.firstName}}</p>

            </div>
            <div class="hoopr_form_field_group field_width_50">
              <div class="hoopr_form_fields">
                <input  type="text" inputmode="text" class="inputText" formControlName="lastName" required/>
                <span class="floating-label">Last Name</span>
                <p><i class="glyphicon glyphicon-exclamation-sign error_info_highlight"> </i></p>
                <!-- <span class="tooltiptext">tooltip error</span> *ngIf="em.value == ''" -->
              </div>
              <p class="form_errors text-left" *ngIf="formErrors.lastName">{{formErrors.lastName}}</p>

            </div>
            <div class="hoopr_form_field_group field_width_100">
              <div class="hoopr_form_fields">
                <input  type="text" inputmode="email" class="inputText" formControlName="email" required/>
                <span class="floating-label">Email*</span>
                <p><i class="glyphicon glyphicon-exclamation-sign error_info_highlight"> </i></p>
                <!-- <span class="tooltiptext">tooltip error</span> *ngIf="em.value == ''" -->
              </div>
              <p class="form_errors text-left" *ngIf="formErrors.email">{{formErrors.email}}</p>

            </div>
            <div class="hoopr_form_field_group field_width_100">
              <div class="hoopr_form_fields">
                <input  type="text" inputmode="tel" class="inputText" formControlName="mobile" required/>
                <span class="floating-label">Phone Number*</span>
                <p><i class="glyphicon glyphicon-exclamation-sign error_info_highlight"> </i></p>
                <!-- <span class="tooltiptext">tooltip error</span> *ngIf="em.value == ''" -->
              </div>
              <p class="form_errors text-left" *ngIf="formErrors.mobile">{{formErrors.mobile}}</p>

            </div>
           <div class="row hoopr_radio_buttons hoopr_radio_buttons_contactus mx-0">
             <div class="col-12 col-lg-3 pr-lg-2">
              <h2>What are you ?</h2>
             </div>
             <div class="col-lg-2 col-6 my-2 px-2">
              <input formControlName="userType" type="radio" id="individual" name="userType" value="individual">
              <label class="   radio_buttons_label" for="individual">
                <i class="fa fa-mobile" aria-hidden="true"></i>Individual
              </label>
             </div>

             <div class="col-lg-2 col-6  my-2 px-2">
              <input formControlName="userType" type="radio" id="Business" name="userType" value="business">
              <label class="  radio_buttons_label" for="Business">
                <i class="fa fa-youtube-play" aria-hidden="true"></i>Business
              </label>
                  
             </div>
             <div class="col-lg-2 col-6  my-2 px-2">
              <input formControlName="userType" type="radio" id="Enterprise" name="userType" value="enterprise">
              <label class="   radio_buttons_label" for="Enterprise">
                <i class="fa fa-television" aria-hidden="true"></i>Enterprise
              </label>

             </div>

             <div class="col-lg-2 col-6  my-2 px-2">
              <input formControlName="userType" type="radio" id="Artist" name="userType" value="artist">
              <label class="   radio_buttons_label" for="Artist">
                <i class="fa fa-users" aria-hidden="true"></i>Artist
              </label>
             </div>
           </div>



            <!-- <div class="row w-100">
            

              <div class="hoopr_radio_buttons hoopr_radio_buttons_contactus ">
                <p class="col-12 my-2">What are you ?</p>
                <input formControlName="userType" type="radio" id="individual" name="userType" value="individual">
                <label class=" mx-2 col-md-2 col-sm-6  radio_buttons_label" for="individual">
                  <i class="fa fa-mobile" aria-hidden="true"></i>Individual
                </label>
  
                <input formControlName="userType" type="radio" id="Business" name="userType" value="business">
                <label class="  mx-2 col-md-2 col-sm-6 radio_buttons_label" for="Business">
                  <i class="fa fa-youtube-play" aria-hidden="true"></i>Business
                </label>
  
                <input formControlName="userType" type="radio" id="Enterprise" name="userType" value="enterprise">
                <label class="  mx-2 col-md-2 col-sm-6 radio_buttons_label" for="Enterprise">
                  <i class="fa fa-television" aria-hidden="true"></i>Enterprise
                </label>
  
                <input formControlName="userType" type="radio" id="Artist" name="userType" value="artist">
                <label class="  mx-2 col-md-2 col-sm-6  radio_buttons_label" for="Artist">
                  <i class="fa fa-users" aria-hidden="true"></i>Artist
                </label>
  
  
              </div>
            </div> -->
            
            
            <div class="hoopr_form_field_group field_width_100">
              <div class="hoopr_form_fields hoopr_form_fields_text_area">
                <textarea formControlName="description" name="name" rows="8" cols="80" placeholder="Brief Description Of your Query ?"></textarea>
              </div>
            </div>
          </div>
          <div class="contact_us_cta justify-content-center-sm">
            <button type="submit" id="sales_contact_us" name="button">Submit Form</button>
          </div>
        </form>
      </div>
    </div>
   
  </div>
  
  <section class="contact">
    <div class="pageContainer p-3 px-md-4 px-xl-5">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 text-center">
          <h3 class="sub-title">We’re happy to answer questions and get you acquainted with Hoopr, 
            Want to know more?</h3>
          <div class="divider"></div>
          <!-- <a href="#" class="btn btn-brand">Contact us</a> -->
          <a href="" class="btn white-outline-btn" routerLink="/faqs">FAQs</a>
        </div><!-- col-6 -->
      </div><!-- row -->
    </div><!-- page container -->
  </section><!-- contact -->
</div>

<footer></footer>



<div class="modal hoopr_popup  " id="conformationPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" *ngIf="contactFormSubmited" style="display:block">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="contactFormSubmited = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="only_contnet_popup">
          <h4>Your query has been submitted successfully!</h4>

          <!-- <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna</p> -->
          <div class="d-flex justify-content-center">
            <button type="button" class="popup-cta" name="button" (click)="contactFormSubmited = false">Ok</button>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>




<!-- <div class="hoopr_radio_buttons hoopr_radio_buttons_contactus ">
  <p>What are you ?</p>
  <input formControlName="userType" type="radio" id="individual" name="userType" value="individual">
  <label class="  radio_buttons_label" for="individual">
    <i class="fa fa-mobile" aria-hidden="true"></i>Individual
  </label>

  <input formControlName="userType" type="radio" id="Business" name="userType" value="business">
  <label class="  radio_buttons_label" for="Business">
    <i class="fa fa-youtube-play" aria-hidden="true"></i>Business
  </label>

  <input formControlName="userType" type="radio" id="Enterprise" name="userType" value="enterprise">
  <label class=" radio_buttons_label" for="Enterprise">
    <i class="fa fa-television" aria-hidden="true"></i>Enterprise
  </label>

  <input formControlName="userType" type="radio" id="Artist" name="userType" value="artist">
  <label class="  radio_buttons_label" for="Artist">
    <i class="fa fa-users" aria-hidden="true"></i>Artist
  </label>


</div> -->