<div class="contactus_page landing__other_sections bg-page">
    <app-header></app-header>
    <div class="contactus_page_container">
      <div class="conatctus_banner p-relative">
        <img src="assets/images/banners/static-banner.webp" alt="" class="fixed-banner">
        <div class="banner-text">
          <h1>About Us</h1>
          <h2 class="text-normal">Everything else you want to know</h2>
        </div>
      </div>
  
      <div class="container policy_contents_layout mt-4 text-content">
        <!-- <p>PRIVACY POLICY</p> -->

        <h2 class="question"> Who we are:</h2>
        <p>
            We are GSharp Media - a content and technology company focused on creating media-tech platforms and content brands. Currently operating two very exciting brands: Songfest India & Hoopr. 
        </p>
        <p>
            In addition, any content that you published during the time of your active subscription shall be cleared for perpetuity. 
        </p>
        <h2 class="question">
            What are we solving:
        </h2>
        <p>
            What are we solving: Hoopr is India's first AI-powered music licensing platform that’s focused on solving a billion-dollar problem faced by content creators and businesses every day – discovering & licensing the right music for their videos! While Hoopr is catering to the demand for licensed music, it will also be providing a massive opportunity for thousands of musicians to finally be able to monetize their music!
        </p>
        <p>
            We are looking to build tools and technologies that leverage data and consumer insights to provide intelligent recommendations, thereby making the search & discovery process super efficient for our consumers.
        </p>
        <p>
            Led by Gaurav Dagaonkar: IIM- A alum turned Music Director with 14+ years of experience in the industry both on the business and the creative side, along with serial entrepreneur Meghna Mittal (ex- Hubilo, Yesssworks), leading marketing, Hoopr is a funded startup with notable investors such as Venture Catalyst (9Unicorns fund), Inflection Point Ventures, Ashneer Grover (BharatPe), Sahil Barua (Delhivery), Anshoo Sharma (Magicpin) and others.  
        </p>

        <div class="text-center text-content">
            <img src="/assets/images/about-us.webp" alt="" class="w-100">
        </div>

      </div>
  
    
    </div>
    <section class="contact">
      <div class="pageContainer p-3 px-md-4 px-xl-5">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6">
            <h3 class="sub-title">We’re happy to answer questions and get you acquainted with Hoopr, Didn't find your answers here?</h3>
            <div class="divider"></div>
            <a routerLink="/contactus" id="about_contact_us" class="btn btn-brand">Contact us</a>
          </div><!-- col-6 -->
        </div><!-- row -->
      </div><!-- page container -->
    </section><!-- contact -->
  </div>
  <footer></footer>