import { Component, OnInit } from '@angular/core';
import { BackendApiService } from '../services/hoopr.services';
@Component({
  selector: 'app-populars',
  templateUrl: './populars.component.html',
  styleUrls: ['./populars.component.scss']
})
export class PopularsComponent implements OnInit {
  public movies: any;
  public artists: any;
  public tracks: any;
  public playlists: any;
  public feedData: any;

  constructor(
    private apiService: BackendApiService
  ) { }

  ngOnInit(): void {
    this.apiService.getPopularFeed().subscribe(
      (response) => {
        console.log(response);
        this.feedData = response;
        this.movies = this.feedData["movies"].data;
        this.artists = this.feedData["artists"].data;
        this.tracks = this.feedData["tracks"].data;
        this.playlists = this.feedData["playlists"].data;
      },
      (err) => {
        console.log('Error while loading feed data', err);
      }
    )
  }

}
