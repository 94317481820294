<div class="hoopr_landing_footer pt-4 footer-bg padding-container-xl">
  <div class="row m-0 w-100">
    <div class="col-md-4 col-lg-3 px-0">
      <div>
        <img src="assets/images/hoopr-logo.webp" alt="hoopr-logo" class="hoopr-footer-logo-pride mb-2" />
      </div>
      <p class="text-small footer-descp text-mute">
        “India's #1 music licensing platform for video creators & brands with over 10,000+ premium tracks and sfx”
      </p>
      <div class="d-flex">
        <p class="icons">
          <a (click)="this.pushAnalytics('facebook','Social links')" href="https://www.facebook.com/hoopr.official" id="fb_contact" target="_blank">
            <img src="/assets/images/fb3x.webp" alt="facebook icon" width="50%" /></a>
        </p>
        <p class="icons">
          <a href="https://wa.me/919324589627" (click)="this.pushAnalytics('whatsapp','Social links')" id="wa_contact" target="_blank">
            <img src="/assets/images/wa3x.webp" alt="whatsapp icon" width="50%" /></a>
        </p>
        <p class="icons">
          <a href="https://www.linkedin.com/company/80080761/admin/"  (click)="this.pushAnalytics('linkedin','Social links')" id="linkedIn_contact" target="_blank">
            <img src="/assets/images/lkdin3x.webp" alt="linkedIn icon" width="50%" /></a>
        </p>
        <p class="icons">
          <a href="https://www.instagram.com/hoopr.ai/?hl=en"  (click)="this.pushAnalytics('instagram','Social links')" id="insta_contact" target="_blank">
            <img src="/assets/images/ig3x.webp" alt="instagram icon" width="50%" /></a>
        </p>
      </div>
    </div><!-- col-4 -->
    <div class="col-6 col-md-2 d-flex justify-content-between px-0 px-md-3">
      <div class="flex-column">
        <p class="cursor footer-list text-small " (click)="this.pushAnalytics('discover')" routerLink="/">
          Home
        </p>
        <p class="cursor footer-list text-small" (click)="this.pushAnalytics('music-page')" routerLink="/music-page">
          Music
        </p>
        <p class="cursor footer-list text-small" (click)="this.pushAnalytics('sfx')" routerLink="/sfx">
          Sound Effects
        </p>
        <p class="cursor footer-list text-small" (click)="this.pushAnalytics('playlists')" routerLink="/playlists">
          UseCases
        </p>
        <p class="cursor footer-list text-small" (click)="this.pushAnalytics('genres')" routerLink="/genres">
          Genres
        </p>
        <p class="cursor footer-list text-small" (click)="this.pushAnalytics('moods')" routerLink="/moods">
          Moods
        </p>
      </div>
    </div><!-- col-2 -->
    <div class="col-6 col-md-2 d-flex  justify-content-between px-0 px-md-3">
      <div class=" flex-column">
        <p>
          <a class="cursor text-small footer-list text-white" (click)="this.pushAnalytics('Music For Vlogs')" [href]="environment.baseUrlFrontend + '/categories/background-music-for-vlog'">
            Music For Vlogs
          </a>
        </p>
        <p>
          <a class="cursor text-small footer-list text-white" (click)="this.pushAnalytics('Music For Cooking Videos')" 
            [href]="environment.baseUrlFrontend + '/categories/music-for-cooking-videos'">
            Music For Cooking Videos
          </a>
        </p>
        <p>
          <a class="cursor text-small footer-list text-white" (click)="this.pushAnalytics('Music For Gaming')" 
            [href]="environment.baseUrlFrontend + '/categories/no-copyright-gaming-music'">
            Music For Gaming
          </a>
        </p>
        <p>
          <a class="cursor text-small footer-list text-white" (click)="this.pushAnalytics('Royalty Free Intro Music')" 
            [href]="environment.baseUrlFrontend + '/categories/no-copyright-intro-music'">
            Royalty Free Intro Music
          </a>
        </p>
        <p>
          <a class="cursor text-small footer-list text-white" (click)="this.pushAnalytics('Copyright Free Outro Music')" 
            [href]="environment.baseUrlFrontend + '/categories/no-copyright-outro-music'">
            Copyright Free Outro Music
          </a>
        </p>
        <p>
          <a class="cursor text-small footer-list text-white" (click)="this.pushAnalytics('Royalty Free Music For Podcasts')" 
            [href]="environment.baseUrlFrontend + '/categories/royalty-free-music-for-podcasts'">
            Royalty Free Music For Podcasts
          </a>
        </p>
        <p>
          <a class="cursor text-small footer-list text-white" (click)="this.pushAnalytics('No Copyright Streaming Music')" 
            [href]="environment.baseUrlFrontend + '/categories/royalty-free-music-for-streaming'">
            No Copyright Streaming Music
          </a>
        </p>
        <p>
          <a class="cursor text-small footer-list text-white" (click)="this.pushAnalytics('Copyright Free Workout Music')" 
            [href]="environment.baseUrlFrontend + '/categories/no-copyright-workout-music'">
            Copyright Free Workout Music
          </a>
        </p>
        
      </div>
    </div><!-- col-2 -->
    <div class="col-6 col-md-2 d-flex  justify-content-between px-0 px-md-3">
      <div class=" flex-column">
        <p class="cursor text-small footer-list" (click)="this.pushAnalytics('about-us')" routerLink="/about-us">
          About Hoopr
        </p>
        <p>
          <a class="cursor text-small footer-list text-white" (click)="this.pushAnalytics('blogs')" href="https://blog.hoopr.ai/">
            Blogs
          </a>
        </p>
        <p class="cursor text-small footer-list" (click)="this.pushAnalytics('hoopr-for-artiste')" routerLink="/hoopr-for-artiste">
          Hoopr for Artists
        </p>
        <p class="cursor text-small footer-list"(click)="this.pushAnalytics('privacy-policy')" routerLink="/privacy-policy">
          Privacy Policy
        </p>
        <p class="cursor text-small footer-list" (click)="this.pushAnalytics('terms-and-conditions')" routerLink="/terms-and-conditions">
          Terms & Conditions
        </p>
        <p class="cursor text-small footer-list" (click)="this.pushAnalytics('pricing')"  routerLink="/pricing">
          Pricing
        </p>
        <p class="cursor text-small footer-list"(click)="this.pushAnalytics('copyright')"  routerLink="/copyright">
          Copyright Claim Submission
        </p>
      </div>
    </div><!-- col-2 -->

    <div class="col-6 col-md-2 col-lg-3 d-flex  justify-content-between px-0 px-md-3">
      <div class=" flex-column">
        <p class="cursor text-small footer-list" id="footer_contact_us" (click)="this.pushAnalytics('contactus')" routerLink="/contactus">
          Contact Us 
        </p>
        <p class="cursor text-small footer-list" (click)="this.pushAnalytics('faqs')" routerLink="/faqs">
          FAQs
        </p>
        <p  class="cursor text-small footer-list mb-0">
          <span class="text-small mail-id">+91 8369740702</span><br>
        </p>
        <p  class=" text-small">
          <span class="text-small">Monday to Friday:<br>11:00 AM to 7:00 PM</span>
        </p>
        <p  class="cursor text-small footer-list">
          <a class="text-small mail-id" href="mailto:hello@hoopr.ai" target="_blank">hello@hoopr.ai</a>
        </p>
      </div>
    </div><!-- col-md-2 -->
    
    <!-- </div> -->
  </div>
</div>