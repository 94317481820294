import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LandingComponent } from "./_page_landing/landing.component";
import { TrackComponent } from './track/track.component';
import { pageNotFoundComponent } from "./_page_error_state_404/pageNotFound.component";
import { PrivacyPolicyComponent } from "./_page_privacy_policy/privacy_policy.component";
import { TermsConditionsComponent } from "./_page_terms_conditions/terms_conditions.component";
import { UserGuard } from "./guards/user.guard";
import { GlobalSearchComponent } from './global-search/global-search.component';
import { PlaylistsComponent } from "./playlists/playlists.component";
import { GenresComponent } from './genres/genres.component'
import { MoviesComponent } from "./movies/movies.component";
import { IndividualComponent } from "./individual/individual.component";
import { ArtistsComponent } from "./artists/artists.component";
import { ArtistComponent } from "./artist/artist.component";
import { TrendingsComponent } from "./trendings/trendings.component";
import { PopularsComponent } from "./populars/populars.component";
import { MoodsComponent } from "./moods/moods.component";
import { LanguagesComponent } from "./languages/languages.component";

const routes: Routes = [

  { path: '', component: LandingComponent },
  { path: "search", component: GlobalSearchComponent },
  { path: "terms-and-conditions", component: TermsConditionsComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "artists", component: ArtistsComponent },
  { path: "artist/:artistId", component: ArtistComponent },
  { path: "playlists", component: PlaylistsComponent },
  { path: "playlist/:value", component: IndividualComponent, data: { type: "playlist" } },
  { path: "genres", component: GenresComponent },
  { path: "genre/:value", component: IndividualComponent, data: { type: "genre" } },
  { path: "movies", component: MoviesComponent },
  { path: "movie/:value", component: IndividualComponent, data: { type: "movie" } },
  { path: "trending", component: TrendingsComponent },
  { path: "languages", component: LanguagesComponent },
  { path: "language/:value", component: IndividualComponent, data: { type: "language" } },
  { path: "popular", component: PopularsComponent },
  { path: 'track/:trackId', component: TrackComponent },
  { path: 'moods', component: MoodsComponent },
  { path: 'mood/:value', component: IndividualComponent, data: { type: 'mood' } },
  {
    path: "user",
    canActivate: [UserGuard],
    canActivateChild: [UserGuard],
    canLoad: [UserGuard],
    loadChildren: () => import("./user/user.module").then((m) => m.UserModule),
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  { path: "**", pathMatch: "full", component: pageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      initialNavigation: "enabled",
    }),
  ],

  exports: [RouterModule],
})
export class AppRoutingModule { }

