import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BackendApiService } from '../services/hoopr.services';
import { MetaService } from '../services/meta.service';
declare var window: any;
@Component({
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactUsComponent {
  contactusForm: FormGroup;
  userformData: any;
  forgotPwdResponse: any;
  errorMessage: any;
  userdata: any;
  forgotDataMessage: any;
  contactFormSubmited: any;
  ApisuccessResponse: any;
  formErrors = {
    "firstName": '',
    "lastName": '',
    "email": '',
    "mobile": '',
    "userType": '',
  };
  // Form Error Object
  validationMessages = {
    "firstName": {
      'pattern': 'Enter valid Name',
      'required': 'Please enter Name'
    },
    "lastName": {
      'pattern': 'Enter valid Name',
      'required': 'Please enter Name'
    },
    "email": {
      'pattern': 'Enter valid mail Id',
      'required': 'Please enter email'
    },
    "mobile": {
      'pattern': 'Enter valid Mobile Number',
      'required': 'Please enter Mobile Number'
    },
  };

  initForm() {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.contactusForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: [''],
      email: ['', [Validators.required, Validators.pattern(re)]],
      mobile: ['', [Validators.required]],
      userType: [''],
      description: [''],
    });


    this.contactusForm.valueChanges
      .subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // (re)set validation messages now
  }

  // Reactive form Error Detection
  onValueChanged(data?: any) {
    if (!this.contactusForm) { return; }
    const form = this.contactusForm;
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          let msg = messages[key] ? messages[key] : '';
          this.formErrors[field] += msg + ' ';
        }
      }

    }

  }

  onSubmit() {
    if (!this.contactusForm.valid) {
      if (!this.contactusForm) { return; }
      const form = this.contactusForm;
      for (const field in this.formErrors) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            this.formErrors[field] += messages[key] + ' ';
          }
        }
      }
    }
    if (this.contactusForm.valid) {

      // push data to gtm
      window.dataLayer.push({
        event: 'business_360_subscribe',
        first_name: this.contactusForm?.value?.firstName || null,
        last_name: this.contactusForm?.value?.lastName || null,
        email_id: this.contactusForm?.value?.email || null,
        phone: this.contactusForm?.value?.mobile || null,
        occupation: this.contactusForm?.value?.userType || null,
      });

      this.userformData = {
        "name": this.contactusForm.value.firstName + this.contactusForm.value.lastName,
        "email": this.contactusForm.value.email,
        "mobile": this.contactusForm.value.mobile,
        "userType": this.contactusForm.value.userType,
        "description": this.contactusForm.value.description
      };

      const keysToCheck = ['utm_source', 'utm_campaign', 'code', 'utm_medium', 'utm_term', 'service', 'placement', 'device', 'device_model', 'fbclid', 'gclid', 'wbraid', 'gbraid', 'li_fat_id', 'date_of_lead_creation', 'landing_page'];
      keysToCheck.forEach(key => {
        const value = localStorage.getItem(key);
        if (value) {
          this.userformData[key] = value;
        }
      });

      this.Usercontactus();
    }
  }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private apiService: BackendApiService,
    private metaService: MetaService,
  ) {
    this.contactFormSubmited = false;
  }

  Usercontactus() {
    this.apiService.consumerContactUs(this.userformData).subscribe(response => {
      this.ApisuccessResponse = response['data'];
      this.contactFormSubmited = true;
      this.initForm();

      // this.router.navigateByUrl('/'+ this.forgotPwdResponse.data.entry.id +'/overview');
    }, (error) => {
      this.errorMessage = error.error.info.message;
      setTimeout(() => {
        this.errorMessage = false;
      }, 7000);
    });
  }



  ngOnInit() {
    this.metaService.setMetaTags('contactUs');
    this.initForm();
  }

}
