<!-- Played more than 5 free tracks -->
<div *ngIf="this.popUpType === 'NOT_LOGGED_PLAY_SONG'">
    <div class="row m-0 justify-content-center mb-3">
        <div class="col-1"></div>
        <div class="col-9 d-flex justify-content-center align-items-center flex-column">
            <h2 class="popup-title">Love what you heard?</h2>
            <p class="popup-text my-3 text-mute">Sign up to continue discovering more tracks and<br> listen to all tracks on Hoopr!</p>
            <button type="button" name="button"
                (click)="this.urlRoute('play'); this.router.navigate(['/auth/get-started']);this.closePopUp()"
                class="CTA_button my-4">SIGN UP for free</button>
            <p class="text-center mb-1 m-0 w-100">
                <span class="text-small text-mute">Already have an account? </span><span
                    class="text-small text-underline cursor"
                    (click)="this.urlRoute('play');this.router.navigate(['/auth/get-started']);this.closePopUp()">LOGIN</span>
            </p>

        </div><!-- col-9 -->
        <div class="col-1"></div>
    </div>

</div>
<!-- parent-container -->


<!-- Download a track that is not free -->
<div *ngIf="this.popUpType === 'NOT_LOGGED_DOWNLOAD'">
    <div class="row m-0 justify-content-center mb-3">
        <div class="col-lg-1"></div>
        <div class="col-lg-10 d-flex justify-content-center align-items-center flex-column">
            <div class="w-100">
                <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
            </div>
            <h2 class="popup-title mb-0">Unlock Unlimited Downloads!</h2>
            <p class="text-small text-mute my-4">Download this track & get access to thousands of premium tracks used by top creators and brands!</p>
            <button type="button" (click)="this.urlRoute();this.router.navigate(['/auth/get-started']);this.closePopUp()"
                name="button" class="popup-cta mb-2">Subscribe now</button>
            <p class="text-center mb-1 m-0 w-100">
                <span class="text-small text-mute">Already have an account? </span><span
                    class="text-small text-underline pointer"
                    (click)="this.urlRoute();this.router.navigate(['/auth/get-started']);this.closePopUp()">LOGIN</span>
            </p>
        </div>
        <div class="col-lg-1"></div>
    </div>
</div>
<!-- parent-container modal-2 -->


<!-- Add track to playlist -->
<div *ngIf="this.popUpType === 'NOT_LOGGED_ADD_TO_PLAYLIST'">
    <div class="row m-0 justify-content-center mb-3">
        <h2 class="popup-title mb-0 mt-3">We're glad you want to add this Track!</h2>
        <p class="my-3 w-100">Get Started to add this track to your Playlist. </p>
        <button type="button" name="button"
            (click)="this.urlRoute(this.musicInfo);this.router.navigate(['/auth/get-started']);this.closePopUp()"
            class="btn btn-pink">GET STARTED</button>
    </div>
</div>
<!-- modal-3 -->

<!-- Like a track -->
<div *ngIf="this.popUpType === 'NOT_LOGGED_LIKE'">
    <div class="row m-0 justify-content-center mb-3">
        <h2 class="popup-title mb-0 mt-3">We're glad you want to like this Track!</h2>
        <p class="my-3 w-100">Get Started to add this to your liked tracks collection. </p>
        <button type="button" name="button"
            (click)="this.urlRoute(this.musicInfo);this.router.navigate(['/auth/get-started']);this.closePopUp()"
            class="btn btn-pink">GET STARTED</button>
    </div>
</div>
<!-- modal-4 -->


<!--Request a quote-->
<div *ngIf="this.popUpType === 'NOT_LOGGED_REQUEST_QUOTE'">
    <div class="row m-0 justify-content-center mb-3">
        <h2 class="popup-title mb-0 mt-3">We're glad you want to request this Track!</h2>
        <p class="my-3 w-100">Get started to request quote for this track. </p>
        <button type="button" name="button"
            (click)="this.urlRoute(this.musicInfo);this.router.navigate(['/auth/get-started']);this.closePopUp()"
            class="btn btn-pink">GET STARTED</button>
    </div>
</div>

<div *ngIf="this.popUpType === 'NOT_LOGGED_LIKE_PLAYLIST'">
    <div class="row m-0 justify-content-center mb-3">
        <h2 class="popup-title mb-0">We're glad you want to like this Track!</h2>
        <p class="my-3 w-100">Please sign up to add this to your Playlist. </p>
        <button type="button" name="button"
            (click)="this.urlRoute(this.musicInfo);this.router.navigate(['/auth/get-started']);this.closePopUp()"
            class="btn btn-pink">GET STARTED</button>
    </div>
</div>

<div *ngIf="this.popUpType === 'NOT_LOGGED_SUBSCRIBE_PLAN'">
    <div class="row m-0 justify-content-center mb-3">
        <div class="col-lg-1"></div>
        <div class="col-lg-10 d-flex justify-content-center align-items-center flex-column">
            <div class="w-100">
                <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
            </div>
            <h2 class="popup-title mb-0">We're glad you want to subscribe!</h2>
            <p class="text-small text-mute my-4">Please click the 'Sign Up' button below to continue</p>
            <button type="button" name="button"
            (click)="this.urlRoute();this.router.navigate(['/auth/get-started']);this.closePopUp()"
                class="popup-cta">SIGN UP</button>
            <p class="text-center mt-4 mb-2 m-0 w-100">
                <span class="text-small text-mute">Already have an account? </span><span
                    class="text-small text-underline pointer"
                    (click)="this.urlRoute();this.router.navigate(['/auth/get-started']);this.closePopUp()">LOGIN</span>
            </p>
        </div>
        <div class="col-lg-1"></div>
    </div>
</div>
<!-- modal-6 -->

<!-- modal-7 -->


<!-- Download a free track -->
<div *ngIf="this.popUpType === 'NOT_LOGGED_FREE_DOWNLOAD'">
    <div class="row m-0 justify-content-center mb-3">
        <div class="col-lg-1"></div>
        <div class="col-lg-10 d-flex justify-content-center align-items-center flex-column">
            <div class="w-100">
                <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
            </div>
            <h2 class="popup-title mb-0">Unlock Unlimited Downloads!</h2>
            <p class="text-small text-mute my-4">Download this track & get access to thousands of premium tracks used by top creators and brands!</p>
            <button type="button" (click)="this.urlRoute();this.pushAnalytics();this.router.navigate(['/auth/get-started']);this.closePopUp()"
                name="button" class="popup-cta mb-2">Subscribe now</button>
            <p class="text-center mb-1 m-0 w-100">
                <span class="text-small text-mute">Already have a subscription? </span><span
                    class="text-small text-underline pointer"
                    (click)="this.urlRoute();this.router.navigate(['/auth/get-started']);this.closePopUp()">LOGIN</span>
            </p>
        </div>
        <div class="col-lg-1"></div>
    </div>
</div>
<!-- modal-8 -->


<!-- hoopr-artist-form popup -->
<div *ngIf="this.popUpType === 'HOOPR_ARTIST_FORM_SUCCESS'">
    <div class="row m-0 justify-content-center mb-3">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <div class="w-100">
                <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="hoopr-logo">
            </div>
            <h2 class="popup-title mb-0">Glad you want to explore sync with Hoopr</h2>
            <p class="text-normal text-mute my-4 w-100">We have received your details. Our team will connect with you
                soon.</p>
            <div class="d-flex justify-content-center mb-4">
                <button type="button" name="button" (click)="this.router.navigate(['/']);this.closePopUp();"
                    class="popup-cta">DISCOVER HOOPR</button>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>
</div>

<!-- referral-code -->
<div *ngIf="this.popUpType === 'REFERRAL_POPUP'">
    <div class="row m-0 justify-content-center mb-3">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <div class="w-100">
                <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="hoopr-logo">
            </div>
            <h2 class="popup-title mb-0">You have used {{this.trackInfo.username}}'s referral link.</h2>
            <p class="text-normal text-mute w-100 mt-3">If you Signup as a Individual content creator and Subscribe
                within 3 days you can get:</p>
            <ul class="text-normal text-mute mb-4 w-100">
                <li>1 month free on monthly subscription</li>
                <!-- <li>1 month free on quarterly subscription</li> -->
                <!-- <li>2 month free on half yearly subscription</li> -->
                <li>3 months free on yearly subscription</li>
            </ul>
            <div class="d-flex justify-content-center mb-4">
                <button type="button" name="button" (click)="this.router.navigate(['/auth/get-started']);this.closePopUp();"
                    class="popup-cta">SIGN UP</button>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>
</div>

<div *ngIf="this.popUpType === 'REFERRAL_POPUP_ERROR'">
    <div class="row m-0 justify-content-center mb-3">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <div class="w-100">
                <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="hoopr-logo">
            </div>
            <h2 class="popup-title mb-0">You have used invalid referral link.</h2>
            <p class="text-normal text-mute my-4 w-100">please user a valid referral link before signup to avail
                referral benefits.</p>
        </div>
        <div class="col-md-1"></div>
    </div>
</div>

<!-- IFP India Film Project Promotional 2 month free susbcription Offer-->
<div *ngIf="this.popUpType === 'IFP'">
    <div class="row m-0 justify-content-center mb-3">
        <div class="w-100">
            <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="hoopr-logo">
        </div>
        <h2 class="popup-title mb-0">Welcome creators!</h2>
        <p class="text-normal text-mute my-4 w-100">You're just one step away from unlocking the free 2 months subscription </p>
        <button type="button" name="button" (click)="this.router.navigate(['/auth/get-started']);this.closePopUp();"
            class="popup-cta">SIGN UP NOW</button>
    </div>
</div>

<div *ngIf="this.popUpType === 'NOT_LOOGED_ADD_TRACK_TO_CART'">
    <div class="row m-0 justify-content-center mb-3">
        <div class="w-100">
            <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
        </div>
        <h2 class="popup-title mb-0">We're glad you want to purchase this Track!</h2>
        <p class="text-normal text-mute my-4 w-100">Please sign up to add this track to Cart </p>
        <button type="button" name="button"
            (click)="this.urlRoute(this.musicInfo);this.router.navigate(['/auth/get-started']);this.closePopUp()"
            class="popup-cta">SIGN UP for free</button>
        <p class="text-center mb-1 m-0 w-100">
            <span class="text-small text-mute">Already have an account? </span><span
                class="text-small text-underline pointer"
                (click)="this.urlRoute('like');this.router.navigate(['/auth/get-started']);this.closePopUp()">LOGIN</span>
        </p>
    </div>
</div>

<!-- <div *ngIf="this.popUpType === 'FILMMAKER_FORM_SUBMITTED'">
    <div class="row m-0 justify-content-center mb-3">
        <div class="w-100">
            <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
        </div>
        <h2 class="popup-title mb-0">Thanks for sharing your details !</h2>
        <p class="text-normal text-mute my-4 w-100">We want to support filmmakers such as you and we’ll be in touch with you soon.</p>
        <button type="button" name="button"
            (click)="this.closePopUp()"
            class="popup-cta">OK</button>
    </div>
</div> -->