import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-home-popular',
  templateUrl: './home-popular.component.html',
  styleUrls: ['./home-popular.component.scss']
})
export class HomePopularComponent {
  @Input() popularData:any;
  constructor() { }

}
