<ng-container *ngIf="this.breadcrumbs && this.breadcrumbs.length > 0">
	<nav aria-label="breadcrumb" class="page-breadcumb">
		<ol class="breadcrumb">
			<ng-container *ngFor="let breadcrumb of this.breadcrumbs; let last = last">
				<ng-container *ngIf="!last; else lastBreadcrumb">
					<li class="breadcrumb-item"><a [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a></li>
				</ng-container>
				<ng-template #lastBreadcrumb>
					<li class="breadcrumb-item active" aria-current="page">{{ breadcrumb.label }}</li>
				</ng-template>
			</ng-container>
		</ol>
	</nav>
</ng-container>