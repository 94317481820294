<app-header></app-header>
<div class="container trending">
    <h1 class="trending-title ">Popular&nbsp;<img src="/assets/images/star-black.svg" class="star">

    </h1>
    <p class="col-md-8 col-lg-6 col-12 p-0">Here goes some copy that matches the length of the body text below the
        heading here. It even spans longer, like
        this.</p>

    <!--Tracks-->
    <div class="py-5">
        <h2 class="title">Tracks</h2>
        <div class="row">
            <div class="col-md-4 col-12 py-3" *ngFor="let track of this.tracks | slice:0:12">
                <app-track-block [musicInfo]="track" [size]="'small'"></app-track-block>
            </div>

        </div>
    </div>
    <!--Tracks-->

    <!--Movies-->
    <div class="py-5">
        <h2 class="title">Movies</h2>
        <app-grid [data]="this.movies | slice:0:12" [items]="6" [mobileItems]="2" [assetType]="'Movies'" [labelColor]="'black'"></app-grid>
    </div>
    <!--Movies-->

    <!--Playlist-->
    <div class="py-5">
        <h2 class="title">Playlists</h2>
        <app-grid [data]="this.playlists" [items]="6" [mobileItems]="2" [assetType]="'Playlists'"  [labelColor]="'black'"></app-grid>
    </div>
    <!--Playlist-->

    <!--Artist-->
    <div class="py-5">
        <h2 class="title">Artists</h2>
        <app-grid [data]="this.artists | slice:0:12" [items]="6" [mobileItems]="2" [assetType]="'Artist'"></app-grid>
    </div>
    <!--Artist-->

</div>