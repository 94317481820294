import { Component, OnChanges, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
declare var window: any


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  public breadcrumbs: Breadcrumb[];
  constructor(
    private router: Router,
  ) { 
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.breadcrumbs = this.createBreadcrumbs(window.location.pathname);
      }
    })
  }

  ngOnInit(): void {
    this.breadcrumbs = this.createBreadcrumbs(window.location.pathname);
  }

  createBreadcrumbs(path: string): Breadcrumb[] {
    const pathParts = path.split('/').filter(part => part !== '');

    let resultObject: Breadcrumb[] = [];
    let accumulatedPath = '';
  
    for (let i = 0; i < pathParts.length; i++) {
      const currentPart = pathParts[i];
      accumulatedPath += `/${currentPart}`;
      resultObject.push({
        label: (currentPart.charAt(0).toUpperCase() + currentPart.slice(1)).replaceAll('-', ' '),
        url: accumulatedPath
      });
    }
  
    return resultObject;
  }
}

interface Breadcrumb {
  label: string;
  url: string;
}
