<div *ngIf="assetType && assetType.length > 0 && items && mobileItems && data && data.length > 0" class="row" 
    [ngClass]="[mobileItems ? 'row-cols-' + mobileItems : 'row-cols-2', items ?'row-cols-md-' + items : 'row-cols-md-3']" 
    >
    <ng-container *ngFor="let arr of this.data">
        <div class="col">
            <app-genre-frame [data]="arr" *ngIf="assetType === 'Genres'" [showLabel]="true">
            </app-genre-frame>

            <app-playlist-frame [data]="arr" *ngIf="assetType === 'Playlists'" [showLabel]="true" [labelColor]="this.labelColor">
            </app-playlist-frame>

            <app-movie-frame [data]="arr" *ngIf="assetType === 'Movies'" [showLabel]="true" [labelColor]="this.labelColor">
            </app-movie-frame>

            <app-artist-frame [data]="arr" *ngIf="assetType === 'Artist'" [showLabel]="true" [labelColor]="this.labelColor" >
            </app-artist-frame>

            <app-language-frame [data]="arr" *ngIf="assetType === 'Language' || assetType === 'Moods'" [showLabel]="true" [labelColor]="this.labelColor">
            </app-language-frame>
        </div>
    </ng-container>
</div>