import { Component, OnInit , Input } from '@angular/core';

@Component({
  selector: 'app-feature-category-grid',
  templateUrl: './feature-category-grid.component.html',
  styleUrls: ['./feature-category-grid.component.scss']
})
export class FeatureCategoryGridComponent implements OnInit {
  @Input() feature: string;


  constructor() { }

  ngOnInit(): void {
  }

}
