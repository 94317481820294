import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-data-deletion',
  templateUrl: './data-deletion.component.html',
  styleUrls: ['./data-deletion.component.scss']
})
export class DataDeletionComponent implements OnInit {


  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }


  ngOnInit() {
    this.titleService.setTitle("Data Deletion | Hoopr - India ka Sound");
    
    this.metaTagService.updateTag(
      { name: 'description', content: 'Instruction on how to remove your data from Hoopr' },
    );
  }

}
