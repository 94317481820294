import { Component, HostListener, ViewChild, Inject, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd, NavigationCancel, Event as NavigationEvent } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import {BackendApiService} from '../services/hoopr.services';

@Component({
  templateUrl: './pageNotFound.component.html',
  styleUrls: ['./pageNotFound.scss']
})
export class pageNotFoundComponent {
  constructor(private router:Router,private formBuilder: FormBuilder, private apiService:BackendApiService){}

  ngOnInit() { }

  onClickDiscover(){
    this.router.navigate(['/'])
  }

}
