import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-artist-frame',
  templateUrl: './artist-frame.component.html',
  styleUrls: ['./artist-frame.component.scss']
})
export class ArtistFrameComponent {
 
  @Input() data: any;
  @Input() labelColor:string;
  @Input() showLabel: boolean;

  constructor() { }

}
