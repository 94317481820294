<div class="hoopr_elastic_search d-flex px-3 py-4">
  <div class="search_box search-box d-flex m-0">
    <div class="row m-0 w-100">
      <div class="col p-0">
        <div class="row m-0 search-input-container">
          <div class="badge-container">
            <div class="badge-outline badge-outline-action mr-3 cursor"
              *ngFor="let chip of this.searchHelper.searchChips; index as i" (click)="this.removeFilter(i)">
              <p class="m-0">
                {{chip.type | titlecase}}: {{chip.name}}
                <span (click)="removeFilter(filter)" class="remove-filter">
                  <img src="/assets/images/close.svg" class="close-icon" alt="">
                </span>
              </p>
            </div>
          </div>
          <!-- keyup.shift.enter is for apple mobile keyboard -->
          <input type="text" name="" value="" id="feed_search" class="col py-2 pr-0 search-input"
            placeholder="Search songs, moods, languages or any other keyword" (keyup.enter)="this.getSearchResults();"
            (keyup.shift.enter)="this.getSearchResults();" [(ngModel)]="this.searchText" autocomplete="off" />
        </div>
      </div>
    </div><!-- row -->
  </div>
</div>