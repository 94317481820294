import { BackendApiService } from './../../services/hoopr.services';
import { Router } from '@angular/router';
import { PopupService } from './../../services/popup.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/services/utils.service';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  FormControl,
} from '@angular/forms';
import { countries } from '../../../country-data-store';
import { AbstractControl, ValidatorFn } from '@angular/forms';

export function multipleOfTenValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (value % 10 !== 0) {
      return { 'notMultipleOfTen': { value: value } };
    }
    return null;
  };
}

@Component({
  selector: 'app-subscribed',
  templateUrl: './subscribed.component.html',
  styleUrls: ['./subscribed.component.scss']
})
export class SubscribedComponent implements OnInit {

  public popUpType = undefined;
  public userDetails = undefined;
  public trackInfo: any = {};
  public limitTotal: any;
  socialPopupForm: FormGroup;
  socialPopupFormData: any;
  socialPopupError: boolean = false;
  primaryArtist: string;

  public countries: any = countries;
  selectedCountry: any;
  showcountry = false;

  // referral user data input from
  referralAwardForm: FormGroup;
  formErrors: {
    [key: string]: Object,
  };
  validationMessages: {
    [key: string]: Object,
  };
  submittedData: any;

  // referral coins input form
  claimPointsForm: FormGroup;
  claimFormErrors: {
    [key: string]: Object,
  };
  productSku: string;
  public redeemNowLoader: boolean;

  constructor(
    private popupService: PopupService,
    private formBuilder: FormBuilder,
    public router: Router,
    private apiService: BackendApiService,
    public utilsService: UtilsService
  ) {

    this.submittedData = {};
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneNumberRegex = /^\d{9,15}$/

    this.referralAwardForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(emailRegex)]],
      PhoneNumber: ['', [Validators.required, Validators.pattern(phoneNumberRegex)]],
      PhoneCode: ['+91', [Validators.required]]
    });

    this.claimPointsForm = this.formBuilder.group({
      numberInput: ['100', [Validators.required, multipleOfTenValidator(), Validators.min(100), Validators.max(1000)]]
    });

    this.formErrors = {
      email: '',
      PhoneNumber: '',
    }

    this.claimFormErrors = { numberInput: '' }

    this.validationMessages = {
      email: {
        required: 'We need the correct email to send your rewards.',
        pattern: 'Please enter a valid email id',
      },
      PhoneNumber: {
        pattern: 'Enter a valid phone number',
        required: 'Phone No. required',
      },
      numberInput: {
        required: 'Enter Redemption Amount',
        min: 'Enter Minimum 100 coins, in multiple of 10',
        max: 'Max only 1000 coins allowed in single redemption',
        notMultipleOfTen: 'Enter Minimum 100 coins, in multiple of 10'
      }
    }
  }

  ngOnInit(): void {
    this.popupService.popUpType.subscribe((popUpType) => {
      this.userDetails = this.apiService.getUserData();
      this.popUpType = popUpType;
    });
    this.popupService.trackInfo.subscribe(trackInfo => {
      this.trackInfo = trackInfo;
      this.primaryArtist = this.trackInfo?.tracksArtistRole?.find(i => i.isPrimary == true).artist || this.trackInfo?.tracks_artist_role?.find(i => i.isPrimary == true).artist || this.trackInfo?.artists?.find(artist => artist.ArtistTrackRoleMappingModel.isPrimary == true) || this.trackInfo?.artists && this.trackInfo?.artists && this.trackInfo?.artists[0];
    })

    this.socialPopupForm = this.formBuilder.group({
      Youtube: [''],
      Facebook: [''],
      Instagram: [''],
    });
  }
  closePopUp() {
    this.popupService.popUpType.next(undefined);
  }

  socialPopupSubmit() {
    this.socialPopupFormData = {
      youtube: this.socialPopupForm?.value?.Youtube?.trim() || "",
      facebook: this.socialPopupForm?.value?.Facebook?.trim() || "",
      instagram: this.socialPopupForm?.value?.Instagram?.trim() || "",
    }

    if (!(this.socialPopupFormData.youtube.length > 0) && !(this.socialPopupFormData.facebook.length > 0) && !(this.socialPopupFormData.instagram.length > 0)) {
      this.socialPopupError = true;
      setTimeout(() => {
        this.socialPopupError = false;
      }, 7000)
      return
    }

    this.apiService.addSocialMediaLinks(this.socialPopupFormData).subscribe((res: any) => {
      // this.successMsg.social = res?.data?.message || 'Saved';
      this.apiService.setUserDetails().subscribe((userData) => {
        this.popupService.showPopUp("DOWNLOAD_FORMAT_POPUP", this.trackInfo);
      }, err => {
        console.log('error updating user details', err);
      });
    }, error => {
      // this.errorMessage.socialError = error?.data?.additionalInfo?.message || "Error"
    });

  }


  addLifetimeLictoCart() {
    let reqObj = {
      itemType: "license",
      licenseType: "standard lifetime",
      trackId: this.trackInfo?.id,
    };
    this.apiService.apiAddtoCart(reqObj).subscribe(
      (res: any) => {
        if (res?.info.status == "201") {
          this.closePopUp();
          this.router.navigateByUrl("/user/mycart");
        }
      },
      (err) => {
        console.log("Error found while adding item to cart", err);
      }
    );
  }

  redirectRoute() {
    const redirectlocation = localStorage.getItem('redirectUrl') ? localStorage.getItem('redirectUrl') : '/'
    this.router.navigate([redirectlocation])
    localStorage.removeItem('redirectUrl')
  }

  downloadAfterLimitCheck() {
    this.popupService.showPopUp("DOWNLOAD_FORMAT_POPUP", this.trackInfo)
  }

  redirectToBusinessPricing(): void {
    this.router.navigate(['/pricing/businesses-and-brands']);
  }

  viewPlansCtaRedirect(): void {
    this.closePopUp();
    this.router.navigate(['/pricing/individual'], { queryParams: { validity: this.userDetails.subscription.subscriptionPlan?.validityType || 'annual' } })
  }

  redeemNow(sku: string): void {

    this.productSku = sku;

    if (!this.claimPointsForm.valid) {
      if (!this.claimPointsForm) { return; }
      const form = this.claimPointsForm;
      for (const field in this.claimFormErrors) {
        this.claimFormErrors[field] = '';
        const control = form.get(field);
        if (control && !control.valid) {
          const messages: { [key: string]: any } = this.validationMessages[field];
          this.claimFormErrors[field] += messages[Object.keys(control.errors)[0]] + ' ';
        }
      }
    }

    if (this.claimPointsForm.valid) {
      this.popupService.showPopUp("REDEEM_DETAIL_POPUP");
    }
  }

  onPhoneCodeSelect(event) {
    this.showcountry = !this.showcountry;
    this.selectedCountry = countries.find(country => country.number === event?.target?.value)?.name//event?.target?.value?.name;
    this.referralAwardForm.get('PhoneCode').setValue(event?.target?.value);
  }

  onRedeemFormSubmit() {
    if (!this.referralAwardForm.valid) {
      if (!this.referralAwardForm) { return; }
      const form = this.referralAwardForm;
      for (const field in this.formErrors) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && !control.valid) {
          const messages: { [key: string]: any } = this.validationMessages[field];
          for (const key in control.errors) {
            this.formErrors[field] += messages[key] + ' ';
          }
        }
      }
    }
    if (this.referralAwardForm.valid) {
      this.submittedData = {
        email: this.referralAwardForm.value.email?.toLowerCase(),
        phoneNumber: this.referralAwardForm.value.PhoneNumber,
        phoneCode: this.referralAwardForm.value.PhoneCode,
        amountRedeemed: this.claimPointsForm.value.numberInput,
        SKU: this.productSku,
      };

      this.redeemNowLoader = true;
      this.apiService.redeemReward(this.submittedData).subscribe((res) => {
        this.closePopUp();
        this.redeemNowLoader = false;
        if (res.info.status == '200') {
          this.popupService.showPopUp("SUCCESSFULL_REDEMPTION");
        } else if (res.info.status == '201') {
          this.popupService.showPopUp("PROCESSING_REDEMPTION");
        } else {
          this.popupService.showPopUp("FAILED_REDEMPTION");
        }
      }, (err: any) => {
        this.redeemNowLoader = false;
        if (err.error.data.type === 'TimeoutException') {
          this.popupService.showPopUp("PROCESSING_REDEMPTION");
        } else {
          this.popupService.showPopUp("FAILED_REDEMPTION");
        }
      })
    }
  }

  get numberInput() {
    return this.claimPointsForm.get('numberInput');
  }

}
