<app-header></app-header>
<div>
  <h1 class="heading py-3 px-3 px-md-0 ">Browse our music by playlists</h1>
  <app-popular [items]="'3.4'" [mobileItems]="'1.4'" [popular]="'Playlists'" [popularItems]="this.popularPlaylists"></app-popular>
  <div class="container" >
    <div>
      <div class="row py-5">
        <div *ngIf="this.trendingPlaylists" class="col-md-6 ">
          <app-trending [data]="this.trendingPlaylists" [showLabel]="true"></app-trending>
        </div>
        <div *ngIf="this.featuredCategory1" class="col-md-6">
          <div class="h-100">
            <div class="d-flex justify-content-between">
                <h3 class="text-left title">Featured Category </h3>
                <div class="text-right d-flex  align-self-center">
                  <a class="view-all-text " routerLink="/artists">View all<i
                      class="ph ph-caret-right"></i>
                  </a>
                </div>
                  </div>
            <app-grid [data]="this.featuredCategory1" [assetType]="'Playlists'" [items]="3" [mobileItems]="2" [labelColor]="'black'"></app-grid>
          </div>
        </div>
      </div>
    </div>
    <div  *ngIf="this.featuredCategory2" class="d-flex justify-content-between">
      <h3 class="text-left title">Featured Category</h3>
      <div class="text-right d-flex  align-self-center">
        <a class="view-all-text " routerLink="/artists">View all<i
            class="ph ph-caret-right"></i>
        </a>
      </div>
</div>
    <app-slider [items]="'6'" [mobileItems]="'2.3'" [sliderItems]="this.featuredCategory2" [popular]="'Playlists'" [showLabel]="true" [labelColor]="'black'"></app-slider>
  </div>
</div>
