<app-header></app-header>
<div class="search">
  <div class="global-search-header">
    <div class="search-box-container">
      <app-search-box [prefillSearchText]="this.prefillSearchText" [searchType]="this.searchPageSection">
      </app-search-box>
      
    </div><!-- container -->
  </div><!-- global-search-header -->
  <div class="d-flex justify-content-md-center px-3 py-3 search-tab-container">
    <div class="search-tab cursor px-2" [ngClass]="this.searchPageSection === 'all' ? 'active': ''"
      (click)="redirectToSection('all', this.prefillSearchText);">
      <p class="m-0 ">All</p>
    </div>
    <div class="search-tab cursor " [ngClass]="this.searchPageSection === 'music' ? 'active': ''"
      (click)="redirectToSection('music', this.prefillSearchText)">
      <p class="m-0 ">Tracks</p>
    </div>
    <div class="search-tab cursor pl-1 pr-2 px-lg-3"
      [ngClass]="this.searchPageSection === 'playlist' ? 'active': ''"
      (click)="redirectToSection('playlist', this.prefillSearchText)">
      <p class="m-0 ">Playlists</p>
    </div>
    <div class="search-tab cursor pl-1 pr-2 px-lg-3" [ngClass]="this.searchPageSection === 'artist' ? 'active': ''"
      (click)="redirectToSection('artist', this.prefillSearchText)">
      <p class="m-0 ">Artists</p>
    </div>
    <div class="search-tab cursor pl-1 pr-2 px-lg-3" [ngClass]="this.searchPageSection === 'genre' ? 'active': ''"
      (click)="redirectToSection('genre', this.prefillSearchText)">
      <p class="m-0 ">Genres</p>
    </div>
    <div class="search-tab cursor pl-1 pr-2 px-lg-3 border-0" [ngClass]="this.searchPageSection === 'movie' ? 'active': ''"
      (click)="redirectToSection('movie', this.prefillSearchText)">
      <p class="m-0 ">Movies</p>
    </div>
  </div>
  <div class="container align-items-center">
    <div  *ngIf="this.searchPageSection === 'music' || this.searchPageSection === 'playlist'">
      <div class="  search-content-container filter-section  ">
        <app-global-filter [filterTabItems]='this.filterTabItems'></app-global-filter>
      </div>
    </div><!-- row -->
    <ng-container *ngFor="let result of this.searchResults" >
      <div infiniteScroll [infiniteScrollDistance]="2" infiniteScrollThrottle="50" (scrolled)="this.loadMoreData()" >

      <!-- Tracks -->
      <ng-container *ngIf="result['asset-type'] === 'music'" >
        <h1 class="search-heading pt-5">Tracks<span>({{result.data.length}})</span></h1>
        <app-tracks-list [tracks]="result.data" [size]="'large'"></app-tracks-list>
      </ng-container>
      <!-- Playlists -->
      <ng-container *ngIf="result['asset-type'] === 'playlist'">
        <h1 class="search-heading pt-5">Playlists<span>({{result.data.length}})</span></h1>
        <app-grid [data]="result.data" [assetType]="'Playlists'" [items]="'6'" [labelColor]="'black'" [mobileItems]="'2'"></app-grid>
      </ng-container>
      <!-- Artists -->
      <ng-container *ngIf="result['asset-type'] === 'artist'" class="py-4">
        <h1 class="search-heading pt-5">Artists<span>({{result.data.length}})</span></h1>
        <app-grid [data]="result.data" [assetType]="'Artist'" [items]="'6'" [labelColor]="'black'" [mobileItems]="'2'"></app-grid>
      </ng-container>
      <!-- Genres -->
      <ng-container *ngIf="result['asset-type'] === 'genre'" class="py-4">
        <h1 class="search-heading pt-5">Genres<span>({{result.data.length}})</span></h1>
        <app-grid [data]="result.data" [assetType]="'Genres'" [items]="'3'" [labelColor]="'black'" [mobileItems]="'1.4'"></app-grid>
      </ng-container>
      <!-- Movies -->
      <ng-container *ngIf="result['asset-type'] === 'movie'" class="py-4">
        <h1 class="search-heading pt-5">Movies<span>({{result.data.length}})</span></h1>
        <app-grid [data]="result.data" [assetType]="'Movies'" [items]="'6'" [labelColor]="'black'" [mobileItems]="'2'"></app-grid>
      </ng-container>
    </div>
    </ng-container>
  </div><!-- container -->
</div><!-- search -->


<ng-container *ngIf="this.searchResults?.length == 0">
  <div class="row mx-0 w-100 justify-content-center no-result-found">
    <div class="col-md-10 col-lg-9">
      <div class="row mx-0 justify-content-center">
        <div class="col d-flex justify-content-center">
          <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
        </div>
        <h1 class="text-center w-100 my-3 sub-title">
          Oops! Looks like we don't have an exact
          match for your search
        </h1>
        <div class="popular-search-card">
          <p class="text-normal text-center">Try some of our most popular searches instead:</p>
          <div class="row m-0 justify-content-center">
            <div class="col-auto m-1 popular-badge cursor" (click)="this.searchPillClick('Vlogging')">
              <p class="text-sm mb-0">Vlogging</p>
            </div>
            <div class="col-auto m-1 popular-badge cursor" (click)="this.searchPillClick('Happy')">
              <p class="text-sm mb-0">Happy</p>
            </div>
            <div class="col-auto m-1 popular-badge cursor" (click)="this.searchPillClick('Cinematic')">
              <p class="text-sm mb-0">Cinematic</p>
            </div>
            <div class="col-auto m-1 popular-badge cursor" (click)="this.searchPillClick('Comedy')">
              <p class="text-sm mb-0">Comedy</p>
            </div>
            <div class="col-auto m-1 popular-badge cursor" (click)="this.searchPillClick('Sad')">
              <p class="text-sm mb-0">Sad</p>
            </div>
            <div class="col-auto m-1 popular-badge cursor" (click)="this.searchPillClick('Bollywood')">
              <p class="text-sm mb-0">Bollywood</p>
            </div>
            <div class="col-auto m-1 popular-badge cursor" (click)="this.searchPillClick('Poetry')">
              <p class="text-sm mb-0">Poetry</p>
            </div>
            <div class="col-auto m-1 popular-badge cursor" (click)="this.searchPillClick('Podcasts')">
              <p class="text-sm mb-0">Podcasts</p>
            </div>
          </div>

        </div>
      </div><!-- row -->
    </div><!-- col-md-10 -->
  </div><!-- row -->
</ng-container>


<div class="modal fade hoopr_popup  " id="collapseFilter" tabindex="-1" role="dialog"
  aria-labelledby="collapseFilterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="background: #222222;">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body filter-modal-body">
        <app-global-filter [filterTabItems]='this.filterTabItems' class="filter-container"></app-global-filter>
      </div>
    </div>
  </div>
</div>


<!-- <footer></footer> -->