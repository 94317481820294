import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoggedInComponent } from './logged-in/logged-in.component';
import { AnonymousComponent } from './anonymous/anonymous.component';
import { SubscribedComponent } from './subscribed/subscribed.component';
import { PopupComponent } from './popup.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SwiperModule } from 'swiper/angular';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

@NgModule({
  declarations: [
    LoggedInComponent,
    AnonymousComponent,
    SubscribedComponent,
    PopupComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SwiperModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
  ],
  exports: [
    CommonModule,
    LoggedInComponent,
    AnonymousComponent,
    SubscribedComponent,
    PopupComponent,
    SwiperModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PopupModule { }
