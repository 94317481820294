import { Component, OnInit , Input } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  @Input() popular: string;
  @Input() pagination: boolean = false;
  @Input() navigation: boolean = false;
  @Input() showLabel: boolean;
  @Input() labelColor:string;
  @Input() sliderItems: any;
  @Input() items: number;
  @Input() mobileItems: number;

  Playlists : SwiperOptions = {
    slidesPerView: 3.5,
    spaceBetween: 50,
    navigation: true,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
    breakpoints: {
      320: {
        slidesPerView: 1.1,
        spaceBetween: 10
      },
      425: {
        slidesPerView: 1.2,
        spaceBetween: 30
      },
      728:{
        slidesPerView: 2.5,
        spaceBetween: 30
      },
      1024: {
        slidesPerView: 2.5,
        spaceBetween: 50
      },
      1440: {
        slidesPerView: 3.5,
        spaceBetween: 50
      }
    }
  };

  Genres : SwiperOptions = {
    slidesPerView: 1.5,
    spaceBetween: 50,
    navigation: true,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
    breakpoints: {
      320: {
        slidesPerView: 1.5  ,
        spaceBetween: 30
      },
      425: {
        slidesPerView: 1.5,
        spaceBetween: 20
      },
      1024: {
        slidesPerView: 1.5,
        spaceBetween: 50
      },
      1440: {
        slidesPerView: 3.5,
        spaceBetween: 50
      }
    }
  };
  
  Movies : SwiperOptions = {
    slidesPerView: 3.5,
    spaceBetween: 50,
    navigation: true,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
    breakpoints: {
      320: {
        slidesPerView: 1.5,
        spaceBetween: 30
      },
      425: {
        slidesPerView: 1.5,
        spaceBetween: 30
      },
      1024: {
        slidesPerView: 3.5,
        spaceBetween: 30
      },
      1440: {
        slidesPerView: 3.5,
        spaceBetween: 50
      }
    }
  };


  Artist : SwiperOptions = {
    slidesPerView: 3.5,
    spaceBetween: 50,
    navigation: true,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
    breakpoints: {
      320: {
        slidesPerView: 1.5,
        spaceBetween: 30
      },
      425: {
        slidesPerView: 1.5,
        spaceBetween: 30
      },
      1024: {
        slidesPerView: 3.5,
        spaceBetween: 30
      },
      1440: {
        slidesPerView: 3.5,
        spaceBetween: 50
      }
    }
  };
  Moods : SwiperOptions = {
    slidesPerView: 3.5,
    spaceBetween: 50,
    navigation: true,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
    breakpoints: {
      320: {
        slidesPerView: 1.1,
        spaceBetween: 10
      },
      425: {
        slidesPerView: 1.2,
        spaceBetween: 30
      },
      728:{
        slidesPerView: 2.5,
        spaceBetween: 30
      },
      1024: {
        slidesPerView: 2.5,
        spaceBetween: 50
      },
      1440: {
        slidesPerView: 3.5,
        spaceBetween: 50
      }
    }
  };
  Language : SwiperOptions = {
    slidesPerView: 3.5,
    spaceBetween: 50,
    navigation: true,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
    breakpoints: {
      320: {
        slidesPerView: 1.1,
        spaceBetween: 10
      },
      425: {
        slidesPerView: 1.2,
        spaceBetween: 30
      },
      728:{
        slidesPerView: 2.5,
        spaceBetween: 30
      },
      1024: {
        slidesPerView: 2.5,
        spaceBetween: 50
      },
      1440: {
        slidesPerView: 3.5,
        spaceBetween: 50
      }
    }
  };

  constructor() { }

  ngOnInit(): void {
    //
    this[`${this.popular}`].breakpoints[1440].slidesPerView = this.items ? this.items : 5; 
    this[`${this.popular}`].breakpoints[1024].slidesPerView = this.items ? this.items : 5;
    this[`${this.popular}`].breakpoints[425].slidesPerView = this.mobileItems ? this.mobileItems : 2.3;
    this[`${this.popular}`].breakpoints[320].slidesPerView = this.mobileItems ? this.mobileItems : 2.3;
  }
  
}
