import { Component, Input, OnInit } from '@angular/core';
import { BackendApiService } from '../services/hoopr.services';
import 'swiper/scss/pagination';
import 'swiper/scss';
import SwiperCore, { Pagination, Navigation, FreeMode, Autoplay, SwiperOptions } from "swiper";
import { Router } from '@angular/router';

SwiperCore.use([Pagination, Navigation, Autoplay, FreeMode]);


@Component({
  selector: 'app-genres',
  templateUrl: './genres.component.html',
  styleUrls: ['./genres.component.scss']
})
export class GenresComponent implements OnInit {

  @Input() data: any;

  public popularGenres: any;
  public trendingGenres: any = [];
  public featuredCategory1: any;
  public featuredCategory2: any;
  public feedData: any;
  public searchQuery: string = '';


  constructor(
    private apiService: BackendApiService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.apiService.getGenreListFeed().subscribe(
      (response) => {
        this.feedData = response;
        this.popularGenres = this.feedData['popular'];
        this.trendingGenres = this.feedData['trending'].data;
        this.featuredCategory1 = this.feedData['featured-1'].data;
        this.featuredCategory2 = this.feedData['featured-2'].data;
      },
      (err) => {
        console.log('Error while loading feed data', err);
      }
    )

  }
  searchNavigate(){
    this.router.navigate(['/search'], { queryParams: { q: this.searchQuery, t : "genre" } });
  }

}
