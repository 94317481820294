
<!-- for wavesurfer package -->
<div class="track playerLarge" *ngIf="this.size === 'large'" [class.active]="isPlaying">
  <div class="d-flex align-items-center flex-wrap gap-3">
    <div class="track-img">
      <img [src]="this.musicInfo?.imageUrl || this.musicInfo?.image_url"
        onerror="this.src='/assets/images/default/track.webp'" alt="{{this.musicInfo?.name}}" class="trackImg" />
        <div class="badge badge-bottom">
          <img src="/assets/images/popularPlaylist.webp" alt="popular-badge"  class="frame-badge" *ngIf="this.musicInfo?.payload == 'popular'">
          <img src="/assets/images/trendingPlaylist.webp" alt="popular-badge"  class="frame-badge" *ngIf="this.musicInfo?.payload == 'trending'">
          <img src="/assets/images/bothPlaylist.webp" alt="popular-badge"  class="frame-badge-medium" *ngIf="this.musicInfo?.payload == 'popular-trending'">
        </div>
      <div class="stateIcon play align-items-center justify-content-center" (click)="play()">
        <img src="assets/images/play.svg" alt="play" />
      </div><!-- stateicons -->
      <div class="stateIcon pause align-items-center justify-content-center" (click)="pause()">
        <img src="assets/images/pause.svg" alt="pause" />
      </div><!-- stateicons -->
    </div><!-- img -->
    <div class="track-info">
      <h3>
        <a [routerLink]="'/track/' + this.musicInfo?.name_slug">
          {{this.musicInfo?.name}}
        </a>
      </h3>
      <p>
        <span *ngFor="let artist of this.primaryArtists; let isLast = last">
          <a [routerLink]="'/artist/' +  artist?.artist?.name_slug">{{artist?.artist?.name}}</a><ng-container *ngIf="!isLast">,</ng-container>
        </span>
      </p>
    </div><!-- info -->
    <div class="track-moods d-none d-lg-inline">
      <ng-container *ngIf="musicInfo['display_tags'] || musicInfo['displayTags']">
        <span *ngFor="let tag of this.displaytags;let isLast = last" class="cursor p-1 keywords-tag text-mute" (click)="this.redirectToSearch(tag)">
          {{tag}}<span  *ngIf="!isLast">,</span>
        </span>
        <!-- <p class="pointer" [routerLink]="'/search/' + musicInfo['display_tags']">{{musicInfo['display_tags']}}</p> -->
      </ng-container>
    </div><!-- Moods -->
    <div class="track-actions d-flex justify-content-end align-items-center gap-3">
      <p>{{formatDuration(this.musicInfo?.duration)}}</p>
      <button type="button" class="btn btn-act d-none d-lg-inline" data-toggle="tooltip" data-placement="top" title="Share Track"
        (click)="this.shareTrack()">
        <i class="ph ph-share-network"></i>
      </button>
      <button class="btn btn-act d-none d-lg-inline" data-toggle="tooltip" data-placement="top" title="Add to Playlist"
        (click)="this.addToPlaylist()">
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 8.2334C1 6.55749 2.39347 5.19888 4.11239 5.19888H12.4121C14.1311 5.19888 15.5245 6.55749 15.5245 8.2334V16.3255C15.5245 18.0014 14.1311 19.36 12.4121 19.36H4.11239C2.39347 19.36 1 18.0014 1 16.3255V8.2334Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" class="path"/>
          <path d="M8.25496 12.2764V9.24185M8.25496 12.2764V15.3109M8.25496 12.2764H11.3673M8.25496 12.2764H5.14258" stroke="black" stroke-linecap="round" stroke-linejoin="round" class="path"/>
          <path d="M5.14258 1.15063H16.5547C18.2736 1.15063 19.6671 2.50924 19.6671 4.18516V15.3117" stroke="black" stroke-linecap="round" stroke-linejoin="round" class="path"/>
          </svg>
      </button>
      <button class="btn btn-act d-none d-lg-inline" data-toggle="tooltip" data-placement="top" title="Like Favorite"
        (click)="this.likeTrack()">
        <i class="ph ph-heart" [ngClass]="this.likeStatus ?  'ph-fill red-heart' : ''"></i>
      </button>
      <button class="btn btn-black d-none d-lg-inline" (click)="this.requestQuote()"><i class="ph ph-shopping-cart"></i>REQUEST</button>
      <div class="dropdown d-inline-block d-lg-none">
        <button class="btn btn-act dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i
            class="ph ph-dots-three-vertical"></i></button>
        <div class="dropdown-menu dropdown-menu-right px-2">
          <div class="dropdown-item" (click)="this.shareTrack()">
            <i class="ph ph-share-network mr-2"></i>Share Track
          </div>
          <div class="dropdown-item" (click)="this.addToPlaylist()">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
              <path d="M1 8.2334C1 6.55749 2.39347 5.19888 4.11239 5.19888H12.4121C14.1311 5.19888 15.5245 6.55749 15.5245 8.2334V16.3255C15.5245 18.0014 14.1311 19.36 12.4121 19.36H4.11239C2.39347 19.36 1 18.0014 1 16.3255V8.2334Z" stroke="#AAAAAA" stroke-linecap="round" stroke-linejoin="round" class="path"/>
              <path d="M8.25496 12.2764V9.24185M8.25496 12.2764V15.3109M8.25496 12.2764H11.3673M8.25496 12.2764H5.14258" stroke="#AAAAAA" stroke-linecap="round" stroke-linejoin="round" class="path"/>
              <path d="M5.14258 1.15063H16.5547C18.2736 1.15063 19.6671 2.50924 19.6671 4.18516V15.3117" stroke="#AAAAAA" stroke-linecap="round" stroke-linejoin="round" class="path"/>
              </svg>Add to Playlist
            </div>
          <div class="dropdown-item" (click)="this.likeTrack()">
            <i class="ph ph-heart mr-2"></i>Like this song
          </div>
          <button class="btn btn-black w-100 mt-3" (click)="this.requestQuote()">
            <i class="ph ph-shopping-cart"></i>REQUEST
          </button>
        </div>
      </div><!-- dropdown -->
    </div><!-- actions -->
  </div><!-- flex -->
</div><!-- large -->
<!-- ====================================playerMedium====================================== -->
<div class="track playerMedium" *ngIf="this.size === 'medium'" [class.active]="isPlaying">
  <div class="d-flex align-items-center flex-wrap gap-3">
    <div class="track-img">
      <img [src]="this.musicInfo?.imageUrl || this.musicInfo?.image_url"
        onerror="this.src='/assets/images/default/track.webp'" alt="{{this.musicInfo?.name}}" class="trackImg" />
        <div class="badge badge-bottom">
          <img src="/assets/images/popularPlaylist.webp" alt="popular-badge"  class="frame-badge" *ngIf="this.musicInfo?.payload == 'popular'">
          <img src="/assets/images/trendingPlaylist.webp" alt="popular-badge"  class="frame-badge" *ngIf="this.musicInfo?.payload == 'trending'">
          <img src="/assets/images/bothPlaylist.webp" alt="popular-badge"  class="frame-badge-medium" *ngIf="this.musicInfo?.payload == 'popular-trending'">
        </div>
      <div class="stateIcon play align-items-center justify-content-center" (click)="play()">
        <img src="assets/images/play.svg" alt="play" />
      </div><!-- stateicons -->
      <div class="stateIcon pause align-items-center justify-content-center" (click)="pause()">
        <img src="assets/images/pause.svg" alt="pause" />
      </div><!-- stateicons -->
    </div><!-- img -->
    <div class="track-info">
      <h3>
        <a [routerLink]="'/track/' + this.musicInfo?.name_slug">
          {{this.musicInfo?.name}}
        </a>
      </h3>
      <p>
        <span *ngFor="let artist of this.primaryArtists; let isLast = last">
          <a [routerLink]="'/artist/' +  artist?.artist?.name_slug">{{artist?.artist?.name}}</a><ng-container *ngIf="!isLast">,</ng-container>
        </span>
      </p>
    </div><!-- info -->
    <div class="track-actions d-flex justify-content-end align-items-center gap-3">
      <p>{{formatDuration(this.musicInfo?.duration)}}</p>
      <button type="button" class="btn btn-act" data-toggle="tooltip" data-placement="top" title="Share Track"
        (click)="this.shareTrack()">
        <i class="ph ph-share-network"></i></button>
      <button type="button" class="btn btn-act" data-toggle="tooltip" data-placement="top" title="Add to Playlist"
        (click)="this.addToPlaylist()">
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
          <path
            d="M1 8.2334C1 6.55749 2.39347 5.19888 4.11239 5.19888H12.4121C14.1311 5.19888 15.5245 6.55749 15.5245 8.2334V16.3255C15.5245 18.0014 14.1311 19.36 12.4121 19.36H4.11239C2.39347 19.36 1 18.0014 1 16.3255V8.2334Z"
            stroke="#AAAAAA" stroke-linecap="round" stroke-linejoin="round" class="path" />
          <path d="M8.25496 12.2764V9.24185M8.25496 12.2764V15.3109M8.25496 12.2764H11.3673M8.25496 12.2764H5.14258"
            stroke="#AAAAAA" stroke-linecap="round" stroke-linejoin="round" class="path" />
          <path d="M5.14258 1.15063H16.5547C18.2736 1.15063 19.6671 2.50924 19.6671 4.18516V15.3117" stroke="#AAAAAA"
            stroke-linecap="round" stroke-linejoin="round" class="path" />
        </svg>
        </button>
      <button type="button" class="btn btn-act" data-toggle="tooltip" data-placement="top" title="Like Favourite"
        (click)="this.likeTrack()">
        <i class="ph ph-heart" [ngClass]="this.likeStatus ?  'ph-fill red-heart' : ''"></i>
      </button>
      <button class="btn btn-black d-none d-xl-inline" (click)="this.requestQuote()">
        <i class="ph ph-shopping-cart"></i>REQUEST
      </button>
      <div class="dropdown d-inline-block d-xl-none">
        <button class="btn btn-act dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
          <i class="ph ph-dots-three-vertical"></i></button>
        <div class="dropdown-menu dropdown-menu-right px-2">
          <div class="dropdown-item" (click)="this.shareTrack()">
            <i class="ph ph-share-network mr-2"></i>Share Track
          </div>
          <div class="dropdown-item" (click)="this.addToPlaylist()">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
              <path d="M1 8.2334C1 6.55749 2.39347 5.19888 4.11239 5.19888H12.4121C14.1311 5.19888 15.5245 6.55749 15.5245 8.2334V16.3255C15.5245 18.0014 14.1311 19.36 12.4121 19.36H4.11239C2.39347 19.36 1 18.0014 1 16.3255V8.2334Z" stroke="#AAAAAA" stroke-linecap="round" stroke-linejoin="round" class="path"/>
              <path d="M8.25496 12.2764V9.24185M8.25496 12.2764V15.3109M8.25496 12.2764H11.3673M8.25496 12.2764H5.14258" stroke="#AAAAAA" stroke-linecap="round" stroke-linejoin="round" class="path"/>
              <path d="M5.14258 1.15063H16.5547C18.2736 1.15063 19.6671 2.50924 19.6671 4.18516V15.3117" stroke="#AAAAAA" stroke-linecap="round" stroke-linejoin="round" class="path"/>
              </svg>Add to Playlist
          </div>
          <div class="dropdown-item" (click)="this.likeTrack()">
            <i class="ph ph-heart mr-2" [ngClass]="this.likeStatus ?  'ph-fill red-heart' : ''">
            </i>Like this song
          </div>
          <button class="btn btn-black w-100 mt-3" (click)="this.requestQuote()">
            <i class="ph ph-shopping-cart"></i>REQUEST
          </button>
        </div>
      </div><!-- dropdown -->
    </div><!-- actions -->
  </div><!-- flex -->
</div><!-- medium -->

<!-- ================================playerSmall========================================== -->

<div class="track playerSmall" *ngIf="this.size === 'small'" [class.active]="isPlaying">
  <div class="d-flex align-items-center flex-wrap gap-3">
    <div class="track-img">
      <img [src]="this.musicInfo?.imageUrl || this.musicInfo?.image_url"
        onerror="this.src='/assets/images/default/track.webp'" alt="{{this.musicInfo?.name}}" class="trackImg" />
        <div class="badge badge-bottom">
          <img src="/assets/images/popularPlaylist.webp" alt="popular-badge"  class="frame-badge" *ngIf="this.musicInfo?.payload == 'popular'">
          <img src="/assets/images/trendingPlaylist.webp" alt="popular-badge"  class="frame-badge" *ngIf="this.musicInfo?.payload == 'trending'">
          <img src="/assets/images/bothPlaylist.webp" alt="popular-badge"  class="frame-badge-medium" *ngIf="this.musicInfo?.payload == 'popular-trending'">
        </div>
      <div class="stateIcon play align-items-center justify-content-center" (click)="play()">
        <img src="assets/images/play.svg" alt="play" />
      </div><!-- stateicons -->
      <div class="stateIcon pause align-items-center justify-content-center" (click)="pause()">
        <img src="assets/images/pause.svg" alt="pause" />
      </div><!-- stateicons -->
    </div><!-- img -->
    <div class="track-info" [ngClass]="this.labelColor == 'white' ? 'color-white' : ''">
      <h3>
        <a [routerLink]="'/track/' + this.musicInfo?.name_slug" [ngClass]="this.labelColor == 'white' ? 'color-white' : ''">
          {{this.musicInfo?.name}}
        </a>
      </h3>
      <p>
        <span *ngFor="let artist of this.primaryArtists; let isLast = last">
          <a [routerLink]="'/artist/' +  artist?.artist?.name_slug" [ngClass]="this.labelColor == 'white' ? 'color-gray' : ''">{{artist?.artist?.name}}</a><ng-container *ngIf="!isLast">,</ng-container>
        </span>
      </p>
    </div><!-- info -->
    <div class="track-actions d-flex justify-content-end align-items-center gap-3">
      <p [ngClass]="this.labelColor == 'white' ? 'color-white' : ''">{{formatDuration(this.musicInfo?.duration)}}</p>
      <div class="dropdown">
        <button class="btn btn-act dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
          <i class="ph ph-dots-three-vertical" [ngClass]="this.labelColor == 'white' ? 'color-gray' : ''"></i></button>
        <div class="dropdown-menu dropdown-menu-right px-2">
          <div class="dropdown-item" (click)="this.shareTrack()">
            <i class="ph ph-share-network mr-2"></i>Share Track
          </div>
          <div class="dropdown-item" (click)="this.addToPlaylist()">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
              <path d="M1 8.2334C1 6.55749 2.39347 5.19888 4.11239 5.19888H12.4121C14.1311 5.19888 15.5245 6.55749 15.5245 8.2334V16.3255C15.5245 18.0014 14.1311 19.36 12.4121 19.36H4.11239C2.39347 19.36 1 18.0014 1 16.3255V8.2334Z" stroke="#AAAAAA" stroke-linecap="round" stroke-linejoin="round" class="path"/>
              <path d="M8.25496 12.2764V9.24185M8.25496 12.2764V15.3109M8.25496 12.2764H11.3673M8.25496 12.2764H5.14258" stroke="#AAAAAA" stroke-linecap="round" stroke-linejoin="round" class="path"/>
              <path d="M5.14258 1.15063H16.5547C18.2736 1.15063 19.6671 2.50924 19.6671 4.18516V15.3117" stroke="#AAAAAA" stroke-linecap="round" stroke-linejoin="round" class="path"/>
              </svg>Add to Playlist
          </div>
          <div class="dropdown-item" (click)="this.likeTrack()">
            <i class="ph ph-heart mr-2" [ngClass]="this.likeStatus ?  'ph-fill red-heart' : ''">
            </i>Like this song
          </div>
          <button class="btn btn-black w-100 mt-3" (click)="this.requestQuote()">
            <i class="ph ph-shopping-cart"></i>REQUEST
          </button>
        </div>
      </div><!-- dropdown -->
    </div><!-- actions -->
  </div><!-- flex -->
</div><!-- small -->

<!-- ========================================================================== -->

<div class="waveHolder" *ngIf="this.size === 'wave'">
	<div class="container d-flex gap-2 align-items-center">
		<button class="btn btn-white btn-ic btn-circle shadowed" (click)="isPlaying ? pause() : play()">
			<i class="ph-fill" [ngClass]="isPlaying ? 'ph-pause' : 'ph-play'"></i>
		</button>
		<div class="media_player_wave media_player_wave_timing w-100">
			<div class="w-100 d-flex flex-column mx-2" #player></div>
		</div>
		<p class="m-0">{{formatDuration(this.musicInfo?.duration)}}</p>
	</div><!-- padded section -->
</div>


<div class="d-none" #player>
</div>