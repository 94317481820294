<a class="" [routerLink]="'/artist/' + this.data?.name_slug">
  <div class="hoopr-artist-card ">
    <div class="badge badge-bottom-left">
        <img src="/assets/images/Popular.webp" alt="popular-badge"  class="frame-badge-small" *ngIf="this.data?.payload == 'popular'">
        <img src="/assets/images/Trending.webp" alt="popular-badge"  class="frame-badge-small" *ngIf="this.data?.payload == 'trending'">
        <img src="/assets/images/Both.webp" alt="popular-badge"  class="frame-badge" *ngIf="this.data?.payload == 'popular-trending'">


    </div>

    <img [src]="this.data.profileImageUrl || this.data.profile_image_url"
      onerror="this.src='/assets/images/default/track.webp'" [alt]="this.data?.name || 'Hoopr Artist'"
      class="song-artist-image">
    <ng-container *ngIf="showLabel">
      <p class="text-center py-2 name-text" [ngClass]="labelColor === 'black' ? 'text-black' : 'text-white'">
        {{this.data?.name || ''}}</p>
    </ng-container>
  </div>
</a>