declare var Swal: any;

export class SwalHelper {

  public static showToast(title: string, iconHtml: string, html: string) {
    // swal({
    //   toast: false,
    //   position: 'top',
    //   showConfirmButton: false,
    //   timer: 3000,
    //   type,
    //   title: message
    // });
    Swal.fire({
      title: title,
      toast: true,
      position: 'top',
      showConfirmButton: false,
      html: html,
      // buttonsStyling:false,
      showCloseButton: true,
      background: '#F1F1F1',
      color: '#212529',
      iconHtml: iconHtml,
      heightAuto: false,
      timer: 4000,
    })
  }
  public static showPaymentToast(title, text) {
    Swal.fire({
      title: title,
      // toast:true,
      icon: "success",
      text: text,
      position: 'top',
      showConfirmButton: false,
      showCloseButton: true,
      background: '#F1F1F1',
      color: '#FFFFFF',
      // iconHtml:iconHtml,
      // heightAuto:false,
      timer: 4000,
    })

  }
}
