<div class="h-100">
    <div class="d-flex justify-content-between">
        <h3 class="text-left title" *ngIf="feature == 'Playlists'">Featured Category </h3>
        <h3 class="text-left title" *ngIf="feature == 'Artists'">Popular</h3>

        <!-- <a class="text-right view-all-text">View All</a> -->
    </div>
    <div class="row" *ngIf="feature == 'Playlists'">
        <div class=" col-6 col-md-4 col-sm-6 col-xs-12">
            <app-playlist-frame></app-playlist-frame>
        </div>
        <div class=" col-6 col-md-4 col-sm-6 col-xs-12">
            <app-playlist-frame></app-playlist-frame>
        </div>
        <div class=" col-6 col-md-4 col-sm-6 col-xs-12">
            <app-playlist-frame></app-playlist-frame>
        </div>
        <div class=" col-6 col-md-4 col-sm-6 col-xs-12">
            <app-playlist-frame></app-playlist-frame>
        </div>
        <div class=" col-6 col-md-4 col-sm-6 col-xs-12">
            <app-playlist-frame></app-playlist-frame>
        </div>
        <div class=" col-6 col-md-4 col-sm-6 col-xs-12">
            <app-playlist-frame></app-playlist-frame>
        </div>
    </div>
    <div class="row" *ngIf="feature == 'Artists'">
        <div class=" col-6 col-md-4 col-sm-6 col-xs-12">
            <app-artist-frame></app-artist-frame>
        </div>
        <div class=" col-6 col-md-4 col-sm-6 col-xs-12">
            <app-artist-frame></app-artist-frame>
        </div>
        <div class=" col-6 col-md-4 col-sm-6 col-xs-12">
            <app-artist-frame></app-artist-frame>
        </div>
        <div class=" col-6 col-md-4 col-sm-6 col-xs-12">
            <app-artist-frame></app-artist-frame>
        </div>
        <div class=" col-6 col-md-4 col-sm-6 col-xs-12">
            <app-artist-frame></app-artist-frame>
        </div>
        <div class=" col-6 col-md-4 col-sm-6 col-xs-12">
            <app-artist-frame></app-artist-frame>
        </div>
    </div>
</div>