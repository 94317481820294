import { Component, OnInit } from '@angular/core';
import { BackendApiService } from '../services/hoopr.services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-artist',
  templateUrl: './artist.component.html',
  styleUrls: ['./artist.component.scss']
})
export class ArtistComponent implements OnInit {
  public artistBanner: any;
  public topTracks: any;
  public topPlaylists: any;
  public featuredIn: any

  constructor(
    private apiService: BackendApiService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {
      const artistId = params['artistId'];
      this.getArtist(artistId);
    })
    
  }

  private getArtist(artistId: string): void {
    this.apiService.getSingleArtist(artistId).subscribe((data: any) => {
      this.artistBanner = data.banner || null;
      this.topTracks = data.topTracks || null;
      this.topPlaylists = data.topPlaylists || null;
      this.featuredIn = data.featuredIn || null;
    }, (err) => {
      console.log('Error while loading artist data', err);
    });
  }

}
