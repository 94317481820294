import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BackendApiService } from '../services/hoopr.services';
import { ActivatedRoute , Router } from '@angular/router';
import { formatDuration } from "../services/utils";
import { PopupService } from '../services/popup.service';
import { SwalHelper } from '../_helpers/swal-helpers';
import { PlayerService } from '../services/player.service';
import { UserInfoService } from '../services/user-info.service';

@Component({
  selector: 'app-track',
  templateUrl: './track.component.html',
  styleUrls: ['./track.component.scss']
})
export class TrackComponent implements OnInit, AfterViewInit {

  public feedData = undefined;
  public primaryArtists: any;
  public likeStatus = false;
  public userData: any;
  private token: string;
  public formatDuration = formatDuration;

  constructor(
    private apiService: BackendApiService,
    private titleService: Title,
    private metaTagService: Meta,
    private activatedRoute: ActivatedRoute,
    public popUpService: PopupService,
    public playerService: PlayerService,
    private userInfoService: UserInfoService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      const trackId = params.get('trackId');
      this.getTrack(trackId);
    });
    this.userData = this.apiService.getUserData();
  }

  ngAfterViewInit(): void {
    this.playerService.likeUpdate.subscribe(likeObj => {
      // const x = Date.now();
      if (likeObj && this.feedData && likeObj?.id === this.feedData?.id) {
        this.likeStatus = likeObj?.status;
        this.feedData.liked = likeObj?.status;
        this.cd.detectChanges();
      }
    })
  }
  
  public getTrack(trackId: string): void {
    this.apiService.getSingleTrack(trackId).subscribe(
      (response) => {
        this.feedData = response;
        this.primaryArtists = this.feedData?.tracksArtistRole?.filter(i => i.isPrimary == true)
        this.checkTrackLiked();
        // this.trackMeta(this.trackBannerDetails?.data?.name || 'this song', this.trackBannerDetails?.data?.imageUrl)
      },
      (err) => {
        console.log('Error while loading feed data', err);
      }
    );
  }

  private checkTrackLiked(): void {
    this.userData?.likedTracks?.forEach((trackId: string) => {
      if (this.feedData.id === trackId) {
        this.likeStatus = true;
        this.feedData.liked = true;
      }
    })
  }

  public shareTrack(): void {
    this.popUpService.showPopUp('SHARE_SONG', this.feedData);
    // TODO: add analytics
  }

  public likeTrack(): void {
    this.token = this.apiService.getToken();

    if (!this.token) {
      this.popUpService.showPopUp("LIKED_SONG");
      localStorage.setItem('musicInfo', JSON.stringify(this.feedData))
      // this.cd.detectChanges();
      return;
    }

    const originalStatus = (this.likeStatus = !!this.feedData.liked);
    let reqObj = {
      trackId: this.feedData.id,
    };

    this.apiService.consumerlikeTrack(reqObj).subscribe(
      (response: any) => {
        if (this.likeStatus == false) {
          SwalHelper.showToast(
            '<p>Successfully added to your Likes</p>',
            '<i class="ph ph-fill ph-check-circle" style="color:#2C96FF"></i>',
            `<a href='/user/my-collection/my-likes' class='swal-text-link text-normal'>VIEW LIKES</a>`
          );        } else {
          SwalHelper.showToast(
            this.feedData.name + " Track removed from your likes!",
            '<i class="ph ph-fill ph-check-circle" style="color:#2C96FF"></i>',
            "<a href='/user/my-collection/my-likes' class='swal-text-link text-normal'>VIEW LIKES</a>"
          );
        }
        this.apiService.updateLikedTracks(response.data)
        this.likeStatus = !originalStatus;
        this.feedData.liked = this.likeStatus;
        this.playerService.likeUpdate.next({ id: this.feedData.id, status: this.likeStatus })
        let event = this.likeStatus ? "Liked Track" : "Unliked Track";
        // TODO: activate below line
        // this.logger.logTrackEvent(event, this.feedData.id, this.feedData.name);
        // log data to backend

        if (this.feedData.asset_type) {
          if (this.feedData.type === "music") {
            this.feedData.sub_genres = this.feedData.sub_filters
          } else {
            this.feedData.sfx_subcategory = this.feedData.sub_filters
            this.feedData.sfx_category = this.feedData.filters
          }
        }
        else if (this.feedData.filters) {
          const genres = [];
          const moods = [];
          const usecases = [];
          const sub_genres = [];
          const sfx_subcategory = [];
          const sfx_category = [];
          this.feedData?.filters?.forEach(filter => {
            if (filter.type === "genre") {
              genres.push(filter.name)
            } else if (filter.type === "mood") {
              moods.push(filter.name)
            } else if (filter.type === "usecase") {
              usecases.push(filter.name)
            } else if (filter.type === "sfxcategory") {
              sfx_category.push(filter.name)
            }
          })
          this.feedData?.subFilters?.forEach(subfilter => {
            if (subfilter.type === "subgenre") {
              sub_genres.push(subfilter.name)
            } else if (subfilter.type === "subsfxcategory") {
              sfx_subcategory.push(subfilter.name)
            }
          })
          this.feedData.genres = genres;
          this.feedData.moods = moods;
          this.feedData.usecases = usecases;
          this.feedData.sfx_category = sfx_category;
          this.feedData.sub_genres = sub_genres;
          this.feedData.sfx_subcategory = sfx_subcategory;
        }

        if (this.feedData?.keywords && this.feedData?.keywords[0] && typeof this.feedData?.keywords[0] !== "string") {
          let keywords = []
          this.feedData?.keywords?.forEach(keyword => {
            keywords.push(keyword.keyword)
          })
          this.feedData.keywords = keywords
        }

        const pageType = window.location?.pathname.split("/")[1] || null;
        const musicMetaData = {
          event_type: 'liked_track',
          track_id: this.feedData?.id || null,
          track_name: this.feedData?.name || null,
          track_genre: this.feedData?.genres ? this.feedData.genres : null,
          track_moods: this.feedData?.moods ? this.feedData.moods : null,
          track_usecases: this.feedData?.usecases ? this.feedData.usecases : null,
          track_keywords: this.feedData?.keywords ? this.feedData.keywords : null,
          track_bpm: this.feedData?.bpm,
          // TODO: review primary artist
          // primary_artist: this.feedData?.tracksArtistRole?.filter(i => i.isPrimary == true).artist?.name || this.feedData?.tracks_artist_role?.filter(i => i.isPrimary == true).artist?.name || this.feedData?.artists.filter((artist) => artist.ArtistTrackRoleMappingModel.isPrimary == true)?.name || this.feedData?.artists[0].name,
          // track_meta: { ...this.feedData },
          is_vocal: this.feedData?.hasVocals === true || this.feedData?.has_vocals === "true" ? true : false,
          sfx_category: this.feedData?.sfx_category || null,
          sfx_subcategory: this.feedData?.sfx_subcategory || null,
          sub_genres: this.feedData?.sub_genres || null,
          track_origin_region: this.feedData?.region || null,
          track_release_region: this.feedData?.release_region || this.feedData?.releaseRegion || null,
          is_explicit: this.feedData?.isExplicit === true || this.feedData?.is_explicit === "true" ? true : false,
          track_type: this.feedData?.type || null,
          duration: this.feedData?.duration || null,
          display_tags: this.feedData?.displayTags || this.feedData?.display_tags || null,
          page_name: window.location?.pathname?.substring(0, 125) || null,
          page_type: window.location?.pathname?.split("/")[1]?.substring(0, 125) || null,
          page_url: window.location?.href?.substring(0, 125) || null,
          playlist_name: pageType === 'playlist' ? window.location?.pathname?.split("/")[2]?.substring(0, 125) : null,
          search_term: pageType === 'search' ? window.location?.pathname?.split("/")[2]?.substring(0, 125) : null,
        }
        this.userInfoService.logData(musicMetaData);
        // this.cd.detectChanges();
      },
      (error) => {
        this.likeStatus = originalStatus;
        this.feedData.liked = this.likeStatus;
        // this.cd.detectChanges();
      }
    );
  }


  public addToPlaylist(): void {
    this.popUpService.showPopUp('ADD_TO_PLAYLIST', this.feedData);
    // TODO: add analytics
  }
  public requestQuote() : void{
    this.token = this.apiService.getToken();
    if(this.token){
      const isUserOnboarded = this.apiService.isUserOnboarded();
      this.feedData.isUserOnboarded = isUserOnboarded;
      this.popUpService.showPopUp('REQUEST_QUOTE',this.feedData);
    }else{
      this.popUpService.showPopUp('REQUEST_QUOTE',this.feedData);
    }
    let url = this.router.url.split('?')[0];
    localStorage.setItem('redirectUrl', url.split('?')[0]);
    }

  public trackMeta(trackName: string, imageUrl: string): void {
    const title = `Listen To ${trackName} | Hoopr`
    const desc = `Listen to ${trackName} now on Hoopr. Subscribe now & access to our comprehensive royalty free Indian music for better content engagement.`;
    this.titleService.setTitle(title);
    this.metaTagService.updateTag({
      name: 'description',
      content: desc,
    });
    this.metaTagService.updateTag({
      property: 'og:title',
      content:title,
    });
    this.metaTagService.updateTag({
      property: 'og:description',
      content: desc,
    });
    this.metaTagService.updateTag({
      property: 'og:image',
      content: imageUrl || '/assets/images/default/track.webp',
    });
    this.metaTagService.updateTag({
      name: 'twitter:title',
      content:title,
    });
    this.metaTagService.updateTag({
      name: 'twitter:description',
      content: desc,
    })
  }
}