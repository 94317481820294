<div *ngIf="this.popUpType === 'COMPLETE_PROFILE'">
  <div class="row m-0 justify-content-center mb-3">
    <div class="col-12 profile">
      <p class="text-uppercase">Hoopr Select Profile</p>
      <h2 class="popup-title mb-3">Enhance Experience by Completing Profile </h2>
      <form class=" hoopr_form_field_group" [formGroup]="this.onboardingForm" (ngSubmit)="onOnboardingFormSubmit()">
        <div class="hoopr_form_fields dark-label">
          <input type="text" class="inputText" formControlName="name" required />
          <span class="floating-label">Name<span class="text-red">*</span> </span>
        </div>
        <div class="row">
          <div class="col">
            <div class="hoopr_form_field_group mb-0">
              <div class="form-group hoopr_form_fields dark-label">
                <input type="text" inputmode="email" class="inputText" formControlName="email" required
                  [disabled]="isUserEmailVerfied" />
                <span class="floating-label">Email Address<span class="text-red">*</span> </span>
                <img class="text-right tick" src="/assets/images/complete.svg" alt="completed" *ngIf="isUserEmailVerfied">
              </div>
            </div><!-- form-field -->
          </div><!-- col -->
          <div class="col-auto pl-0" *ngIf="!isUserEmailVerfied">
            <button type="button" class="btn btn-black  w-100 height-50" (click)="this.verifyOnboardingEmail()">
              VERIFY</button>
          </div>
        </div>
        <span class="pb-2 ml-3 form_errors" *ngIf="onboardingFromErrors.email">{{onboardingFromErrors.email}}</span>
        <div class="otp text-center" *ngIf="showEmailOtpBox">
          <p class="otp-text">OTP sent to <span>{{this.emailToBeVerfied}}</span></p>
          <!-- <form>  -->
          <div class="d-flex" formGroupName="emailOtp">
            <input #input1 id="input1" type="text" min="0" maxlength="1" formControlName="otp1"
              class=" text-center form-control otp-input" autofocus placeholder="" (keyup)="onOtpInput($event, input2)"
              (keydown)="onKeyDown($event)">
            <input #input2 id="input2" type="text" maxlength="1" formControlName="otp2"
              class=" text-center form-control otp-input" placeholder="" (keyup)="onOtpInput($event, input3)"
              (keydown)="onKeyDown($event, input1, input2)">
            <input #input3 id="input3" type="text" maxlength="1" formControlName="otp3"
              class=" text-center form-control otp-input" placeholder="" (keyup)="onOtpInput($event, input4)"
              (keydown)="onKeyDown($event, input2, input3)">
            <input #input4 id="input4" type="text" maxlength="1" formControlName="otp4"
              class=" text-center form-control otp-input" placeholder="" (keyup)="onOtpInput($event, input5)"
              (keydown)="onKeyDown($event, input3, input4)">
            <input #input5 id="input5" type="text" maxlength="1" formControlName="otp5"
              class=" text-center form-control otp-input" placeholder="" (keyup)="onOtpInput($event, input6)"
              (keydown)="onKeyDown($event, input4, input5)">
            <input #input6 id="input6" type="text" maxlength="1" formControlName="otp6"
              class=" text-center form-control otp-input" placeholder="" (keyup)="onOtpInput($event)"
              (keydown)="onKeyDown($event, input5, input6)">
          </div>
          <p class="form_errors text-right" *ngIf="this.errorMessage">{{this.errorMessage}}</p>
          <!-- </form> -->
          <p class="code-text mt-4">Didn’t get a code?
            <a (click)="this.verifyOnboardingEmail()">Click here to resend</a>
          </p>
        </div>

        <div class="hoopr_form_fields dark-label mt-1 mt-md-0">
          <input type="text" inputmode="email" class="inputText" formControlName="companyName" required />
          <span class="floating-label">Company Name<span class="text-red">*</span> </span>
        </div>
        <div class="hoopr_form_field_group">
          <div class="form-group hoopr_form_fields dark-label">
            <select class="custom-select w-100" aria-label="Default select example"
              formControlName="companyType">
              <option value="Brand">Brand</option>
              <option value="Agency">Agency</option>
              <option value="Production House">Production House</option>
              <option value="OTT platform">OTT platform</option>
              <option value="Individual Creator">Individual Creator</option>
              <option value="Independent Filmmaker">Independent Filmmaker</option>
              <option value="TV Channel">TV Channel</option>
              <option value="Radio Station">Radio Station</option>
              <option value="Other, please specify">Other, please specify</option>
            </select>
            <span class="floating-label">Type of company<span class="text-red">*</span> </span>
          </div><!-- field -->
        </div><!-- group -->

        <button type="submit" class="btn btn-yellow w-100 height-50 ">SUBMIT</button>
      </form>
      <p class="skip-text" *ngIf="this.showSkip" (click)="this.closePopUp()">SKIP</p>
    </div>

  </div>
</div>
<div *ngIf="this.popUpType === 'REQUEST_QUOTE'">
  <div class="profile">
    <div>
      <p class="text-uppercase">Hoopr Select</p>
      <h2 class=" mb-3">Request Your Custom Quote Today </h2>
    </div>
    <div class="track">
      <div class="row align-items-center">
        <div class="col-4">
          <img alt="track-image" [src]="this.trackInfo?.imageUrl || this.trackInfo?.image_url"
            onerror="this.src='/assets/images/default/track.webp'" class="track-image">
        </div>
        <div class="col-8">
          <h3>{{this.trackInfo.name}}</h3>
          <p>{{this.artistString}}</p>
        </div>
      </div>
    </div><!-- track -->
    <button type="submit" class="btn btn-yellow w-100 height-50 " (click)="requestQuote()">REQUEST A QUOTE</button>
    <p class="skip-text" (click)="navigate()">ADD MORE</p>
  </div><!-- profile -->
</div><!-- REQUEST_QUOTE -->
<div *ngIf="this.popUpType === 'THANKYOU'">
  <div class="row m-0 justify-content-center mb-3">
    <div class="col-12 profile">
      <div class="">
        <p class="text-uppercase">Thank You for Your Request</p>
        <h2 class="popup-title mb-3">Our Sales Team Will Contact You Soon </h2>
      </div>
      <img src="/assets/images/complete-big.svg" alt="complete">


    </div>

  </div>
</div>

<div *ngIf="this.popUpType === 'TEMP'">
  <div class="row m-0  mb-3 ">
    <div class="col-12 p-0 add">
      <h2>Add to playlist</h2>
      <div class="py-2">
        <button type="submit " class="btn btn-yellow w-100 height-50"><img src="/assets/images/plus.svg" alt="plus">
          NEW PLAYLIST</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="this.popUpType === 'ADD_TO_PLAYLIST'">
  <div class="add">
    <h2>Add to playlist</h2>
    <div class="row m-0  mb-3 ">
      <div class="col-12 p-0 ">
        <div class="playlist-holder">
          <div class="playlists p-2 my-2" *ngFor="let playlist of presentPlaylists">
            <div class="row align-items-center" (click)="this.addTrackToplaylist(playlist['id'])">
              <div class="col-3  col-md-2 ">
                <img src="/assets/images/playlist-image.png" alt="playlist" class="playlist-image">
              </div>
              <div class="col-9 ocl-md-10">
                <h3 class="playlist-name">{{playlist['name']}}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-2">
      <button type="submit " class="btn btn-yellow w-100 height-50" (click)="this.createNewPlaylist()">
        <img src="/assets/images/plus.svg" alt="plus">
        NEW PLAYLIST</button>
    </div>
  </div>
</div>

<div *ngIf="this.popUpType === 'NEW_PLAYLIST'">
  <div class="row m-0  mb-3 ">
    <div class="col-12 p-0 add">
      <h2 class="">New Playlist</h2>
      <div class="hoopr_form_fields dark-label">
        <input type="text" name="playlistName" class="inputText" required [(ngModel)]="this.playlistName" />
        <span class="floating-label">Name of the playlist<span>*</span> </span>
      </div>
      <button type="submit " class="btn btn-yellow w-100 height-50" (click)="this.onPlaylistSubmit($event)">
        CREATE PLAYLIST</button>
    </div>
  </div>
</div>

<div *ngIf="this.popUpType === 'SELECTED_PLAYLIST'">
  <div class="row m-0  mb-3 ">
    <div class="col-12 p-0 add">

      <h2>Add to playlist</h2>
      <div class="py-2">
        <button type="submit " class="btn btn-yellow w-100 height-50"><img src="/assets/images/plus.svg" alt="plus">
          NEW PLAYLIST</button>

      </div>


    </div>
  </div>
</div>

<div *ngIf="this.popUpType === 'RENAME_PLAYLIST'">
  <div class="row m-0  mb-3 ">
    <div class="col-12 p-0 add">
      <h2 class="">Rename Playlist</h2>
      <div class="hoopr_form_fields dark-label mt-4">
        <input type="text" name="playlistName" class="inputText" required [(ngModel)]="this.playlistName" />
        <span class="floating-label">Playlist Name<span>*</span> </span>
      </div>
      <button type="submit " class="btn btn-yellow w-100 height-50" (click)="this.renamePlaylistSubmit()">
        SAVE</button>
      <p class="skip-text pt-3" (click)="this.closePopUp()">CANCEL
      </p>

    </div>

  </div>
</div>

<div *ngIf="this.popUpType === 'DELETE_PLAYLIST'">
  <div class="row m-0  mb-3 ">
    <div class="col-12 p-0 add">
      <h2 class="">Delete Playlist</h2>
      <p class="mb-5">Are you sure you want to remove this playlist?</p>
      <button type="submit " class="btn btn-yellow w-100 height-50" (click)="this.deletePlaylistSubmit($event)"><img
          src="/assets/images/delete.svg" alte="delete">
        DELETE
      </button>
      <p class="skip-text pt-3" (click)="this.closePopUp()">CANCEL</p>
    </div>
  </div>
</div>

<div *ngIf="this.popUpType === 'SHARE_SONG'">
  <div class="row m-0  mb-3 ">
    <div class="col-12 p-0 add">
      <h2>Share {{this.trackInfo?.type}}</h2>
      <div class="share-song-card  track mt-3 p-2 row m-0 justify-content-center ">
        <div class="col-auto d-flex align-items-center px-0 justify-content-center">
          <img class="song-thumbnail" [src]="this.trackInfo?.imageUrl || this.trackInfo?.image_url"
            onerror="this.src='/assets/images/default/track.webp'">
        </div>
        <div class="col d-flex justify-content-center flex-column">
          <h2 class=" align-left mb-0">{{ trackInfo?.name || 'Play a track' }}</h2>
          <p class=" mb-0 text-clamp" *ngIf="this.trackInfo.description">{{this.trackInfo?.description}}</p>
          <p class=" mb-0 text-clamp" *ngIf="this.trackInfo.artists">
            <span *ngFor="let artist of this.trackInfo.artists; let isLast = last">
              <a [routerLink]="'/artist/' +  artist?.artist?.name_slug">{{artist.artist?.name}}</a>
              <ng-container *ngIf="!isLast">,</ng-container>
            </span>
          </p>
        </div>
      </div>
      <div class="col-12 pt-3  d-flex justify-content-center global-share-container">
        <share-buttons class="text-center mx-2 share-buttons" [theme]="'material-dark'"
          [include]="['whatsapp','email','facebook' ,'linkedin','copy']" [url]="this.trackUrl"
          (click)="this.copyEmbedCode()" [image]="this.trackInfo?.imageUrl || this.trackInfo?.image_url"
          [autoSetMeta]="false"></share-buttons>
      </div>
    </div>
  </div>
</div>

<div *ngIf="this.popUpType === 'LOGGED_FREE_DOWNLOAD'">
  <div class="row m-0 justify-content-center">
    <div class="w-100">
      <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
    </div>
    <h2 class="popup-title mb-4">You are now downloading...</h2>
    <div class="row m-0 justify-content-between w-100">
      <div class="col-8 col-md-7 d-flex align-items-center">
        <img
          [src]="this.trackInfo?.imageUrl || this.trackInfo?.image_url? this.trackInfo?.imageUrl|| this.trackInfo?.image_url:'/assets/images/login-popup-globe.webp'"
          alt="" class="globe-img mr-3">
        <div>
          <h2 class="globe-text text-left">{{this.trackInfo?.name}}</h2>
          <p class="m-0 globe-subtext w-100 text-left">{{this.trackInfo.tracksArtistRole ?
            this.trackInfo.tracksArtistRole[0]?.artist.name:undefined|| this.trackInfo.tracks_artist_role?
            this.trackInfo.tracks_artist_role[0]?.artist.name:undefined ||this.trackInfo.artists[0].name}}</p>
        </div>
      </div>
    </div>
    <div class="row m-0">
      <div class="col-md-12">
        <div class="card popup-card p-3 mt-3">
          <div class="row m-0 popup-card-header">
            <h2 class="text-normal">Creator License</h2>
            <p>You're about to download the "creator license" for this track</p>
            <img src="/assets/images/popup-card-header.webp" alt="" class="w-100">
          </div>
          <div class="row m-0">
            <div class="col col-md-6 card-list">
              <div class="row m-0 my-2 align-items-start">
                <img src="/assets/images/icon/correct.svg" alt="" class="col-auto px-0">
                <p class="col">Personal social media accounts</p>
              </div>
              <div class="row m-0 my-2 align-items-start">
                <img src="/assets/images/icon/correct.svg" alt="" class="col-auto px-0">
                <p class="col">Vlogs, live streams, presentations, personal websites</p>
              </div>
              <div class="row m-0 my-2 align-items-start">
                <img src="/assets/images/icon/correct.svg" alt="" class="col-auto px-0">
                <p class="col">Monetize video on social media</p>
              </div>
            </div>
            <div class="col col-md-6 card-list">
              <div class="row m-0 my-2 align-items-start">
                <img src="/assets/images/icon/wrong.svg" alt="" class="col-auto px-0">
                <p class="col">Business social media account</p>
              </div>
              <div class="row m-0 my-2 align-items-start">
                <img src="/assets/images/icon/wrong.svg" alt="" class="col-auto px-0">
                <p class="col">Ad/TVC/influencer campaigns, Films, OTT web shows, Broadcast or Apps</p>
              </div>
              <div class="row m-0 my-2 align-items-start">
                <img src="/assets/images/icon/wrong.svg" alt="" class="col-auto px-0">
                <p class="col">Derivative music works like covers, remixes or reproductions of the audio
                  for selling or distribution</p>
              </div>
            </div>
          </div>
          <!-- <div class="license-btn-div">
                        <button class="popup-cta license-btn">Download</button>
                    </div> -->
        </div>
      </div>
      <!-- <div class="col-md-4">
                <div class="card popup-card p-3 mt-3">
                    <div class="row m-0 popup-card-header">
                        <h2 class="text-sm">Lifetime License</h2>
                        <p>Single Track</p>
                        <img src="/assets/images/popup-card-header.webp" alt="" class="w-100">
                    </div>
                    <div class="row m-0 card-list">
                        <h1>₹1499</h1>
                        <p class="">This license allows you to use this track for lifetime on your social media platforms even when your monthly subscription expires.</p>
                    </div>
                    <div class="license-btn-div">
                        <button class="popup-cta license-btn" (click)="addLifetimeLictoCart()">add to cart</button>
                    </div>
                </div>
            </div> -->
    </div>
    <div class="row m-0 license-tnc-container my-1 mb-4">
      <div class="col">
        <p class="lience-tnc text-mute">***This license covers the perpetual usage and clearance of this track
          in videos published during an active subscription. Videos published after expiry of subscription
          could result in a permanent Content ID claim.</p>
      </div>
    </div>
  </div>
</div>
<!-- modal-1 -->

<div class="" *ngIf="this.popUpType === 'LOGGED_PAID_DOWNLOAD'">
  <div class="row m-0 justify-content-center mb-3">
    <div class="col-lg-1"></div>
    <div class="col-lg-10">
      <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
      <!-- <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt=""> -->
      <h2 class="popup-title mb-0">Unlock Unlimited Downloads!</h2>
      <p class="text-small text-mute my-4">Download this track & get access to our library of 12,000+ premium
        tracks used by top creators and brands!</p>

      <div class="row m-0 justify-content-center">
        <div class="col-md-6 px-2 d-flex justify-content-center align-items-center">
          <button type="button" name="button"
            (click)="urlRoute('download');this.pushAnalytics();this.router.navigate(['/pricing']);this.closePopUp()"
            class="popup-cta mb-2">Subscribe Now</button>
        </div>
        <!-- <div class="col-md-6 px-2 my-1 d-flex justify-content-center align-items-center">
                    <button type="button" name="button" (click)="go();this.closePopUp()" class="popup-cta-outline">EXPLORE FREETRAX</button>
                </div> -->
      </div>
    </div>
    <div class="col-lg-1"></div>
  </div>
</div>
<!-- modal-3 -->

<!-- -->
<div class="" *ngIf="this.popUpType === 'LOGGED_FREE_DOWNLOAD_EXHAUSTED'">
  <div class="row m-0 justify-content-center mb-3">
    <div class="col-1"></div>
    <div class="col-9">
      <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
      <h2 class="popup-title">We're glad you want to download this Track!</h2>

      <!-- <h2 class="brand-pink-color popup-text m-0 my-2">Subscribe</h2> -->
      <p class="text-small my-4">Become a subscribed creator to download this track</p>
      <div class="w-100 d-flex justify-content-center align-items-center my-4">
        <button type="button" name="button" (click)="this.router.navigate(['/pricing']);this.closePopUp()"
          class="popup-cta">VIEW PRICING PLANS</button>
      </div>

    </div>
    <div class="col-1"></div>
  </div>
</div>
<!-- modal-4 -->


<div class="" *ngIf="this.popUpType === 'CREATE_NEW_PLAYLIST'">
  <div class="row m-0 justify-content-center mb-3">
    <!-- <div class="w-100">
            <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
        </div> -->
    <h2 class="popup-title">Create New Playlist</h2>
  </div>
  <form class="popup-form-field" (submit)="this.onPlaylistSubmit($event)">
    <div class="row">
      <div class="col-lg-1"></div>
      <div class="col d-flex flex-column justify-content-center">
        <input type="text" name="playlistName" id="playlistName" placeholder="Playlist Name"
          [(ngModel)]="this.playlistName" required class="w-100 mb-3" />
        <textarea name="playlistDesc" id="playlistDesc" placeholder="Description"
          [(ngModel)]="this.playlistDesc"></textarea>
      </div>
      <div class="col-lg-1"></div>
    </div>
    <div class="row m-0 mt-3">
      <!-- <div class="col-lg-1"></div> -->
      <div class="col d-flex justify-content-center">
        <button class="popup-cta" type="submit">Create playlist</button>
      </div>
    </div>
  </form>
</div>
<!-- create-playlist-modal -->

<div class="" *ngIf="this.popUpType === 'LOGGED_ADD_TO_PLAYLIST'">
  <div class="row m-0 justify-content-center mb-3">
    <div class="w-100">
      <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
    </div>
    <h2 class="popup-title mb-0">Add to Playlist</h2>
  </div>
  <div class="row m-0 justify-content-center mb-3 playlist-content-container">
    <div class="col-auto mb-2 p-md-3 px-2 d-flex justify-content-center">
      <div class="create-card popup-playlist-card" (click)="this.createNewPlaylist()">
        <div>
          <img src="/assets/images/icon/add-circle.svg" alt="">
        </div>
      </div>
    </div>
    <div *ngFor="let playlist of presentPlaylists" class="col-auto mb-2 p-md-3 px-2">
      <div class="popup-playlist-card">
        <img src="/assets/images/default/track.webp" (error)="this.src = this.imageNotFound($event, this.assetType)"
          (click)="this.addTrackToplaylist(playlist['id'])" alt="" class="w-100">
        <p class="playlist-name text-normal text-clamp">{{playlist['name']}}</p>
      </div>
    </div>
  </div>
  <!-- <add-playlist-popup [track]=""></add-playlist-popup> -->
</div>



<!-- INDIVIDUAL_BUSINESS_CONVERT -->
<div *ngIf="this.popUpType === 'INDIVIDUAL_BUSINESS_CONVERT'">
  <div class="row m-0 justify-content-center mb-3">
    <div class="w-100">
      <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
    </div>
    <div class="row m-0 justify-content-center mb-3">
      <div class="col-lg-1"></div>
      <div class="col-lg-10">
        <h2 class="popup-title mb-3">Thanks for your interest in the Business Plan</h2>
        <p class="text-small my-4 text-mute">Hey {{this.userDetails.name}}, you are signed in as an Individual user.
          <br>
          Hence, you can only purchase an Individual subscription.<br>
          In order to subscribe to a business plan, please sign up as a business.
        </p>
        <div class="pro-tip-container w-100">
        </div>
      </div>
      <div class="col-lg-1"></div>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-md-6 d-flex justify-content-center justify-content-lg-end">
      <button type="button" name="button" class="popup-cta my-1" (click)="this.signUpASBusiness()">Signup As A
        Business</button>
    </div>
    <div class="col-md-6 d-flex justify-content-center justify-content-lg-start">
      <button type="button" name="button2" class="btn-white-o  my-1"
        (click)="this.router.navigate(['/pricing/individual-content-creator']); this.closePopUp()">Explore Individual
        Plan</button>
    </div>
  </div>
</div>

<!-- INDIVIDUAL_Agency_CONVERT -->
<div *ngIf="this.popUpType === 'INDIVIDUAL_AGENCY_CONVERT'">
  <div class="row m-0 justify-content-center mb-3">
    <div class="w-100">
      <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
    </div>
    <div class="row m-0 justify-content-center mb-3">
      <div class="col-lg-1"></div>
      <div class="col-lg-10">
        <h2 class="popup-title mb-3">Thank You For Your Interest In The Business Pro Plan</h2>
        <p class="text-small my-4 text-mute">We can see that you are registered as an Individual user. To purchase the
          business pro plan, please sign up as a business and continue with the purchase
        </p>
        <div class="pro-tip-container w-100">
        </div>
      </div>
      <div class="col-lg-1"></div>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-md-6 d-flex justify-content-center justify-content-lg-end">
      <button type="button" name="button2" class="btn-white-o  my-1"
        (click)="this.router.navigate(['/pricing/individual-content-creator']); this.closePopUp()">Explore Individual
        Plan</button>
    </div>
    <div class="col-md-6 d-flex justify-content-center justify-content-lg-start">
      <button type="button" name="button" class="popup-cta my-1" (click)="this.signUpASBusiness()">Signup As A
        Business</button>
    </div>
  </div>
</div>

<div *ngIf="this.popUpType === 'BUY_BUISNESS_TRACK'">
  <div class="row m-0 justify-content-center mb-3">
    <div class="w-100">
      <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
    </div>
  </div>
  <div class="row m-0 justify-content-center mb-3">
    <div class="col-lg-1"></div>
    <div class="col-lg-10">
      <h2 class="popup-title mb-3">Thank for your interest in the purchasing license for this track</h2>
      <p class="text-small my-4 text-mute">Hey {{this.userDetails.name}}, you are signed in as an individual user.
        In order to purchase licenses for Branded Content , kindly sign up as a business</p>
      <div class="pro-tip-container w-100">
      </div>
    </div>
    <div class="col-lg-1"></div>
  </div>
  <div class="row m-0">
    <div class="col d-flex justify-content-center">
      <button type="button" name="button" class="popup-cta my-1" (click)="this.signUpASBusiness()">Signup As A
        Business</button>
    </div>
    <!-- <div class="col-md-6 d-flex justify-content-center">
      <button type="button" name="button2" class="btn-white-o  my-1"
        (click)="this.router.navigate(['/pricing/individual-content-creator']); this.closePopUp()">Explore Individual
        Plan</button>
    </div> -->
  </div>
</div>

<div *ngIf="this.popUpType === 'BUY_INDIVIDUAL_TRACK'">
  <div class="row m-0 justify-content-center mb-3">
    <div class="w-100">
      <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
    </div>
  </div>
  <div class="row m-0 justify-content-center mb-3">
    <div class="col-lg-1"></div>
    <div class="col-lg-10">
      <h2 class="popup-title mb-3">Thank for your interest in the purchasing license for this track</h2>
      <p class="text-small my-4 text-mute">Hey {{this.userDetails.name}}, you are signed in as a Business user.
        In order to purchase licenses for Individual Creator or Metaverse Creator, kindly sign up as an individual</p>
      <div class="pro-tip-container w-100">
      </div>
    </div>
    <div class="col-lg-1"></div>
  </div>
  <div class="row m-0">
    <div class="col d-flex justify-content-center">
      <button type="button" name="button" class="popup-cta my-1" (click)="this.signUpASBusiness()">Signup As A
        Individual</button>
    </div>
    <!-- <div class="col-md-6 d-flex justify-content-center">
      <button type="button" name="button2" class="btn-white-o  my-1"
        (click)="this.router.navigate(['/pricing/businesses-and-brands']); this.closePopUp()">Explore Buisness
        Plan</button>
    </div> -->
  </div>
</div>

<!-- Subscription Processing -->
<div *ngIf="this.popUpType === 'SUBSCRIPTION_PROCESSING'">
  <div class="row m-0 justify-content-center">
    <div class="w-100">
      <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
    </div>
    <h2 class="popup-title mb-3">Subscription Processing</h2>
    <p class="text-small my-4">Hey {{this.userDetails.name}}, we are still processing your subscription, please try
      after a minute</p>
    <div class="pro-tip-container w-100">
    </div>
    <button type="button" name="button" class="popup-cta my-1"
      (click)="this.redirectRoute();this.closePopUp()">Okay</button>
  </div>
</div>


<!-- Onboarding complete -->
<!--   -->
<div *ngIf="this.popUpType === 'ONBOARDING_COMPLETE'">
  <div class="row m-0 justify-content-center">
    <div class="w-100">
      <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
    </div>
    <h2 class="popup-title mb-3 w-100">Welcome Onboard, {{this.userDetails.name}}!</h2>
    <button type="button" name="button" class="popup-cta my-1" (click)="this.redirectRoute();this.closePopUp()">READY
      TO
      DIVE IN!</button>
  </div>
</div>

<!-- Onboarding Complete Buisness -->
<div *ngIf="this.popUpType === 'ONBOARDING_COMPLETE_BUSINESS'">
  <div class="row m-0 justify-content-center">
    <div class="w-100">
      <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
    </div>
    <h2 class="popup-title mb-3">Thank You For Signing Up, {{this.userDetails.name}}!</h2>
    <p class="text-small my-4">You are now part of a community comprising of over 1,40,000+ creators, some of
      the biggest brands, production houses, and broadcasters across India.</p>
    <div class="white-divider"></div>
    <swiper [config]="this.brands" class="mySwiper">
      <ng-template swiperSlide>
        <img src="/assets/images/landing/altbalaji-logo.webp" alt="alt-balaji" class="featured-logo w-100">
        <img src="/assets/images/landing/viacom18-logo.webp" alt="viacom" class="featured-logo w-100">
      </ng-template>
      <ng-template swiperSlide>
        <img src="/assets/images/landing/cadbury-logo.webp" alt="cadbury" class="featured-logo w-100">
        <img src="/assets/images/landing/magicpin-logo.webp" alt="magicpin" class="featured-logo w-100">
      </ng-template>
      <ng-template swiperSlide>
        <img src="/assets/images/landing/nestle-logo.webp" alt="nestle" class="featured-logo w-100">
        <img src="/assets/images/brand_logos/bharat-pay.webp" alt="bharat-pay" class="featured-logo w-100">
      </ng-template>
      <ng-template swiperSlide>
        <img src="/assets/images/landing/flipkart-logo.webp" alt="flipkart" class="featured-logo w-100">
        <img src="/assets/images/brand_logos/HIKE-BW.webp" alt="hike" class="featured-logo w-100">
      </ng-template>
      <ng-template swiperSlide>
        <img src="/assets/images/landing/sunsilk-logo.webp" alt="sunsilk" class="featured-logo w-100">
        <img src="/assets/images/landing/bakersdozen-logo.webp" alt="bakersdozen" class="featured-logo w-100">
      </ng-template>
      <ng-template swiperSlide>
        <img src="/assets/images/landing/saregama-logo.webp" alt="saregama" class="featured-logo w-100">
        <img src="/assets/images/landing/zivame-logo.webp" alt="zivame" class="featured-logo w-100">
      </ng-template>
      <ng-template swiperSlide>
        <img src="/assets/images/landing/nestle-logo.webp" alt="nestle" class="featured-logo w-100">
        <img src="/assets/images/landing/bharat24-logo.webp" alt="bharat24" class="featured-logo w-100">
      </ng-template>
    </swiper>
  </div>
</div>

<!-- modal-5 -->
<!--  -->
<div *ngIf="this.popUpType === 'CHECKOUT_COMPLETE'">
  <div class="row m-0 justify-content-center mb-3">
    <div class="col-lg-1"></div>
    <div class="col-lg-10 d-flex justify-content-center align-items-center flex-column">
      <div class="w-100 mb-4">
        <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
      </div>
      <h2 class="popup-title mb-4">Payment Successful!</h2>
      <p class="text-normal text-mute my-3">Thank you for Subscribing to Hoopr!</p>
    </div>
    <div class="col-lg-1"></div>
  </div>
</div>

<!-- @TODO: Ashish -->
<!-- <div *ngIf="this.popUpType === 'AGENCY_CHECKOUT_COMPLETE'">
  <div class="row m-0 justify-content-center mb-3">
    <div class="col-lg-1"></div>
    <div class="col-lg-10 d-flex justify-content-center align-items-center flex-column">
      <div class="w-100 mb-4">
        <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
      </div>
      <h2 class="popup-title mb-4">Thank You For Purchasing Agency Pro Pack</h2>
      <p class="text-normal text-mute my-3">Please feel free to browse through our platform and download the tracks you need for your project </p>
    </div>
    <div class="col-lg-1"></div>
  </div>
</div> -->

<div *ngIf="this.popUpType === 'TRACKS_CHECKOUT_COMPLETE'">
  <div class="row m-0 justify-content-center mb-3">
    <div class="col-lg-1"></div>
    <div class="col-lg-10 d-flex justify-content-center align-items-center flex-column">
      <div class="w-100 mb-4">
        <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
      </div>
      <h2 class="popup-title mb-3">Thank You for Purchasing Lifetime License</h2>
      <p class="text-normal text-mute mb-3 mt-2 w-100">Your download will be available shortly</p>
      <button type="button" name="button" class="popup-cta my-1"
        (click)="this.router.navigateByUrl('/user/my-account/purchases/lifetime-licenses');this.closePopUp()">MY
        DOWNLOADS</button>
    </div>
    <div class="col-lg-1"></div>
  </div>
</div>

<!-- ifp two month free subscription -->
<div *ngIf="this.popUpType === 'IFP_ONBOARD_COMPLETE'">
  <div class="row m-0 justify-content-center">
    <div class="w-100">
      <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
    </div>
    <h2 class="popup-title mb-3">Hooray, {{this.userDetails.name}}!</h2>
    <p class="text-normal text-mute my-4 w-100">You have unlocked two month free subscription !</p>
    <button type="button" name="button" class="popup-cta my-1"
      (click)="this.redirectToSubscription();this.closePopUp();">CHECK SUBSCRIPTION</button>
  </div>
</div>


<!--take to subscription tab on cart page-->
<!-- <div *ngIf="this.popUpType === 'TAKE_TO_SUB_TAB'">
  <div class="row m-0 justify-content-center">
    <div class="w-100">
      <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
    </div>
    <h2 class="popup-title mb-3">Thank You for Purchasing Lifetime License</h2>
    <p class="text-normal text-mute my-4 w-100">We see that you still have a subscription in your cart. Would you like
      to proceed with the purchase of a subscription? </p>
    <button type="button" name="button" class="popup-cta my-1"
      (click)="this.redirectToCartTab('subscription');this.closePopUp();">TAKE ME TO CART</button>
  </div>
</div> -->

<!--take to license tab on cart page-->
<!-- <div *ngIf="this.popUpType === 'TAKE_TO_LICENSE_TAB'">
  <div class="row m-0 justify-content-center">
    <div class="w-100">
      <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
    </div>
    <h2 class="popup-title mb-3">Thank You For Subscribing!</h2>
    <p class="text-normal text-mute my-4 w-100">We see that you have lifetime license in your cart. Would you like to
      proceed with the purchase of lifetime licenses?</p>
    <button type="button" name="button" class="popup-cta my-1"
      (click)="this.redirectToCartTab('license');this.closePopUp();">TAKE ME TO CART</button>
  </div>
</div> -->

<div *ngIf="this.lifetimeLicenseAbout">
  <div class="modal hoopr_popup  " id="lifetimeLicenseAbout" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style="display:block">
    <div class="modal-dialog  modal-dialog-centered" role="document">
      <div class="modal-content" style="background: #222222;">
        <div class="modal-header border-bottom-0">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="aboutpopupClose()">
            <span aria-hidden="true">
              <img src="/assets/images/icon/close-button.svg" alt="" class="popup-close-btn">
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0 justify-content-center">
            <div class="col-md-10">
              <div class="row m-0">
                <div class="w-100">
                  <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
                </div>
                <h2 class="popup-title text-normal mb-3 text-center w-100">About the Lifetime license:</h2>
                <div class="pro-tip-container w-100">
                </div>
                <p class="sub-text-small font-light mb-0 text-mute">
                  The lifetime license allows you to use the track that you have purchased at any point in the future
                  in
                  your videos. You can purchase a separate lifetime license for each song/track on Hoopr and don’t
                  need
                  a subscription for the same.
                </p>
                <h4 class="text-normal text-bold text-left my-3 text-mute">How is this different from a Subscription?
                </h4>
                <p class="sub-text-small font-light mb-0 text-mute">
                  Hoopr Subscription gives you access to our entire library. However, the videos containing music from
                  Hoopr must be uploaded within your subscription period to be cleared of any copyright claims that
                  may
                  arise. For example, if you buy a 1-month subscription on January 1st, you must upload the video
                  containing music from Hoopr by January 31st for us to clear any copyright claims that may
                  arise.<br><br>
                  With a lifetime license, such time-based restrictions do not apply. You can upload a video
                  containing
                  the track you have purchased a lifetime license for at any point in the future, and we will still
                  clear all claims associated with it. Please note that there are different categories and different
                  levels of clearances as far as the lifetime licenses are concerned. Please check the lifetime
                  license
                  pricing page for details on specific use cases where you can music from Hoopr.
                </p>
                <h4 class="text-normal text-bold text-left my-3 text-mute">Different Kinds of Lifetime License</h4>
                <p class="sub-text-small font-light mb-0 text-mute">We have created four types of licenses that you
                  can
                  purchase:</p>
                <ol>
                  <li class="sub-text-small font-light mb-0 text-mute">Individual Creator</li>
                  <li class="sub-text-small font-light mb-0 text-mute">Branded Content</li>
                  <li class="sub-text-small font-light mb-0 text-mute">Movies/TV Shows/Web Series</li>
                  <li class="sub-text-small font-light mb-0 text-mute">Web 3 / Metaverse Productions</li>
                </ol>
                <p class="sub-text-small font-light mb-5 text-mute">
                  Please go through the information you see after clicking ' <i class="fa fa-shopping-cart"></i> '
                  beside each track for the specific use cases where you can and cannot use tracks that you have
                  purchased a lifetime license for.<br><br>
                  If you have any questions, please write to us at <a class="text-underline"
                    href="mailTo:'hello@hoopr.in'">hello&#64;hoopr.in</a>
                </p>
              </div>
            </div><!-- col-md-10 -->
            <!-- <button type="button" name="button" class="popup-cta my-1"
                        (click)="this.redirectRoute();this.closePopUp()">READY TO DIVE IN!</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="this.popUpType === 'CONTINUE_LISTENING'">
  <div class="row m-0 justify-content-center mb-3">
    <div class="w-100">
      <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
    </div>
    <div class="row m-0 justify-content-center mb-3">
      <div class="col-lg-1"></div>
      <div class="col-lg-10">
        <h2 class="popup-title mb-3">We're glad you're enjoying our music, {{this.userDetails.name}}</h2>
        <p class="text-small my-4">Since you've been listening for more than 180 minutes, we want to confirm whether
          you'd like to continue listening.</p>
        <div class="pro-tip-container w-100">
        </div>
      </div>
      <div class="col-lg-1"></div>
    </div>
    <div class="row m-0">
      <div class="col d-flex justify-content-center">
        <button type="button" name="button" class="popup-cta my-1" (click)="this.continuePlaying()">Continue
          Listening</button>
      </div>
      <div class="col-md-6 d-flex justify-content-center">
        <button type="button" name="button2" class="btn-white-o  my-1" (click)="this.closePopUp()">Stop Playing
          Music</button>
      </div>
    </div>
  </div>
</div>