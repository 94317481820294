<div class=" trending-tracks" >
  <div class="d-flex justify-content-between" *ngIf="showLabel">
    <h3 class="text-left title">Trending&nbsp;<img src="/assets/images/trending.svg"> </h3>
    <div class="text-right d-flex  align-self-center">
      <a class="view-all-text " routerLink="/trending">View all<i class="ph ph-caret-right"></i>
      </a>
    </div>
  </div><!--d-flex-->
  <swiper [config]="this.trending" class="mySwiper trending-swipper">
    <ng-template swiperSlide *ngFor="let ar of this.data">
      <a class="hoopr-artist-card" [routerLink]="ar.url">
        <div class="image-container">
          <img [src]="ar.image" onerror="this.src='/assets/images/instagram.png'" alt="song-artist-default" class="song-artist-image">
          <h3 class="floating-text">{{ar.title}}</h3>
          <a class="play-box"[routerLink]="ar.url" >
            <img src="/assets/images/arrow-up-right.png" alt="arrow">
          </a>
        </div>
      </a>
    </ng-template>
  </swiper>
</div><!--container-fluid-->