<div class="contactus_page landing__other_sections bg-page">
    <app-header></app-header>
    <div class="contactus_page_container">
      <div class="conatctus_banner p-relative">
        <img src="assets/images/banners/static-banner.webp" alt="" class="fixed-banner">
        <div class="banner-text">
          <h1>Data Deletion Instructions</h1>
          <h2 class="text-normal">Guide on how to remove your data from this platform</h2>
        </div>
      </div>
  
      <div class="container policy_contents_layout mt-4">

        <p>To remove your data from this platform, please contact us at support@hoopr.ai.</p>

        <p>Hoopr app uses the Facebook login and we do not save your personal data in our server. As per Facebook policy, we have to provide User with <a href=""></a> Data Deletion Instructions URL.</p>
  
        <p>
            If you want to delete your activities for Hoopr app, you can remove your information by following these steps:<br>
            1.  Go to your Facebook Account's Setting & Privacy. Click Settings.<br>
            2.  Look for Apps and Websites and you will see all of the apps and websites you linked with your Facebook.<br>
            3.  Search and click Hoopr in the search bar.<br>
            4.  Scroll and click Remove.<br>
            5.  Congratulations, you have successfully removed your app activities.<br>
  
        </p>
  
      </div>

    </div>
    <section class="contact">
      <div class="pageContainer p-3 px-md-4 px-xl-5">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6">
            <h3 class="sub-title">We’re happy to answer questions and get you acquainted with Hoopr, Didn't find your
              answers here?</h3>
            <div class="divider"></div>
            <a routerLink="/contactus" id="privacy_policy_contact_us" class="btn btn-brand">Contact us</a>
          </div><!-- col-6 -->
        </div><!-- row -->
      </div><!-- page container -->
    </section><!-- contact -->
  </div>
  <footer></footer>