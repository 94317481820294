import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopupService } from '../services/popup.service';
import { take } from 'rxjs/operators';
declare var bootstrap: any;

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {

  popUpType: string
  modalInstance: any;

  constructor(
    public popupService: PopupService,
  ) { }

  ngOnInit(): void {
    this.popupService.popUpType.pipe().subscribe(value => {
      this.popUpType = value;
      if (this.popUpType) {
        this.openPopUp();
      } else {
        this.onClose();
      }
    })
  }

  openPopUp() {
    const modalElement = document.getElementById('conformationPopup');
    this.modalInstance = new bootstrap.Modal(modalElement , {
      backdrop: 'static', // Prevent closing by clicking outside
      keyboard: false     // Prevent closing by pressing the Escape key
    });
    this.modalInstance.show();
  }

  onClose() {
    
    if (this.modalInstance) {
      this.modalInstance.hide();
    }
  }

}
