import { Component, OnInit } from '@angular/core';
import { BackendApiService } from '../services/hoopr.services';
@Component({
  selector: 'app-trendings',
  templateUrl: './trendings.component.html',
  styleUrls: ['./trendings.component.scss']
})
export class TrendingsComponent implements OnInit {
  public movies: any;
  public artists: any;
  public tracks: any;
  public feedData: any;
  public trendingList: any = [];
  constructor(
    private apiService: BackendApiService
  ) { }

  ngOnInit(): void {
    this.apiService.getTrendingFeed().subscribe(
      (response) => {
        this.feedData = response;
        this.movies = this.feedData["movies"].data;
        this.artists = this.feedData["artists"].data;
        this.tracks = this.feedData["tracks"].data;
        this.trendingList.push(this.feedData["trending-1"]);
        this.trendingList.push(this.feedData["trending-2"]);
        this.trendingList.push(this.feedData["trending-3"]);
        this.trendingList.push(this.feedData["trending-4"]);
        this.trendingList.push(this.feedData["trending-5"]);
        this.trendingList.push(this.feedData["trending-6"]);
      },
      (err) => {
        console.log('Error while loading feed data', err);
      }
    )
  }

}
