<div class="modal fade hoopr_popup  " id="conformationPopup" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true" 
  data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog  modal-dialog-centered" role="document">
    <div class="modal-content" >
      <div class="modal-header border-bottom-0">
        <button type="button" class="close d-flex" data-dismiss="modal" aria-label="Close" (click)="onClose()">
            <img src="/assets/images/close.svg" alt="close" class="">
        </button>
      </div>
      <div class="modal-body">
        <div class="only_contnet_popup" >
          <app-anonymous></app-anonymous>
          <app-logged-in></app-logged-in>
        </div>
      </div>
    </div>
  </div>
</div>