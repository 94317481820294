<div *ngIf="this.sliderItems && this.sliderItems.length > 0 && this.items && this.items > 0 && this.mobileItems && this.mobileItems > 0">
  <swiper [config]="this[this.popular]" *ngIf="popular && popular.length > 0" [navigation]="this.navigation" [pagination]="this.pagination ? { clickable: true } : false">
    <ng-template swiperSlide *ngFor="let data of this.sliderItems">
      <app-playlist-frame [data]="data" [showLabel] = "showLabel" *ngIf="popular == 'Playlists'" [labelColor]="labelColor">
      </app-playlist-frame>

      <app-genre-frame [data]="data" [showLabel] = "showLabel"*ngIf="popular == 'Genres'">
      </app-genre-frame>

      <app-movie-frame [data]="data"  [showLabel] = "showLabel" *ngIf="popular == 'Movies'" [labelColor]="labelColor">
      </app-movie-frame>

      <app-trending [data]="data" [showLabel] = "showLabel" *ngIf="popular == 'Trending'" [showLabel]="true" >
      </app-trending>

      <app-artist-frame [data]="data"  [showLabel] = "showLabel" *ngIf="popular == 'Artist'" [labelColor]="labelColor">
      </app-artist-frame>

      <app-language-frame [data]="data" [showLabel] = "showLabel" *ngIf="popular === 'Language' || popular === 'Moods'"  [labelColor]="labelColor">
      </app-language-frame>
    </ng-template>
  </swiper>
</div>
