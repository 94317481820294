import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoggerServiceService } from '../services/logger-service.service';
import { GtmAnalyticsService } from '../services/gtm-analytics.service';
import { environment } from 'src/environments/environment';

declare var window: any;

@Component({
 selector: 'footer',
 templateUrl: './footer.component.html',
 styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
 href: any;
 environment = environment;
 constructor(
  private router: Router,
  private logger: LoggerServiceService,
  private gtmAnalytics: GtmAnalyticsService
 ) {
  this.href = this.router.url;
 }

 ngOnInit() {}

 log() {
  this.logger.logEvent('Interested Artist');
  this.router.navigate(['/hoopr-for-artiste']);
 }

 pushAnalytics(link_text,link_section?: string) {
  this.gtmAnalytics.pushCtaAnalytics({
   event:'ga4 footer_interaction',
   event_name:'footer_interaction',
   artist_name: '',
   instrument_type: '',
   link_name: 'footer',
   link_header: '',
   link_section:link_section?link_section:'',
   link_text,
   link_url: (window.location?.pathname.length > 125 ? window.location?.pathname.substring(0, 125) : window.location?.pathname) || null
   ,
   login_status: JSON.parse(localStorage.getItem('userData'))?.id
    ? 'login'
    : 'guest',
   page_type: window.location?.pathname.split('/')[1] || null,
   search_link: '',
   search_term: '',
   track_name: '',
   user_id: JSON.parse(localStorage.getItem('userData'))?.id || null,
  });
 }
}