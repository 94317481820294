import {
  AfterViewInit, ChangeDetectorRef, Component,
  ElementRef, EventEmitter, Input, OnChanges, OnInit,
  Output, ViewChild, OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GtmAnalyticsService } from 'src/app/services/gtm-analytics.service';
import { BackendApiService } from 'src/app/services/hoopr.services';
import { LoggerServiceService } from 'src/app/services/logger-service.service';
import { PlayerService } from 'src/app/services/player.service';
import { PopupService } from 'src/app/services/popup.service';
import { TrackAuthorizationService } from 'src/app/services/track-authorization.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { UtilsService } from 'src/app/services/utils.service';
import { WindowRefService } from 'src/app/services/window-ref.service';
import { formatDuration } from "../../services/utils";
import { fromEvent, Subject } from "rxjs";
import { debounceTime, takeUntil, throttleTime } from "rxjs/operators";
import { SwalHelper } from 'src/app/_helpers/swal-helpers';
declare const WaveSurfer;
declare var $: any;

enum playerSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
  wave = 'wave',
}
@Component({
  selector: 'app-track-block',
  templateUrl: './track-block.component.html',
  styleUrls: ['./track-block.component.scss']
})
export class TrackBlockComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild("player") player: ElementRef;
  @Input() public size: playerSizes;
  @Input() public musicInfo: any;
  @Input() labelColor : string;
  // @Input() mobileMode = false;

  public wave;
  public isPlaying: boolean = false;
  public showControls: boolean = false;
  public primaryArtists: any = [];
  public loading: boolean;
  public error: any;
  public formatDuration = formatDuration;

  private token: string;
  private currentTime: string = "0:00";
  public likeStatus = false;
  private peaks: any;
  private takeUntil$ = new Subject();
  private userData: any;
  private signInwarning: boolean;
  private displaytags: any;

  constructor(
    private cd: ChangeDetectorRef,
    public playerService: PlayerService,
    public router: Router,
    public apiService: BackendApiService,
    private logger: LoggerServiceService,
    public popUpService: PopupService,
    private userInfoService: UserInfoService,
    public utilsService: UtilsService,
    private gtmAnalytics: GtmAnalyticsService
  ) { }

  ngOnInit(): void {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="tooltip"]').tooltip({
      customClass: 'mic-tooltip'
    });
    if (this.musicInfo && this.musicInfo.tracks_artist_role && typeof this.musicInfo.tracks_artist_role === 'string') {
      this.musicInfo.tracks_artist_role = JSON.parse(this.musicInfo.tracks_artist_role);
    }

    this.primaryArtists = this.musicInfo?.tracksArtistRole?.filter(i => i.isPrimary == true) || this.musicInfo?.tracks_artist_role?.filter(i => i.isPrimary == true)

    this.playerService.musicInfo
      .pipe(takeUntil(this.takeUntil$))
      .subscribe((currentMusic) => {
        // const n = Date.now();
        if (currentMusic?.id === this.musicInfo.id) {
          // const x = Date.now();
          this.isPlaying = true;
          this.cd.detectChanges();
          // console.log(Date.now() - x, 'sub current music on Init fn , if block')
        } else {
          this.isPlaying = false;
          this.wave?.seekTo(0)
          this.cd.detectChanges();
          // console.log(Date.now() - n, 'sub current music on Init Fn')
        }
      });

    this.playerService.pause.pipe(takeUntil(this.takeUntil$)).subscribe((music) => {
      // const y = Date.now();
      if (music && this.musicInfo && music?.id === this.musicInfo.id) {
        if (music.isTimeOut) {
          setTimeout(() => {
            this.isPlaying = false;
            this.cd.detectChanges()
          }, 1000)
        } else {
          this.isPlaying = false;
        }
        if (music?.isFinished) {
          this.currentTime = '0:00'
          this.wave?.seekTo(0)
        }
        this.cd.detectChanges()
      }
      // console.log(Date.now() - y, '--player service play pause waves')

    });
    const tempdisplaytags = this.musicInfo["displayTags"]
    ? this.musicInfo["displayTags"].split(",")
    : this.musicInfo["display_tags"]
      ? this.musicInfo["display_tags"].split(",")
      : [];
  this.displaytags = [];
  for (let tag of tempdisplaytags) {
    this.displaytags.push(tag);
  }
    this.userData = this.apiService.getUserData();
    this.userData?.likedTracks?.forEach((trackId: string) => {
      if (this.musicInfo.id === trackId) {
        this.likeStatus = true;
        this.musicInfo.liked = true;
      }
    })
  }


  ngOnChanges() {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
      $('[data-toggle="tooltip"]').tooltip({
        customClass: 'mic-tooltip'
      });
    })

    if (this.musicInfo && this.musicInfo.tracks_artist_role && typeof this.musicInfo.tracks_artist_role === 'string') {
      this.musicInfo.tracks_artist_role = JSON.parse(this.musicInfo.tracks_artist_role)
    }

    this.primaryArtists = this.musicInfo?.tracksArtistRole?.filter(i => i.isPrimary == true) || this.musicInfo?.tracks_artist_role?.filter(i => i.isPrimary == true)
    this.playerService.musicInfo
      .pipe(takeUntil(this.takeUntil$), throttleTime(1000))
      .subscribe((currentMusic) => {
        // const n = Date.now();
        // const n = Date.now();
        if (currentMusic?.id === this.musicInfo.id) {
          // const x = Date.now();
          this.isPlaying = true;
          this.cd.detectChanges();
          // console.log(Date.now() - x)
        } else {
          this.isPlaying = false;
          this.wave?.seekTo(0)
          this.currentTime = '0:00'
          setTimeout(() => {
            this.preRenderWaveforms()
          }, 500);
          this.cd.detectChanges();
        }
        // console.log(Date.now() - n, 'sub current music on changes fn')
      });


  }

  ngAfterViewInit(): void {
    const shadow = this.player.nativeElement.attachShadow({ mode: "open" });
    this.wave = WaveSurfer.create({
      container: this.player.nativeElement,
      waveColor: "#666666",
      progressColor: "#FFFFFF",
      barWidth: 2,
      barGap: 2,
      minPxPerSec: 10,
      responsive: true,
      backend: 'MediaElement',
      hideScrollbar: true,
      height: 30,
      normalize: true,
      plugins: [
        WaveSurfer.cursor.create({
          showTime: true,
          opacity: 10,
          customShowTimeStyle: {
            "background-color": "#000",
            color: "#fff",
            padding: "2px",
            "font-size": "10px",
          },
        }),
      ],
    });
    shadow.appendChild(this.player.nativeElement.children[0]);

    this.wave.setMute(true);

    setTimeout(() => {
      this.preRenderWaveforms();
    }, 1000)


    // added because on wavesurfer internal re draw buffer on screen resize was getting fired too many times.
    this.registerWaveEvents();

    this.playerService.continousStream.pipe(takeUntil(this.takeUntil$), throttleTime(100)).subscribe(object => {
      if (object?.id === this.musicInfo?.id) {
        let value;
        if (object?.valueSeek) {
          value = object.valueSeek
          object.value = (object?.duration / 100) * (object.valueSeek * 100)
        } else {
          value = ((object.value * 100) / object.duration) / 100;
        }
        if (value >= 0 && value <= 1) {
          this.wave?.seekTo(value);
          this.currentTime = object?.value;
          this.isPlaying = object.isPlaying;
        }
      }
    })

    this.cd.detectChanges();

    this.playerService.likeUpdate.subscribe(likeObj => {
      // const x = Date.now();
      if (likeObj?.id === this.musicInfo?.id) {
        this.likeStatus = likeObj?.status;
        this.musicInfo.liked = likeObj?.status;
        this.cd.detectChanges();
      }
      // console.log(Date.now() - x, '--after view init waves');
    })

  }

  ngOnDestroy() {
    this.wave?.unAll();
    this.wave?.cancelAjax();
    this.wave?.stop();
    this.wave?.empty();
    this.takeUntil$.next();
    this.takeUntil$.complete();
  }

  private registerWaveEvents() {
    this.wave.on("seek", (value) => {
      this.playerService.globalPlaySeek.next({ value: value, id: this.musicInfo?.id });
    });

    this.wave.on("error", (error) => {
      console.log("Waveform faced error:", error);
    });
  }

  play() {
    this.isPlaying = true;
    this.playerService.musicInfo.next({
      ...this.musicInfo,
      currentTime: this.currentTime,
      peaks: this.peaks,
      isTopliningTrack: false
    });
    this.pushAnalytics('play', this.musicInfo?.name)
  }

  pause() {
    this.isPlaying = false;
    this.playerService.pause.next(true)
    this.pushAnalytics('pause', this.musicInfo?.name)
  }

  public likeTrack(): void {
    this.token = this.apiService.getToken();

    if (!this.token) {
      this.signInwarning = true;
      this.popUpService.showPopUp("LIKED_SONG");
      localStorage.setItem('musicInfo', JSON.stringify(this.musicInfo))
      this.cd.detectChanges();
      return;
    }

    const originalStatus = (this.likeStatus = !!this.musicInfo.liked);
    let reqObj = {
      trackId: this.musicInfo.id,
    };

    this.apiService.consumerlikeTrack(reqObj).subscribe(
      (response: any) => {
        if (this.likeStatus == false) {
          SwalHelper.showToast(
            this.musicInfo.name + " Track added to your likes!",
            '<i class="ph ph-fill ph-check-circle" style="color:#2C96FF"></i>',
            "<a href='/user/my-collection/my-likes' class='swal-text-link text-normal'>VIEW LIKES</a>"
          );
        } else {
          SwalHelper.showToast(
            this.musicInfo.name + " Track removed from your likes!",
            '<i class="ph ph-fill ph-check-circle" style="color:#2C96FF"></i>',
            "<a href='/user/my-collection/my-likes' class='swal-text-link text-normal'>VIEW LIKES</a>"
          );
        }
        this.apiService.updateLikedTracks(response.data)
        this.likeStatus = !originalStatus;
        this.musicInfo.liked = this.likeStatus;
        this.playerService.likeUpdate.next({ id: this.musicInfo.id, status: this.likeStatus })
        let event = this.likeStatus ? "Liked Track" : "Unliked Track";
        this.logger.logTrackEvent(event, this.musicInfo.id, this.musicInfo.name);
        // log data to backend

        if (this.musicInfo.asset_type) {
          if (this.musicInfo.type === "music") {
            this.musicInfo.sub_genres = this.musicInfo.sub_filters
          } else {
            this.musicInfo.sfx_subcategory = this.musicInfo.sub_filters
            this.musicInfo.sfx_category = this.musicInfo.filters
          }
        }
        else if (this.musicInfo.filters) {
          const genres = [];
          const moods = [];
          const usecases = [];
          const sub_genres = [];
          const sfx_subcategory = [];
          const sfx_category = [];
          this.musicInfo?.filters?.forEach(filter => {
            if (filter.type === "genre") {
              genres.push(filter.name)
            } else if (filter.type === "mood") {
              moods.push(filter.name)
            } else if (filter.type === "usecase") {
              usecases.push(filter.name)
            } else if (filter.type === "sfxcategory") {
              sfx_category.push(filter.name)
            }
          })
          this.musicInfo?.subFilters?.forEach(subfilter => {
            if (subfilter.type === "subgenre") {
              sub_genres.push(subfilter.name)
            } else if (subfilter.type === "subsfxcategory") {
              sfx_subcategory.push(subfilter.name)
            }
          })
          this.musicInfo.genres = genres;
          this.musicInfo.moods = moods;
          this.musicInfo.usecases = usecases;
          this.musicInfo.sfx_category = sfx_category;
          this.musicInfo.sub_genres = sub_genres;
          this.musicInfo.sfx_subcategory = sfx_subcategory;
        }

        if (this.musicInfo?.keywords && this.musicInfo?.keywords[0] && typeof this.musicInfo?.keywords[0] !== "string") {
          let keywords = []
          this.musicInfo?.keywords?.forEach(keyword => {
            keywords.push(keyword.keyword)
          })
          this.musicInfo.keywords = keywords
        }

        const pageType = window.location?.pathname.split("/")[1] || null;
        const musicMetaData = {
          event_type: 'liked_track',
          track_id: this.musicInfo?.id || null,
          track_name: this.musicInfo?.name || null,
          track_genre: this.musicInfo?.genres ? this.musicInfo.genres : null,
          track_moods: this.musicInfo?.moods ? this.musicInfo.moods : null,
          track_usecases: this.musicInfo?.usecases ? this.musicInfo.usecases : null,
          track_keywords: this.musicInfo?.keywords ? this.musicInfo.keywords : null,
          track_bpm: this.musicInfo?.bpm,
          // TODO: review primary artist
          // primary_artist: this.musicInfo?.tracksArtistRole?.filter(i => i.isPrimary == true).artist?.name || this.musicInfo?.tracks_artist_role?.filter(i => i.isPrimary == true).artist?.name || this.musicInfo?.artists.filter((artist) => artist.ArtistTrackRoleMappingModel.isPrimary == true)?.name || this.musicInfo?.artists[0].name,
          // track_meta: { ...this.musicInfo },
          is_vocal: this.musicInfo?.hasVocals === true || this.musicInfo?.has_vocals === "true" ? true : false,
          sfx_category: this.musicInfo?.sfx_category || null,
          sfx_subcategory: this.musicInfo?.sfx_subcategory || null,
          sub_genres: this.musicInfo?.sub_genres || null,
          track_origin_region: this.musicInfo?.region || null,
          track_release_region: this.musicInfo?.release_region || this.musicInfo?.releaseRegion || null,
          is_explicit: this.musicInfo?.isExplicit === true || this.musicInfo?.is_explicit === "true" ? true : false,
          track_type: this.musicInfo?.type || null,
          duration: this.musicInfo?.duration || null,
          display_tags: this.musicInfo?.displayTags || this.musicInfo?.display_tags || null,
          page_name: window.location?.pathname?.substring(0, 125) || null,
          page_type: window.location?.pathname?.split("/")[1]?.substring(0, 125) || null,
          page_url: window.location?.href?.substring(0, 125) || null,
          playlist_name: pageType === 'playlist' ? window.location?.pathname?.split("/")[2]?.substring(0, 125) : null,
          search_term: pageType === 'search' ? window.location?.pathname?.split("/")[2]?.substring(0, 125) : null,
        }
        this.userInfoService.logData(musicMetaData);
        this.cd.detectChanges();
      },
      (error) => {
        this.likeStatus = originalStatus;
        this.musicInfo.liked = this.likeStatus;
        this.cd.detectChanges();
      }
    );
  }

  public shareTrack(): void {
    const data = {...this.musicInfo};
    data.type = 'Track';
    data.artists = this.primaryArtists;
    this.popUpService.showPopUp('SHARE_SONG', data);
    // TODO: add analytics
  }

  public addToPlaylist(): void {
    this.popUpService.showPopUp('ADD_TO_PLAYLIST', this.musicInfo);
    // TODO: add analytics
  }
  public requestQuote() : void{
    const isUserOnboarded = this.apiService.isUserOnboarded();
    this.musicInfo.isUserOnboarded = isUserOnboarded;
    this.popUpService.showPopUp('REQUEST_QUOTE',this.musicInfo);
    let url = this.router.url.split('?')[0];
    localStorage.setItem('redirectUrl', url.split('?')[0]);

  }
 
  private preRenderWaveforms() {
    if (this.musicInfo.type) {
      this.playerService
        .getWaveForm(this.musicInfo)
        .pipe(takeUntil(this.takeUntil$))
        .subscribe(
          (waveformData) => {
            this.peaks = waveformData['data']
            this.wave.backend.setPeaks(
              waveformData["data"],
              this.musicInfo.duration
            );
            // const x = Date.now();
            this.wave.drawBuffer();
            this.cd.detectChanges();
            // console.log(Date.now() - x, '--prerender wave form Fn')
          },
          (error) => {
            this.loading = false;
            this.error = error;
            this.cd.detectChanges();
            console.log(error);
          }
        );
    }
  }

  public async redirectToSearch(searchQuery) {
    this.router.navigate(['/search'], { queryParams: { "q": searchQuery, "t": 'all' } })
  }

  pushAnalytics(link_text: string, track_name: string) {
    this.gtmAnalytics.pushCtaAnalytics({
      event: 'ga4 track_interaction',
      event_name: 'track_interaction',
      artist_name: '', // TODO: Add artist name,
      instrument_type: '',
      link_name: '',
      link_header: '',
      link_section: this.musicInfo.name,
      link_text,
      link_url: (window.location?.pathname.length > 125 ? window.location?.pathname.substring(0, 125) : window.location?.pathname) || null,
      login_status: JSON.parse(localStorage.getItem('userData'))?.id
        ? 'login'
        : 'guest',
      page_type: window.location?.pathname.split('/')[1] || null,
      search_link: '',
      search_term: '',
      track_name,
      user_id: JSON.parse(localStorage.getItem('userData'))?.id || null,
    });
  }
}
