import { Component, OnInit,Input } from '@angular/core';
import { BackendApiService } from '../services/hoopr.services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-moods',
  templateUrl: './moods.component.html',
  styleUrls: ['./moods.component.scss']
})
export class MoodsComponent implements OnInit {
  @Input() data: any;

  public popularMoods: any;
  public trendingMoods: any = [];
  public featuredCategory1: any;
  public featuredCategory2: any;
  public feedData: any;
  public searchQuery: string = '';


  constructor(
    private apiService: BackendApiService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.apiService.getMoodListFeed().subscribe(
      (response) => {
        this.feedData = response;
        this.popularMoods = this.feedData['popular'];
        this.trendingMoods = this.feedData['trending'].data;
        this.featuredCategory1 = this.feedData['featured-1'].data;
        this.featuredCategory2 = this.feedData['featured-2'].data;
      },
      (err) => {
        console.log('Error while loading feed data', err);
      }
    )


  }
  searchNavigate(){
    this.router.navigate(['/search'], { queryParams: { q: this.searchQuery, t : "mood" } });
  }

}
