import { Component, OnInit } from '@angular/core';
import { BackendApiService } from '../services/hoopr.services';

@Component({
  selector: 'app-playlists',
  templateUrl: './playlists.component.html',
  styleUrls: ['./playlists.component.scss']
})
export class PlaylistsComponent implements OnInit {

  public popularPlaylists: any;
  public trendingPlaylists: any = [];
  public featuredCategory1: any;
  public featuredCategory2: any;
  public feedData: any;

  constructor(
    private apiService: BackendApiService,
  ) { }

  ngOnInit(): void {
    this.apiService.getPlaylistListFeed().subscribe(
      (response) => {
        this.feedData = response;
        this.popularPlaylists = this.feedData['popular'];
        this.featuredCategory1 = this.feedData['featured-1'].data;
        this.featuredCategory2 = this.feedData['featured-2'].data;
        this.trendingPlaylists.push(this.feedData['trending-1']);
        this.trendingPlaylists.push(this.feedData['trending-2']);
        this.trendingPlaylists.push(this.feedData['trending-3']);
      },
      (err) => {
        console.log('Error while loading feed data', err);
      }
    )
  }
  
}
