<div class="contactus_page landing__other_sections bg-page">
  <app-header></app-header>

  <div class="contactus_page_container">
    <div class="conatctus_banner p-relative">
      <img src="assets/images/banners/static-banner.webp" alt="" class="fixed-banner">
      <div class="banner-text">
        <h1>Terms & Conditions Of Use</h1>
        <h2 class="text-normal">Everything else you want to know</h2>
      </div>
    </div>

    <div class="container policy_contents_layout mt-4">
      <p>TERMS AND CONDITIONS OF USE</p>
      <p class="">
        These Terms and Conditions of Use for the Website, Content, and Services (“Terms”) is a legally binding
        agreement between you and GSharp Media Private Limited having registered office at A-1203, Serenity Complex, Off
        Link Road, Oshiwara West Mumbai 400102 (“Company”, “we” or “us”) regarding your use of the Hoopr website at
        https://www.Hoopr.ai (the “Website”), your use of Hoopr content available via the Website (“Content”) and the
        services operated by the Company, including without limitation any apps, plugins, executables, or other software
        provided to you by the Company (collectively, the “Service(s)”).<br><br>

        A. The Website<br><br>

        1. Website Access<br>
        Subject to your compliance with these Terms, Company grants you a limited, non-exclusive, non-transferable,
        non-sublicensable license to access and make personal and non-commercial use of the Website. This license does
        not include 
        <span class="d-flex">
          <span>(a)</span>
          <span class="pl-2">
            any resale or commercial use of any components of the Website; or
          </span>
        </span>
        <span class="d-flex">
          <span>(b)</span>
          <span class="pl-2">
            any collection and use of
            any product listings, descriptions, or prices or any downloading, copying, or other use of account information.
            Any use of data mining, robots, or Terms is reserved and retained by Company or its licensors, suppliers,
            publishers, rightsholders, or other content providers and any use not explicitly licensed under a written
            agreement signed by Company is strictly prohibited. Neither the Website nor any part thereof, may be reproduced,
            duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without the express
            written consent of Company. You may not frame or utilize framing techniques to enclose any trademark, logo, or
            other proprietary information (including images, text, page layout, or form) of Company without express written
            consent. You may not use any meta tags or any other "hidden text" utilizing Company's name or trademarks without
            the express written consent of Company. You may not misuse the Website.
          </span>
        </span>
       <br>

        As a condition of use of the Website, you represent and warrant that you shall not use the Website for any
        purpose that is unlawful or prohibited by these Terms. You agree to abide by all applicable local, state,
        national and international laws and regulations and you shall be solely responsible and liable for all acts or
        omissions that occur as a result of or while you access the Website. Any licenses granted by Company terminate
        if you do not comply with these Terms. You assume all responsibility and risk for the use of this Website and
        the Internet generally. This Website is provided by Company on an “AS IS” basis and Company makes no
        representations or warranties of any kind, express or implied, as to the operation of this Website or the
        information, content, materials, or products included on this Website, to the fullest extent permissible by
        applicable law. Company disclaims all warranties, express or implied, including, but not limited to, implied
        warranties of merchantability and fitness for a particular purpose, non-infringement of intellectual property
        rights or other proprietary rights, and freedom from errors, viruses, bugs, or other harmful components.<br><br>

        2. User Created Content at the Website<br><br>

        Subject to the Company’s Privacy Policy (https://hoopr.ai/privacy-policy), any communication or material
        that you transmit to the Website or to us, whether by email or other means, for any reason, will be treated as
        non-confidential and non-proprietary user content (“User Content”). While you retain all ownership rights to the
        User Content, you grant us (including our employees and affiliates), a non-exclusive, perpetual, royalty-free,
        and worldwide right (but not the obligation) to copy, distribute, display, publish, translate, adapt, modify,
        and otherwise use the User Content for any purpose whatsoever, regardless of the form or medium in which it is
        used, and including the right to sublicense such rights in the User Content to third parties. You are solely
        responsible for any comments, content, or posts you leave on the Website. By posting information on the Website,
        or by otherwise using any communications service, message board, newsgroup, or other interactive service
        available on the Website, you agree that you will not post comments, content, messages, links, code or other
        information that:<br><br>

        <span class="d-flex">
          <span>a.</span>
          <span class="pl-2">Is unlawful, threatening, abusive, harassing, defamatory, deceptive, fraudulent, tortious,
            invasive of another’s
            privacy, or includes graphic descriptions of sexual or violent content;</span>
        </span>
        <span class="d-flex">
          <span>b.</span>
          <span class="pl-2">
            Victimizes, harasses, degrades, or intimidates an individual or group of individuals on the basis of
            religion, gender, sexual orientation, race, ethnicity, age, or disability;
          </span>
        </span>
        <span class="d-flex">
          <span>c.</span>
          <span class="pl-2">
            Infringes on any patent, trademark, trade secret, copyright, right of publicity, or other proprietary right
            of any party;
          </span>
        </span>
        <span class="d-flex">
          <span>d.</span>
          <span class="pl-2">
            Consists of unsolicited advertising, junk or bulk email (also known as “spam”), chain letters, any other
            form of unauthorized solicitation, or any form of lottery or gambling;
          </span>
        </span>
        <span class="d-flex">
          <span>e.</span>
          <span class="pl-2">
            Contains any form of malicious code, files, or programs that are designed or intended to disrupt, damage, or
            limit the functionality of any software, hardware, or telecommunications equipment or otherwise causes
            damage,
            or allows you to obtain unauthorized access to any data or other information of any third party;
          </span>
        </span>
        <span class="d-flex">
          <span>f.</span>
          <span class="pl-2">
            Breaches the security of, compromises or otherwise allows access to secured, protected or inaccessible areas
            of this Site, or attempts to gain access to other network or server via your account on this Site; or
          </span>
        </span>
        <span class="d-flex">
          <span>g.</span>
          <span class="pl-2">
            Impersonates any person or entity, including any of our employees or representatives.
          </span>
        </span>

        Company neither endorses nor assumes any liability for any material uploaded or submitted by users on any part
        of the Website. Although Company does not pre-screen, police or monitor comments posted on the Website, Company
        and its agents reserve the right to remove any and all materials uploaded or submitted that Company feels does
        not comply with these Terms or any other rules for our Website, or that are otherwise harmful, objectionable, or
        inaccurate, in Company’s sole discretion. Company is not responsible for any failure or delay in removing such
        postings.<br><br>

        3. Advertisements<br>
        From time to time, the Website may include advertisements offered by third parties. You may enter into
        correspondence with or participate in promotions of the advertisers showing their products on the Website. Any
        such correspondence or promotions, including the delivery of and the payment for goods and services by those
        third parties, and any other terms, conditions, warranties or representations associated therewith, are solely
        between you and the advertiser. Company assumes no liability, obligation or responsibility for any part of any
        such correspondence or promotion.<br><br>

        4. Third Party Sites, Information and Content at the Website<br>
        This Website may redirect or link to other websites on the Internet, or may otherwise include references to
        information, products or services made available by unaffiliated third parties. While we make every effort to
        work with trusted, reputable providers, from time to time such sites may contain information, material or
        policies that some may find inappropriate or personally objectionable. You understand that we are not
        responsible for the accuracy, completeness, decency or legality of content hosted by third party websites, nor
        are we responsible for errors or omissions in any references made on those websites. The inclusion of such a
        link or reference is provided merely as a convenience and does not imply endorsement of, or association with the
        Website or party by us, or any warranty of any kind, either express or implied. Company provides tools that
        enable you to link your account on the Website with an account on a third party service, such as YouTube. If you
        do so at your sole discretion, you may also be subject to the terms and conditions of the provider of that third
        party service provider. For example, by linking your account on Company with an account on YouTube, you are
        agreeing to be bound by and subject to YouTube’s Terms of Service which are available here:
        https://www.youtube.com/t/terms.<br><br>

        B. Services<br>
        As part of the Services, Company may make available certain apps, plugins, executables, or other software,
        including documentation with respect to such apps, plugins, executables or other software (collectively,
        “Software”). Company grants only a non-exclusive, non-transferable, limited license to use a copy of any
        Software solely for your internal purposes in conjunction with accessing and using the Content and during the
        term of your respective Plan.<br><br>

        You will not:<br>
        create or enable the creation of derivative works, modifications, or adaptations of the Software, except for the
        use of and ownership by Company (and you agree to assign all rights in such creations to Company);
        engineer, reverse engineer or otherwise create any comparable software to the Software;
        distribute or disclose the Software to third parties other than as expressly permitted hereunder;
        rent, sublicense or otherwise allow any third party to use the Software; or
        use the Software for any purpose other than as set forth in these Terms.<br><br>

        Company retains all title to the Software and any copies or derivations (whether derivation is created by
        Company or you) thereof in any form. You have only a license to use the Software as set out in these Terms. This
        is not a contract of sale of the Software. All intellectual property rights in and to the Software are retained
        by Company. You agree not to remove, deface, or destroy any copyright, patent notice, trademark, service mark,
        other proprietary markings, or confidential legends placed on or within the Software. All rights not licensed
        hereunder are expressly reserved by Company.<br><br>

        You acknowledge that the Software and the attributes of the Software including, without limitation, the design,
        functionalities, performance characteristics and your evaluation of the Software, and any other non-public
        verbal or written information that may be supplied by Company to you or obtained through your access to the
        Software, are confidential and the proprietary property information of Company (“Confidential Information”). You
        will maintain the confidentiality of all Confidential Information in the same manner that you maintain your own
        confidential information (but with no less than a reasonable degree of care).<br><br>

        You will:<br>
        not disclose any Confidential Information to any third party without the prior written consent of Company;
        limit internal access to any Confidential Information only to your employees who have a need to access the
        Software or any Confidential Information; and
        not use any Confidential Information for any purpose other than using or accessing the Content.<br><br>

        The obligations set forth in this section will not apply to Confidential Information that you can document:
        is generally available to the public other than through a breach of these Terms;
        was independently developed by you without reference to Company’s Confidential Information; or
        was already lawfully in your possession at the time of receipt of the Confidential Information from
        Company.<br><br>

        C. Content<br><br>

        1. Ownership of Content<br>
        Content includes any artistic compositions, sound recordings, published works, photos, video, graphics, music,
        sound effect, or other artistic material that can be viewed and/or licensed by users on our Website. All Content
        presented to you on this Website is, as between you and Company, the sole and exclusive property of Company (or
        its affiliates and licensors) and is protected by any and all intellectual property and/or other proprietary
        rights available within India. All such grant of rights in the Content shall be subject to and in accordance
        with these Terms and the End User License Terms. All Content provided by Company is licensed to you pursuant to
        and under these Terms. Except as explicitly set out in these Terms, you may not copy, reproduce, modify,
        republish, upload, post, transmit, resell, or distribute any Content in any form or by any means whatsoever
        without prior written permission from Company. Any unauthorized use of Content violates intellectual property
        interests and could result in criminal or civil penalties. Please note that any single user is limited to 50
        (fifty) downloads of Content per day from the Website. You may not implicitly or explicitly imply any connection
        with, sponsorship or endorsement by Company or the original creator of the Content with you, your use of
        Content, or any product or service without our separate prior written permission. In some cases, we may require
        you to incorporate notices of copyright, trademark, or other proprietary rights that we or the original creator
        or rights holder in Content requires to be incorporated into any media that contains or uses the Content. The
        Parties agree that the Intellectual Property Rights in the Content and its Derivative Works shall remain vested
        with the Company (or its affiliates or authorized licensors) during the Term. Any statutory royalty’s payment
        for the Content to the respective copyright society shall be at your sole cost and responsibility, without any
        recourse to the Company toward any author or performer in accordance with applicable laws, without any recourse
        to the Company. Please note that we have the legal capacity, either as the owner of the Content or as an agent
        of the owner/ right holder of the Content, to sub-license i.e., grant to you the permission to use the Content
        in accordance with these Terms. The Content which you have downloaded from the Website and/or Service may
        include without limitation literary and musical works (such as lyrics and musical compositions), sound
        recordings, published works, photos, video, graphics, music, sound effect, or other artistic material. The
        Content presented to you on the Website and/or Service is, as between you and the Company, is the sole and
        exclusive property of the Company (or its affiliates and authorized licensors) and is protected by any and all
        intellectual property and/or other proprietary rights as per the laws of India.<br><br>

        2. Content Plans<br>
        We may make certain Content available and to download free of charge up to a maximum of 3 (three) pieces of
        Content each month during your Term of subscription on the Application for specified purposes, however all such
        free Content is licensed to you under these Terms (even if you are not charged a Fee for such Content).
        Music, sound effect and video Content may be licensed individually (by the music track, sound effect and video)
        under a transactional license, based upon the Fee charged and collected by Company. Your license to Content is
        contingent upon your payment of the appropriate transactional Fee for, and your compliance with the terms,
        conditions and limitations associated with, the use of the Content (as posted on our Website). Your
        transactional license to Content shall continue for the full term of the copyright in such Content and you may
        use, download, reproduce, distribute, perform and display the Content for the full term of the copyright in such
        Content in multiple Projects for personal use or on social media handles of the User (“Platform(s)”).
        Notwithstanding anything to the contrary contained under this Terms, You may not:
        <span class="d-flex">
          <span>(a)</span>
          <span class="pl-2">
            Use the Content licensed
            under a transactional license as a standalone work (i.e. not incorporated into a Project) or through any
            mode or
            media which are not deemed as Platforms authorized by the Company;
          </span>
        </span>
        <span class="d-flex">
          <span>(b)</span>
          <span class="pl-2">
            Create derivative works of the Content including without limitation (cover versions, remixes, adaptations,
            renditions);
          </span>
        </span>
        <span class="d-flex">
          <span>(c)</span>
          <span class="pl-2">
            Sub-license or sub-assign
            the license to use the Content granted in accordance with this Terms and/or permit third party usage of the
            Content in any manner, except as provided under this Terms;
          </span>
        </span>
        <span class="d-flex">
          <span>(d)</span>
          <span class="pl-2">
            Use, exploit, create and/or utilize in any
            manner the Content on any platforms, modes, medium beyond the Platform(s).
          </span>
        </span>
        <br>
        Company also offers licenses to music, sound effect and video Content through various types of subscription and
        team plans (the “Plans”), which Plans can be purchased based upon the type of Content and/or combined into
        bundles that include multiple types of Content. Please refer to the Plans available at
        https://hoopr.ai/pricing. Your license to Content is contingent upon your payment of the appropriate Fee for
        the specific type of Content or bundle of Content and if you sign up and pay a Fee for a specific Plan, you
        agree to the terms, conditions and limitations associated with the Plan you choose (as posted on our Website).
        After termination or expiration of your Plan, you may not create any new Projects or otherwise prepare any
        derivatives works using the Content and you may not use the Content as a standalone work (i.e. not incorporated
        into a Project). You agree that we may change the Plans and the features applicable to the Plans from time to
        time. The prices displayed on the Website are quoted in Indian Rupees, unless otherwise indicated.
        From time to time we may make certain music or sound effect Content available on the Website (“Live Stream
        Content”) for your use in connection with the streaming of your video content over the internet for a live
        audience (each, a “Live Streaming Event”). The Content for use in such Live Streaming Event is non-exclusive,
        non-sublicensable and non-transferable and limited to the right to download, reproduce, distribute, perform, and
        display the Live Stream Content solely as combined with your work of authorship and as part of a Live Stream
        Event for a lawful purpose (each, a “Live Stream Project”). You may reproduce, distribute, perform and display
        the Live Stream Project solely on the Platform(s) on a perpetual basis only as part of the Live Stream Project
        following the creation of a Live Stream Project but the license grant does not permit you to use any Content
        used for a Live Stream Project as a standalone work, or after the termination or expiration of your Plan, create
        any new Live Stream Projects or otherwise prepare any derivatives works from Content or the Live Stream Project
        that includes the Content. If you have not purchased a Plan or a transactional license for the applicable
        Content and elect to hold a Live Streaming Event, or distribute a recording of a Live Stream Event as permitted
        hereunder, through a third party platform provider, such as YouTube, Facebook Gaming, or Twitch, you acknowledge
        and agree that we are entitled to collect applicable advertising revenue in accordance with the terms and
        conditions made available by such third party platform provider.<br><br>

        3. Limited License to Content<br>
        All Content licenses granted to you are non-exclusive, limited, non-sublicensable, and non-transferable. You may
        download, reproduce, distribute, perform and display the Content solely as combined with your own works of
        authorship for any lawful purpose (the combination of Content and your own works of authorship is referred to as
        a “Project”) to be exploited solely on the Platform(s) (defined herein). The term of your license to Content
        incorporated within a Project is perpetual and allows you to reproduce, distribute, perform and display Projects
        for any lawful purpose on the Platform(s) in compliance with the Terms.
        All rights not expressly granted to you by Company are hereby reserved. You may not sublicense or assign the use
        of the Content for standalone distribution. You shall not
        <span class="d-flex">
          <span>(a)</span>
          <span class="pl-2">
            Make available, distribute, resell, or perform the
            Content separately from the Project i.e. on a stand-alone basis into which the Content has been incorporated
            or
            on any platforms, modes, medium save and except for Platform(s) (defined below);
          </span>
        </span>
        <span class="d-flex">
          <span>(b)</span>
          <span class="pl-2">
            Use the Content in
            connection with defamatory, or fraudulent content or in connection with pornographic, unlawful or illegal
            images
            (including without limitation adult content or videos, adult entertainment venues, escort services), sounds,
            or
            content, or any depictions of illegal activity whatsoever, whether directly or in context or by
            juxtaposition
            with other materials;
          </span>
        </span>
        <span class="d-flex">
          <span>(c)</span>
          <span class="pl-2">
            Make any change in the language, or translate the Content;
          </span>
        </span>
        <span class="d-flex">
          <span>(d)</span>
          <span class="pl-2">
            Modify, alter or change
            the Content (except incorporating into a larger work is permitted), including altering the harmonic
            structure or
            melody of the Content; or
          </span>
        </span>
        <span class="d-flex">
          <span>(e)</span>
          <span class="pl-2">
            Remove any proprietary or intellectual property markings or notices on any
            Content. Certain subscription plans may include additional license limitations, such as limiting the use of
            an
            Offering to one or more particular media.
          </span>
        </span>
        <br><br>

        4. Royalties & Music Cue Sheets<br>
        In the event you arrange a public performance of any work that includes Content in connection with any medium
        that retains valid performance licenses from the Indian Performing Rights Society (“IPRS”), Phonographic
        Performance Limited (“PPL”), or other applicable performing rights society, you shall deliver to us a music cue
        sheet with regard to such work within thirty (30) days of the initial commercial broadcast of such work.
        Notwithstanding anything else herein, if a work incorporating Content, or part thereof, is distributed through a
        medium not owned by you, then you acknowledge and agree that such medium must have blanket performance licensing
        in place with such performance rights organizations, or a separate performance fee must be negotiated in good
        faith and paid to the publisher. You agree and undertake to be liable to pay any and all statutory royalties
        (including performance royalties) as applicable and in a manner in accordance with the applicable laws in force
        of the jurisdiction as shall be applicable for collection of royalties and the Company shall not be responsible
        thereof in any manner.<br><br>

        5. Content Availability and Indexing<br>
        Once we have selected specific Content available to you under your Plan, we encourage you to download the
        Content promptly. As a convenience to you we may continue to make the specific Content available to you under
        your Plan available for re-download, but we do not guarantee that such Content will be available for re-download
        and Company will not be liable to you if it becomes unavailable for further re-download. We may add or remove
        Content from our Plans and our Service at any time and make no guarantee as to the availability of specific
        Content in any Plan or as to any minimum amount of Content in any Plan. Some of our Content may be offered in
        limited territories, and we may use geo-filtering technology to restrict access outside of those territories
        presently or in the future. You understand and agree that temporary interruptions of service may occur as normal
        events that are out of our control. You also understand and agree that we have no control over the third-party
        networks or service(s) that we may use to provide you with Content. You agree that the Content and Services
        available on this Site are provided “AS IS” and that we assume no responsibility for the timeliness, deletion,
        mis-delivery or failure to store any Content, communications, or personalization settings. While we have made
        reasonable efforts to correctly categorize, keyword, caption, description and title the Content, we do not
        warrant the accuracy of such information.<br><br>

        6. Content Restrictions<br>
        You may not
        <span class="d-flex">
          <span>(i)</span>
          <span class="pl-2">
            transfer, copy or display Content, except as permitted in these Terms;
          </span>
        </span>
        <span class="d-flex">
          <span>(ii)</span>
          <span class="pl-2">
            sell, rent, lease, distribute, or broadcast any Content except as permitted in these Terms;
          </span>
        </span>
        <span class="d-flex">
          <span>(iii)</span>
          <span class="pl-2">
            remove any proprietary notices or labels on Content;
          </span>
        </span>
        <span class="d-flex">
          <span>(iv)</span>
          <span class="pl-2">
            attempt to disable, bypass, modify, defeat, or otherwise circumvent any digital rights management or other
            protection system applied to Content or used as part of the Service;
          </span>
        </span>
        <span class="d-flex">
          <span>(v)</span>
          <span class="pl-2">
            use the Service or Content for any obscene or illegal purpose.
          </span>
        </span>
        <br><br>

        7. Monetization of Your Projects on Third Party Platforms<br>
        Subject to your compliance with these Terms, you may allow, and receive payment for, the display of third party
        ads in connection with uploading your Projects (such process, to “Monetize”) on third party platforms, such as
        www.YouTube.com or any of YouTube’s applications, that provide Monetization capabilities (each, a “Third Party
        Platform”). In order to Monetize Projects on a Third Party Platform, you must:
        <span class="d-flex">
          <span>(a)</span>
          <span class="pl-2">
            register your profile channel(s) on which you upload your Projects onto the Third Party Platform (each, a
            “Channel”) with Company in
            accordance with the instructions that Company provides from time to time; or
          </span>
        </span>
        <span class="d-flex">
          <span>(b)</span>
          <span class="pl-2">
            follow the instructions that
            Company provides from time to time to generate a single use code to register each item of Content in each
            Project (for example, each song that you license from Company and use in a Project must have its own single
            use
            code for that specific Project), but please be aware that the single use code is only applicable to (i) each
            specific item of Content you licensed; and (ii) one specific Project (which means you have to generate a new
            single use code if you use the same item of Content in a different Project). Once your Channel or specific
            Project is properly registered with Company, it is considered “Listed”; and
          </span>
        </span>
        <span class="d-flex">
          <span>(c)</span>
          <span class="pl-2">
            accord due credits to the
            original creator of the Content being licensed through the Company for Monetization. Please note that you
            are
            only able to List your Projects and Channels while you have an active subscription to Company. If you did
            not
            successfully List your Projects and/or Channels prior to ending your subscription, you will not be able to
            do so
            until you purchase a new subscription.
          </span>
        </span>
        <br>

        Please be aware that Company Monetizes Content on Third Party Platforms that it believes is unlicensed. When
        your Channels and/or Projects are not Listed or are not Listed correctly, Company is unable to tell a licensed
        use of Content from an unlicensed use of Content. In some cases, your Project or Channel may be properly Listed
        but Company’s process may still accidentally and incorrectly interpret your use of Content to be unlicensed. If
        Company Monetizes one of your Projects licensed under these Terms or you otherwise receive a claim from the
        Third Party Platform regarding your Project’s use of unlicensed Content, you must ensure your Project and/or
        Channels are correctly Listed and:
        <span class="d-flex">
          <span>(a)</span>
          <span class="pl-2">
            notify Company at Support@Hoopr.Ai and provide (i) the email address you
            used to sign up for your account with Company, (ii) the title of the applicable Project as it appears on the
            Third Party Platform, (iii) the URL to the uploaded Project, (iv) a screenshot of the claim you received,
            and
            (v) any other information Company requests;
          </span>
        </span>
        <span class="d-flex">
          <span>(b)</span>
          <span class="pl-2">
            use the features available on the Content ID tab of your account
            page on Company and submit the URL of the Project on the Third Party Platform; or
          </span>
        </span>
        <span class="d-flex">
          <span>(c)</span>
          <span class="pl-2">
            follow the claim process
            at https://hoopr.ai/copyright, in order for Company to discontinue Monetizing your Project and/or to
            release
            the claim against your uploaded Project.
          </span>
        </span>
        <br>

        Under no circumstances will Company have any responsibility to you or any third party for, or in connection,
        with your inability to Monetize any Projects or any claims against your Projects that you upload onto any Third
        Party Platforms or your Channel(s). Further, Company will not reimburse you or any third party in any manner if
        Company Monetizes any of your Projects on a Third Party Platform or for your inability to Monetize any of your
        Projects regardless of the reason why.<br><br>

        D. Your Account<br>
        If you do not have a Company account, you will need to establish an account with Company to access the Content
        and/or use the Services that are licensed for a fee via the website. All use of Content is conditioned upon
        payment of the appropriate fee (the “Fee”) for the Content that you use (and if you do not pay the appropriate
        Fee, you may not use the Content included within the Fee). You are responsible for maintaining the
        confidentiality of your account and password and for restricting access to your account, and you agree to accept
        responsibility for all activities that occur under your account or password. If you have an individual Plan, you
        may not share your account or account login and password with anyone else. Some Plans we provide are “team”
        plans, in which case you may not provide or share your team account or team account login credentials and/or
        passwords with anyone outside of the covered team and you agree that the administrator for your team Plan will
        be responsible for providing and monitoring individual access by your team members, as well as revoking
        individual access covered under the team Plan when any individual leaves the team that is covered by the Plan. A
        Company license under a team Plan is a license to the team and not the individuals within the team, even if
        individuals within the team may have their own access credentials/passwords; individuals that leave a team
        covered under a team Plan are no longer licensed to use the Content covered under the team Plan (provided that
        such individuals may sign up for an individual Plan in order to have continued use of Content). Company also
        reserves the right to limit the number of individuals/unique access credentials within a team Plan and/or revoke
        access to individual team members or to the team, collectively. You are responsible for all of your team members
        and personnel, including, without limitation, any third-party vendors or contractors, and their use of the
        Website, Content, and Services.<br><br>

        E. Fees and Renewal<br>
        Fees for your Content, Plan or Service will be stated at the time of your purchase or sign-up, as applicable,
        and provided in our Website pages. All fees are payable in advance. The fees for Plans or Services may change at
        any time. Fees may be subject to tax, collected by us or a third party through which you transact, and except as
        set out in these Terms are non-refundable. We have multiple payment modes including: 
        <span class="d-flex">
          <span>(a)</span>
          <span class="pl-2">
            credit card; 
          </span>
        </span>
        <span class="d-flex">
          <span>(b)</span>
          <span class="pl-2">
            UPI;
          </span>
        </span>
        <span class="d-flex">
          <span>(c)</span>
          <span class="pl-2">
            debit card;
          </span>
        </span>
        <span class="d-flex">
          <span>(d)</span>
          <span class="pl-2">
            Net-banking etc. If your Plan or Service involves a recurring payment of a Fee, then the
            same be deducted solely in accordance with applicable laws. Unless you notify us before a charge that you want
            to cancel or do not want to automatically renew your Plan or Service, you understand it will automatically
            continue and you authorize us, or a third party through which you transact (without notice to you, unless
            required by applicable law) to collect the then-applicable Fee and any taxes, using any credit card we have on
            record for you. If all payment modes we have on file for you are declined for payment of Fees, we may cancel
            your Plan or Service, as applicable, unless you make the necessary payment of Fees.
          </span>
        </span>
        <br>

        F. Cancellation<br>
        You may cancel your Plan or Service by visiting page provided on the Company website and adjusting your Plan or
        Service settings and subscription preferences, or by contacting our Customer Service team. If you cancel your
        Plan or Service, you will not receive a refund of any Fees already paid, unless otherwise stated in these
        Terms.<br><br>

        G. Service Restrictions<br>
        We reserve the right to accept or refuse or to restrict your use of any Plan or Service in our discretion. You
        may not transfer or assign your Plan or Service benefits, or any Content you access. We may take actions we deem
        reasonably necessary to prevent fraud and abuse, including placing restrictions on the amount of Content that
        can be accessed at any one time.<br><br>

        H. Changes to Terms<br>
        We may, in our discretion, change the Terms and all elements of them and any aspect of any Plan or Service,
        without notice to you. If any change to the Terms is found invalid, void, or for any reason unenforceable, that
        change is severable and does not affect the validity and enforceability of any remaining changes and the
        remainder of the Terms.
        Your continued use of the Content, Plan and/or Service after we change the Terms constitutes your acceptance of
        the changes. If you do not agree to any changes, you must not use the Content, Plan or Service.<br><br>

        I. Termination by Company<br>
        Our business may change over time and we reserve the right to cancel any specific Plan or Service and change
        Content, in whole or in part, and to terminate your access to and use of the Content, Plan, or Service at our
        discretion without notice. If we do so, we may give you a prorated refund based on the number of days remaining
        in your Plan unless we terminate your Plan or access to Content for conduct that we determine, in our
        discretion, violates the Terms, violates any applicable law, involves fraud or misuse of the Content, Plan or
        Service, or is harmful to our interests or another user in which case no refund will become applicable. Our
        failure to insist upon or enforce your strict compliance with the Terms will not constitute a waiver of any of
        our rights. Upon termination and regardless of the reason(s) motivating such termination, your right to use the
        Content available on this Website will immediately cease and be revoked, save and except such Content which have
        already been published on the Platform(s) solely as part of the Project, which shall be available until
        perpetuity. We shall not be liable to you or any third party for any claims for damages arising out of any
        termination or suspension or any other actions taken by us in connection therewith.<br><br>

        J. Indemnity<br>
        You hereby indemnify the Company against all liabilities, demands and / or claims whatsoever, including but not
        limited to third party claims, for loss, damages, costs, expenses including reasonable attorney’s fees arising
        for not being in compliance with applicable laws, rules and regulations and also against any losses, damages or
        expenses suffered or incurred or that may be suffered or incurred by the Company for any reason whatsoever
        including as a result of and / or in relation to, any breach and / or non-fulfilment, non-performance by you and
        any of his/ her representations, warranties, undertakings terms and covenants set out in this Terms and
        infringement of or claim on any Intellectual Property Rights in relation to the Content thereof. To the fullest
        extent permitted by applicable law, you hereby waive all claims against the Company and its affiliates,
        authorized licensors, distributors or financiers for any indirect, incidental, punitive, and consequential
        damages In no event will the Company be liable for any indirect, incidental, special, punitive or consequential
        damages, including loss of profits, incurred by you, whether in an action in contract, breach of warranty or
        tort, even if you have been advised of the possibility of such damages.<br><br>

        K. Disputes Will Be Settled by Courts<br>
        Any dispute or claim relating in any way to these Terms or your use of the Content, Plan, or Service will be
        resolved by the exclusive jurisdiction of courts of Mumbai, India, in accordance with the laws of India.<br><br>

        L. Electronic Communications<br>
        When you use the Company Website, Content or Services, or send e-mails, text messages, and other communications
        from your desktop or mobile device to us, you may be communicating with us electronically. You consent to
        receive communications from us electronically, such as e-mails, texts, mobile push notices, or notices and
        messages on this site or through the other the Company Website, Content or Services, and you can retain copies
        of these communications for your records. You agree that all agreements, notices, disclosures, and other
        communications that we provide to you electronically satisfy any legal requirement that such communications be
        in writing.<br><br>

        M. Copyright and Trademarks<br>
        All Content included in or made available through Company is the owned and/or licensed property of Company or
        its content suppliers and protected by Indian (Copyright Act, 1957) and international copyright laws. All
        trademarks, graphics, logos, page headers, buttons, icons, scripts, and service names included in or made
        available through Company are trademarks or trade dress of Company in the India and other countries. Company's
        trademarks and trade dress may not be used in connection with any product or service that is not Company's, in
        any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits
        Company. All other trademarks not owned by Company but available via the Website, Content or Services are the
        property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by
        Company.<br><br>

        N. International Use, Sanctions and Export Policy<br>
        Although this Website may be accessible worldwide, Company makes no representation that materials on this
        Website are appropriate or available for use in locations outside India. Those who choose to access this Website
        from other locations do so on their own initiative and at their own risk. If you choose to access this Website
        from outside India, you are responsible for compliance with local laws in your jurisdiction, including but not
        limited to, the taxation of products purchased over the Internet. Any offer for any Content or Service, and/or
        information made in connection with this Website, is void where prohibited. You may not use any Company Content
        or Services if you are the subject of Indian sanctions or of sanctions consistent with Indian law imposed by the
        governments of the country where you are using the Company Website, Content or Services. You must comply with
        all Indian or other export and re-export restrictions that may apply to goods, software, technology, and
        services.<br><br>

        O. DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY<br>
        THE COMPANY WEBSITE, CONTENT OR SERVICES AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING APPS) AND
        OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE COMPANY WEBSITE, CONTENT OR SERVICES
        ARE PROVIDED BY COMPANY ON AN "AS IS" AND "AS AVAILABLE" BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING. COMPANY
        MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE COMPANY
        WEBSITE, CONTENT OR SERVICES, OR THE INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING APP) OR OTHER SERVICES
        INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE COMPANY WEBSITE, CONTENT OR SERVICES, UNLESS
        OTHERWISE SPECIFIED IN WRITING. YOU EXPRESSLY AGREE THAT YOUR USE OF THE COMPANY WEBSITE, CONTENT OR SERVICES IS
        AT YOUR SOLE RISK.<br>
        TO THE FULL EXTENT PERMISSIBLE BY LAW, COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT
        LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. COMPANY DOES NOT WARRANT
        THAT THE COMPANY WEBSITE, CONTENT OR SERVICES, INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING APPS) OR
        OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE COMPANY WEBSITE, CONTENT OR SERVICES,
        COMPANY'S SERVERS OR ELECTRONIC COMMUNICATIONS SENT FROM COMPANY ARE FREE OF VIRUSES OR OTHER HARMFUL
        COMPONENTS. TO THE FULL EXTENT PERMISSIBLE BY LAW, COMPANY WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND
        ARISING FROM THE USE OF ANY COMPANY SERVICE, OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING
        SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH ANY COMPANY SERVICE,
        INCLUDING, BUT NOT LIMITED TO PUNITIVE, SPECIAL, INDIRECT, EXEMPLARY, CONSEQUENTIAL OR INCIDENTAL DAMAGES,
        UNLESS OTHERWISE SPECIFIED IN WRITING.<br>
        SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE LIMITATIONS
        MAY NOT APPLY TO YOU. PLEASE CONSULT THE LAWS IN YOUR JURISDICTION.<br>
        IN NO EVENT SHALL WE OR OUR AFFILIATES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY PUNITIVE, SPECIAL, INDIRECT,
        EXEMPLARY, CONSEQUENTIAL OR INCIDENTAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER, INCLUDING, WITHOUT
        LIMITATION, THOSE RESULTING FROM LOSS OF USE, DATA OR PROFIT LOSS, WHETHER OR NOT WE HAVE BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES, AND ON ANY THEORY OF LIABILITY, ARISING OUT OF OR IN CONNECTION WITH THE USE OF
        THIS SITE OR OF ANY WEBSITE REFERENCED OR LINKED TO FROM THIS SITE WHETHER THE ACTION IS BASED ON CONTRACT, TORT
        (INCLUDING NEGLIGENCE), INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OR OTHERWISE. IN THE EVENT THAT APPLICABLE
        LAW DOES NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR DAMAGES, YOU AGREE THAT IN NO EVENT SHALL THE
        TOTAL LIABILITY OF COMPANY TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION OF ANY KIND EXCEED THE AMOUNT OF
        SUBSCRIPTION FEE PAID BY YOU TO THE COMPANY.<br>
        FURTHER, WE SHALL NOT BE LIABLE IN ANY WAY FOR THIRD PARTY PROMISES REGARDING CONTENT OR CONTENT OR FOR
        ASSISTANCE IN CONDUCTING COMMERCIAL TRANSACTIONS WITH THE THIRD PARTY THROUGH THIS SITE, INCLUDING WITHOUT
        LIMITATION THE PROCESSING OF ORDERS.<br>
        SOME JURISDICTIONS PROHIBIT THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO
        THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. PLEASE CONSULT THE LAWS IN YOUR JURISDICTION.<br><br>

        P. Jurisdiction and Governing Law<br>
        These Terms shall be construed, governed and enforced under the laws of India (State of Maharashtra). You agree
        that venue for all actions, relating in any manner to these Terms, shall be Mumbai, Maharashtra. Each party
        agrees that a final judgment in any such action shall be conclusive and may be enforced in any other
        jurisdiction in any manner provided by law.<br><br>

        Q. Take Down Notices & Claims<br>
        We respect the intellectual property rights of others, and we ask you to do the same. In instances where we are
        notified of alleged infringing Company or User Content or Content through our Designated Agent, a decision may
        be made to remove access or disable access to such materials, in compliance with the safe harbour provisions of
        the Copyright Act, 1957. We may also make a good faith attempt to contact the person who submitted the affected
        material so that they may make a counter-notification. If you believe that you or someone else’s copyright has
        been infringed on this Website or the Service, you (or the owner or rights holder, collectively, “Rights
        Holder”) should send notification to our Designated Agent immediately. Prior to sending us notice, the Rights
        Holder may wish to consult a lawyer to determine their rights and legal obligations under the Copyright Act,
        1957 and any other applicable laws. Nothing here or anywhere on this Website is intended as a substitute for
        qualified legal advice. To file a Notice of Infringing Material, we ask that the Rights Holder to visit
        https://hoopr.ai/copyright and provide the following information:<br>
        Reasonably sufficient details about the nature of the copyrighted work in question, or, in the case of multiple
        alleged infringements, a representative list of such works. This should include, title(s), author(s), any Indian
        Copyright Registration number(s), URL(s) etc.;
        Reasonably sufficient details to enable us to identify and locate the material that is allegedly infringing the
        Rights Holders’ work(s) (for example, file name or URL of the page(s) that contain(s) the material);
        The Rights Holder’s contact information so that we can contact them (including for example, the Rights Holder’s
        address, telephone number, and email address);
        A statement that the Rights Holder has a good faith belief that the use of the material identified above in 2 is
        not authorized by the copyright owner, its agent, or the law;
        A statement, under penalty of perjury, that the information in the notification is accurate and that the Rights
        Holder is authorized to act on behalf of the copyright owner; and
        The Rights Holder’s electronic signature.<br><br>

        Notice may be sent to:<br>
        By Mail: A-1203, Serenity Complex, Off Link Road, Oshiwara West Mumbai 400102 Attn: Gaurav Dagaonkar.<br>
        By E-Mail: Support@Hoopr.Ai; Claims@Hoopr.Ai<br>
        If Material That You Have Posted To Our Website Has Been Taken Down, You May File A Counter-Notification That
        Contains The Following details:<br>
        Identification of the material that has been removed or to which access has been disabled and the location at
        which the material appeared before it was removed or access to it was disabled;
        A statement, under penalty of perjury, that you have a good faith belief that the material was removed or
        disabled as a result of mistake or misidentification of the material in question;
        Your name, address and telephone number;
        A statement that you consent to the jurisdiction of Mumbai, India, and that you will accept service of process
        from the person who provided notification in compliance with Copyright Act, 1957, or an agent of such person.
        Your physical or electronic signature.<br><br>

        Notice may be sent to:<br>
        By Mail: A-1203, Serenity Complex, Off Link Road, Oshiwara West Mumbai 400102 Attn: Gaurav Dagaonkar<br>
        By e-mail: Support@Hoopr.Ai; Claims@Hoopr.Ai<br>
        You also acknowledge and agree that upon receipt of a notice of a claim of copyright infringement, we may
        temporarily or permanently remove the identified materials from our site without liability to you or any other
        party. Repeat infringers will be terminated and barred from using the Website.<br><br>

        R. Miscellaneous Conditions Applicable to All Users of Website, Content Or Services<br>
        In addition to any excuse provided by applicable law, Company shall be excused from liability for non-delivery
        or delay in delivery of products, Content, or Services available through our Website arising from any event
        beyond our reasonable control, whether or not foreseeable by either party, including but not limited to: labour
        disturbance, war, fire, accident, adverse weather, inability to secure transportation, governmental act or
        regulation, and other causes or events beyond our reasonable control, whether or not similar to those which are
        enumerated above. If any part of these Terms is held invalid or unenforceable, that portion shall be construed
        in a manner consistent with applicable law to reflect, as nearly as possible, the original intentions of the
        parties, and the remaining portions shall remain in full force and effect. These Terms constitute the entire
        agreement and understanding between the parties concerning the subject matter hereof and supersedes all prior
        agreements and understandings of the parties with respect thereto. These Terms may NOT be altered, supplemented,
        or amended by the use of any other document(s). To the extent that anything in or associated with this Website
        is in conflict or inconsistent with these Terms, these Terms shall take precedence. If you learn that any
        Content is subject to a threatened or actual third party claim of infringement, violation of another right, or
        any other claim for which Company, the Content contributors or any of their respective affiliates may be liable,
        you will promptly notify Company of any such claim. If Company, the Content contributors or any of their
        respective affiliates learns of such a claim from you, the third party or otherwise and Company, in its sole
        good faith discretion, determine that the claim raises an inappropriate legal risk, upon notice from us, you
        will (i) remove the Content from your computer systems and storage devices (electronic or physical), and (ii)
        cease any future use of the Content at your own expense if possible. If you do remove and cease use of the
        Content, we may choose to refund your license fees for the applicable Content or without charge provide you with
        other content that we determine with your consent, not to be unreasonably withheld or delayed, is comparable,
        subject to the other terms and conditions of these Terms. The Terms shall survive termination or expiration of
        your use of the Website or your Plan. Company, its content contributors or any of their respective affiliates
        are intended third party beneficiaries of these terms; nothing in these Terms, express or implied, is intended
        to or shall confer upon any other third party any rights, benefits or remedies of any nature whatsoever. You
        shall promptly reimburse Company, its content contributors and any of their respective affiliates for any costs
        (including reasonable attorneys' fees and court costs) that are incurred in collecting any Fees or enforcing
        these Terms.<br><br>

      </p>
    </div>

  </div>
  <section class="contact">
    <div class="pageContainer p-3 px-md-4 px-xl-5">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6">
          <h3 class="sub-title">We’re happy to answer questions and get you acquainted with Hoopr, Didn't find your
            answers here?</h3>
          <div class="divider"></div>
          <a routerLink="/contactus" id="tc_contact_us" class="btn btn-brand">Contact us</a>
        </div><!-- col-6 -->
      </div><!-- row -->
    </div><!-- page container -->
  </section><!-- contact -->
</div>
<footer></footer>