import { WindowRefService } from "./../services/window-ref.service";
import { PopupService } from "./../services/popup.service";
import { environment } from "./../../environments/environment";
import { DeviceDetectorService } from 'ngx-device-detector';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { fromEvent, Subject } from "rxjs";
import { debounceTime, filter, skip, takeUntil, throttleTime } from "rxjs/operators";
// import { subscribeMessage } from "../popup/popup.component";
import { BackendApiService } from "../services/hoopr.services";
import { LoggerServiceService } from "../services/logger-service.service";
import { PlayerService } from "../services/player.service";
import { formatDuration } from "../services/utils";
declare var $: any;
declare var window: any;
import { Options } from '@angular-slider/ngx-slider';
import { UserInfoService } from "../services/user-info.service";
// import { Stem } from "src/swagger";
import { UtilsService } from "../services/utils.service";
import { SwalHelper } from "../_helpers/swal-helpers";
import { GtmAnalyticsService } from "../services/gtm-analytics.service";
declare var window: any;

declare const WaveSurfer;
@Component({
  selector: "global-player",
  templateUrl: "./global-player.component.html",
  styleUrls: ["./global-player.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalPlayerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("mainWaves", { static: true }) mainWaves: ElementRef;
  @ViewChild("likeBtn") likeBtn: any;

  @HostListener("document:keydown.space", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    if (
      event.code === "Space" &&
      document.activeElement === document.body &&
      this.musicInfo?.id
    ) {
      this.playPause();
      event.preventDefault();
    }
  }

  public musicInfo: any;
  public playing = false;
  public trackPeaks = undefined;
  public primaryArtists: any = [];
  public wave;
  public currentTime: string = "0:00";
  public loading: boolean;
  public completeTrackLoaded = false;
  public trackurl;
  private environment = environment;
  public sliderValue = 0;

  private BaseUrl = environment.baseUrlBackend;
  private dontShow: boolean;
  private totalAutoPlayTime: number = 0.0;

  public sliderOptions: Options = {
    floor: 1,
    ceil: 100,
    showSelectionBar: true
  }
  SKIP_SECONDS = 10;
  takeUntil$ = new Subject();
  showSubscribePopup;

  formatDuration = formatDuration;
  likeStatus: boolean = false;
  signInwarning: boolean;
  token: string;
  private userData = undefined;

  // subscribeMessage = subscribeMessage;
  waveSmallInner = true;
  waveSmallInnerWidth;

  deviceInfo;
  isMobile;
  isTablet;
  isDesktopDevice;

  public waveSliderWidth;


  constructor(
    private cd: ChangeDetectorRef,
    private playerService: PlayerService,
    public router: Router,
    public apiService: BackendApiService,
    private logger: LoggerServiceService,
    private winRef: WindowRefService,
    private popUpService: PopupService,
    private deviceService: DeviceDetectorService,
    private userInfoService: UserInfoService,
    public utilsService: UtilsService,
    private gtmAnalytics: GtmAnalyticsService
  ) {
    this.deviceDetect();
  }

  ngOnInit() {
    this.userData = this.apiService?.getUserData();
    this.token = this.apiService?.getToken();
    // this.trackurl = this.environment.baseUrlFrontend + "/track/" + this.musicInfo?.name_slug
    this.primaryArtists = this.musicInfo?.tracksArtistRole?.filter(i => i.isPrimary == true) || this.musicInfo?.tracks_artist_role?.filter(i => i.isPrimary == true)
    this.userData?.likedTracks?.forEach((trackId) => {
      if (this.musicInfo?.id === trackId) {
        this.likeStatus = true;
        this.musicInfo.liked = true;
      }
    });
  }

  deviceDetect() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  ngAfterViewInit() {

    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        if (e.url === "/") {
          this.onClose();
        }
      });
    const shadow = this.mainWaves.nativeElement.attachShadow({ mode: "open" });
    this.wave = this.createWave();

    shadow.appendChild(this.mainWaves.nativeElement.children[0]);
    this.registerWaveEvents();

    this.playerService.musicInfo
      .pipe(takeUntil(this.takeUntil$))
      .subscribe((currentMusicInfo) => {
        // this.toggleCollapse();
        // this.primaryArtist = currentMusicInfo?.artists?.find(artist => artist.ArtistTrackRoleMappingModel.isPrimary === true) || currentMusicInfo?.artists[0]
        // this.primaryArtist = currentMusicInfo?.tracksArtistRole?.find(i => i.isPrimary == true).artist || currentMusicInfo?.tracks_artist_role?.find(i => i.isPrimary == true).artist || currentMusicInfo?.artists?.find(artist => artist.ArtistTrackRoleMappingModel.isPrimary == true) || currentMusicInfo?.artists[0]
        if (!currentMusicInfo || !Object.keys(currentMusicInfo).length) return;

        this.primaryArtists = currentMusicInfo?.tracksArtistRole?.filter(i => i.isPrimary == true) || currentMusicInfo?.tracks_artist_role?.filter(i => i.isPrimary == true)
        if (currentMusicInfo?.id !== this.musicInfo?.id) {
          this.playerService.pause.next({ id: this.musicInfo?.id });
          this.loading = false;
          this.completeTrackLoaded = true;
          let peaks = currentMusicInfo.peaks;
          this.trackPeaks = currentMusicInfo.peaks;

          this.playerService
            ._getTrack(currentMusicInfo)
            .pipe(takeUntil(this.takeUntil$))
            .subscribe((track) => {
              this.wave.load(track, peaks, 'auto');
              if (
                !currentMusicInfo.currentTime ||
                currentMusicInfo.currentTime == '0:00' ||
                currentMusicInfo.currentTime == 0
              ) {
                let user = this.apiService.getUserData();
                this.wave.play(currentMusicInfo.currentTime || 0);
                this.logger.logTrackEvent(
                  "Song Streamed",
                  currentMusicInfo.id,
                  currentMusicInfo.name
                );
                // log data to backend

                if (currentMusicInfo.asset_type) {
                  if (currentMusicInfo.type === "music") {
                    currentMusicInfo.sub_genres = currentMusicInfo.sub_filters
                  } else {
                    currentMusicInfo.sfx_subcategory = currentMusicInfo.sub_filters
                    currentMusicInfo.sfx_category = currentMusicInfo.filters
                  }
                }
                else if (currentMusicInfo.filters) {
                  const genres = [];
                  const moods = [];
                  const usecases = [];
                  const sub_genres = [];
                  const sfx_subcategory = [];
                  const sfx_category = [];
                  currentMusicInfo?.filters?.forEach(filter => {
                    if (filter.type === "genre") {
                      genres.push(filter.name)
                    } else if (filter.type === "mood") {
                      moods.push(filter.name)
                    } else if (filter.type === "usecase") {
                      usecases.push(filter.name)
                    } else if (filter.type === "sfxcategory") {
                      sfx_category.push(filter.name)
                    }
                  })
                  currentMusicInfo?.subFilters?.forEach(subfilter => {
                    if (subfilter.type === "subgenre") {
                      sub_genres.push(subfilter.name)
                    } else if (subfilter.type === "subsfxcategory") {
                      sfx_subcategory.push(subfilter.name)
                    }
                  })
                  currentMusicInfo.genres = genres;
                  currentMusicInfo.moods = moods;
                  currentMusicInfo.usecases = usecases;
                  currentMusicInfo.sfx_category = sfx_category;
                  currentMusicInfo.sub_genres = sub_genres;
                  currentMusicInfo.sfx_subcategory = sfx_subcategory;
                }

                if (currentMusicInfo?.keywords && currentMusicInfo?.keywords[0] && typeof currentMusicInfo?.keywords[0] !== "string") {
                  let keywords = []
                  currentMusicInfo?.keywords?.forEach(keyword => {
                    keywords.push(keyword.keyword)
                  })
                  currentMusicInfo.keywords = keywords
                }

                const pageType = window.location?.pathname.split("/")[1] || null;
                const musicMetaData = {
                  event_type: 'song_streamed',
                  track_id: currentMusicInfo?.id?.substring(0, 125) || null,
                  track_name: currentMusicInfo?.name?.substring(0, 125) || null,
                  track_genre: currentMusicInfo?.genres ? currentMusicInfo?.genres : null,
                  track_moods: currentMusicInfo?.moods ? currentMusicInfo?.moods : null,
                  track_usecases: currentMusicInfo?.usecases ? currentMusicInfo?.usecases : null,
                  track_keywords: currentMusicInfo?.keywords ? currentMusicInfo?.keywords : null,
                  track_bpm: currentMusicInfo?.bpm,
                  //  TODO: review primary_Artist key
                  // primary_artist: currentMusicInfo?.artists.find((artist) => artist.ArtistTrackRoleMappingModel.isPrimary === true)?.name || currentMusicInfo?.artists[0].name,
                  primary_artist: currentMusicInfo.tracksArtistRole?.filter(i => i.isPrimary == true),
                  // track_meta: { ...this.musicInfo },
                  is_vocal: currentMusicInfo?.hasVocals === true || currentMusicInfo?.has_vocals === "true" ? true : false,
                  sfx_category: currentMusicInfo?.sfx_category || null,
                  sfx_subcategory: currentMusicInfo?.sfx_subcategory || null,
                  sub_genres: currentMusicInfo?.sub_genres || null,
                  track_origin_region: currentMusicInfo?.region || null,
                  track_release_region: currentMusicInfo?.release_region || currentMusicInfo?.releaseRegion || null,
                  is_explicit: currentMusicInfo?.isExplicit === true || currentMusicInfo?.is_explicit === "true" ? true : false,
                  track_type: currentMusicInfo?.type || null,
                  duration: currentMusicInfo?.duration || null,
                  display_tags: currentMusicInfo?.displayTags || currentMusicInfo?.display_tags || null,
                  page_name: window.location?.pathname?.substring(0, 125) || null,
                  page_type: window.location?.pathname.split("/")[1]?.substring(0, 125) || null,
                  page_url: window.location?.href?.substring(0, 125) || null,
                  playlist_name: pageType === 'playlist' ? window.location?.pathname?.split("/")[2]?.substring(0, 125) : null,
                  search_term: pageType === 'search' ? window.location?.pathname?.split("/")[2]?.substring(0, 125) : null,
                  stem: null, // TODO: review
                }
                this.userInfoService.logData(musicMetaData);
              } else {
                this.dontShow = false;
                this.wave.play(currentMusicInfo.currentTime || 0)
              }
            });
        } else if (!this.loading && this.completeTrackLoaded) {
          this.wave.play(currentMusicInfo.currentTime || 0);
        }
        if (this.dontShow === true) {
          return;
        }
        this.musicInfo = currentMusicInfo;
        this.playerService.currentMusic = currentMusicInfo;
        this.likeStatus = !!this.musicInfo.liked;
        this.trackurl = this.environment.baseUrlFrontend + "/track/" + this.musicInfo?.name_slug;
        this.cd.detectChanges();
      });


    if (this.likeBtn?.nativeElement)
      fromEvent(this.likeBtn.nativeElement, "click")
        .pipe(debounceTime(this.token?.length ? 500 : 0))
        .subscribe(() => this.likeTrack());

    this.playerService.pause.pipe(takeUntil(this.takeUntil$)).subscribe((_) => {
      if (this.loading) {
        this.wave.cancelAjax();
        this.loading = false;
      }
      this.wave.pause();
      this.playing = false;
      this.cd.detectChanges();
    });

    this.playerService.globalPlaySeek
      // .pipe(skip(1))
      .pipe(takeUntil(this.takeUntil$))
      .subscribe(({ value, id }) => {
        if (this.musicInfo?.id === id) {
          let seekDuration = (this.musicInfo?.duration / 100) * (value * 100)
          if (this.wave?.getDuration() > 15) {
            let precision = 1
            if (!(seekDuration.toPrecision(precision) == this.wave.getCurrentTime().toPrecision(precision))) {
              this.wave.seekTo(value);
            }
          } else {
            if ((Math.trunc(seekDuration) == Math.trunc(this.wave.getCurrentTime())) || this.wave.getCurrentTime() - seekDuration < 0.5) {
              // console.log("not hitting")
            } else {
              // console.log(seekDuration, ' ', this.wave.getCurrentTime())
              this.wave.seekTo(value);
            }
          }
        }
      });

    this.playerService.likeUpdate.subscribe(likeObj => {
      if (likeObj?.id === this.musicInfo?.id && this.musicInfo) {
        this.likeStatus = likeObj?.status;
        this.musicInfo.liked = likeObj?.status;
        this.cd.detectChanges()
      }
    })
  }

  public shareTrack(): void {
    this.popUpService.showPopUp('SHARE_SONG', this.musicInfo);
    // TODO: fix analytics
    // this.pushAnalytics('add to fav','primaryArtist?.name,musicInfo?.name', '')
  }

  createWave() {
    return WaveSurfer.create({
      container: this.mainWaves?.nativeElement,
      waveColor: "#666666",
      progressColor: "#e3c329",
      barWidth: 2,
      barGap: 2,
      minPxPerSec: 10,
      responsive: true,
      backend: 'MediaElement',
      height: 40,
      hideScrollbar: true,
      normalize: true,
      plugins: [
        WaveSurfer.cursor.create({
          showTime: true,
          opacity: 1,
          customShowTimeStyle: {
            "background-color": "#000",
            color: "#fff",
            padding: "2px",
            "font-size": "10px",
          },
        }),
      ],
    });
  }
  copyEmbedCode() {
    navigator.clipboard.writeText(this.trackurl);
  }
  registerWaveEvents() {
    this.wave.on("play", () => {
      this.playing = true;
      this.loading = false;
      this.cd.detectChanges();
    });
    this.wave.on("loading", (value) => {
      this.loading = value !== 100;
      this.completeTrackLoaded = value >= 1;
      this.cd.detectChanges();
    });
    this.wave.on("audioprocess", (value) => {
      // console.log('audioprocess ', value, ", duration ", this.wave?.getDuration());
      this.playerService.continousStream.next({ value: value, id: this.musicInfo?.id, duration: this.wave?.getDuration(), isPlaying: this.wave?.isPlaying() });
      this.waveSliderWidth = `${Math.round((value / this.wave.getDuration()) * 100)}`;
      this.sliderValue = this.waveSliderWidth;
      this.currentTime = formatDuration(value);
      this.cd.detectChanges();
    });


    this.wave.on("seek", (value) => {
      // console.log('Seek value is', value)
      this.playerService.continousStream.next({ valueSeek: value, id: this.musicInfo?.id, duration: this.wave?.getDuration(), isPlaying: this.wave?.isPlaying() });
      const sliderValue = Math.round(value * 100)
      // console.log('slider value is', sliderValue)
      this.sliderValue = sliderValue
      // this.playerService.smallPlaySeek.next(value);
      // this.waveSmallInnerWidth= this.playerService.smallPlaySeek.next(value);
    });
    this.wave.on("finish", () => {
      this.playing = false;
      this.playerService.pause.next({ id: this.musicInfo?.id, isFinished: true });
      // autoplay next track if time doesn't exceed 180 minutes
      this.totalAutoPlayTime = this.totalAutoPlayTime + Number(this.musicInfo.duration);
      if ((environment.baseUrlFrontend === "https://select.hoopr.ai" && this.totalAutoPlayTime > 10800) || (environment.baseUrlFrontend !== "https://hoopr.ai" && this.totalAutoPlayTime > 300)) {
        this.playerService.pause.next({ id: this.musicInfo.id });
        //reset counter
        this.totalAutoPlayTime = 0.0;
        // show warning
        this.popUpService.showPopUp("CONTINUE_LISTENING", this.musicInfo);
      } else {
        this.playerService.autoPlay(this.musicInfo?.id || null);
      }
      this.cd.detectChanges();
    });
    this.wave.setVolume(1);
  }

  onSliderClick(event) {
    const seekValue = (event.value / 100) * this.musicInfo.duration;
    this.wave.play(seekValue);
    // this.playerService.smallPlaySeek.next(event.target.value / 100);
  }

  playPause() {
    this.wave?.playPause();
    this.playing = !this.playing;
    if (this.wave?.isPlaying()) {

      this.playerService.musicInfo.next({
        ...this.musicInfo,
        currentTime: this.wave.getCurrentTime(),
      });
    }
    else {
      this.playerService.pause.next({ id: this.musicInfo.id });
    }
    this.cd.detectChanges();
  }

  changeVolume(event) {
    let volume = event.target.value / 100;
    this.wave?.setVolume(volume);
  }
  goToArtistPage(id) {
    if (id) {
      this.router.navigateByUrl("/artist/" + id);
    }
  }

  onClose() {
    if (this.wave?.isPlaying()) {
      this.wave?.playPause();
      this.playing = !this.playing;
    }
    this.playerService.pause.next({ id: this.musicInfo?.id });
    this.musicInfo = null;
    this.cd.detectChanges();
  }

  public likeTrack(): void {
    this.token = this.apiService.getToken();
    if (!this.token) {
      this.popUpService.showPopUp("LIKED_SONG");
      this.signInwarning = true;
      this.cd.detectChanges();
      return;
    }
    const originalStatus = (this.likeStatus = !!this.musicInfo.liked);
    let reqObj = {
      trackId: this.musicInfo.id,
    };
    this.apiService.consumerlikeTrack(reqObj).subscribe(
      (response: any) => {
        if (this.likeStatus == false) {
          SwalHelper.showToast(
            this.musicInfo.name + " Track added to your likes!",
            '<i class="ph ph-fill ph-check-circle" style="color:#2C96FF"></i>',
            "<a href='/user/my-collection/my-likes' class='swal-text-link text-normal'>VIEW LIKES</a>"
          );
        } else {
          SwalHelper.showToast(
            this.musicInfo.name + " Track removed from your likes!",
            '<i class="ph ph-fill ph-check-circle" style="color:#2C96FF"></i>',
            "<a href='/user/my-collection/my-likes' class='swal-text-link text-normal'>VIEW LIKES</a>"
          );
        }
        this.apiService.updateLikedTracks(response.data)
        this.likeStatus = !originalStatus;
        this.musicInfo.liked = this.likeStatus;
        this.cd.detectChanges();
        this.playerService.likeUpdate.next({ id: this.musicInfo.id, status: this.likeStatus })
        let event = this.likeStatus ? "Liked Track" : "Unliked Track";
        this.logger.logTrackEvent(
          event,
          this.musicInfo.id,
          this.musicInfo.name
        );


        if (this.musicInfo.asset_type) {
          if (this.musicInfo.type === "music") {
            this.musicInfo.sub_genres = this.musicInfo.sub_filters
          } else {
            this.musicInfo.sfx_subcategory = this.musicInfo.sub_filters
            this.musicInfo.sfx_category = this.musicInfo.filters
          }
        }
        else if (this.musicInfo.filters) {
          const genres = [];
          const moods = [];
          const usecases = [];
          const sub_genres = [];
          const sfx_subcategory = [];
          const sfx_category = [];
          this.musicInfo?.filters?.forEach(filter => {
            if (filter.type === "genre") {
              genres.push(filter.name)
            } else if (filter.type === "mood") {
              moods.push(filter.name)
            } else if (filter.type === "usecase") {
              usecases.push(filter.name)
            } else if (filter.type === "sfxcategory") {
              sfx_category.push(filter.name)
            }
          })
          this.musicInfo?.subFilters?.forEach(subfilter => {
            if (subfilter.type === "subgenre") {
              sub_genres.push(subfilter.name)
            } else if (subfilter.type === "subsfxcategory") {
              sfx_subcategory.push(subfilter.name)
            }
          })
          this.musicInfo.genres = genres;
          this.musicInfo.moods = moods;
          this.musicInfo.usecases = usecases;
          this.musicInfo.sfx_category = sfx_category;
          this.musicInfo.sub_genres = sub_genres;
          this.musicInfo.sfx_subcategory = sfx_subcategory;
        }


        if (this.musicInfo?.keywords && this.musicInfo?.keywords[0] && typeof this.musicInfo?.keywords[0] !== "string") {
          let keywords = []
          this.musicInfo?.keywords?.forEach(keyword => {
            keywords.push(keyword.keyword)
          })
          this.musicInfo.keywords = keywords
        }

        // log data to backend
        const pageType = window.location?.pathname.split("/")[1] || null;
        const musicMetaData = {
          event_type: 'liked_track',
          track_id: this.musicInfo?.id,
          track_name: this.musicInfo?.name,
          track_genre: this.musicInfo?.genres ? this.musicInfo?.genres : null,
          track_moods: this.musicInfo?.moods ? this.musicInfo?.moods : null,
          track_usecases: this.musicInfo?.usecases ? this.musicInfo?.usecases : null,
          track_keywords: this.musicInfo?.keywords ? this.musicInfo?.keywords : null,
          track_bpm: this.musicInfo?.bpm,
          primary_artist: this.musicInfo?.tracksArtistRole?.find(i => i.isPrimary == true).artist?.name || this.musicInfo?.tracks_artist_role?.filter(i => i.isPrimary == true).artist?.name || this.musicInfo?.artists.find((artist) => artist.ArtistTrackRoleMappingModel.isPrimary == true)?.name || this.musicInfo?.artists[0].name,
          // track_meta: { ...this.musicInfo },
          is_vocal: this.musicInfo?.hasVocals === true || this.musicInfo?.has_vocals === "true" ? true : false,
          sfx_category: this.musicInfo?.sfx_category || null,
          sfx_subcategory: this.musicInfo?.sfx_subcategory || null,
          sub_genres: this.musicInfo?.sub_genres || null,
          track_origin_region: this.musicInfo?.region || null,
          track_release_region: this.musicInfo?.release_region || this.musicInfo?.releaseRegion || null,
          is_explicit: this.musicInfo?.isExplicit === true || this.musicInfo?.is_explicit === "true" ? true : false,
          track_type: this.musicInfo?.type || null,
          duration: this.musicInfo?.duration || null,
          display_tags: this.musicInfo?.displayTags || this.musicInfo?.display_tags || null,
          page_name: window.location?.pathname?.substring(0, 125) || null,
          page_type: window.location?.pathname?.split("/")[1]?.substring(0, 125) || null,
          page_url: window.location?.href?.substring(0, 125) || null,
          playlist_name: pageType === 'playlist' ? window.location?.pathname?.split("/")[2] : null,
          search_term: pageType === 'search' ? window.location?.pathname?.split("/")[2] : null,
        }
        this.userInfoService.logData(musicMetaData);

      },
      (error) => {
        this.likeStatus = originalStatus;
        this.musicInfo.liked = this.likeStatus;
        this.cd.detectChanges();
      }
    );
  }
  public requestQuote(){
    const isUserOnboarded = this.apiService.isUserOnboarded();
    this.musicInfo.isUserOnboarded = isUserOnboarded;
    this.popUpService.showPopUp('REQUEST_QUOTE',this.musicInfo);
    let url = this.router.url.split('?')[0];
    localStorage.setItem('redirectUrl', url.split('?')[0]);

  }
  public addToPlaylist(): void {
    this.popUpService.showPopUp('ADD_TO_PLAYLIST', this.musicInfo);
    // TODO: add analytics
    // this.pushAnalytics(link_text, artist_name, track_name)
  }


  addTrackToCart(link_text: string, artist_name: string, track_name: string): void {
    this.pushAnalytics(link_text, artist_name, track_name)
    this.popUpService.showPopUp('ADD_TRACK_TO_CART', this.musicInfo);
  }

  ngOnDestroy() {

    this.wave?.cancelAjax();
    this.wave?.stop();
    this.wave?.empty();
    this.wave?.unAll();
    this.takeUntil$.next();
    this.takeUntil$.complete();
  }

  getTrackImage() {
    return `${this.environment.BASE_CDN_URL}/${this.musicInfo.type}s/${this.musicInfo.id}/${this.musicInfo.id}-image.jpg`;
  }

  copyTrackLink() {
    navigator.clipboard.writeText(
      this.environment.baseUrlFrontend + "/track/" + this.musicInfo.id
    );
    alert("Your link has been copied");
  }

  pushAnalytics(link_text: string, artist_name: string, track_name: string, instrument_type?: string) {
    this.gtmAnalytics.pushCtaAnalytics({
      event: 'ga4 track_interaction',
      event_name: 'track_interaction',
      artist_name,
      instrument_type: instrument_type || '',
      link_name: '',
      link_header: '',
      link_section: '',
      link_text,
      link_url: (window.location?.pathname.length > 125 ? window.location?.pathname.substring(0, 125) : window.location?.pathname) || null,
      login_status: JSON.parse(localStorage.getItem('userData'))?.id
        ? 'login'
        : 'guest',
      page_type: window.location?.pathname.split('/')[1] || null,
      search_link: '',
      search_term: '',
      track_name,
      user_id: JSON.parse(localStorage.getItem('userData'))?.id || null,
    });
  }

}
