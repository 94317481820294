import { SearchBoxComponent } from './search-box/search-box.component';
import { CheckboxComponent } from './../checkbox/checkbox.component';
import { HeaderComponent } from './header/header.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { PlaylistFrameComponent } from './playlist-frame/playlist-frame.component';
import { SwiperModule } from 'swiper/angular';
import { MovieFrameComponent } from './movie-frame/movie-frame.component';
import { LanguageFrameComponent } from './language-frame/language-frame.component';
import { GenreFrameComponent } from './genre-frame/genre-frame.component';
import { TracksListComponent } from './tracks-list/tracks-list.component';
import { TrackBlockComponent } from './track-block/track-block.component';
import { ArtistFrameComponent } from './artist-frame/artist-frame.component';

const components= [
  HeaderComponent,
  CheckboxComponent,
  SearchBoxComponent,
  MovieFrameComponent,
  LanguageFrameComponent,
  PlaylistFrameComponent,
  GenreFrameComponent,
  TracksListComponent,
  TrackBlockComponent,
  ArtistFrameComponent
]

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
    SwiperModule
  ],
  exports: [
   ...components,
    CommonModule,
   ]
})
export class CommonComponentsModule { }
