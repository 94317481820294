import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  public readonly LANDING_PAGE_PLAYLISTS = [
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/playlist/77e10f77-946d-4715-801d-5e23a94192a5/cover.jpg',
      playlist_url: '/playlist/lofi-mehfil',
      cover_name:'Lofi Mehfil'
    },
    {
      cover_image:'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/playlist/eddf8422-3f80-480a-b1f1-cc0ac176219a/cover.jpg',
      playlist_url: '/playlist/the-reveal',
      cover_name:'The Reveal'
    },
    {
      cover_image:'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/playlist/2d3f429b-4c02-424d-9412-70889c45b2b9/cover.png',
      playlist_url: '/playlist/pahadi',
      cover_name:'Pahadi'
    },
    {
      cover_image:'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/playlist/a3ff4fb3-cb19-4ef4-b17a-2775ca788be9/cover.png',
      playlist_url: '/playlist/gujarat',
      cover_name:'Gujarat'
    },
    {
      cover_image:'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/playlist/059ea469-55e2-4df8-a59c-cc7bfab9f9f4/cover.jpg',
      playlist_url: '/playlist/discover-india',
      cover_name:'Discover India'
    },
    {
      cover_image:'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/playlist/663c4f75-a1a7-487d-a894-71e370095fbb/cover.png',
      playlist_url: '/playlist/bhajans',
      cover_name:'Bhajans'
    },
    {
      cover_image:'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/playlist/574e74e5-504f-4215-9d75-7c993f0fd664/cover.png',
      playlist_url: '/playlist/cooking-essentials',
      cover_name:'Cooking Essentials'
    },
    {
      cover_image:'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/playlist/9a46a9a9-6a41-47f1-8a10-63d35245b81e/cover.jpg',
      playlist_url: '/playlist/deep-focus',
      cover_name:'Deep Focus'
    },
    {
      cover_image:'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/playlist/eddf8422-3f80-480a-b1f1-cc0ac176219a/cover.jpg',
      playlist_url: '/playlist/headshots-and-quickscopes',
      cover_name:'Headshots & Quickscopes'
    },
    {
      cover_image:'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/playlist/bbf29858-b387-4156-8f17-2e4846867aaa/cover.png',
      playlist_url: '/playlist/marvel',
      cover_name:'Marvel'
    },
  ];

  public readonly LANDING_PAGE_SONGS = [
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/musics/0639520d-1113-42ac-b507-f8ae0b69fe8d/0639520d-1113-42ac-b507-f8ae0b69fe8d-image.png',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/musics/0639520d-1113-42ac-b507-f8ae0b69fe8d/0639520d-1113-42ac-b507-f8ae0b69fe8d-mp3.mp3',
      cover_name: 'Kyun Main Kahoon?',
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/musics/9f169458-37e0-4cde-9025-2bd03a6184a9/9f169458-37e0-4cde-9025-2bd03a6184a9-image.png',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/musics/9f169458-37e0-4cde-9025-2bd03a6184a9/9f169458-37e0-4cde-9025-2bd03a6184a9-mp3.mp3',
      cover_name: 'Gajanana',
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/musics/0c0fcc25-886c-41df-bff7-7a10e3b9a414/0c0fcc25-886c-41df-bff7-7a10e3b9a414-image.png',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/musics/0c0fcc25-886c-41df-bff7-7a10e3b9a414/0c0fcc25-886c-41df-bff7-7a10e3b9a414-mp3.mp3',
      cover_name: 'Chan Kithan',
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/musics/67a36f24-c9a8-4474-9fdb-065b800ebe77/67a36f24-c9a8-4474-9fdb-065b800ebe77-image.jpg',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/musics/67a36f24-c9a8-4474-9fdb-065b800ebe77/67a36f24-c9a8-4474-9fdb-065b800ebe77-mp3.mp3',
      cover_name: 'Aadatein',
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/musics/dd059b47-078d-4b89-9aaa-9c8325ca95ce/dd059b47-078d-4b89-9aaa-9c8325ca95ce-image.jpg',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/musics/dd059b47-078d-4b89-9aaa-9c8325ca95ce/dd059b47-078d-4b89-9aaa-9c8325ca95ce-mp3.mp3',
      cover_name: 'Jhoome Re',
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/musics/1519057b-eded-40a0-a8bd-023945122c06/1519057b-eded-40a0-a8bd-023945122c06-image.jpg',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/musics/1519057b-eded-40a0-a8bd-023945122c06/1519057b-eded-40a0-a8bd-023945122c06-mp3.mp3',
      cover_name: 'Judaaiyan (feat. Mame Khan)',
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/musics/b87bacad-baa1-48b3-8808-5a2c73fa5f1b/b87bacad-baa1-48b3-8808-5a2c73fa5f1b-image.jpg',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/musics/b87bacad-baa1-48b3-8808-5a2c73fa5f1b/b87bacad-baa1-48b3-8808-5a2c73fa5f1b-mp3.mp3',
      cover_name: 'Single Single',
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/musics/0ce77db0-e7bc-4b41-9275-7f3b2c5a9824/0ce77db0-e7bc-4b41-9275-7f3b2c5a9824-image.png',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/musics/0ce77db0-e7bc-4b41-9275-7f3b2c5a9824/0ce77db0-e7bc-4b41-9275-7f3b2c5a9824-mp3.mp3',
      cover_name: 'Chamiya',
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/musics/e5dafa17-533d-440d-9bca-50068a47aa52/e5dafa17-533d-440d-9bca-50068a47aa52-image.jpg',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/musics/e5dafa17-533d-440d-9bca-50068a47aa52/e5dafa17-533d-440d-9bca-50068a47aa52-mp3.mp3',
      cover_name: 'Intezaar Tera',
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/musics/0bebfe7b-479a-433f-9aa3-066940e4f179/0bebfe7b-479a-433f-9aa3-066940e4f179-image.jpg',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/musics/0bebfe7b-479a-433f-9aa3-066940e4f179/0bebfe7b-479a-433f-9aa3-066940e4f179-mp3.mp3',
      cover_name: 'Mehendi',
    },
  ]

  public readonly LANDING_PAGE_THEMES = [
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/musics/806a8d61-593d-4d4f-bdf8-3d31ccd6ff37/806a8d61-593d-4d4f-bdf8-3d31ccd6ff37-image.jpg',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/musics/806a8d61-593d-4d4f-bdf8-3d31ccd6ff37/806a8d61-593d-4d4f-bdf8-3d31ccd6ff37-mp3.mp3',
      cover_name: 'Flute Is Future',
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/musics/653e3557-2ed3-4d90-8312-9e6fa1e5da0a/653e3557-2ed3-4d90-8312-9e6fa1e5da0a-image.jpg',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/musics/653e3557-2ed3-4d90-8312-9e6fa1e5da0a/653e3557-2ed3-4d90-8312-9e6fa1e5da0a-mp3.mp3',
      cover_name: 'Everyone On The Floor',
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/musics/a102ab87-a731-40ff-acfb-2721ab0bff52/a102ab87-a731-40ff-acfb-2721ab0bff52-image.png',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/musics/a102ab87-a731-40ff-acfb-2721ab0bff52/a102ab87-a731-40ff-acfb-2721ab0bff52-mp3.mp3',
      cover_name: 'Bedardi Raja ',
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/musics/0d561bce-a6f8-4c36-9754-8b481e9e3eb1/0d561bce-a6f8-4c36-9754-8b481e9e3eb1-image.jpg',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/musics/0d561bce-a6f8-4c36-9754-8b481e9e3eb1/0d561bce-a6f8-4c36-9754-8b481e9e3eb1-mp3.mp3',
      cover_name: 'Downpour',
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/musics/12086366-a0e6-4701-9064-a8b9f850086b/12086366-a0e6-4701-9064-a8b9f850086b-image.png',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/musics/12086366-a0e6-4701-9064-a8b9f850086b/12086366-a0e6-4701-9064-a8b9f850086b-mp3.mp3',
      cover_name: 'Beware',
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/musics/0c39b222-2b61-409c-bdeb-3c3c5564bad4/0c39b222-2b61-409c-bdeb-3c3c5564bad4-image.png',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/musics/0c39b222-2b61-409c-bdeb-3c3c5564bad4/0c39b222-2b61-409c-bdeb-3c3c5564bad4-mp3.mp3',
      cover_name: 'Chyrsalis',
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/musics/8eb185df-56ec-4ba1-85bf-41c9e4fc0b09/8eb185df-56ec-4ba1-85bf-41c9e4fc0b09-image.png',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/musics/8eb185df-56ec-4ba1-85bf-41c9e4fc0b09/8eb185df-56ec-4ba1-85bf-41c9e4fc0b09-mp3.mp3',
      cover_name: 'Thar Vibes',
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/musics/b696d027-aca2-45c5-b6d6-74d935a565a3/b696d027-aca2-45c5-b6d6-74d935a565a3-image.jpg',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/musics/b696d027-aca2-45c5-b6d6-74d935a565a3/b696d027-aca2-45c5-b6d6-74d935a565a3-mp3.mp3',
      cover_name: 'Punjabi Hip Hop Beat',
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/musics/5348ed5b-880c-4726-8ab0-8575c305a99d/5348ed5b-880c-4726-8ab0-8575c305a99d-image.jpg',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/musics/5348ed5b-880c-4726-8ab0-8575c305a99d/5348ed5b-880c-4726-8ab0-8575c305a99d-mp3.mp3',
      cover_name: 'Suno Sajani',
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/musics/ce890bb1-f22f-4233-8794-3217cac1f924/ce890bb1-f22f-4233-8794-3217cac1f924-image.jpg',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/musics/ce890bb1-f22f-4233-8794-3217cac1f924/ce890bb1-f22f-4233-8794-3217cac1f924-mp3.mp3',
      cover_name: 'Oh Nana',
    },
  ]

  public readonly LANDING_PAGE_SFX =  [
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/sfxs/c55a87f1-a70a-484b-979c-257520f01e7b/c55a87f1-a70a-484b-979c-257520f01e7b-image.png',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/sfxs/c55a87f1-a70a-484b-979c-257520f01e7b/c55a87f1-a70a-484b-979c-257520f01e7b-mp3.mp3',
      cover_name: 'Bowler Footsteps 1'
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/sfxs/ebe859bc-334c-4d18-b7d8-6e35f46a9cc3/ebe859bc-334c-4d18-b7d8-6e35f46a9cc3-image.png',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/sfxs/ebe859bc-334c-4d18-b7d8-6e35f46a9cc3/ebe859bc-334c-4d18-b7d8-6e35f46a9cc3-mp3.mp3',
      cover_name: 'Walking on Sand with Shoes'
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/sfxs/9d883e6c-cfb9-41be-9bb7-e778b774e049/9d883e6c-cfb9-41be-9bb7-e778b774e049-image.png',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/sfxs/9d883e6c-cfb9-41be-9bb7-e778b774e049/9d883e6c-cfb9-41be-9bb7-e778b774e049-mp3.mp3',
      cover_name: 'Thriller Horn'
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/sfxs/3b0843d2-ff2d-4d62-9ad3-347ea335fa73/3b0843d2-ff2d-4d62-9ad3-347ea335fa73-image.jpg',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/sfxs/3b0843d2-ff2d-4d62-9ad3-347ea335fa73/3b0843d2-ff2d-4d62-9ad3-347ea335fa73-mp3.mp3',
      cover_name: 'Revolver Gunshots '
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/sfxs/4e82f160-b3c8-43c9-97f4-12d715fd0fc3/4e82f160-b3c8-43c9-97f4-12d715fd0fc3-image.jpeg',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/sfxs/4e82f160-b3c8-43c9-97f4-12d715fd0fc3/4e82f160-b3c8-43c9-97f4-12d715fd0fc3-mp3.mp3',
      cover_name: 'Local Train Arriving'
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/sfxs/01f2761e-9c7b-420a-9983-66ac32ccb223/01f2761e-9c7b-420a-9983-66ac32ccb223-image.jpg',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/sfxs/01f2761e-9c7b-420a-9983-66ac32ccb223/01f2761e-9c7b-420a-9983-66ac32ccb223-mp3.mp3',
      cover_name: 'Whistle (Slow Down)'
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/sfxs/81cfd84a-043d-4e91-b84e-2c44186c0398/81cfd84a-043d-4e91-b84e-2c44186c0398-image.jpg',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/sfxs/81cfd84a-043d-4e91-b84e-2c44186c0398/81cfd84a-043d-4e91-b84e-2c44186c0398-mp3.mp3',
      cover_name: 'Powerful Punch 02'
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/sfxs/c26f426e-f152-4f2c-a272-d0fc5fc118a7/c26f426e-f152-4f2c-a272-d0fc5fc118a7-image.jpg',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/sfxs/c26f426e-f152-4f2c-a272-d0fc5fc118a7/c26f426e-f152-4f2c-a272-d0fc5fc118a7-mp3.mp3',
      cover_name: 'Unboxing (Cardboard Box) 01'
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/sfxs/25af6729-483c-4930-890e-f885884d6c77/25af6729-483c-4930-890e-f885884d6c77-image.jpg',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/sfxs/25af6729-483c-4930-890e-f885884d6c77/25af6729-483c-4930-890e-f885884d6c77-mp3.mp3',
      cover_name: 'Alien Portal Opening 01'
    },
    {
      cover_image: 'https://d300bt25kxflkn.cloudfront.net/fit-in/200x200/filters:quality(80)/sfxs/37918082-f383-4797-9e19-7829f70f8efe/37918082-f383-4797-9e19-7829f70f8efe-image.jpg',
      song_url: 'https://d2ntslqmfg7dws.cloudfront.net/sfxs/b546126e-b066-4a41-baa1-cb526446b84f/b546126e-b066-4a41-baa1-cb526446b84f-mp3.mp3',
      cover_name: 'Camera - Multiple Shutter Release 02'
    },
  ]
  
  constructor() { }
  public imageKitUrl(imageUrl: String, size: number = null) {
    if (imageUrl === null || imageUrl === undefined || imageUrl === '') {
      return null;
    }
    if (!size) {
      size = 512
    }
    let ikurl = imageUrl?.replace(
      environment.BASE_CDN_URL,
      `${environment.IMAGE_CDN_URL}/fit-in/${size}x${size}/filters:quality(80)`
    );
    // ikurl = ikurl + `?tr=w-${size}`;
    return ikurl;
  }
}
