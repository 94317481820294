<app-header></app-header>
<div>
  <h1 class="heading py-3 px-3 px-md-0 ">Browse our Artists</h1>
  <div class="featured-artist">
    <div class="row">
      <div class="col-md-8 col-xl-7 artist-banner">
        <div class="row align-items-center">
          <div class="col-md-6 artist-content ">
            <div class="px-md-0 py-md-0 px-3 py-5">
              <p class="artist-title">
                <span class="d-md-block artist-title">{{featuredArtistBanner?.title}}</span>
              </p>
              <p class="artist-sub-text w-md-75 w-100">
                {{featuredArtistBanner?.description}}
              </p>
              <button type="button" class="btn-white" [routerLink]="featuredArtistBanner?.url">
                EXPLORE &nbsp; <i class="ph ph-arrow-right my-auto"></i>
              </button>
            </div>
          </div>
          <!--col-->
          <div class="col-md-6 p-0">
            <div class="d-flex  justify-content-center h-100 p-0">
              <img
                src="/assets/images/artist.png"
                alt="artist"
                class=" artist-image"
              />
            </div>
          </div>
          <!--col-->
        </div>
        <!--row-->
      </div>
      <!--artist-banner-->
      <!--col-->
      <div class="col-md-4 col-xl-5 px-5 px-md-3 py-5 py-md-0">
        <app-tracks-list [tracks]="featuredArtistTracks" [size]="'small'"></app-tracks-list>
      </div>
      <!--col-->
    </div>
    <!--row-->
  </div>


  <div class="container" >
  <div>
      <div class="row py-5">
        <div *ngIf="this.trending && this.trending.length > 0" class="col-md-6 ">
          <app-trending [data]="this.trending" [showLabel]="true" ></app-trending>
        </div>
        <div class="col-md-6" *ngIf="this.popularArtists">
          <div class="d-flex justify-content-between align-items-center">
            <h3 class="text-left title">Popular</h3>
          </div>
          <!--flex-->
          <app-grid [data]="this.popularArtists" [assetType]="'Artist'" [items]="'3'" [mobileItems]="'2'" [labelColor]="'black'"></app-grid>
          <!--row end-->
        </div>
      </div>
    </div>
    <!--search-->
    <ng-container *ngIf="this.allArtists">
    <div class="d-flex justify-content-between align-items-center">
        <!--flex-->
        <h3 class="text-left title">All Artists</h3>
        <div class="d-flex justify-content-center align-items-center">
          <i class="ph-bold ph-magnifying-glass"></i>
          &nbsp;
          <a class="text-right search-text">Search</a>
        </div>
      </div>
      <!--flex-->
      <app-grid [data]="this.allArtists" [assetType]="'Artist'" [items]="'6'" [mobileItems]="'2'" [labelColor]="'black'"></app-grid>
      <!--row end-->
    </ng-container>
  </div>
</div>
