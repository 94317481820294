import { Injectable, OnDestroy } from "@angular/core";
import { BackendApiService } from "./hoopr.services";

const CACHE_KEY = 'PLAYED_SONGS_METADATA'

/**
 * @description 
 * Service to keep record of tracks played & synced it with localstorage
 */

@Injectable()
export class TrackAuthorizationService {
    private _local = [];

    constructor() {
        this._local = JSON.parse(localStorage?.getItem(CACHE_KEY) || "[]");
    }

    /**
     * @description 
     * Count of tracks played 
     * @returns number of tracks played
     */
    getCount(): number {
        return this._local.length;
    }

    /**
     * @description
     * Check if Track was allowed to play previously 
     * 
     * @param trackId string with track ID
     * @returns boolean
     */
    isAllowed(trackId: string): boolean {
        return this._local.includes(trackId);
    }

    /**
     * @description
     * Add track ID to allowed list
     * 
     * @param trackId string with track ID
     */
    add(trackId: string): void {
        if (!this._local.includes(trackId)) {
            this._local.push(trackId);
            this.syncLocalStorage();
        }
    }

    private syncLocalStorage() {
        localStorage?.setItem(CACHE_KEY, JSON.stringify(this._local));
    }

    /**
     * @description Clear all record and remove it from localStorage
     */
    clearAll() {
        this._local = [];
        this.syncLocalStorage();
    }
}