import { Component, OnInit , Input} from '@angular/core';

@Component({
  selector: 'app-tracks-list',
  templateUrl: './tracks-list.component.html',
  styleUrls: ['./tracks-list.component.scss'],
})
export class TracksListComponent {
  @Input() size: string;
  @Input() tracks: any;
  
  constructor() { }

}
