import { LoggerServiceService } from './logger-service.service';
import { FiltersSchema } from './../../swagger/model/filtersSchema';
import { SwalHelper } from './../_helpers/swal-helpers';
import { SearchService } from './../../swagger/api/search.service';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserInfoService } from "../services/user-info.service";
import { GtmAnalyticsService } from './gtm-analytics.service';
import { Configuration } from 'src/swagger';
declare var window: any;

interface FilterItem {
  selected?: boolean;
  slug: string;
  name: string;
  image: string;
  disabled: boolean;
  count: number;
}

@Injectable({
  providedIn: 'root'
})


export class SearchHelperService {

  public filterMasterList = {}
  public loadingResults: boolean = false;
  public preDefinedFilters: FiltersSchema = {
    vocals: undefined,
    pro: undefined,
    asset_type: undefined,
    playlist_type: undefined,
    genres: [],
    moods: [],
    usecases: [],
    languages: [],
    artists: [],
    playlists: [],
    subgenres: [],
    sfxcategories: [],
    sfxsubcategories: [],
    contextMeta: undefined,
    bpm: {
      min: undefined,
      max: undefined,
    },
    bpm_bucket: undefined,
  }

  public $selectedFilters = new BehaviorSubject<FiltersSchema>({
    vocals: undefined,
    pro: undefined,
    asset_type: undefined,
    playlist_type: undefined,
    genres: [],
    moods: [],
    usecases: [],
    languages: [],
    artists: [],
    playlists: [],
    subgenres: [],
    sfxcategories: [],
    sfxsubcategories: [],
    contextMeta: undefined,
    bpm: {
      min: undefined,
      max: undefined,
    },
    bpm_bucket: undefined
  });
  public selectedFilters: FiltersSchema = {
    vocals: undefined,
    pro: undefined,
    asset_type: undefined,
    playlist_type: undefined,
    genres: [],
    moods: [],
    usecases: [],
    languages: [],
    artists: [],
    playlists: [],
    subgenres: [],
    sfxcategories: [],
    sfxsubcategories: [],
    contextMeta: undefined,
    bpm: {
      min: undefined,
      max: undefined,
    },
    bpm_bucket: undefined
  };

  public $searchKeyword = new BehaviorSubject<string>("");
  public searchKeyword: string = "";

  public $searchResults = new BehaviorSubject<any[]>(undefined);
  public searchResults = undefined;

  public $searchType = new BehaviorSubject<string>(undefined);
  public searchType = undefined;
  public searchMeta = undefined;

  // public $selectableFilters = new BehaviorSubject<any[]>(undefined);

  public searchChips = [];
  public hardDisabledFilters: FiltersSchema = {
    vocals: undefined,
    asset_type: undefined,
    playlist_type: undefined,
    genres: [],
    moods: [],
    usecases: [],
    languages: [],
    artists: [],
    playlists: [],
    subgenres: [],
    sfxcategories: [],
    sfxsubcategories: [],
    contextMeta: undefined,
    bpm: {
      min: undefined,
      max: undefined,
    },
    bpm_bucket: undefined
  }

  constructor(
    private searchService: SearchService,
    private userInfoService: UserInfoService,
    private logger: LoggerServiceService,
    private gtmAnalytics: GtmAnalyticsService
  ) {
    // set swagger services authorization token
    this.setSwaggerConfig();

    this.getFilterMasterList();

    // when filters are updated fetch new results
    // TODO: check if being used ????
    this.$selectedFilters.subscribe((filters) => {
      if (!filters) {
        this.resetFilters();
      } else {
        this.selectedFilters = filters;
      }
      this.fetchNewSearchResults();
    })

    // when search term is updated
    this.$searchKeyword.subscribe((searchString: string) => {
      this.searchKeyword = searchString;
      // this.fetchNewSearchResults();
    })

    // when search type is updated
    this.$searchType.subscribe((searchPageInfo: string) => {
      this.searchType = searchPageInfo;
      // this.searchMeta = searchPageInfo?.meta
      this.fetchNewSearchResults();
    })
  }

  /**
   * Fetches master data list for all filter types
   */
  public async getFilterMasterList() {
    return new Promise((resolve, reject) => {
      this.searchService.getFilters().subscribe((filterList) => {
        this.filterMasterList['genres'] = filterList["genres"];
        this.filterMasterList['moods'] = filterList["moods"];
        this.filterMasterList['usecases'] = filterList["usecases"];
        this.filterMasterList['languages'] = filterList["languages"];
        resolve(null)
      }, err => {
        console.log("Error found while fetcing master filter list", err);
        reject(err)
      });
    })
  }

  /**
   * Invoked when a new filters or search term is entered
   * takes
   */
  public fetchNewSearchResults() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.loadingResults = true;
    this.$searchResults.next(undefined);
    if (this.searchType === 'global') {
      this.searchService.globalSearch(this.searchKeyword).subscribe((searchResponse) => {
        this.$searchResults.next(searchResponse)
        this.loadingResults = false;
        this.searchResults = searchResponse;
        this.logData();
      }, err => {
        console.log('Error occurred while loading global search results', err);
        this.loadingResults = false;
      })
    } else if (this.searchType === 'faceted') {
      this.searchService.facetSearch(this.selectedFilters, this.searchKeyword, undefined).subscribe((searchResponse) => {
        const filterIndex = searchResponse.findIndex(section => section['asset-type'] === 'filters')
        const selectableFilters = searchResponse[filterIndex]
        searchResponse.splice(filterIndex, 1)
        // this.$selectableFilters.next(selectableFilters['data']);
        if (selectableFilters && selectableFilters['data']) {
          this.processSelectableFilters(selectableFilters['data'])
        }
        this.$searchResults.next(searchResponse)
        this.loadingResults = false;
        this.searchResults = searchResponse;
        this.logData();
      }, err => {
        console.log('Error occurred while loading faceted search results', err);
        this.loadingResults = false;
      })
    } else if (this.searchType === 'feed') {
      this.searchService.feedSearch(this.selectedFilters, this.searchKeyword).subscribe((searchResponse) => {
        const filterIndex = searchResponse.findIndex(section => section['asset-type'] === 'filters')
        const selectableFilters = searchResponse[filterIndex]
        searchResponse.splice(filterIndex, 1)
        if (selectableFilters && selectableFilters['data']) {
          this.processSelectableFilters(selectableFilters['data'])
        }
        this.$searchResults.next(searchResponse)
        this.loadingResults = false;
        this.searchResults = searchResponse;
        if (this.searchKeyword) {
          this.logData();
        }
      }, err => {
        console.log('Error occurred while loading feed search results', err);
        this.loadingResults = false;
      })
    } else {
      // console.log('some random search type')
      this.loadingResults = false;
    }
  }

  /**
   * log data to amplitude fn
   */
  public async logData() {
    // log data to amplitude
    this.logger.logSearchEvent('Keyword searched', this.searchKeyword?.trim() || null)
    if (this.searchKeyword?.trim().length > 0) {
      let count = 0;
      if (this.searchResults && this.searchResults.length > 0) {
        this.searchResults.forEach((asset) => {
          if (asset['asset-type'] === "playlist" || asset['asset-type'] === 'sfx' || asset['asset-type'] === 'music' || asset['asset-type'] === 'artist' || asset['asset-type'] === 'genre' || asset['asset-type'] === 'subsfxcategory' || asset['asset-type'] === 'subgenre' || asset['asset-type'] === 'mood' || asset['asset-type'] === 'sfxcategory') {
            count += asset.data.length
          }
        })
      }
      const searchMetaData = {
        event_type: 'search',
        page_name: window.location?.pathname?.substring(0, 125) || null,
        page_type: window.location?.pathname?.split("/")[1]?.substring(0, 125) || null,
        page_url: window.location?.href?.substring(0, 125) || null,
        search_term: this.searchKeyword?.trim() || null,
        search_results_count: count,
      }

      this.userInfoService.logData(searchMetaData);
    }
  }
  /**
   * Gets data for new page
   * @param pageNumber page number for which we want to fetch results
   * @returns data for new page
   */
  public async fetchNewPageResults(pageNumber: number): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.searchService.facetSearchPage(this.selectedFilters, this.searchKeyword, undefined, pageNumber).subscribe((newPageResults) => {
        resolve(newPageResults);
      }, err => {
        reject(err);
      })
    })
  }

  /**
   * Mark filter selected or disabled from the list fetched after elastic query
   * @param filters List of new filters fetched from search
   */
  private processSelectableFilters(filters) {
    const genreNameList = filters["genres"][0].data.map(
      (genreFilterItem) => genreFilterItem.value
    );
    this.filterMasterList['genres'].forEach((genreElement) => {
      if (genreNameList.indexOf(genreElement.slug) !== -1) {
        genreElement.disabled = false;
      } else {
        genreElement.disabled = true;
      }
    });

    const moodNameList = filters["moods"][0].data.map(
      (moodFilterItem) => moodFilterItem.value
    );
    this.filterMasterList['moods'].forEach((moodElement) => {
      if (moodNameList.indexOf(moodElement.slug) !== -1) {
        moodElement.disabled = false;
      } else {
        moodElement.disabled = true;
      }
    });

    const usecaseNameList = filters["usecases"][0].data.map(
      (usecaseFilterItem) => usecaseFilterItem.value
    );
    this.filterMasterList['usecases'].forEach((usecaseElement) => {
      if (usecaseNameList.indexOf(usecaseElement.slug) !== -1) {
        usecaseElement.disabled = false;
      } else {
        usecaseElement.disabled = true;
      }
    });

    const languages = filters["languages"][0].data.map(
      (languageFilterItem) => languageFilterItem.value
    );
    this.filterMasterList['languages'].forEach((languageElement) => {
      if (languages.indexOf(languageElement.slug) !== -1) {
        languageElement.disabled = false;
      } else {
        languageElement.disabled = true;
      }
    });
  
  }

  /**
   * Select or deselect a filter
   * @param filterType Type of filter to select
   * @param index index of filter in filter master list with respect to the type
   * @param value for string filter type like has_vocals, bpm etc
   * @param fetchNewResults Should new results be fetched. Defaults to true
   */


  public selectFilter(filterType: string, index: number, value = undefined, fetchNewResults = true) {

    let linkText = null;
    if(filterType === 'bpm' || filterType === 'bpm_bucket')
      linkText = 'bpm';
    else if (filterType === 'vocals')
      linkText = value ? 'vocals' : 'instrumental';
    else if (filterType === 'playlist_type')
      linkText = `playlist-${value}`
    else if (filterType === 'Pro')
      linkText = value === true ? 'Pro' : 'Non-Pro'
    else
      linkText = `${filterType.toLowerCase()}s-${this.filterMasterList[`${filterType.toLowerCase()}s`][index].slug}`

    this.gtmAnalytics.pushCtaAnalytics({
      event: 'ga4 filter_by',
      event_name: 'filter_by',
      artist_name: '',
      instrument_type: '',
      link_name: 'Filter',
      link_header: '',
      link_section: '',
      link_text: linkText,
      link_url: (window.location?.pathname.length > 125 ? window.location?.pathname.substring(0, 125) : window.location?.pathname) || null,
      login_status: JSON.parse(localStorage.getItem('userData'))?.id
        ? 'login'
        : 'guest',
      page_type: window.location?.pathname.split('/')[1] || null,
      search_link: '',
      search_term: '',
      track_name: '',
      user_id: JSON.parse(localStorage.getItem('userData'))?.id || null,
    })
    if (this.loadingResults) {
      SwalHelper.showToast('Chill', '', 'Please wait until loading results')
      return;
    }
    let filterSlug;
    if (filterType === "genre") {
      // Step -1 Check if filter is hard disabled for feed search pages
      filterSlug = this.filterMasterList['genres'][index].slug
      if (this.filterMasterList['genres'][index].selected && this.hardDisabledFilters.genres.indexOf(filterSlug) !== -1) {
        SwalHelper.showToast('', '', `<b>${this.filterMasterList['genres'][index].name}</b> filter cannot be unselected on this page`)
        return;
      }
      // Step -2 If filter is not selected - mark selected else flip the selected value
      this.filterMasterList['genres'][index].selected = this.filterMasterList['genres'][index].selected
        ? !this.filterMasterList['genres'][index].selected
        : true;

      if (this.filterMasterList['genres'][index].selected) {
        //Step - 3 if the new value is selected push the value in selected filters list and search chips list
        this.selectedFilters["genres"].push(this.filterMasterList['genres'][index].slug);
        this.searchChips.push({
          name: this.filterMasterList['genres'][index].name,
          slug: this.filterMasterList['genres'][index].slug,
          type: this.filterMasterList['genres'][index].type
        })
      } else {
        // Step -3 if the new value is unselected remove the filter from selected filter list and search chip list
        const filterIndex = this.selectedFilters["genres"].indexOf(
          this.filterMasterList['genres'][index].slug
        );
        filterSlug = this.selectedFilters["genres"][filterIndex]
        const searchChipIndex = this.searchChips.findIndex(chip => chip.slug === filterSlug);
        this.searchChips.splice(searchChipIndex, 1);
        this.selectedFilters["genres"].splice(filterIndex, 1);

      }
    } else if (filterType === "mood") {
      filterSlug = this.filterMasterList['moods'][index].slug
      if (this.filterMasterList['moods'][index].selected && this.hardDisabledFilters.moods.indexOf(filterSlug) !== -1) {
        SwalHelper.showToast('', '', `<b>${this.filterMasterList['moods'][index].name}</b> filter cannot be unselected on this page`)
        return
      }
      this.filterMasterList['moods'][index].selected = this.filterMasterList['moods'][index].selected
        ? !this.filterMasterList['moods'][index].selected
        : true;
      if (this.filterMasterList['moods'][index].selected) {
        this.selectedFilters["moods"].push(this.filterMasterList['moods'][index].slug);
        this.searchChips.push({
          name: this.filterMasterList['moods'][index].name,
          slug: this.filterMasterList['moods'][index].slug,
          type: this.filterMasterList['moods'][index].type
        })
      } else {
        const filterIndex = this.selectedFilters["moods"].indexOf(
          this.filterMasterList['moods'][index].slug
        );
        filterSlug = this.selectedFilters["moods"][filterIndex]
        const searchChipIndex = this.searchChips.findIndex(chip => chip.slug === filterSlug);
        this.searchChips.splice(searchChipIndex, 1);
        this.selectedFilters["moods"].splice(filterIndex, 1);
      }
    } else if (filterType === "usecase") {
      filterSlug = this.filterMasterList['usecases'][index].slug
      if (this.filterMasterList['usecases'][index].selected && this.hardDisabledFilters.usecases.indexOf(filterSlug) !== -1) {
        SwalHelper.showToast('', '', `<b>${this.filterMasterList['usecases'][index].name}</b> filter cannot be unselected on this page`)
        return
      }
      this.filterMasterList['usecases'][index].selected = this.filterMasterList['usecases'][index].selected
        ? !this.filterMasterList['usecases'][index].selected
        : true;
      if (this.filterMasterList['usecases'][index].selected) {
        this.selectedFilters["usecases"].push(this.filterMasterList['usecases'][index].slug);
        this.searchChips.push({
          name: this.filterMasterList['usecases'][index].name,
          slug: this.filterMasterList['usecases'][index].slug,
          type: this.filterMasterList['usecases'][index].type
        })
      } else {
        const filterIndex = this.selectedFilters["usecases"].indexOf(
          this.filterMasterList['usecases'][index].slug
        );
        filterSlug = this.selectedFilters["usecases"][filterIndex]
        const searchChipIndex = this.searchChips.findIndex(chip => chip.slug === filterSlug);
        this.searchChips.splice(searchChipIndex, 1);
        this.selectedFilters["usecases"].splice(filterIndex, 1);
      }
    } else if (filterType === "language") {
      filterSlug = this.filterMasterList['languages'][index].slug
      if (this.filterMasterList['languages'][index].selected && this.hardDisabledFilters.languages.indexOf(filterSlug) !== -1) {
        SwalHelper.showToast('', '', `<b>${this.filterMasterList['languages'][index].name}</b> filter cannot be unselected on this page`)
        return
      }
      this.filterMasterList['languages'][index].selected = this.filterMasterList['languages'][index].selected
        ? !this.filterMasterList['languages'][index].selected
        : true;
      if (this.filterMasterList['languages'][index].selected) {
        this.selectedFilters["languages"].push(this.filterMasterList['languages'][index].slug);
        this.searchChips.push({
          name: this.filterMasterList['languages'][index].name,
          slug: this.filterMasterList['languages'][index].slug,
          type: this.filterMasterList['languages'][index].type
        })
      } else {
        const filterIndex = this.selectedFilters["languages"].indexOf(
          this.filterMasterList['languages'][index].slug
        );
        filterSlug = this.selectedFilters["languages"][filterIndex]
        const searchChipIndex = this.searchChips.findIndex(chip => chip.slug === filterSlug);
        this.searchChips.splice(searchChipIndex, 1);
        this.selectedFilters["languages"].splice(filterIndex, 1);
      }

    } else if (filterType === 'vocals') {
      this.selectedFilters.vocals = value
    } else if (filterType === 'Pro') {
      this.selectedFilters.pro = value
    } else if (filterType === 'playlist_type') {
      this.selectedFilters.playlist_type = value
    } else if (filterType === 'bpm') {
      this.selectedFilters.bpm.min = value.minValue
      this.selectedFilters.bpm.max = value.maxValue
    } else if (filterType === 'bpm_bucket') {
      this.selectedFilters.bpm_bucket = value;
    }
    if (fetchNewResults) {
      this.fetchNewSearchResults()
    }
  }

  // TODO: review this
  // public bpmFilter(bpmValue) {

  // }

  /**
   * Reset all filter values and variables
   * @param fetchNewResults Should new results be fetched after resetting?
   */
  public async resetFilters(fetchNewResults?: boolean) {
    this.$searchResults.next(undefined);
    this.searchChips = [];
    // this.hardDisabledFilters = [];
    this.selectedFilters = {
      vocals: undefined,
      pro: undefined,
      asset_type: this.selectedFilters.asset_type,
      playlist_type: undefined,
      genres: [],
      moods: [],
      usecases: [],
      languages: [],
      artists: [],
      playlists: [],
      subgenres: [],
      sfxcategories: [],
      sfxsubcategories: [],
      contextMeta: this.selectedFilters.contextMeta,
      bpm: {
        min: undefined,
        max: undefined,
      },
      bpm_bucket: undefined
    }
    this.filterMasterList['genres']?.forEach(genreElement => {
      genreElement.selected = false;
      genreElement.disabled = false;
    });
    this.filterMasterList['moods']?.forEach(moodElement => {
      moodElement.selected = false;
      moodElement.disabled = false;
    });
    this.filterMasterList['usecases']?.forEach(usecaseElement => {
      usecaseElement.selected = false;
      usecaseElement.disabled = false;
    });
    this.filterMasterList['languages']?.forEach(languageElement => {
      languageElement.selected = false;
      languageElement.disabled = false;
    })
    if (fetchNewResults) {
      await this.processPredefinedFilters(this.hardDisabledFilters);
      this.fetchNewSearchResults();
    }
  }

  /**
   * Mark predefined filters as selected
   * @param preDefinedFilters List of predefined filters
   */
  public async processPredefinedFilters(preDefinedFilters: FiltersSchema) {
    if (preDefinedFilters.asset_type) {
      this.selectedFilters.asset_type = preDefinedFilters.asset_type
    }
    if (preDefinedFilters.contextMeta) {
      this.selectedFilters.contextMeta = preDefinedFilters.contextMeta
    }
    if (preDefinedFilters.genres && preDefinedFilters.genres.length > 0) {
      // this.selectedFilters.genres.push(...preDefinedFilters.genres)
      preDefinedFilters.genres.forEach((preDefinedGenre) => {
        const genreIndex = this.filterMasterList['genres'].findIndex((genre) => genre.slug === preDefinedGenre)
        this.selectFilter('genre', genreIndex, undefined, false)
      })
    }
    if (preDefinedFilters.subgenres && preDefinedFilters.subgenres.length > 0) {
      this.selectedFilters.subgenres.push(...preDefinedFilters.subgenres)
    }
    if (preDefinedFilters.moods && preDefinedFilters.moods.length > 0) {
      // this.selectedFilters.moods.push(...preDefinedFilters.moods)
      preDefinedFilters.moods.forEach((preDefinedMood) => {
        const moodIndex = this.filterMasterList['moods'].findIndex((mood) => mood.slug === preDefinedMood)
        this.selectFilter('mood', moodIndex, undefined, false)
      })
    }
    if (preDefinedFilters.artists && preDefinedFilters.artists.length > 0) {
      this.selectedFilters.artists.push(...preDefinedFilters.artists)
    }
    if (preDefinedFilters.playlists && preDefinedFilters.playlists.length > 0) {
      this.selectedFilters.playlists.push(...preDefinedFilters.playlists)
    }
    if (preDefinedFilters.sfxcategories && preDefinedFilters.sfxcategories.length > 0) {
      this.selectedFilters.sfxcategories.push(...preDefinedFilters.sfxcategories)
    }
    if (preDefinedFilters.sfxsubcategories && preDefinedFilters.sfxsubcategories.length > 0) {
      this.selectedFilters.sfxsubcategories.push(...preDefinedFilters.sfxsubcategories)
    }
    // this.fetchNewSearchResults()
  }

  /**
   * Sets the list of filters that should be hard disabled for feed pages
   * @param filterList List of name slugs to keep disabled for the page
   */
  public async setHardDisabledFilters(filterList: FiltersSchema) {
    this.hardDisabledFilters = filterList;
  }

  public setSwaggerConfig(): void {
    const config: Configuration = new Configuration();
    config.apiKeys = {};
    config.apiKeys.Authorization = localStorage.getItem("authorizationToken") ?  'Bearer ' + localStorage.getItem("authorizationToken") : "";
    this.searchService.configuration = config;
  }

}
