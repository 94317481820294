import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

const metaTags: Metatag = {
  "discover": {
    title: 'Discover the best Royalty Free Music for your Content | Hoopr',
    desc: 'Discover 12,000+ royalty free songs and sound effects curated for influencers, streamers, gamers, vlogs, etc. Click here for three free background tracks and subscribe for me',
  },
  "mood": {
    title: 'Diverse Royalty Free Music for Various Moods | Hoopr',
    desc: 'Browse music based on moods from 40+ categories that you can use in your content. Subscribe for premium copyright music to take your content to next level!',
  },
  "category": {
    title: 'Explore Music Based On Creator Categories - Hoopr',
    desc: 'Explore premium music specially curated to complement your content for vlogging, gaming, cooking & many more. Start your creator journey by using our music.',
  },
  "sfx": {
    title: 'Download No Copyright Indian Background Sound Effects | Hoopr',
    desc: 'Browse & download best indian background sound effects for your videos. Get access to our extensive library of royalty free sound effects. Subscribe Now!',
  },
  "genre": {
    title: '20+ Different Genres & Categories | Hoopr',
    desc: 'Browse 20+ different genres such as Indian classical, bollywood, jazz, & many more. Subscribe for various royalty free tracks to improve your content.',
  },
  "playlist": {
    title: 'Custom playlists for Indian Content Creators | Hoopr',
    desc: 'Check out our custom playlists created for gamers, parties, podcasts, shortfilms, etc. Access royalty free music from India that you can use ',
  },
  "artist": {
    title: 'Listen & Download Copyright Free Songs by {{val1}} | Hoopr',
    desc: 'Browse copyright free songs by {{val1}} on hoopr & elevate your content. Subscribe now & get best royalty free music created by {{val1}} with Hoopr.',
  },
  "subSfx": {
    title: 'Download Royalty Free {{val2}} Sound Effects (SFX) | Hoopr',
    desc: 'Looking for royalty free {{val1}} sound effect to complement your content? Subscribe & access copyright free {{val1}} sound effects from Hoopr.',
  },
  "contactUs": {
    title: 'Contact Us | Hoopr - India Ka Sound',
    desc: 'All your questions on subscription to Hoopr and access to royalty free music for videos answered. Get in touch now!',
  },
  "contactSales": {
    title: 'Contact Sales | Hoopr - India Ka Sound',
    desc: 'Do you have specific requirement for copyright free music? Contact us with your requirement and provide details to our sales team.',
  },
  "referralLanding": {
    title: 'Introducing Hoopr Creator Rewards Program',
    desc: 'Join our community of creators & start earning Hoopr coins by sharing your unique referral link! Redeem your Hoopr coins for Amazon Pay Gift Cards. Subscribe Today!',
  },
  "referralT&C": {
    title: 'Terms and Conditions - Creator Rewards Program',
    desc: 'Read our terms & conditions for guidelines on Hoopr Creator Rewards Program which includes details about eligibility, referral process, limitations and many more',
  }
};

@Injectable({
  providedIn: 'root'
})

export class MetaService {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
  ) { }

  async setMetaTags(type: string, extraData?: any) {
    let { title, desc } = metaTags[type];
    if (extraData && extraData.val1) {
      title = title.replaceAll('{{val1}}', extraData.val1);
      desc = desc.replaceAll('{{val1}}', extraData.val1);
    }
    if (extraData && extraData.val2) {
      title = title.replaceAll('{{val2}}', extraData.val2);
      desc = desc.replaceAll('{{val2}}', extraData.val2);
    }
    this.titleService.setTitle(
      title,
    );
    this.metaTagService.updateTag({
      name: 'description',
      content: desc,
    });
    this.metaTagService.updateTag({
      property: 'og:title',
      content: title,
    });
    this.metaTagService.updateTag({
      property: 'og:description',
      content: desc,
    });
  }
}

interface Metatag {
  [key: string]: {
    title?: string;
    desc?: string;
  };
}

