<div class="individual">
  <app-header></app-header>
  <div *ngIf="this.feedData">
    <div class="container-fluid">
      <div class="holder">
        <div class="row holder">
          <div class="col-md-4 p-0 song-image d-flex flex-column">
            <img [src]="this.feedData?.banner?.image" onerror="this.src='/assets/images/default/track.webp'"
              alt="cover-image" class="background-poster" />
            <div class="d-flex justify-content-between align-items-center  pt-4 px-3 px-md-3 pl-xl-5 pr-xl-2">
              <h5 class="text-left navigation-text ">
                <app-breadcrumb></app-breadcrumb>
              </h5>
              <div class="d-flex gap-2 mr-2">
                <div class="text-right share-box" (click)="share()">
                  <img src="/assets/images/share-black.svg" alt="share">
                </div>
                <div *ngIf="showEditIcon" class="text-right share-box" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  <img src="/assets/images/edit-black.svg" alt="edit">
                </div>
                
                  <div class="dropdown-menu " aria-labelledby="navbarDropdownMenuLink">
                  <div class="dropdown-item" (click)="this.renamePlaylist()">
                      <img src="/assets/images/edit-gray.svg" alt="edit">&nbsp; Edit
                    </div>
                  <div class="dropdown-item" (click)="this.deleteUserPlaylist()">
                      <img src="/assets/images/delete-gray.svg" alt="edit">&nbsp; Delete
                    </div>
                  
                  </div>
                </div>
            </div>
            <div class="text-center pt-xl-5 pt-3 d-flex flex-column align-items-center">
              <img [src]="this.feedData?.banner?.image" onerror="this.src='/assets/images/default/track.webp'"
                alt="playlist-image" class=" song-img" />
              <h2 class="song-title">{{this.feedData?.banner?.title || ''}}</h2>
              <h4 class="song-text">
                {{this.feedData?.banner?.description || ''}}
              </h4>
              <div class="d-md-none d-flex gap-1  mt-3">
                <div class="  share-box-mobile ">
                  <img src="/assets/images/share-black.svg" alt="share">
                </div>
                  <div class=" share-box-mobile" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <img src="/assets/images/edit-black.svg" alt="edit">
                  
                  </div>
                  
                  <div class="dropdown-menu " aria-labelledby="navbarDropdownMenuLink">
                    <div class="dropdown-item" (click)="this.shareTrack()">
                      <img src="/assets/images/edit-gray.svg" alt="edit">&nbsp; Edit
                    </div>
                    <div class="dropdown-item" (click)="this.addToPlaylist()">
                      <img src="/assets/images/delete-gray.svg" alt="edit">&nbsp; Delete
                    </div>
                  
                  </div>
              </div>
              
              </div>

            <div class="music p-3 mt-5 mt-md-auto">
              <h5 class="end-text d-flex align-items-center justify-content-center flex-wrap gap-2">
                <span *ngIf="false">
                  YRF Music &#x2022;
                </span>
                 <span *ngIf="this.feedData?.banner?.year">
                   {{this.feedData.banner.year}} &#x2022;
                 </span>
                {{this.feedData?.banner?.count ||  this.feedData?.tracks?.length }} Tracks</h5>
            </div>
          </div>
          <!--col-->
          <div class="col-md-8">
            <div class="row h-100 " [ngClass]="this.feedData.tracks.length > 0 ? 'align-items-center':'align-items-baseline' ">
              <div class="col-xl-11  pt-4 pt-md-0">
                <div *ngIf="this.tracks.length > 0">
                  <h2 class="d-none d-md-inline">All Tracks</h2>
                  <div class="tracks-list mt-0 mt-md-2">
                    <app-tracks-list [tracks]="this.tracks" [size]="'large'"
                    infiniteScroll [infiniteScrollDistance]="1" infiniteScrollThrottle="50" (scrolled)="this.loadMoreData()"
                    ></app-tracks-list>
                  </div>
                </div>
                <div class="content px-5 my-5" *ngIf="this.tracks.length == 0 && showEditIcon">
                  <h1>All Tracks</h1>
                  <p>You don't have any tracks yet! Browse some Tracks and start liking your favorite tracks!</p>
                  <button type="button" [routerLink]="'/'" class="btn btn-light-yellow w-50 w-lg-25">EXPLORE TRACKS</button>
                </div>
              </div><!-- col -->
            </div><!-- row -->
          </div>
          <!--col-->
        </div>
        <!--row-->
      </div>
    </div>
    <!--container-fluid-->
  </div>
  <!--holder-->
</div>