<app-header></app-header>
<div class="auth-page-container d-flex align-items-center justify-content-center">
  <div class="px-3">
    <h3 class="title text-bold text-center">404 Error: Page not found</h3>
    <p class="text-normal text-center text-mute  mt-3 mb-4">The page you're looking for does not exist</p>
    <div class="d-flex justify-content-center">
      <button class="CTA_button " type="button" id="404-discover" name="404-discover" routerLink="/">Discover Music</button>
    </div>
  </div>
</div>
<footer></footer>

