import { ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef, Component, HostListener, NgZone, OnDestroy } from "@angular/core";
import { Router, Event as NavigationEvent } from '@angular/router';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BackendApiService } from '../../services/hoopr.services';
import { LoggerServiceService } from '../../services/logger-service.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GtmAnalyticsService } from 'src/app/services/gtm-analytics.service';
declare var window: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
  scrolled: boolean = false;
  takenUntil$ = new Subject();
  newAvatorUrl: string;
  searchQuery: string = '';


  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrolled = window.scrollY > 0;
  }

  currentpage: any;
  isMobile;
  isTablet;
  isDesktop;
  public searchText: string = '';
  public pageRoute: string = '';
  public redeemPointsAvailable: number = 0;

  constructor(
    private router: Router,
    private apiService: BackendApiService,
    private logger: LoggerServiceService,
    private deviceService: DeviceDetectorService,
    private gtmAnalytics: GtmAnalyticsService
  ) {
  }

  deviceDetect() {
    // this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();
  }

  userLogout() {
    this.currentpage.token = '';
    this.currentpage.userData = {};
    this.logger.logEvent('Logout');
    this.apiService.setToken(this.currentpage.token);
    this.apiService.setUserData(this.currentpage.userData);
    this.pushAnalytics('ga4 profile_interaction', 'profile_interaction	', 'account', 'my_profile', 'logout')
    location.reload();
  }

  gotoPricing() {
    if (this.currentpage.userData.subscription) {
      if (
        this.currentpage.userData.subscription.subscriptionPlan.type == 'individual'
      ) {
        this.router.navigateByUrl('/pricing/individual-content-creator');
      } else if (
        this.currentpage.userData.subscription.subscriptionPlan.type == 'business'
      ) {
        this.router.navigateByUrl('/pricing/businesses-and-brands');
      }
    } else {
      this.router.navigateByUrl('/pricing');
    }
  }

  urlRoute() {
    let url = this.router.url.split('?')[0];
    localStorage.setItem('redirectUrl', url);
    this.router.navigateByUrl('/auth/get-started');
    this.pushToDataLayer('sign-up');
    this.pushAnalytics('ga4 click_interaction', 'click_interaction', 'top_navigation', 'header', 'Sign In');
  }

  ngOnInit() {
    if (this.router.parseUrl(this.router.url).root?.children['primary']?.segments[0]?.path === 'search') {
      this.pageRoute = 'search';
    }

    this.apiService.avatorImg
      .pipe(takeUntil(this.takenUntil$))
      .subscribe((imgUrl) => {
        if (!imgUrl) return;
        this.newAvatorUrl = imgUrl;
      });

    this.currentpage = {};
    this.currentpage.token = this.apiService.getToken();
    if (this.currentpage.token) {
      this.currentpage.userData = this.apiService.getUserData();
      this.apiService.cartItemCount.subscribe((total) => {
        this.currentpage.userData.totalCartItems = total;
      });
    }
    this.deviceDetect();
  }

  ngOnDestroy(): void {
    this.takenUntil$.next();
    this.takenUntil$.complete();
  }

  public getSearchResults() {
    this.gtmAnalytics.pushCtaAnalytics({
      event: 'ga4 search_interaction',
      event_name: 'search_interaction',
      artist_name: '',
      instrument_type: '',
      link_name: 'top_navigation',
      link_header: '',
      link_section: 'top_navigation',
      link_text: 'search',
      link_url: '',
      login_status: JSON.parse(localStorage.getItem('userData'))?.id
        ? 'login'
        : 'guest',
      page_type: window.location?.pathname.split('/')[1] || null,
      search_link: this.searchText?.trim() || null,
      search_term: this.searchText?.trim() || null,
      track_name: '',
      user_id: JSON.parse(localStorage.getItem('userData'))?.id || null,
    });
    this.router.navigate(['/search'], {
      queryParams: { q: this.searchText, t: 'all' },
    });
  }

  searchNavigate(){
    this.router.navigate(['/search'], { queryParams: { q: this.searchQuery, t : "all" } });
  }
  pushToDataLayer(type: string): void {
    window.dataLayer.push({
      event: 'btn-click',
      source: 'header',
      type,
    });
  }
  
  pushAnalytics(
    event: string,
    event_name: string,
    link_name: string,
    link_section: string,
    link_text: string,
    link_header?: string
  ): void {
    this.gtmAnalytics.pushCtaAnalytics({
      event,
      event_name,
      artist_name: '',
      instrument_type: '',
      link_name,
      link_header: link_header ? link_header : '',
      link_section,
      link_text,
      link_url: (window.location?.pathname.length > 125 ? window.location?.pathname.substring(0, 125) : window.location?.pathname) || null,
      login_status: JSON.parse(localStorage.getItem('userData'))?.id
        ? 'login'
        : 'guest',
      page_type: window.location?.pathname.split('/')[1] || null,
      search_link: '',
      search_term: '',
      track_name: '',
      user_id: JSON.parse(localStorage.getItem('userData'))?.id || null,
    });
  }
}

