import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import "swiper/scss/pagination";
import "swiper/scss";
import SwiperCore, {
  Pagination,
  Navigation,
  FreeMode,
  Autoplay,
  SwiperOptions,
} from "swiper";
import { BackendApiService } from "../services/hoopr.services";
import { PopupService } from "../services/popup.service";
declare var $: any;
declare var window: any;

SwiperCore.use([Pagination, Navigation, Autoplay, FreeMode]);

@Component({
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LandingComponent implements OnInit {
  public categoryPromotion: any;
  public redContainer: any;
  public featurePromotion: any;
  public trackPlaylistPromotion: any;
  public popularData: any = {};
  public trendingList: any = [];
  public trendingTracks: any;
  public movies: any;
  public featuredArtistBanner: any;
  public featuredArtistTracks: any;
  public popularArtists: any;
  public genres: any;
  public languages: any;
  public categoryName: any;
  public feedData: any;
  public showPopup: boolean = true;
  public popUpType = undefined;
  public data = [1, 2, 4, 5, 67, 9]
  public nonsignedIn: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 50,
    navigation: true,
    loop: true,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
  };
  public signedIn: SwiperOptions = {
    slidesPerView: "auto",
    centeredSlides: true,
    loop: true,
    slidesPerGroup: 1,
    spaceBetween: 20,
    navigation: true,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
  };
  public isUserLoggedIn: boolean = false;

  constructor(
    private apiService: BackendApiService,
    private popupService: PopupService
  ) { }

  ngOnInit() {

    // if user has signed up show complete profile popup
    const isSignup = localStorage.getItem("isSignup");
    if (isSignup && isSignup == "true") {
      this.popupService.showPopUp("COMPLETE_PROFILE");
      localStorage.removeItem("isSignup");
    }

    // check if user logged in to decide type of banner to show.
    const token = this.apiService.getToken();
    if (token && token.length > 0) {
      this.isUserLoggedIn = true;
    }

    this.apiService.getDiscoverFeed().subscribe(
      (response) => {
        this.feedData = response;
        this.redContainer = this.feedData["red-container"];
        this.categoryPromotion = this.feedData["category-promotion"];
        this.featurePromotion = this.feedData["feature-promotion"];
        this.trackPlaylistPromotion = this.feedData["track-playlist-promotion"];
        this.popularData.movies = this.feedData["popular-on-select-movies"].data;
        this.popularData.tracks = this.feedData["popular-on-select-tracks"].data;
        this.popularData.playlists = this.feedData["popular-on-select-playlists"].data;
        this.popularData.artists = this.feedData["popular-on-select-singers"].data;
        this.trendingList.push(this.feedData["trending-1"]);
        this.trendingList.push(this.feedData["trending-2"]);
        this.trendingList.push(this.feedData["trending-3"]);
        this.trendingTracks = this.feedData["trending-tracks"];
        this.movies = this.feedData["movies"];
        this.featuredArtistBanner = this.feedData["featured-artist-banner"];
        this.featuredArtistTracks = this.feedData["featured-artist-tracks"].data;
        this.popularArtists = this.feedData["popular-artists"];
        this.genres = this.feedData["genres"];
        this.languages = this.feedData["languages"];
        this.categoryName = this.feedData["category-name"];
        // populate track list for autoplay
      },
      (err) => {
        console.log("Error while loading feed data", err);
      }
    );
  }
}

// export class LandingComponent implements OnInit, AfterViewInit {

//   isMobile;
//   isTablet;
//   isDesktopDevice;
//   isB2b;
//   playlistData: []
//   bannerTextpart1;
//   bannerTextpart2;
//   bannerTextpart3;
//   bannerTextpart4;
//   bannerTextpart5;
//   bannerimgUrl;
//   bannerCTA;
//   hideSearch;

//   isPlaying: boolean = false;
//   public currentTrack: HTMLAudioElement = new Audio;
//   files = {
//     file1: 'https://d2ntslqmfg7dws.cloudfront.net/assests/landing-page/ind-rail.wav',
//     file2: 'https://d2ntslqmfg7dws.cloudfront.net/assests/landing-page/ganga-aarti-varanasi.wav',
//     file3: 'https://d2ntslqmfg7dws.cloudfront.net/assests/landing-page/chai-wale.wav',
//     file4: 'https://d2ntslqmfg7dws.cloudfront.net/assests/landing-page/repeating-swoosh-bang.wav',

//     music1: 'https://d2ntslqmfg7dws.cloudfront.net/assests/landing-page/chokki-phutri.wav',
//     music2: 'https://d2ntslqmfg7dws.cloudfront.net/assests/landing-page/groovonium.wav',
//     music3: 'https://d2ntslqmfg7dws.cloudfront.net/assests/landing-page/lamhe-tere-mere.wav',
//     music4: 'https://d2ntslqmfg7dws.cloudfront.net/assests/landing-page/boogie.wav',
//   }
//   public activeTrack = undefined;
//   iconImage = {
//     play: '/assets/images/icon/Iconawesome-play.webp',
//     pause: '/assets/images/icon/landing-pause.svg'
//   }

//   ngOnInit(): void {
//     this.activatedRoute.params.subscribe(params => {
//       if (params.version == 1) {
//         this.bannerimgUrl = `<img src="/assets/images/landing/landing-banner-bg.webp" alt="" class="w-100 p-absolute h-100 banner-img">`
//         this.bannerTextpart1 = ` <p class="text-normal font-light banner-text-normal">This is Hoopr, This is <span class="brand-pink text-bold">#Indiakasound</span></p>`
//         this.bannerTextpart2 = `No more boring stock<span class="brand-pink"> music</span>.<br> No more <span
//         class="brand-pink">copyright</span> strikes.<br>`
//         this.bannerTextpart3 = `<h2 class="text-normal text-mute py-4 mb-2 font-light banner-text-normal">Download premium Indian music, specially curated for indian content creators.<br>Sign up now & get started</h2>`
//         this.bannerTextpart4 = `Start for Free`
//         this.bannerCTA = `<div class="col d-flex justify-content-center m-40">
//         <a class="btn CTA_button" id="start_free_discover" type="button" name="button" href="/auth/login">
//         Click here to access the Music
//         </a>
//       </div>`
//       }
//       else if (params.version == 2) {
//         this.bannerimgUrl = `<img src="/assets/images/landing/landing-banner-bg.webp" alt="" class="w-100 p-absolute h-100 banner-img">`
//         this.bannerTextpart1 = ` <p class="text-normal font-light banner-text-normal">This is Hoopr, This is <span class="brand-pink text-bold">#Indiakasound</span></p>`
//         this.bannerTextpart2 = `No more worrying about <span class="brand-pink">copyright</span> strikes!`
//         this.bannerTextpart3 = `<h2 class="text-normal text-mute py-4 mb-2 font-light banner-text-normal">Download premium music, specially curated for indian content creators.<br>Sign-up for free and listen to <span class="brand-pink text-bold">12,000+</span> songs, sound effects for you!</h2>`
//         this.bannerTextpart4 = `Check out music for your content!`
//         this.bannerCTA = `<div class="col d-flex justify-content-center m-40">
//         <a class="btn CTA_button" id="start_free_discover" type="button" name="button" href="/auth/login">
//         Click here to access the Music
//         </a>
//       </div>`
//       }
//       else if (params.version == 3) {
//         this.bannerimgUrl = `<img src="/assets/images/landing/landing-banner-bg.webp" alt="" class="w-100 p-absolute h-100 banner-img">`
//         this.bannerTextpart1 = ` <p class="text-normal font-light banner-text-normal">This is Hoopr, This is <span class="brand-pink text-bold">#Indiakasound</span></p>`
//         this.bannerTextpart2 = `Forget about <span class="brand-pink">Copyright</span> strikes!<br>
//         <span class="brand-pink">12,000+</span> Tracks and Effects for your content
//         <br>`
//         this.bannerTextpart3 = `<h2 class="text-normal text-mute py-4 mb-2 font-light banner-text-normal">Access music created for content creators in India.<br>Sign up and get started</h2>`
//         this.bannerTextpart4 = `Click here to check out music for you!`
//         this.bannerCTA = `<div class="col d-flex justify-content-center m-40">
//         <a class="btn CTA_button" id="start_free_discover" type="button" name="button" href="/auth/login">
//         Click here to access the Music
//         </a>
//       </div>`
//       }
//       else if (params.version == 4) {
//         this.bannerimgUrl = `<img src="/assets/images/landing/landing-banner-bg.webp" alt="" class="w-100 p-absolute h-100 banner-img">`
//         this.bannerTextpart1 = `<p class="text-normal font-light banner-text-normal">This is Hoopr, This is <span class="brand-pink text-bold">#Indiakasound</span></p>`
//         this.bannerTextpart2 = `Music for your <span class="brand-pink">YouTube</span> Videos.<br>Stop worrying about <span class="brand-pink">copyrights</span>, royalties & other penalties<br>`
//         this.bannerTextpart3 = `<h2 class="text-normal text-mute py-4 mb-2 font-light banner-text-normal">Download premium music created for Indian content creators.<br>Get <span class=brand-pink>12,000+</span> songs and sound effects</h2>`
//         this.bannerTextpart4 = `Click here to access the Music`
//         this.bannerCTA = `<div class="col d-flex justify-content-center m-40">
//         <a class="btn CTA_button" id="start_free_discover" type="button" name="button" href="/auth/login">
//         Click here to access the Music
//         </a>
//       </div>`
//       }
//       else if (params.version == 5) {
//         this.bannerimgUrl = `<img src="/assets/images/landing/diwali-landing-banner.webp" alt="" class="w-100 p-absolute h-100 banner-img">`
//         this.bannerTextpart1 = ` <p class="text-normal font-light banner-text-normal">THIS DIWALI GET  <span class="badge-pink font-bold">20% OFF</span> ON All SUBSCRIPTIONS</p> `
//         this.bannerTextpart2 = `Exclusive <span class="brand-pink">Diwali Playlist</span> Pe Aao,<br>
//         Hoopr Ke Saath Content Banao!<br>`
//         this.bannerTextpart3 = `<h2 class="text-normal text-mute py-4 mb-2 font-light banner-text-normal">Discover 25000+ premium Indian tracks & sfx to use in your vlogs & videos<br>
//         join thousands of creators and brands today!</h2>
//         `
//         this.bannerTextpart4 = `Click here to access the Music`
//         this.bannerCTA = `<div class="col col-lg-6 d-flex justify-content-center m-40 mb-diwali-cta">
//         <a class="CTA_button diwali-CTA-button" id="start_free_discover" type="button" name="button" href="/playlist/happy-diwali?utm_source=landing_page&utm_medium=cta_click&utm_campaign=diwali
//         ">
//         checkout DIWALI PLAYLIST
//         </a>
//       </div>
//       <div class="col col-lg-6  d-flex justify-content-center m-40 mb-diwali-cta-2" [innerHtml]=this.bannerCTA2>
//       <a class="CTA-white-outline" id="start_free_discover" type="button" name="button" href="/pricing/individual-content-creator?utm_source=landing_page&utm_medium=cta_click&utm_campaign=diwali">Subscribe to get 20% off </a>
//       </div>`
//         // this.bannerCTA2 = `<span class="CTA-white-outline" id="start_free_discover" type="button" name="button" routerLink="/auth/login">Subscribe to get 20% off </span>`
//       }
//       else if (params.version == 'b2b') {
//         this.isB2b = true;
//         this.bannerimgUrl = `<img src="/assets/images/landing/b2b-landing-bg.png" alt="" class="w-100 p-absolute h-100 banner-img d-none d-md-block">
//         <img src="/assets/images/landing/b2b-landing-bg-resp.png" alt="" class="w-100 p-absolute h-100 banner-img d-md-none">` /* TODO: @Ashish webp */
//         this.bannerTextpart1 = ``
//         this.bannerTextpart2 = `<span class="text-gradient">Premium Music</span> For Your Branded Content <p class="text-normal fw-200 text-left my-4">Enhance Your Brands Storytelling with Tailored, High-Quality Music Solutions</p>`
//         this.bannerTextpart3 = `<h2 class="text-normal text-mute pt-2 pb-5 banner-text-normal">Copyright Safe Music From Diverse Artists, Sound Effects, Songs, Scores That Take <br> Your Content To The Next Level<br></h2>`
//         this.bannerTextpart4 = `Start for Free`
//         this.bannerCTA = `<div class="d-flex justify-content-center justify-content-md-start m-40">
//           <a class="btn CTA_button" id="start_free_discover" type="button" name="button" href="/auth/signup;select=business">
//             START FOR FREE
//           </a>
//         </div>`
//       }
//       else {
//         // this.bannerimgUrl = `<img src="/assets/images/landing/new-default-landing-banner-bg.webp" alt="" class="w-100 p-absolute h-100 banner-img d-none d-md-block">
//         // <img src="/assets/images/landing/new-default-landing-banner-bg-resp.webp" alt="" class="w-100 p-absolute h-100 banner-img d-md-none">`
//         this.bannerimgUrl = `<img src="banner.webp" class="banner-img h-100 w-100"
//         srcset="https://hoopr.ai/assets/images/landing/new-default-landing-banner-bg-resp.webp 430w,
//         https://hoopr.ai/assets/images/landing/new-default-landing-banner-bg.webp 1500w"
//         alt="test_image">`
//         this.bannerTextpart1 = ``
//         this.bannerTextpart2 = `Enhance Your Content With 100% Copyright-Free Music & SFX`
//         this.bannerTextpart3 = `<h2 class="text-normal text-mute pt-2 pb-md-5 pb-2 banner-text-normal">Premium Indian Music & Sound Effects (SFX) All In One Place</h2>`
//         this.bannerTextpart4 = `Start for Free`
//         this.bannerCTA = `<a class="btn CTA_button d-inline-block btn-rounded" id="start_free_discover" type="button" name="button" href="/auth/signup">
//         START FOR FREE
//         </a>`
//       }
//     });

//     this.addMetaTags();

//     const userDetails = this.apiService.getUserData();
//     if (userDetails && Object.keys(userDetails).length !== 0) {
//       this.router.navigate(['/']);
//     }

//     $('#cta_container').on('click', '.CTA_button', function(e){
//         window.dataLayer.push({
//           event: 'ga4 click_interaction',
//           event_name: 'click_interaction',
//           artist_name: '',
//           instrument_type: '',
//           link_name: 'Banner',
//           link_header: '',
//           link_section: 'hero_banner',
//           link_text: 'Start for free',
//           link_url: '',
//           login_status: JSON.parse(localStorage.getItem('userData'))?.id
//            ? 'login'
//            : 'guest',
//           page_type: window.location?.pathname.split('/')[1] || null,
//           search_link: '',
//           search_term: '',
//           track_name: '',
//           user_id: JSON.parse(localStorage.getItem('userData'))?.id || null,
//         })
//    });
//   }

//   scrolled: boolean = false;
//   userdata: any;
//   public searchText = "";
//   showSubscribePopup = false;

//   @HostListener("window:scroll", [])

//   onWindowScroll() {
//     let element = document.querySelector(".navbar") as HTMLElement;
//     if (window.pageYOffset > element.clientHeight) {
//       element.classList.add("navbar-bg");
//     } else {
//       element.classList.remove("navbar-bg");
//     }
//   }

//   constructor(
//     private apiService: BackendApiService,
//     private logger: LoggerServiceService,
//     private router: Router,
//     private titleService: Title,
//     private metaTagService: Meta,
//     private deviceService: DeviceDetectorService,
//     private activatedRoute: ActivatedRoute,
//     public utilsService: UtilsService,
//     private gtmAnalytics:GtmAnalyticsService
//   ) {
//     this.userdata = {};
//     this.userdata.token = this.apiService.getToken();
//     if (this.userdata.token) {
//       this.userdata.userData = this.apiService.getUserData();
//     }

//     this.deviceDetect();

//   }

//   deviceDetect() {
//     this.isMobile = this.deviceService.isMobile();
//     this.isTablet = this.deviceService.isTablet();
//     this.isDesktopDevice = this.deviceService.isDesktop();
//   }

//   ngAfterViewInit(): void {
//     if (!this.userdata?.userData?.subscription) {
//       setTimeout(() => {
//         this.showSubscribePopup =
//           !localStorage.getItem("subscribeNowPopupShown") &&
//           this.userdata?.userData?.token;
//       }, 5000);
//     }
//     // load playlists when user scrolls down
//     this.apiService.getLandingPAgePlaylistsId().subscribe((data) => {
//       this.playlistData = data;
//     })
//   }

//   async addMetaTags() {
//     this.titleService.setTitle(
//       '100% Royalty & Copyright Free Music for Creators | Download No Copyright Music',
//     );

//     this.metaTagService.updateTag({
//       name: 'description',
//       content:
//         'Download the best royalty free music. Use our 100% copyright free music for YouTube, social media & more from our extensive library. Subscribe Now!',
//     });

//     this.metaTagService.updateTag({
//       name: 'keywords',
//       content:
//         'copyright free music, no copyright music, non copyright music, royalty free music, copyright free music download, royalty free music download',
//     });

//     this.metaTagService.updateTag({
//       property: 'og:title',
//       content:
//       '100% Royalty & Copyright Free Music for Creators | Download No Copyright Music',
//     });

//     this.metaTagService.updateTag({
//       property: 'og:description',
//       content:
//       'Download the best royalty free music. Use our 100% copyright free music for YouTube, social media & more from our extensive library. Subscribe Now!',
//     });

//     const canonicalURL = environment.baseUrlFrontend || 'https://hoopr.ai/';
//     // Create a canonical link element
//     const linkElement = document.createElement('link');
//     linkElement.setAttribute('rel', 'canonical');
//     linkElement.setAttribute('href', canonicalURL);

//     // Append the link element to the head of the document
//     document.head.appendChild(linkElement);
//   }

// }
