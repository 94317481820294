<h2 class="faq-heading">FAQs</h2>
<div class="faq-container">
  <div *ngFor="let section of faqData" class="faq-section">
    <div class="faq-section-header" (click)="toggleSection(section)" [ngClass]="{ 'active': section.open }">
      <span>{{ section.name }}</span>
      <img class="icon" [ngClass]="section.open ? 'arrow-up' : 'arrow-down'" src="assets/images/arrow-down.png" alt="Arrow Icon">
    </div>
    <div class="faq-questions" *ngIf="section.open">
      <div *ngFor="let question of section.questions" class="faq-question">
        <div class="faq-question-header" (click)="toggleQuestion(question)" [ngClass]="{ 'active-question': question.open }">
          <span>{{ question.text }}</span>
          <i class="icon-plus" [ngClass]="question.open ? 'minus-icon' : 'plus-icon'"></i>
        </div>
        <div class="faq-answer" *ngIf="question.open">
          {{ question.answer }}
        </div>
      </div>
    </div>
  </div>
</div>
