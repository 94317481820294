<!-- updated-subscribed-download -->
<div class="row m-0 justify-content-center mb-3" *ngIf="this.popUpType === 'SUBSCRIBED_PAID_DOWNLOAD'">
    <div class="w-100">
        <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
    </div>
    <h2 class="popup-title mb-4 w-100">You are now downloading...</h2>
    <div class="row mx-0 w-100 justify-content-center">
        <div class="col-md-7">
            <div class="cover-image-card row m-0 py-3 w-100">
                <img src="/assets/images/hoopr-bookmark.webp" class="hoopr-bookmark" alt="hoopr-bookmark">
                <div class="col-4">
                    <img [src]="this.utilsService.imageKitUrl(this.trackInfo?.imageUrl || this.trackInfo?.image_url? this.trackInfo?.imageUrl|| this.trackInfo?.image_url:'/assets/images/login-popup-globe.webp',80)"
                        onerror="this.src='/assets/images/login-popup-globe.webp'" alt="track-image"
                        class="globe-img mr-0">
                </div>
                <div class="col-6 pl-0 d-flex flex-column justify-content-center">
                    <p class="text-small my-1 text-left w-100 text-ellipsis">{{this.trackInfo.name}}</p>
                    <p class="text-mute text-small text-left w-100 mb-2 mt-0"
                        *ngIf="this.primaryArtist &&this.primaryArtist.name">
                        {{this.primaryArtist?.name || ''}}</p>
                </div>
                <div class="col-2"></div>
            </div>
        </div>
    </div>
</div>
<!-- modal-1 -->





<div *ngIf="this.popUpType === 'MONTHLY_DOWNLOAD_LIMIT'">
    <div class="row m-0 justify-content-center mb-3">
        <div class="col-1"></div>
        <div class="col-9">
            <img src="/assets/images/hoopr-icon-pink.webp" alt="">
            <h2 class="popup-title">Oops! you're out of your downloads </h2>
            <p class="popup-text my-3">You've exhausted the limit of tracks download for your subscription.</p>
        </div>
        <div class="col-1"></div>
        <div class="row m-0">
            <div class="d-flex justify-content-center">
                <button class="btn-white-o  my-1" (click)="this.closePopUp()">Close</button>
            </div>

        </div>
    </div>
</div>
<!-- modal-2 -->

<!-- agency-download-limmit -->
<div *ngIf="this.popUpType === 'AGENCY_DOWNLOAD_LIMIT'">
    <div class="row m-0 justify-content-center mb-3">
        <div class="col-1"></div>
        <div class="col-9">
            <img src="/assets/images/hoopr-icon-pink.webp" alt="">
            <h2 class="popup-title">You Have Exhausted Your Download Limits </h2>
            <p class="popup-text my-3">Please click below to buy another Agency pro Pack</p>
        </div>
        <div class="col-1"></div>
        <div class="row m-0">
            <div class="d-flex justify-content-center">
                <button class="btn-white-o  my-1" (click)="this.redirectToBusinessPricing()">Buy Again</button>
            </div>
        </div>
    </div>
</div>


<div *ngIf="this.popUpType === 'DAILY_DOWNLOAD_LIMIT'">
    <div class="row m-0 justify-content-center mb-3">
        <div class="col-1"></div>
        <div class="col-9">
            <img src="/assets/images/hoopr-icon-pink.webp" alt="">
            <h2 class="popup-title">Oops! you're out of your daily downloads </h2>
            <p class="popup-text my-3">You've exhausted the daily / plan limits for downloads.</p>
        </div>
        <div class="col-1"></div>
        <div class="row m-0">
            <div class="d-flex justify-content-center">
                <button class="btn-white-o  my-1" (click)="this.closePopUp()">Close</button>
            </div>
            <!-- <div class="col-md-6">
                <div class="card popup-card grey-popup-card p-3 mt-3">
                    
                </div>
            </div> -->


        </div>
    </div>
</div>


<!-- SUBSCRIBED_SOCIAL_DOWNLOAD -->
<!--  -->
<div class="row m-0 justify-content-center mb-3" *ngIf="this.popUpType === 'SUBSCRIBED_SOCIAL_DOWNLOAD'">
    <div class="w-100">
        <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
    </div>
    <h2 class="popup-title w-100">Hey {{this.userDetails.name}}, Before You Download This Track</h2>
    <p class="popup-text mt-4 mb-3 text-mute">Please share details of your social media channels. This will help us to
        clear your channels for claims and ensure you don’t face any issues</p>
    <div class="">
    </div>
    <form [formGroup]="socialPopupForm" (ngSubmit)="socialPopupSubmit()">
        <div class="row mx-0 w-100 justify-content-center">
            <div class="col-md-8 mt-2">
                <div class="input-black hoopr_form_fields mb-0 w-100">
                    <img src="/assets/images/youtube-social-download.webp" alt="">
                    <input id="youtube" type="url" class="inputText pt-0 mt-0" formControlName="Youtube"
                        placeholder="YouTube Channel URL"
                        pattern="/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi" />
                </div><!-- item -->
            </div>
            <div class="col-md-8 mt-2">
                <div class="input-black hoopr_form_fields mb-0 w-100">
                    <img src="/assets/images/facebook-social-download.webp" alt="">
                    <input id="facebook" type="url" class="inputText pt-0 mt-0" formControlName="Facebook"
                        placeholder="Facebook Profile/Page URL"
                        pattern="/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi" />
                </div><!-- item -->
            </div>
            <div class="col-md-8 mt-2">
                <div class="input-black hoopr_form_fields mb-0 w-100">
                    <img src="/assets/images/instagram-social-download.webp" alt="">
                    <input id="instagram" type="url" class="inputText pt-0 mt-0" formControlName="Instagram"
                        placeholder="Instagram Profile/Page  URL"
                        pattern="/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi" />
                </div><!-- item -->
            </div>
            <div class="col-md-8 mt-2" *ngIf="this.socialPopupError">
                <span>To continue downloading add atleast one social media link.</span>
            </div>
        </div>
        <div class="d-flex justify-content-center mt-4">
            <button class="popup-cta my-1" type="submit">Save</button>
        </div>
    </form>
</div>

<!-- <div *ngIf="this.popUpType === 'SWITCH_PLANS'">
	<div class="row m-0 justify-content-center mb-3">
		<div class="w-100">
			<img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
		</div>
		<div class="row m-0 justify-content-center mb-3">
			<div class="col-lg-1"></div>
			<div class="col-lg-10">
				<h2 class="popup-title mb-3">Looking To Switch Plans?</h2>
				<p class="text-small text-mute my-4">Dont hesitate to reach out to us, drop an email (hello@hoopr.ai) or
					WhatsApp
					us (+91-9324589627) and we will get back to you immediately.</p>
			</div>
			<div class="col-lg-1"></div>
		</div>
		<div class="row m-0">
			<div class="col d-flex justify-content-center">
				<button type="button" name="button" class="popup-cta my-1" (click)="this.viewPlansCtaRedirect()">VIEW
					PLANS</button>
			</div>
		</div>
	</div>
</div> -->

<div *ngIf="this.popUpType === 'UPGRADE_PLAN'">
    <div class="row m-0 justify-content-center mb-3">
        <div class="w-100">
            <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
        </div>
        <div class="row m-0 justify-content-center mb-3">
            <div class="col-lg-1"></div>
            <div class="col-lg-10">
                <h2 class="popup-title mb-3">Upgrade to {{this.trackInfo}} Today!</h2>
                <p class="text-small text-mute my-4">Please reach out to us if you wish to upgrade to
                    {{this.trackInfo}},
                    and avail these features:</p>
                <div class="row mx-0 justify-content-md-center">
                    <div class="col-auto px-0 my-1">
                        <div class="d-flex align-items-center mx-2">
                            <span class="green-bg-right"><i class="fa fa-check" aria-hidden="true"></i></span>
                            <span class="text-mute"
                                *ngIf="this.trackInfo && this.trackInfo === 'Creator Starter'; else elseBlock1">100%
                                Royalty-free music</span>
                            <ng-template #elseBlock1>
                                <span class="text-mute">Unlimited Downloads</span>
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-auto px-0 my-1">
                        <div class="d-flex align-items-center mx-2">
                            <span class="green-bg-right"><i class="fa fa-check" aria-hidden="true"></i></span>
                            <span class="text-mute"
                                *ngIf="this.trackInfo && this.trackInfo === 'Creator Starter'; else elseBlock2">Worldwide
                                Clearance</span>
                            <ng-template #elseBlock2>
                                <span class="text-mute"
                                    *ngIf="this.trackInfo && this.trackInfo === 'Creator Pro'">Clearance for 3
                                    channels</span>
                                <span class="text-mute"
                                    *ngIf="this.trackInfo && this.trackInfo === 'Creator Elite'">Clearance for 5
                                    channels</span>
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-auto px-0 my-1">
                        <div class="d-flex align-items-center mx-2">
                            <span class="green-bg-right"><i class="fa fa-check" aria-hidden="true"></i></span>
                            <span class="text-mute"
                                *ngIf="this.trackInfo && this.trackInfo === 'Creator Starter'; else elseBlock3">Claim-free
                                Experience</span>
                            <ng-template #elseBlock3>
                                <span class="text-mute text-left">Use for audiobooks/podcasts</span>
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-auto px-0 my-1" *ngIf="this.trackInfo && this.trackInfo === 'Creator Elite'">
                        <div class="d-flex align-items-center mx-2">
                            <span class="green-bg-right"><i class="fa fa-check" aria-hidden="true"></i></span>
                            <span class="text-mute">Brand Collabs</span>
                        </div>
                    </div>
                    <div class="col-auto px-0 my-1" *ngIf="this.trackInfo && this.trackInfo === 'Creator Elite'">
                        <div class="d-flex align-items-center mx-2">
                            <span class="green-bg-right"><i class="fa fa-check" aria-hidden="true"></i></span>
                            <span class="text-mute">Influencer Campaigns</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-1">
            </div>
        </div>
        <div class="row m-0 w-100 justify-content-center mt-3">
            <div class="col-12 col-md-auto d-flex justify-content-center">
                <a href="https://wa.me/919324589627" target="_blank" class="btn CTA-white my-1 w-100 w-md-auto">TEXT ON
                    WHATSAPP
                </a>
            </div>
            <div class="col-12 col-md-auto d-flex justify-content-center">
                <a href="mailto:hello@hoopr.ai" target="_blank" class="btn popup-cta my-1 w-100 w-md-auto">SEND AN EMAIL
                </a>
            </div>
        </div>
    </div>
</div>




<!-- > 100 Hoopr Coins -->
<div class="row m-0 justify-content-center mb-3" *ngIf="this.popUpType === 'SUBSCRIBED_SUFFICIENT_COINS'">
    <div class="col-1"></div>
    <div class="col-9">
        <img src="/assets/images/hoopr-icon-pink.webp" alt="" class="mb-3">
        <h2 class="popup-title">Claim Your Reward Now!</h2>
        <p class="popup-text my-3">Note: Please redeem your Amazon Pay gift card with a minimum of 100 coins
            and a maximum of 1000 coins, only in multiples of 1O.</p>
        <div class="row mx-0 justify-content-center">
            <div class="col-md-10">
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="redeem-card bg-black px-3 py-2 my-1">
                            <p class="text-x-sm my-0 text-left">Redeemable points available:</p>
                            <div class="d-flex align-items-center">
                                <img src="/assets/images/coin.png" alt="referral-coin" class="referral-coin">
                                <span class="text-lg text-bold px-2">{{this.trackInfo.redeemPointsAvailable ||
                                    '0'}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <form [formGroup]="claimPointsForm" (ngSubmit)="onRedeemFormSubmit()">
                    <div class="row mx-0 align-items-center">
                        <div class="col-md px-0 mt-3">
                            <p class="text-x-sm my-0 text-left">Enter to Redeem Coins:</p>
                            <div class="hoopr_form_fields redeem-card my-1 p-2 mb-0">
                                <img src="/assets/images/coin.png" alt="referral-coin" class="referral-coin">
                                <input class="inputText text-lg text-bold px-2" type="number" min="100" max="1000"
                                    formControlName="numberInput" />
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 align-items-center">
                        <div *ngIf="claimFormErrors?.numberInput" class="brand-pink-color">
                            {{ claimFormErrors.numberInput }}
                        </div>
                    </div>
                </form>
                <div class="row mx-0 mt-4 justify-content-center">
                    <!-- <button class="popup-cta my-1 w-100 w-md-auto" type="submit" id="submit-reward-btn"
                        (click)="this.redeemNow('VOUCHERCODE')">
                        REDEEM NOW - VOUCHERCODE
                    </button>
                    <button class="popup-cta my-1 w-100 w-md-auto" type="submit" id="submit-reward-btn"
                        (click)="this.redeemNow('CNPIN')">
                        REDEEM NOW - CNPIN
                    </button> -->
                    <button class="popup-cta my-1 w-100 w-md-auto" type="submit" id="submit-reward-btn"
                        (click)="this.redeemNow('EGCGBGSRP001')">
                        REDEEM NOW
                    </button>
                    <!-- <button class="popup-cta my-1 w-100 w-md-auto" type="submit" id="submit-reward-btn"
                        (click)="this.redeemNow('UBEFLOW')">
                        REDEEM NOW - UBEFLOW
                    </button>
                    <button class="popup-cta my-1 w-100 w-md-auto" type="submit" id="submit-reward-btn"
                        (click)="this.redeemNow('PROCESSINGSTS')">
                        REDEEM NOW - PROCESSINGSTS
                    </button>
                    <button class="popup-cta my-1 w-100 w-md-auto" type="submit" id="submit-reward-btn"
                        (click)="this.redeemNow('DISABLEDSTS')">
                        REDEEM NOW - DISABLEDSTS
                    </button>
                    <button class="popup-cta my-1 w-100 w-md-auto" type="submit" id="submit-reward-btn"
                        (click)="this.redeemNow('testsuccess001')">
                        REDEEM NOW - testsuccess001
                    </button>
                    <button class="popup-cta my-1 w-100 w-md-auto" type="submit" id="submit-reward-btn"
                        (click)="this.redeemNow('APITESTTIMFAIL')">
                        REDEEM NOW - APITESTTIMFAIL
                    </button> -->
                </div>
            </div>
        </div>
    </div>
    <div class="col-1"></div>
</div>


<div class="row m-0 justify-content-center mb-3" *ngIf="this.popUpType === 'REDEEM_DETAIL_POPUP'">
    <div class="col-1"></div>
    <div class="col-9">
        <img src="/assets/images/hoopr-icon-pink.webp" alt="" class="mb-3">
        <h2 class="popup-title">Enter Your Details</h2>
        <p class="popup-text my-3">Don't forget to double-check your email and phone number. We
            wouldn't want your reward dancing away!</p>
        <form [formGroup]="referralAwardForm" (ngSubmit)="onRedeemFormSubmit()">
            <div class="hoopr_form_field_group">
                <div class="hoopr_form_fields"
                    [ngClass]="[formErrors.email? 'field-border-red' : '', (!formErrors.email && (this.referralAwardForm.value.email != '')) ? 'field-border-green': '']">
                    <input type="text" inputmode="email" class="inputText" required formControlName="email" />
                    <span class="floating-label">Email</span>
                </div>
                <p class="form_errors" *ngIf="formErrors.email">
                    {{ formErrors.email }}
                </p>
            </div>
            <div class="hoopr_form_field_group row mx-0">
                <div class="col-auto pl-0">
                    <select class="custom_country_dropdown_menu text-small p-1 p-md-2 mb-0 hoopr_form_fields"
                        name="country" id="country" formControlName="PhoneCode" (change)="onPhoneCodeSelect($event)">
                        <option value="+91" selected>+91</option>
                        <option *ngFor="let country of countries" [value]="country.number">
                            <span class="option">{{'+'+country.number}}</span>
                        </option>
                    </select>
                </div>
                <div class="col px-0">
                    <div class="hoopr_form_fields"
                        [ngClass]="[formErrors.PhoneNumber? 'field-border-red' : '', (!formErrors.PhoneNumber && (this.referralAwardForm.value.PhoneNumber != '')) ? 'field-border-green': '']">
                        <input type="text" inputmode="number" class="inputText" required
                            formControlName="PhoneNumber" />
                        <span class="floating-label">Phone Number</span>
                    </div>
                </div>
                <p class="form_errors w-100" *ngIf="formErrors.PhoneNumber">
                    {{ formErrors.PhoneNumber }}
                </p>
            </div>
            <div class="row m-0 justify-content-center">
                <button class="popup-cta my-1" type="submit" id="submit-reward-btn">
                    <span class="spinner-border spinner-border-sm mx-1" *ngIf="this.redeemNowLoader"></span>
                    SEND MY VOUCHER
                </button>
            </div>
        </form>
    </div>
    <div class="col-1"></div>
</div>

<!-- < 100 HooprCoins -->
<div class="row m-0 justify-content-center mb-3" *ngIf="this.popUpType === 'SUCCESSFULL_REDEMPTION'">
    <div class="col-1"></div>
    <div class="col-9">
        <img src="/assets/images/hoopr-icon-pink.webp" alt="" class="mb-3">
        <h2 class="popup-title">Redemption Successfull !!!</h2>
        <p class="popup-text my-3">Your Amazon Gift Card will be mailed shortly. Keep sharing your unique referral link,
            keep earning!</p>
    </div>
    <div class="col-1"></div>
</div>

<div class="row m-0 justify-content-center mb-3" *ngIf="this.popUpType === 'FAILED_REDEMPTION'">
    <div class="col-1"></div>
    <div class="col-9">
        <img src="/assets/images/hoopr-icon-pink.webp" alt="" class="mb-3">
        <h2 class="popup-title">Redemption Unsuccessfull !!!</h2>
        <p class="popup-text my-3">Please try again, If the issue persists contact us</p>
    </div>
    <div class="col-1"></div>
</div>

<div class="row m-0 justify-content-center mb-3" *ngIf="this.popUpType === 'PROCESSING_REDEMPTION'">
    <div class="col-1"></div>
    <div class="col-9">
        <img src="/assets/images/hoopr-icon-pink.webp" alt="" class="mb-3">
        <h2 class="popup-title">Redemption Processing !!!</h2>
        <p class="popup-text my-3">Your redemption is under processing, we will email you once done</p>
    </div>
    <div class="col-1"></div>
</div>

