import { BackendApiService } from "./hoopr.services";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PopupService {
  public popUpType = new BehaviorSubject<string | null>(null);
  public isUserLogged: boolean = false;
  public isUserSubscribed: boolean = false;
  private userData = null;
  public trackInfo = new BehaviorSubject<any | null>(null);

  constructor(private apiService: BackendApiService) {
    // this.popUpType.next(undefined);
    this.checkLoggedIn();
  }

  public showPopUp(popUpType: string, popUpContext = undefined) {
    this.checkLoggedIn();
    if (!this.isUserLogged) {
      // If user is not logged in
      switch (popUpType) {
        case "LIKED_SONG":
          this.popUpType.next("NOT_LOGGED_LIKE");
          break;
        case "LIKED_PLAYLIST":
          this.popUpType.next("NOT_LOGGED_LIKE_PLAYLIST");
          break;
        case "ADD_TRACK_TO_CART":
          this.popUpType.next("NOT_LOOGED_ADD_TRACK_TO_CART");
          break;
        case "DOWNLOAD_SONG":
          this.trackInfo.next(popUpContext);
          this.popUpType.next("NOT_LOGGED_DOWNLOAD");
          break;
        case "DOWNLOAD_FREE_SONG":
          this.trackInfo.next(popUpContext);
          this.popUpType.next("NOT_LOGGED_FREE_DOWNLOAD");
          break;
        case "ADD_TO_PLAYLIST":
          this.popUpType.next("NOT_LOGGED_ADD_TO_PLAYLIST");
          break;
        case "PLAY_TRACK":
          this.popUpType.next("NOT_LOGGED_PLAY_SONG");
          break;
        case "SUBSCRIBE_PLAN":
          this.popUpType.next("NOT_LOGGED_SUBSCRIBE_PLAN");
          break;
        case "HOOPR_ARTIST_FORM_SUCCESS":
          this.popUpType.next("HOOPR_ARTIST_FORM_SUCCESS");
          break;
        case 'REFERRAL_POPUP':
          this.trackInfo.next(popUpContext)
          this.popUpType.next("REFERRAL_POPUP");
          break;
        case 'REFERRAL_POPUP_ERROR':
          this.popUpType.next("REFERRAL_POPUP_ERROR");
          break;
        case "IFP":
          //IFP India Film Project Promotional 2 month free susbcription Offer popup
          this.popUpType.next("IFP");
          break;
        // case "FILMMAKER_FORM_SUBMITTED":
        //   this.popUpType.next("FILMMAKER_FORM_SUBMITTED");
        //   break;
        case "SHARE_SONG":
          this.trackInfo.next(popUpContext);
          this.popUpType.next("SHARE_SONG");
          break;
          case "REQUEST_QUOTE":
            this.trackInfo.next(popUpContext);
            this.popUpType.next("NOT_LOGGED_REQUEST_QUOTE");
            break;
  
      }
    } else {
      // If user is logged in but not subscribed
      switch (popUpType) {
        case 'COMPLETE_PROFILE':
          this.popUpType.next('COMPLETE_PROFILE');
          break;
        case "REQUEST_QUOTE":
          this.trackInfo.next(popUpContext);
          this.popUpType.next("REQUEST_QUOTE");
          break;
        case "THANKYOU":
          this.popUpType.next("THANKYOU");
          break;
        case "ONBOARDING_COMPLETE":
          this.popUpType.next("ONBOARDING_COMPLETE");
          break;
        case "ONBOARDING_COMPLETE_BUSINESS":
          this.popUpType.next("ONBOARDING_COMPLETE_BUSINESS");
          break;
        case "DOWNLOAD_SONG":
          this.trackInfo.next(popUpContext);
          this.popUpType.next("LOGGED_PAID_DOWNLOAD");
          break;
        case "DOWNLOAD_SONG_LIFETIME":
          this.trackInfo.next(popUpContext);
          this.popUpType.next("SUBSCRIBED_PAID_DOWNLOAD");
          break;
        case "CREATE_NEW_PLAYLIST":
          this.popUpType.next("CREATE_NEW_PLAYLIST");
          break;
        case "SUBSCRIPTION_PROCESSING":
          this.popUpType.next("SUBSCRIPTION_PROCESSING");
          break;
        case 'INDIVIDUAL_BUSINESS_CONVERT':
          this.popUpType.next('INDIVIDUAL_BUSINESS_CONVERT')
          break;
        case 'INDIVIDUAL_AGENCY_CONVERT':
          this.popUpType.next('INDIVIDUAL_AGENCY_CONVERT')
          break;
        case "DOWNLOAD_FREE_SONG":
          this.trackInfo.next(popUpContext);
          // Check if user has not exhausted the download limit
          const downloadLimitExhausted = false;
          if (downloadLimitExhausted) {
            this.popUpType.next("LOGGED_FREE_DOWNLOAD_EXHAUSTED");
          } else {
            this.popUpType.next("LOGGED_FREE_DOWNLOAD");
          }
          break;
        case "DOWNLOAD_FORMAT_POPUP":
          this.trackInfo.next(popUpContext);
          this.popUpType.next("DOWNLOAD_FORMAT_POPUP");
          break;
        case "CHECKOUT_COMPLETE":
          this.popUpType.next("CHECKOUT_COMPLETE");
          break;
        case "TRACKS_CHECKOUT_COMPLETE":
          this.popUpType.next("TRACKS_CHECKOUT_COMPLETE");
          break;
        case "HOOPR_ARTIST_FORM_SUCCESS":
          this.popUpType.next("HOOPR_ARTIST_FORM_SUCCESS");
          break;
        case "IFP_ONBOARD_COMPLETE":
          //IFP India Film Project Promotional 2 month free susbcription Offer popup
          this.popUpType.next("IFP_ONBOARD_COMPLETE");
          break;
        case "ADD_TRACK_TO_CART":
          this.trackInfo.next(popUpContext);
          this.popUpType.next("ADD_TRACK_TO_CART");
          break;
        case "CONTINUE_LISTENING":
          this.trackInfo.next(popUpContext);
          this.popUpType.next("CONTINUE_LISTENING");
          break;
        case "USER_NEVER_SUBSCRIBED":
          this.popUpType.next("USER_NEVER_SUBSCRIBED");
          break;
        case "EXPIRED_LESS_COINS":
          this.popUpType.next("EXPIRED_LESS_COINS");
          break;
        case "EXPIRED_SUFFICIENT_COINS":
          this.popUpType.next("EXPIRED_SUFFICIENT_COINS");
          break;
        case "SHARE_SONG":
          this.trackInfo.next(popUpContext);
          this.popUpType.next("SHARE_SONG");
          break;
        case "ADD_TO_PLAYLIST":
          this.trackInfo.next(popUpContext);
          this.popUpType.next("ADD_TO_PLAYLIST");
          break;
        case "NEW_PLAYLIST":
          this.popUpType.next("NEW_PLAYLIST");
          break;
        case "SELECTED_PLAYLIST":
          this.popUpType.next("SELECTED_PLAYLIST");
          break;
        case "RENAME_PLAYLIST":
          this.trackInfo.next(popUpContext);
          this.popUpType.next("RENAME_PLAYLIST");
          break;
        case "DELETE_PLAYLIST":
          this.trackInfo.next(popUpContext);
          this.popUpType.next("DELETE_PLAYLIST");
          break;
        case "PLAYLISTS":
          this.popUpType.next("PLAYLISTS");
          break;
      }
    }
  }

  private checkLoggedIn() {
    if (
      this.apiService.getToken() === "" ||
      this.apiService.getUserData() === ""
    ) {
      this.isUserLogged = false;
    } else {
      this.isUserLogged = true;
      this.userData = this.apiService.getUserData();
    }
  }
}
