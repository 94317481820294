<app-header></app-header>
<div>
  <h1 class="heading py-3 px-3 px-md-0">Browse our music by Movies</h1>
  <app-popular 
     [popular]="'Movies'"
     [popularItems]="popularMovies"
     [items]="'3.5'" 
     [mobileItems]="'1.3'"
  >
</app-popular>
  <div class="container">
    <div class="py-5" *ngIf="this.trendingMovies">
      <!--trending section-->
      <h2 class="title">Trending&nbsp;<img src="/assets/images/trending.svg"></h2>
      <app-slider 
      [sliderItems]="this.trendingMovies" 
      [popular]="'Movies'"
      [items]="'6'" 
      [mobileItems]="'1.3'"
      [labelColor]="'black'"
      [showLabel]="true"  
      ></app-slider>
    </div>
    <!--trending section end-->
    <div>
      <!--search-->
      <div class="d-flex justify-content-between align-items-center">
        <!--flex-->
        <h3 class="text-left title">All Movies</h3>
        <div class="d-flex justify-content-center align-items-center search" data-toggle="collapse" data-target="#searchInput"
          aria-expanded="false" aria-controls="searchInput" role="button">
          <i class="ph ph-magnifying-glass"></i>
          &nbsp;
          <a class="text-right search-text">Search</a>
      </div>
      <form id="searchInput" class="collapse" (ngSubmit)="searchNavigate()">
        <div class="input-group">
          <div class="input-group-prepend">
            <img src="/assets/images/search-white.svg" alt="search">
          </div>
          <input type="text" class="form-control" autofocus placeholder="Search for movies" [(ngModel)]="searchQuery"
            name="searchQuery" aria-describedby="search-icon">
        </div>
      </form>
      </div><!--flex-->
      <app-grid [assetType]="'Movies'" [items]="6" [mobileItems]="2"[data]="this.featuredCategory1" [labelColor]="'black'"></app-grid>
      <!--row end-->
    </div><!--search end-->
  </div>
</div>
