<div class="hoopr_dashborad_nav_header hoopr-navbar hoopr_navbar-collapse">
  <nav class="navbar navbar-expand-lg px-md-3 px-xl-4 pr-0 d-flex flex-nowrap">
    <a class="navbar-brand hoopr_brand_logo  mr-xl-4 d-md-inline d-none" routerLink="/">
      <img src="assets/images/hoopr-select.png" alt="hoopr-logo">
    </a>
    <div class="d-flex w-100 justify-content-between align-items-center m-0 p-0 navbar-toggler d-md-none" *ngIf="(this.isMobile || this.isTablet)">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <i class="ph ph-list"></i>
      </button>
      <a class="navbar-brand hoopr_brand_logo  mr-xl-4" routerLink="/">
        <img src="assets/images/hoopr-select.png" alt="hoopr-logo">
      </a>
      <form (ngSubmit)="this.getSearchResults();">

          <i class="ph ph-magnifying-glass my-auto d-inline-block d-md-none pr-3"></i>

          <!-- <label for="" class="col-auto px-2">
          <i class="fa fa-search search-icon" aria-hidden="true"></i>
        </label>
        <input type="text" name="" value="" id="feed_search" class="text-small  text-uppercase fw-600 overflow-hidden col px-1"
          placeholder="Search" [(ngModel)]="this.searchText" autocomplete="off"/> -->

      </form>
    </div><!-- search-box -->



    <div class="collapse navbar-collapse" id="navbarSupportedContent" style="height: 100%;">

      <button class="nav-close navbar-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent">
        <i class="ph ph-x"></i>

      </button>

      <ul class="navbar-nav mr-auto align-items-center fixed-width">
        <!-- <li class="nav-item">
          <a class=" nav-link d-flex justify-content-center align-items-center">
            <i class="ph ph-magnifying-glass"></i>
            &nbsp;
            <a class="text-right search-text">Search</a>
          </a>
        </li> -->
        <!-- <div class="search_box search-box d-flex mx-2 py-2" *ngIf="this.isDesktop && this.pageRoute !== 'search'">
          <div class="row m-0 search-input-container align-items-baseline">
            <a for="" class="col-auto pl-3">
            </a>

            <input type="text" name="" value="" id="feed_search" class="text-small text-uppercase fw-600 col px-1" style="color:#000;"
              placeholder="Search"
              (keyup.enter)="this.getSearchResults();" (keyup.shift.enter)="this.getSearchResults();" [(ngModel)]="this.searchText" autocomplete="off"/>
            </div>
          </div> -->
          <li>
            <a>
              <form (ngSubmit)="searchNavigate()">
                <input type="text"   placeholder="Search music" [(ngModel)]="searchQuery" name="searchQuery">
              </form>
            </a> 
         </li>
        <li class="nav-item">
          <a class="nav-link cursor fw-600" id="header_discover" routerLinkActive="active"
            (click)="pushAnalytics('ga4 click_interaction','click_interaction','top_navigation','header','Discover')"
            routerLink="/">Home</a>
        </li>
        <li class="nav-item d-none d-md-block">
          <a class="nav-link cursor dropdown-toggle fw-600" routerLinkActive="active" id="header_playlist_dropdown"
            role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Music
          </a>
          <div class="dropdown-menu header-dropdown align-items-center  overflow-hidden "
            aria-labelledby="header_playlist_dropdown" style="left:auto;top:auto">
            <a class="dropdown-item cursor text-center pr-5 py-2" id="header_Playlists" href="javascript:;"
              (click)="pushAnalytics('ga4 click_interaction','click_interaction','top_navigation','header','Playlists','Playlist')"
              routerLink="/playlists">Playlists</a>
            <a class="dropdown-item cursor text-center pr-5 py-2" id="header_Movies" href="javascript:;"
              (click)="pushAnalytics('ga4 click_interaction','click_interaction','top_navigation','header','Movies','Playlist')"
              routerLink="/movies">Movies</a>
            <a class="dropdown-item cursor text-center pr-5 py-2" id="header_genres" href="javascript:;"
              (click)="pushAnalytics('ga4 click_interaction','click_interaction','top_navigation','header','Genres','Playlist')"
              routerLink="/genres">Genres</a>
          </div>
        </li>
        <li class="nav-item d-md-none">
          <a class="nav-link cursor" id="header_Playlists"
            (click)="pushAnalytics('ga4 click_interaction','click_interaction','top_navigation','header','Playlists','Playlist')"
            routerLink="/playlists">Playlists</a>
          <a class="nav-link cursor" id="header_Movies"
            (click)="pushAnalytics('ga4 click_interaction','click_interaction','top_navigation','header','Movies','Playlist')"
            routerLink="/movies">Movies</a>
          <a class="nav-link cursor" id="header_genres"
            (click)="pushAnalytics('ga4 click_interaction','click_interaction','top_navigation','header','Genres','Playlist')"
            routerLink="/genres">Genres</a>
        </li>
        <!-- search-box -->
      </ul>

      <ul class="navbar-nav align-items-center">
        <li class="nav-item get-started mr-2 ml-1 m-md-0" id="header_sign_up" *ngIf="!currentpage?.token" (click)="urlRoute()">
          <a class="nav-link" href="/auth/get-started">Get Started &nbsp;
          </a>
          <i class="ph ph-arrow-right my-auto"></i>
        </li>
        <li class="nav-item" *ngIf="currentpage?.token">
          <a class="nav-link" href="/user/my-collection">My Collection &nbsp;
          </a>

        </li>
        <li class="nav-item dropdown profile_dropdown" *ngIf="currentpage?.token">
          <div class="nav-link" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <!-- <img src="assets/images/avatar-default.webp" width="40" height="40" class="square-image"
              *ngIf="!currentpage.userData.profileImage&&!newAvatorUrl">
            <img [src]="newAvatorUrl||currentpage.userData.profileImage" width="40" height="40" class="square-image"
              *ngIf="currentpage.userData.profileImage|| newAvatorUrl"> -->
              <img src="/assets/images/profile-icon.png" alt="profile" class="user-image">
          </div>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item cursor" (click)="userLogout();">
              <i class="fa fa-power-off" aria-hidden="true"></i> Log Out
            </a>
          </div>
        </li>
        <!-- TODO: activate below when cart functionality comes  -->
        <li class="nav-item " *ngIf="currentpage?.token">
          <a class="nav-link cursor position-relative" routerLinkActive="active"
            (click)="pushAnalytics('ga4 click_interaction','click_interaction','top_navigation','header','Add to cart')"
            routerLink="/user/request-license">
            <i class="ph ph-shopping-cart"></i>
            <span class="badge badge-primary add-to-cart-badge">{{this.currentpage?.userData?.totalCartItems || 0
              }}</span>
          </a>
        </li>

      </ul>
    </div>
  </nav>
</div>