import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-playlist-frame',
  templateUrl: './playlist-frame.component.html',
  styleUrls: ['./playlist-frame.component.scss'],
})

export class PlaylistFrameComponent implements OnInit {

  @Input() data: any;
  @Input() showLabel: boolean;
  @Input() labelColor:string;

  constructor() { }

  ngOnInit(): void {
  }
  
  
}
