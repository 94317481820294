<app-header></app-header>
<div class="pageBanner" [ngStyle]="{'background-image': 'url(/assets/images/romantic.png)'}"><!-- TODO: -->
	<div class="container">
		<app-breadcrumb></app-breadcrumb>
		<div class="row align-items-center justify-content-center">
			<div class="col-8 col-md-4 col-xl-3">
				<img [src]="this.feedData?.imageUrl"
				 onerror="this.src='/assets/images/default/track.webp'" 
				 alt="track cover image" class="trackImg">
			</div><!-- col -->
			<div class="col-md text-center text-md-left">
				<h1>{{this.feedData?.name || ''}}</h1>
				<p>
					<!-- TODO: playlist in future -->
					<!-- <a routerLink="/">Dhoom 3</a> by  -->
					<span *ngFor="let artist of this.primaryArtists; let isLast = last">
						<a [routerLink]="'/artist/' +  artist?.artist?.name_slug">{{artist?.artist?.name}}</a><ng-container *ngIf="!isLast">,</ng-container>
					</span>
				<br>
				<span>Song  ·  {{formatDuration(this.feedData?.duration)}}  ·  {{this.feedData?.language}}</span>
				</p>
				<div class="btns d-flex flex-column flex-md-row align-items-center gap-2">
					<button class="btn btn-yellow btn-small order-12 order-md-1" (click)="this.requestQuote()">REQUEST A QUOTE</button><!-- btn -->
					<span class="d-flex gap-2 order-1 order-md-12 mb-5 mb-md-0">
						<button class="btn btn-white btn-ic" (click)="this.likeTrack()">
							<i class="ph ph-heart" [ngClass]="this.likeStatus ?  'ph-fill red-heart' : ''"></i>
						</button><!-- btn -->
						<button class="btn btn-white btn-ic" (click)="this.shareTrack()">
							<img src="/assets/images/share-black-ic.svg"/>
						</button><!-- btn -->
						<button class="btn btn-white btn-ic" (click)="this.addToPlaylist()">
							<img src="/assets/images/add-black-ic.svg"/>
						</button><!-- btn -->
					</span>
				</div><!-- btns -->
			</div><!-- col -->
		</div><!-- row -->
	</div><!-- section -->
</div><!-- banner -->

<app-track-block *ngIf="this.feedData && this.feedData.id" [size]="'wave'" [musicInfo]="this.feedData"></app-track-block>

<section *ngIf="this.feedData?.similarTracks">
	<div class="container py-5">
		<h2 class="title">Similar Tracks</h2>
		<app-tracks-list  [tracks]="this.feedData?.similarTracks" [size]="'large'" ></app-tracks-list>
	</div><!-- section -->
</section>