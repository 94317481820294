import { Component } from '@angular/core';

interface FAQSection {
  name: string;
  open: boolean;
  questions: FAQQuestion[];
}

interface FAQQuestion {
  text: string;
  answer: string;
  open: boolean;
}

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  faqData: FAQSection[] = [
    {
      name: 'Catalogue',
      open: false,
      questions: [
        { text: 'How often do you add new songs to your catalogue?', answer: 'The catalog is updated on a real time basis with new songs frequently. We are constantly in the process of acquiring new music and onboarding new artists on to the platform.', open: false },
        { text: 'Can I license a song that is currently not present on the platform?', answer: 'No, currently we have music from limited labels. However, we are in a constant process of adding new music and artists to the platform. This will be an ongoing process. Incase, you do not find what you are looking for, please share your request so that we can get them onboarded in the future', open: false },
        { text: 'What types of catalogues does Hoopr Select offer?', answer: 'We offer a wide collection of premium bollywood music from top Indian music labels such as YRF and Saga. Our catalogue consists of various genres, popular artists and trending songs.', open: false },
        { text: 'Will there be any public-domain music on Hoopr Select for licensing?', answer: `No,currently we don't have any public-domain music on Hoopr select.`, open: false }
      ]
    },
    {
      name: 'General',
      open: false,
      questions: [
        { text: 'How is Hoopr Select different from Hoopr?', answer: 'Hoopr Select offers licensing solutions for premium music from bollywood movies and big labels in india whereas Hoopr has music which is curated by Hoopr from its vast network of 6500+ independent artists. Hoopr offers a subscription model for music licensing which gives you access to 12500+ music tracks, non-vocals, background music and sound effects.  Hoopr Select on the other hand has a vast catalogue of premium Bollywood music which allows you to license tracks individually or in packs based on your specific requirement.', open: false },
        { text: 'Where can I use music from Hoopr Select?', answer: 'Once you get the license you can use Hoopr Select music in digital ad campaigns, films, radio and other media resources.', open: false },
        { text: 'Will there be a subscription for business users on Hoopr Select?', answer: 'No, we do not charge a subscription fee for streaming and discovery on Hoopr Select. Users are required to fill their requirements in the Requirement Form in order for us to generate a custom quote for the specific requirement.', open: false },
        { text: 'Is there a limit on licensing songs/music on Hoopr Select?', answer: 'No, there is no limit in the number of songs that one can license at a time. However, the use will be limited to the terms of agreement between Hoopr and the licensee.', open: false },
        { text: 'Can I edit the music to fit my project under the same license?', answer: 'No, that violates the basic tenets of the agreement between the label/ artist, Hoopr and the licensee and is currently not an option.', open: false }
      ]
    },
    {
      name: 'License',
      open: false,
      questions: [
        { text: 'Why Do Licenses Matter?', answer: 'Artists should be compensated for their music. Licensing songs gives them the legal right to earn money when others use their work, for brands and businesses it helps to protect brand reputation and avoid legal issues.', open: false },
        { text: 'What kind of licenses or clearances does Hoopr Select provide?', answer: 'Hoopr Select is a bespoke offering where every license is custom curated for every single user. As a result, the kind of license that we can offer can be completely customized for each user. This includes but is not limited to brand videos, ad campaigns, etc. Do get in touch with us for additional information. ', open: false },
        { text: 'How can I License Music?', answer: `Steps:
1. Select the tracks that you want to license
2. Mention your use case/s
3. Add duration for which you need the license
4. Add the region for clearances
5. Mention specific requirements (if any)`, open: false },
        { text: ' How long is my license good for, once I license music from Hoopr Select?', answer: 'You can mention the duration of the license while requesting the quote. The agreement will be mutually drawn basis the requirement of the user and the use cases mentioned.', open: false },
        { text: 'Can we get a song from a specific label on request?', answer: 'Yes, you can share your request if you are looking to license a track from specific label. Once the request is shared our team will get in touch with you.', open: false },
        { text: 'Do I have to pay royalties even if I pay the licensing fee to Hoopr Select?', answer: 'No, you do not have to pay music royalties separately if you have licensed music from Hoopr Select.', open: false },
        { text: 'What is the average approval wait time?', answer: 'The average wait time is typically 2-3 business days, but it may vary depending on the responsiveness of the rights owners. We will keep you informed about the process throughout.', open: false },
        { text: 'How is Hoopr select legally licensing this Music?', answer: 'Hoopr works with labels and music owners in order legally to offer their soundtracks for discovery and licensing. Hoopr works as an authorised licensing partner for all labels and music owners listed on the platform at any given point of time.', open: false },
        { text: 'Can I Cancel or Modify a request that I have made for licensing?', answer: 'Yes, that is possible but only till the agreement is signed. Once the licensing agreement is signed, requests for cancellation or modification cannot be entertained.', open: false },
        { text: 'Will I get any legal documentation as a proof that I have licensed a particular song?', answer: 'Yes, an agreement will be created between Hoopr and the user encapsulating all the terms of usage.', open: false },
        { text: 'Can Individual creators license bollywood music on Hoopr Select?', answer: 'Yes, individual creators can also license bollywood music. While requesting a quote you can mention your use case, post that our team will get back to you.', open: false },
        { text: 'Are these licenses valid worldwide?', answer: 'Permissions associated with each license are tailored for a particular usecase. We can absolutely offer a license that is cleared for worldwide use, however, the exact terms of use will be curated as per requirement and on a case-by-case basis. That way, we can offer the best possible product to our customers. ', open: false },
        { text: 'Do I need a license to use music on a personal website?', answer: 'Yes, you will need music license to use on your personal website.', open: false },
        { text: 'Can I use the same license on more than one campaign?', answer: 'Yes, however all these use cases will need to be captured in the agreement. Uses outside the scope of the licensing agreement will not be permitted.', open: false },
        { text: 'Can I transfer my license to another person?', answer: 'No, this is not possible as the licensing agreement will be created with a specific brand/ organisation which can be the sole user of this license ONLY. ', open: false },
        { text: 'Can I see my past licenses?', answer: 'Yes, all licensing deals will be captured in legal agreements which will be shared with the brand/ organisation/ users for their records.', open: false },
        { text: 'Can I license jingles for my ad campaigns?', answer: 'All licenses can be customized basis requirement. Please get in touch with us for details on where you can use a track. But in theory, yes, you can use a track for an ad campaign. ', open: false },
        { text: 'Is there a difference in licensing costs for different types of media (e.g., TV, online, radio)?', answer: 'Yes, there is a difference in licensing costs for different types of media as the use cases are different for different media.', open: false },
        { text: 'Can I license music for non-profit projects at a different rate?', answer: 'Yes, you can request a quote and mention more details about the use case. Remember that even if your non-profit organization is not making a profit from the use of copyrighted music, you still need a license.', open: false }
      ]
    },
    {
      name: 'Support',
      open: false,
      questions: [
        { text: `I didn't use the music I paid for. Can I get a refund?`, answer: 'No, refund is not possible after purchasing the license. Please ensure that the music is being used within the licensed duration.', open: false },
        { text: 'Will I get any copyright claims or strikes if I use them in regular Youtube Videos?', answer: 'We will clear any and all claims associated with the usage of specific tracks that you have purchased a license for if the video is uploaded after the date of purchase. Please do share with us your channel details and we shall get it cleared. Do note that some songs may come with clearance for a finite time period. During this period, we will clear claims. Post this, as per the usage terms, we may not be able clear claims. Please get in touch with us for details. ', open: false },
        { text: 'What payment methods are accepted?', answer: 'All payment methods such as IMPS/ NEFT/ RTGS/ Cheque/ DD/ UPI are accepted. However, cash payments will not be accepted.', open: false },
        { text: 'Will I get a reminder when my licensing period is coming to an end?', answer: 'Yes, you will be informed about the end of licensing period.', open: false },
        { text: 'Who can I contact for technical issues?', answer: 'Please get in touch with us at xxx (Will add email later). You can also call us at zzz', open: false },
        { text: 'How does Hoopr Select handle licensing disputes?', answer: `All the tracks that you can license on Hoopr Select have been sourced through agreements with various labels in India are around the world. A part of this agreement includes ensuring claim clearance for users who have purchased a license through Hoopr select. Please share your video URL with us. Hoopr Select will ensure claim clearance on a timely basis. We will also make it a point to communicate this in greater detail when we are in discussions to issue you a license. For further details, please don't hesitate to get in touch with us. `, open: false },
        { text: 'What should I do if I receive a copyright claim despite licensing the music?', answer: 'Please get in touch with us at the earliest. You can email is at xxx or call us at zzzz. Do also share the YouTube video URL with us and we shall take care of the rest. Please do take into account that we will only clear claims associated with the specific track that you have purchased a license for. ', open: false },
      ]
    }
  ];

  toggleSection(section: FAQSection) {
    section.open = !section.open;
  }

  toggleQuestion(question: FAQQuestion) {
    question.open = !question.open;
  }
}
