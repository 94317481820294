import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {

  constructor(private titleService: Title,  
    private metaTagService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle("About Us | Hoopr - India ka Sound");  
    
    this.metaTagService.updateTag(
      { name: 'description', content: 'Learn more about Hoopr - India ka sound. Get access to over 12,000 royalty free background scores, music, and songs specifically curated for Indian content creators' },  
      
    );
  }

}
