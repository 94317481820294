import { Component, OnInit } from '@angular/core';
import { BackendApiService } from '../services/hoopr.services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-movies',
  templateUrl: './movies.component.html',
  styleUrls: ['./movies.component.scss']
})
export class MoviesComponent implements OnInit {
  
  public popularMovies: any;
  public trendingMovies: any = [];
  public featuredCategory1: any;
  public feedData: any;
  public searchQuery: string = '';

  constructor(
    private apiService: BackendApiService,
    private router : Router
  ) { }

  ngOnInit(): void {
    
    this.apiService.getMoviesListFeed().subscribe(
      (response) => {
        this.feedData = response;
        this.popularMovies = this.feedData['popular'];
        this.trendingMovies =this.feedData['trending'].data;
        this.featuredCategory1 = this.feedData['featured-1'].data;

        // populate track list for autoplay
        // this.playerService.musicInfoQueue.next(this.feedData?.find(assets => assets['asset-type'] === 'music' || 'track')[0]?.data || undefined);
      },
      (err) => {
        console.log('Error while loading feed data', err);
      }
    )
  }
  searchNavigate(){
    this.router.navigate(['/search'], { queryParams: { q: this.searchQuery, t : "movie" } });
  }
}
