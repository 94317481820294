<div class="popular"
  *ngIf="this.popular && this.popularItems && this.popularItems.data && this.popularItems.data.length > 0 
    && items && mobileItems">
  <div class="container-fluid">
    <div class="row py-md-0 py-3">
      <div class="col-md-2 p-0 text-holder">
        <h2 class="popular-text px-3 pb-md-4 px-md-0">
          Popular <br />
          {{popular}}
        </h2>
      </div>
      <!--col-md-->
      <div class="col-md-10 justify-content-center align-items-center px-3 px-md-5 py-md-5">
        <app-slider [items]="this.items" [mobileItems]="this.mobileItems" [sliderItems]="this.popularItems.data" [popular]="this.popular" [navigation]="true"
          [pagination]="true" [showLabel]="false">
        </app-slider>
      </div>
      <!--col-md-->
    </div>
    <!--row-->
  </div>
  <!--container-fluid-->
</div>
<!-- popular -->