<!-- TODO: push analytics on click -->
<a class="" [routerLink]="'/genre/' + this.data?.name_slug">
	<div class="hoopr-artist-card ">
		<div class="position-relative">
			<img [src]="this.data.imageUrl || this.data.image_url" onerror="this.src='/assets/images/default/genre.webp'"
			alt="song-artist-default" class="song-artist-image" alt="song-artist-default" class="song-artist-image">
			<div class="badge badge-bottom-playlist w-10">
				<img src="/assets/images/popularPlaylist.webp" alt="popular-badge"  class="frame-badge" *ngIf="this.data?.payload == 'popular'">
				<img src="/assets/images/trendingPlaylist.webp" alt="popular-badge"  class="frame-badge" *ngIf="this.data?.payload == 'trending'">
				<img src="/assets/images/bothPlaylist.webp" alt="popular-badge"  class="frame-badge-large" *ngIf="this.data?.payload == 'popular-trending'">
			</div>	  
		</div>
		<p class="text-left py-2 name-text" *ngIf="showLabel">{{this.data?.name || ''}}
		</p>
	</div>
</a>