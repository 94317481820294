<app-header></app-header>
<!-- <div class="banner">
  <img src="assets/images/banners/static-banner.webp" alt="">
  <div class="pageContainer p-3 px-md-4 px-xl-5">
    <div>
      <h1>Contact Sales</h1>
    </div>
  </div>
</div> -->
<!-- banner -->
<section class="salesFrom">
  <div class="pageContainer p-3 px-md-4 px-xl-5">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <h1 class="title-color title text-center">
           <span class="brand-pink-color">Contact</span> Sales Form
          <!-- <small class="text-normal mt-1">Select The Plan That’s Right For You</small> -->
        </h1>
        <h2 class="title-color sub-title">
          <!-- <span>Contact</span> Sales Form -->
          <small class="text-normal">Kindly fill in the form below. Our sales team shall get in touch with you within 48 hours. Please feel free to enter your specific requirements so we can provide the best solution for your platform. </small>
        </h2>
        <form class="" [formGroup]='contactusForm' (ngSubmit)='onSubmit()'>
          <div class="d-flex flex-wrap justify-content-between">
            <div class="hoopr_form_field_group field_width_50">
              <div class="hoopr_form_fields">
                <input  type="text" inputmode="text" class="inputText" formControlName="firstName" required/>
                <span class="floating-label">First Name*</span>
                <p><i class="glyphicon glyphicon-exclamation-sign error_info_highlight"> </i></p>
              </div>
              <p class="form_errors text-left" *ngIf="formErrors.firstName">{{formErrors.firstName}}</p>

            </div>
            <div class="hoopr_form_field_group field_width_50">
              <div class="hoopr_form_fields">
                <input  type="text" inputmode="text" class="inputText" formControlName="lastName" required/>
                <span class="floating-label">Last Name*</span>
                <p><i class="glyphicon glyphicon-exclamation-sign error_info_highlight"> </i></p>
              </div>
              <p class="form_errors text-left" *ngIf="formErrors.lastName">{{formErrors.lastName}}</p>

            </div>
            <div class="hoopr_form_field_group field_width_100">
              <div class="hoopr_form_fields">
                <input  type="text" inputmode="email" class="inputText" formControlName="email" required/>
                <span class="floating-label">Email*</span>
                <p><i class="glyphicon glyphicon-exclamation-sign error_info_highlight"> </i></p>
              </div>
              <p class="form_errors text-left" *ngIf="formErrors.email">{{formErrors.email}}</p>

            </div>
            <div class="hoopr_form_field_group field_width_100">
              <div class="hoopr_form_fields">
                <input  type="text" inputmode="text" class="inputText" formControlName="companyName" required/>
                <span class="floating-label">Company Name*</span>
                <p><i class="glyphicon glyphicon-exclamation-sign error_info_highlight"> </i></p>
              </div>
              <p class="form_errors text-left" *ngIf="formErrors.companyName">{{formErrors.companyName}}</p>
            </div>
            <div class="hoopr_form_field_group field_width_100">
              <div class="hoopr_form_fields">
                <input  type="text" inputmode="url" class="inputText" formControlName="websiteUrl" />
                <span class="floating-label">Website URL</span>
                <p><i class="glyphicon glyphicon-exclamation-sign error_info_highlight"> </i></p>
              </div>
            </div>
            <div class="hoopr_form_field_group field_width_100">
              <div class="hoopr_form_fields">
                <input  type="text"  class="inputText" formControlName="country" />
                <span class="floating-label">Country</span>
                <p><i class="glyphicon glyphicon-exclamation-sign error_info_highlight"> </i></p>
              </div>
            </div>
            <div class="hoopr_form_field_group field_width_100">
              <div class="hoopr_form_fields">
                <input  type="text" inputmode="tel" class="inputText" formControlName="mobile" required/>
                <span class="floating-label">Phone Number*</span>
                <p><i class="glyphicon glyphicon-exclamation-sign error_info_highlight"> </i></p>
              </div>
              <p class="form_errors text-left" *ngIf="formErrors.mobile">{{formErrors.mobile}}</p>

            </div>
            <div class="hoopr_form_field_group field_width_100">
              <div class="hoopr_form_fields">
                <input  type="text" class="inputText" formControlName="businessType" />
                <span class="floating-label">What's your business?</span>
                <p><i class="glyphicon glyphicon-exclamation-sign error_info_highlight"> </i></p>
              </div>
            </div>
            <div class="hoopr_form_field_group field_width_100">
              <div class="hoopr_form_fields">
                <input  type="number" class="inputText" formControlName="teamSize" />
                <span class="floating-label">How big is the team that needs access to our catalog?</span>
                <p><i class="glyphicon glyphicon-exclamation-sign error_info_highlight"> </i></p>
              </div>
            </div>

            <div class="hoopr_form_field_group field_width_100">
              <div class="hoopr_form_fields hoopr_form_fields_text_area">
                <textarea formControlName="description" name="name" rows="8" cols="80" placeholder="What challenges are you looking to overcome with Hoopr ?"></textarea>
              </div>
            </div>
          </div>
          <div class="text-center mt-5">
            <button type="submit" id="sales_contact_us_submit" class="btn btn-brand" name="button">Submit Form</button>
          </div>
        </form>
        <h2 class="title-color text-center sub-title mb-3">Want to <span>know more?</span></h2>
        <div class="text-center">
          <a href="/faqs" class="btn white-outline-btn">faqs</a>
        </div>
      </div><!-- col-6 -->
    </div><!-- row -->
  </div><!-- page container -->
</section><!-- form -->
<section class="brands">
  <div class="pageContainer p-3 px-md-4 px-xl-5">
    <h2 class="title-color sub-title">Brands <span>we have worked</span> with </h2>
    <div class="row row-cols-xl-6 row-cols-md-4 row-col-3">
      <div class="col"><img src="/assets/images/brand_logos/bharat-pay-bw.webp" alt="" class="w-100"></div><!-- col -->
      <div class="col"><img src="/assets/images/brand_logos/alt-balaji-bw.webp" alt="" class="w-100"></div><!-- col -->
      <div class="col"><img src="/assets/images/brand_logos/FLIPKART-BW.webp" alt="" class="w-100"></div><!-- col -->
      <div class="col"><img src="/assets/images/brand_logos/HIKE-BW.webp" alt="" class="w-100"></div><!-- col -->
      <div class="col"><img src="/assets/images/brand_logos/VIACOM-BW.webp" alt="" class="w-100"></div><!-- col -->
      <div class="col"><img src="/assets/images/brand_logos/ZIVAME-white.webp" alt="" class="w-100"></div><!-- col -->
      <div class="col"><img src="/assets/images/brand_logos/MARS-WRIGLEY-BW.webp" alt="" class="w-100"></div><!-- col -->
      <div class="col"><img src="/assets/images/brand_logos/KAJARIA-BW.webp" alt="" class="w-100"></div><!-- col -->
      <div class="col"><img src="/assets/images/brand_logos/vaidya-bw.webp" alt="" class="w-100"></div><!-- col -->
      <div class="col"><img src="/assets/images/brand_logos/BAKERS-DOZEN-BW.webp" alt="" class="w-100"></div><!-- col -->
      <div class="col"><img src="/assets/images/brand_logos/SAREGAMA-BW.webp" alt="" class="w-100"></div><!-- col -->
      <div class="col"><img src="/assets/images/brand_logos/APPLLAUSE-BW.webp" alt="" class="w-100"></div><!-- col -->
      <div class="col"><img src="/assets/images/brand_logos/5star-BW.webp" alt="" class="w-100"></div><!-- col -->
      <div class="col"><img src="/assets/images/brand_logos/SUNSILK-BW.webp" alt="" class="w-100"></div><!-- col -->
    </div><!-- row -->
  </div><!-- conainer -->
</section><!-- brands -->


<footer></footer>


<div class="modal hoopr_popup  " id="conformationPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" *ngIf="contactFormSubmited" style="display:block">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="contactFormSubmited = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 justify-content-center mb-3">
          <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
          <!-- <div class="w-100">
          </div> -->
      </div>
        <div class="only_contnet_popup">
          <h4 class="popup-title mb-0">Your information has been submitted successfully!</h4>
          <p class="text-normal text-mute my-4 w-100">We'll be in touch with you shortly.</p>
          <!-- <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna</p> -->
          <div class="d-flex justify-content-center">
            <button type="button" name="button" class="popup-cta my-1" (click)="contactFormSubmited = false">OK</button>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
