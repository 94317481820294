import { PopupService } from './../../services/popup.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BackendApiService } from "./../../services/hoopr.services";
import { GtmAnalyticsService } from 'src/app/services/gtm-analytics.service';
declare var window: any;

@Component({
 selector: 'app-anonymous',
 templateUrl: './anonymous.component.html',
 styleUrls: ['./anonymous.component.scss'],
})
export class AnonymousComponent implements OnInit {
 public trackInfo: any;

 public popUpType = undefined;
 constructor(
  private popupService: PopupService,
  private router: Router,
  private apiService: BackendApiService,
  private gtmAnalytics: GtmAnalyticsService
 ) {}

 ngOnInit(): void {
  this.popupService.popUpType.subscribe((popUpType) => {
   this.popUpType = popUpType;
  });

  this.popupService.trackInfo.subscribe((trackInfo) => {
   this.trackInfo = trackInfo;
  });

 }
 closePopUp() {
  this.popupService.popUpType.next(undefined);
 }
 urlRoute(action) {
  let url = this.router.url.split('?')[0];
  localStorage.setItem('redirectUrl', url.split('?')[0]);
  localStorage.setItem('action', action);
 }

 pushAnalytics() {
  this.gtmAnalytics.pushCtaAnalytics({
   event: 'ga4 click_interaction	',
   event_name: 'click_interaction',
   artist_name: '',
   instrument_type: '',
   link_name: 'pop_up',
   link_header: '',
   link_section: '',
   link_text: 'Subscribe Now',
   link_url: (window.location?.pathname.length > 125 ? window.location?.pathname.substring(0, 125) : window.location?.pathname) || null,
   login_status: JSON.parse(localStorage.getItem('userData'))?.id
    ? 'login'
    : 'guest',
   page_type: window.location?.pathname.split('/')[1] || null,
   search_link: '',
   search_term: '',
   track_name: '',
   user_id: JSON.parse(localStorage.getItem('userData'))?.id || null,
  });
 }

 redirectTo(url: string) {
    this.router.navigateByUrl(url);
    this.closePopUp();
 }
}

				
