<!-- <p>maintenance-page works!</p> -->
<div class="row m-0 page-container">
    <div class="col-md-1"></div>
    <div class="col-md-6 d-flex align-items-center justify-content-center">
      <div>
        <h1 class="title-xl-bold error-title mt-5 mb-4"><span class="color_brand_pink">Oops!</span></h1>
        <h2 class="mb-4 error-sub-title">We're down for<span class="color_brand_pink"> Maintenance</span></h2>
       
      <span class="text-mute error-text mb-4">This page is undergoing maintenance and will be back soon.</span>
     
            <!-- <button class="CTA_button mt-3" type="button" name="button" (click)="this.onClickDiscover()">go to discover</button> -->
   
      
          </div>
    </div>
    <div class="col-md-5 p-relative">
      <img class="error-img" src="/assets/images/error-404-final.webp" alt="">
    </div>
  </div>