import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BackendApiService } from '../services/hoopr.services';
import { MetaService } from '../services/meta.service';
declare var window: any;

@Component({
  templateUrl: './contact-sales.component.html',
  styleUrls: ['./contact-sales.component.scss'],
})
export class ContactSalesComponent {
  contactusForm: FormGroup;
  userformData: any;
  forgotPwdResponse: any;
  errorMessage: any;
  userdata: any;
  forgotDataMessage: any;
  contactFormSubmited: any;
  ApisuccessResponse: any;


  formErrors = {
    "firstName": '',
    "lastName": '',
    "email": '',
    "mobile": '',
    "companyName": '',
  };
  // Form Error Object
  validationMessages = {
    "firstName": {
      'pattern': 'Enter valid first name',
      'required': 'Please enter first name'
    },
    "lastName": {
      'pattern': 'Enter valid last name',
      'required': 'Please enter last name'
    },
    "email": {
      'pattern': 'Enter valid email',
      'required': 'Please enter email'
    },
    "mobile": {
      'pattern': 'Enter valid mobile number',
      'required': 'Please enter mobile number'
    },
    "companyName": {
      'pattern': 'Enter valid company name',
      'required': 'Please enter company name'
    }
  };

  initForm() {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.contactusForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: [''],
      email: ['', [Validators.required, Validators.pattern(re)]],
      mobile: ['', [Validators.required]],
      companyName: [''],
      websiteUrl: [''],
      country: [''],
      businessType: [''],
      teamSize: [null],
      userType: [''],
      description: [''],
    });

    this.contactusForm.valueChanges
      .subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // (re)set validation messages now
  }

  // Reactive form Error Detection
  onValueChanged(data?: any) {
    if (!this.contactusForm) { return; }
    const form = this.contactusForm;
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          let msg = messages[key] ? messages[key] : '';
          this.formErrors[field] += msg + ' ';
        }
      }
    }
  }

  onSubmit() {
    if (!this.contactusForm.valid) {
      if (!this.contactusForm) { return; }
      const form = this.contactusForm;
      for (const field in this.formErrors) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            this.formErrors[field] += messages[key] + ' ';
          }
        }
      }
    }
    if (this.contactusForm.valid) {
      // push data to gtm
      window.dataLayer.push({
        event: 'enterprise_subscribe',
        first_name: this.contactusForm?.value?.firstName || null,
        last_name: this.contactusForm?.value?.lastName || null,
        email_id: this.contactusForm?.value?.email || null,
        phone: this.contactusForm?.value?.mobile || null,
        company_name: this.contactusForm?.value?.companyName || null,
        web_url: this.contactusForm?.value?.websiteUrl || null,
        country: this.contactusForm?.value?.country || null,
        business_info: this.contactusForm?.value?.businessType || null,
        team_size: this.contactusForm?.value?.teamSize || null,
      });

      console.log(this.formErrors);
      this.userformData = {
        "entity": 'ENTERPRISE',
        "name": this.contactusForm.value.firstName + this.contactusForm.value.lastName,
        "email": this.contactusForm.value.email,
        "mobile": this.contactusForm.value.mobile,
        "companyName": this.contactusForm.value.companyName,
        "websiteUrl": this.contactusForm.value.websiteUrl,
        "country": this.contactusForm.value.country,
        "businessType": this.contactusForm.value.businessType,
        "teamSize": this.contactusForm.value.teamSize,
        "description": this.contactusForm.value.description
      }

      const keysToCheck = ['utm_source', 'utm_campaign', 'code', 'utm_medium', 'utm_term', 'service', 'placement', 'device', 'device_model', 'fbclid', 'gclid', 'wbraid', 'gbraid', 'li_fat_id', 'date_of_lead_creation', 'landing_page'];
      keysToCheck.forEach(key => {
        const value = localStorage.getItem(key);
        if (value) {
          this.userformData[key] = value;
        }
      });

      this.Usercontactus();
    }
  }

  constructor(
    // private router:Router,
    private formBuilder: FormBuilder,
    private apiService: BackendApiService,
    private metaService: MetaService,
  ) {
    this.contactFormSubmited = false;
  }

  Usercontactus() {
    this.apiService.consumerRequestQuote(this.userformData).subscribe(response => {
      this.ApisuccessResponse = response['data'];
      this.contactFormSubmited = true;
      this.initForm();
    }, (error) => {
      this.errorMessage = error.error.info.message;
      setTimeout(() => {
        this.errorMessage = false;
      }, 7000);
    });
  }

  ngOnInit() {
    this.metaService.setMetaTags('contactSales');
    this.initForm();
  }

}
