<div class="audio__player text-white" [hidden]="!!!musicInfo?.id">
  <div class="row w-100 m-0" [ngClass]="this.isMobile || this.isTablet ? 'flex-column': ''">
    <div class="col m-0 px-0" *ngIf="this.isMobile || this.isTablet">
      <div class="wave-small d-xl-none" id="wave-small">
        <ngx-slider (userChange)="onSliderClick($event)" [value]="this.sliderValue" [options]="this.sliderOptions"
          id="myRange"></ngx-slider>
      </div>
    </div>
    <div class="col px-0 pl-lg-3 pr-lg-0 global-player-content">
      <div class="row m-0 w-100 justify-content-between flex-nowrap">

        <div class="col-auto d-flex align-items-center px-0">
          <div class="song_image global-player-thumbnail">
            <img class="song__paly_action" [src]="this.musicInfo?.imageUrl || this.musicInfo?.image_url"
              onerror="this.src='/assets/images/default/track.webp'">
          </div>
        </div><!-- col-2 -->
        <div class="col-3  pl-1 pr-0 px-lg-2 col-lg-1 d-flex flex-column justify-content-center song-artist-name">
          <span *ngIf="!completeTrackLoaded && !loading;else playPauseBtns">Play a track</span>
          <ng-template #playPauseBtns>

            <p [routerLink]="['/track', this.musicInfo?.name_slug]" id="nameOfSong"
              class="cursor text-truncate py-1 mb-0" style="white-space: nowrap;"
              [title]="musicInfo?.name || 'Play a track'">
              {{ musicInfo?.name || 'Play a track' }}</p>
            <label for="" *ngIf="this.musicInfo && this.primaryArtists">
              <!-- TODO: fix css for a tag -->
              <span class="names" *ngFor="let artist of this.primaryArtists; let isLast = last">
                <a [routerLink]="'/artist/' +  artist?.artist?.name_slug" class="names"> {{artist.artist.name}}</a><span
                  *ngIf="!isLast">, </span>
              </span>
            </label>

          </ng-template>
        </div><!-- col-2 -->
        <div class="col-auto px-2 d-flex player-btn justify-content-center Player_buttons">
          <div *ngIf="loading" class="d-flex justify-content-center align-items-center mx-3">
            <div class="spinner-border">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div class="d-xl-flex d-none justify-content-center align-items-center player-icons"
            *ngIf="!loading && completeTrackLoaded">
            <div class="px-1 ">
              <img src="/assets/images/play-previous.svg" alt="" class="cursor global-icon" style="font-size: 30px;"
                aria-hidden="true" [title]="'<< '+SKIP_SECONDS+'s'" (click)="wave?.skipBackward(SKIP_SECONDS)">
            </div>

            <div class="mx-2 mx-md-3 d-flex" (click)="playPause()" [ngStyle]="{ cursor: 'pointer' }" title="Play/Pause">
              <img src="/assets/images/icon/play-icon.svg" class="global-icon global-play-pause" alt="" *ngIf="!playing"
                aria-hidden="true" (click)="this.pushAnalytics('play',primaryArtist?.name,musicInfo?.name)">

              <img src="/assets/images/icon/pause-icon.svg" class="global-icon global-play-pause" alt="" *ngIf="playing"
                aria-hidden="true" (click)="this.pushAnalytics('pause',primaryArtist?.name,musicInfo?.name)">
            </div>

            <div class="px-1  ">
              <img src="/assets/images/play-next.svg" alt="" class=" cursor global-icon" style="font-size: 30px;"
                aria-hidden="true" [title]="SKIP_SECONDS+'s >>'" (click)="wave?.skipForward(SKIP_SECONDS)">
            </div>

          </div>
          <!-- </div>/ -->
        </div><!-- col-2 -->

        <div class="d-lg-flex d-none col-3 col-lg align-items-center justify-content-center"
          [ngClass]="this.isMobile || this.isTablet? 'd-none':'d-flex'">
          <p class="mb-0 col-1 d-flex justify-content-center pr-0">{{currentTime}}</p>
          <div class="w-100 mx-2 d-flex flex-column" #mainWaves></div>
          <p class="mb-0 col-1 d-flex justify-content-center px-0">
            {{formatDuration(musicInfo?.duration)}}
          </p>

        </div><!-- col-3 -->
        <!-- 
        <div class="col-2 d-flex align-items-center justify-content-center stems-dropdown"
          *ngIf="!this.isMobile && !this.isTablet">
          <select class="custom_country_dropdown_menu text-small p-1 p-md-2 mb-0 hoopr_form_fields" name="trackType"
                id="trackType" (change)="this.stemSelected($event)">
                <option [value]="-1">Full Mix</option>
                <option *ngFor="let stem of this.stems; index as i" [value]="i">
                  {{stem.stemType}}
                </option>
              </select>
          <div class="selected-option custom_country_dropdown_menu d-flex justify-content-between" data-toggle="collapse" data-target="#stemsDropdown" aria-expanded="false" aria-controls="stemsDropdown" 
          (click)="showStemsOptions()" [ngClass]="this.stemsOptions? 'border-pink' : ''" *ngIf="this.musicInfo && this.musicInfo.type === 'music'">
            <p class="mb-0 text-ellipsis">{{this.selectedStem}}</p>
            <i class="fa view-all-icon brand-color" [ngClass]="this.stemsOptions? 'fa-chevron-up' : 'fa-chevron-down'"></i>
          </div>
          <div class="stems-option collapse" id="stemsDropdown">
            <div class="custom-control custom-radio text-left my-2 d-flex align-items-center">
              <input type="radio" name="stems_radio" id='fulltrack' class="custom-control-input" value="-1"
                (change)="this.stemSelected($event)" [checked]="this.selectedStem === 'Full Track' ? true : false"
                (click)="showStemsOptions()">
              <label for='fulltrack' class="custom-control-label">Full Track</label>
            </div>
            <ng-container *ngIf="this.stems && this.stems.length > 0">
              <div class="custom-control custom-radio text-left my-2" *ngFor="let stem of this.stems; index as i">
                <input type="radio" name="stems_radio" id={{stem.stemType}} class="custom-control-input" [value]="i"
                  (change)="this.stemSelected($event)" [checked]="this.selectedStem === stem.stemType ? true : false"
                  (click)="showStemsOptions()" >
                <label for={{stem.stemType}} class="custom-control-label" [ngClass]="this.selectedStem === stem.stemType ? 'radio-checked':''">{{stem.stemType}}</label>
              </div>
            </ng-container>
          </div>
        </div> -->



        <div class="col-3 pr-0 col-lg-auto d-small-none d-flex justify-content-end">

          <div class="Player_buttons mx-3 d-small-none d-flex justify-content-center align-items-center"
            *ngIf="!musicInfo?.hideActions">

            <div class="mute_unmute_actions mr-0 ">
              <img src="/assets/images/icon/volume-icon.svg" *ngIf="!wave?.getMute()" alt="" class="speaker pointer">
              <i *ngIf="wave?.getMute()" class="fa fa-volume-mute pointer" aria-hidden="true"></i>
              <input type="range" id="vol" name="vol" [value]="100" min="0" max="100" (input)="changeVolume($event )"
                (change)="changeVolume($event)" class="slider">
            </div>
            <div class="d-none d-xl-flex">
              <i class=" ph ph-share-network global-player-icon" data-toggle="tooltip" data-placement="top"
              title="Share Track" (click)="this.shareTrack()">
            </i>
            <i title="Add to Playlist" class="global-player-icon" (click)="this.addToPlaylist()" data-toggle="tooltip"
              data-placement="top">
              <svg width="21" height="30" viewBox="0 0 21 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1 8.2334C1 6.55749 2.39347 5.19888 4.11239 5.19888H12.4121C14.1311 5.19888 15.5245 6.55749 15.5245 8.2334V16.3255C15.5245 18.0014 14.1311 19.36 12.4121 19.36H4.11239C2.39347 19.36 1 18.0014 1 16.3255V8.2334Z"
                  stroke="#B2B2B2" stroke-linecap="round" stroke-linejoin="round" class="path" />
                <path d="M8.25496 12.2764V9.24185M8.25496 12.2764V15.3109M8.25496 12.2764H11.3673M8.25496 12.2764H5.14258"
                  stroke="#B2B2B2" stroke-linecap="round" stroke-linejoin="round" class="path" />
                <path d="M5.14258 1.15063H16.5547C18.2736 1.15063 19.6671 2.50924 19.6671 4.18516V15.3117" stroke="#B2B2B2"
                  stroke-linecap="round" stroke-linejoin="round" class="path" />
              </svg>
            </i>
            <i class="ph ph-heart global-player-icon" [ngClass]="this.likeStatus ?  'ph-fill red-heart' : ''" data-toggle="tooltip"
              data-placement="top"
              title="Add to Favorite" (click)="this.likeTrack()">
            </i>

            </div>
            <span class="d-xl-inline d-none icon-bg-white pointer" title="Download"
              (click)="downloadTrack('download',primaryArtist?.name,musicInfo?.name)">
              <button class="btn-white width-auto" (click)="this.requestQuote()">
                <i class="ph ph-shopping-cart"></i>
                REQUEST
              </button>
            </span>
          </div>


        </div><!-- col-3 for desktop-->

        <div class="col-auto  p-relative justify-content-end pl-4 pr-0"
          [ngClass]="!this.isMobile && !this.isTablet? 'd-none':'d-flex'">
          <div class="Player_buttons d-flex justify-content-center align-items-center" *ngIf="!musicInfo?.hideActions">


            <div class="dropdown d-flex align-items-center d-xl-none">
              <div class="mx-2 mx-md-3 d-flex" (click)="playPause()" [ngStyle]="{ cursor: 'pointer' }"
                title="Play/Pause">
                <img src="/assets/images/icon/play-icon.svg" class="global-icon global-play-pause" alt=""
                  *ngIf="!playing" aria-hidden="true"
                  (click)="this.pushAnalytics('play',primaryArtist?.name,musicInfo?.name)">

                <img src="/assets/images/icon/pause-icon.svg" class="global-icon global-play-pause" alt=""
                  *ngIf="playing" aria-hidden="true"
                  (click)="this.pushAnalytics('pause',primaryArtist?.name,musicInfo?.name)">
              </div>
              <button class="btn btn-act dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i
                  class="ph ph-dots-three-vertical"></i></button>
              <div class="dropdown-menu dropdown-menu-right px-2">
                <div class="dropdown-item">
                  <i class="ph ph-share-network mr-2"></i>Share Track
                </div>
                <div class="dropdown-item">
                  <i class="ph ph-plus-square mr-2"></i>Add to Playlist
                </div>
                <div class="dropdown-item">
                  <i class="ph ph-heart mr-2"></i>Like this song
                </div>
                <button class="btn btn-white w-100 mt-3" (click)="this.requestQuote()">
                  <i class="ph ph-shopping-cart"></i>REQUEST
                </button>
              </div>
            </div><!-- dropdown -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-auto" *ngIf="this.isDesktopDevice">
      <!-- <i class="fa fa-times pointer" aria-hidden="true" (click)="onClose()"></i> -->
      <img src="/assets/images/icon/close-button.svg" class="pointer close-btn" (click)="onClose()" alt="">
    </div>
  </div><!-- parent-row -->
</div><!--  -->

<!-- modal -->

<div class="collapse more-option kdhoopr" id="more-options">
  <div class="dropdown-menu dropdown-menu-right px-2">
    <div class="dropdown-item">
      <i class="ph ph-share-network mr-2"></i>Share Track
    </div>
    <div class="dropdown-item">
      <i class="ph ph-plus-square mr-2"></i>Add to Playlist
    </div>
    <div class="dropdown-item">
      <i class="ph ph-heart mr-2"></i>Like this song
    </div>
    <button class="btn btn-white w-100 mt-3" (click)="this.requestQuote()">
      <i class="ph ph-shopping-cart"></i>REQUEST
    </button>
  </div>

</div>



<div class="modal fade hoopr_popup" id="shareModal" tabindex="-1" role="dialog" aria-labelledby="shareModalTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <img src="/assets/images/hoopr-icon-pink.webp" class="popup-hoopr-icon" alt="">
      <p class="modal-title text-center popup-title" id="exampleModalLongTitle">Share Track</p>
      <div class="modal-body py-4 row m-0 justify-content-center">
        <div class="col-12 d-flex justify-content-center mb-3">
          <div class="share-song-card row m-0 justify-content-center col-auto">
            <div class="col-auto d-flex align-items-center px-0 justify-content-center">
              <img class="song__paly_action song-thumbnail"
                [src]="this.musicInfo?.imageUrl || this.musicInfo?.image_url"
                onerror="this.src='/assets/images/default/track.webp'">
            </div>
            <div class="col d-flex justify-content-center flex-column">
              <p class="text-normal align-left mb-0">{{ musicInfo?.name || 'Play a track' }}</p>
              <p class="text-mute text-small mb-0" *ngIf="this.musicInfo && this.musicInfo.artists">
                {{this.primaryArtist?.name||this.primaryArtist?.artist?.name}}
                <!-- <span class="cursor"
                                (click)="goToArtistPage(musicInfo?.artists[0]?.id||musicInfo?.artists[0]?.artist?.id)">
                            </span> -->
              </p>
            </div>
          </div><!-- share-song-card -->
        </div>
        <div class="col-12 d-flex justify-content-center global-share-container">
          <share-buttons class="text-center mx-2 share-buttons mb-4" [theme]="'material-dark'"
            [include]="['facebook','twitter','linkedin','whatsapp','email','copy','sms']" [show]="9"
            [url]="this.trackurl" (click)="this.copyEmbedCode()"
            [image]="this.musicInfo?.imageUrl || this.musicInfo?.image_url" [autoSetMeta]="false">
          </share-buttons>
        </div>
      </div>
      <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
    </div>
  </div>
</div>