import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class PlayerService {
  musicInfo = new BehaviorSubject<any>(null);
  musicInfoQueue = new BehaviorSubject<any>(null);
  miniPlayerMusicInfo = new BehaviorSubject<any>({});
  private environment = environment;
  progress = new BehaviorSubject<any>(false);
  pause = new BehaviorSubject<any>(null);
  globalPlaySeek = new BehaviorSubject({ value: 0, id: 0 });
  continousStream = new BehaviorSubject<any>(null);
  public currentMusic: any;
  public musicQueue: any;
  public likeUpdate = new BehaviorSubject<any>(null);

  downloadTrack = new Subject();
  inMemoryCache = {};

  constructor(private httpClient: HttpClient) {
    this.musicInfoQueue.subscribe(queue => {
      if (queue && queue.length > 0) {
        // dont allow sfx tracks to autoplay
        const musicTracks = queue.filter(track => track.type === 'music');
        this.musicQueue = musicTracks;
      }
    })
  }

  getTrack(cdn: any) {
    if (!cdn) return of();
    if (this.inMemoryCache[cdn]) {
      return of(this.inMemoryCache[cdn]);
    } else {
      return this.httpClient.get(cdn, { observe: 'body', responseType: 'blob' as 'json' }).pipe(tap((data: any) => {
        this.inMemoryCache[cdn] = data;
      }))
    }
  }

  _getTrack(track: any) {

    if (!track) return of();

    if (track.asset_type) {
      const trackKeyArray = track['id'].raw ? track['id'].raw?.split("|") : track['id']?.split("|")
      track['id'] = trackKeyArray[trackKeyArray.length - 1]
    }

    let cdn = `${this.environment.BASE_CDN_URL}/${track['type']}s/${track['id']}/${track['id']}-mp3.mp3`
    return of(cdn)
    if (this.inMemoryCache[cdn]) {

      return of(this.inMemoryCache[cdn]);
    } else {

      return this.httpClient.get(cdn, { observe: 'body', responseType: 'blob' as 'json' }).pipe(tap((data: any) => {
        this.inMemoryCache[cdn] = data;
      }))
    }
  }
  getWaveForm(track: any) {
    if (!track) return of();
    if (track.asset_type) {
      const trackKeyArray = track['id'].raw ? track['id'].raw?.split("|") : track['id']?.split("|")
      track['id'] = trackKeyArray[trackKeyArray.length - 1]
    }
    let cdn = `${this.environment.BASE_CDN_URL}/${track['type']}s/${track['id']}/${track['id']}-waveform.json`;

    if (this.inMemoryCache[cdn]) {
      return of(this.inMemoryCache[cdn]);
    } else {
      return this.httpClient.get(cdn).pipe(tap((data: any) => {
        this.inMemoryCache[cdn] = data;
      }))
    }
  }

  getStem(trackId, stemId) {
    let cdn = `${this.environment.BASE_CDN_URL}/musics/${trackId}/stems/${stemId}-mp3.mp3`;
    return cdn
  }

  // getStemWaveform(trackId, stemId) {
  //   return new Promise((resolve, reject) => {
  //     let cdn = `${this.environment.BASE_CDN_URL}/musics/${trackId}/stems/${stemId}-waveform.json`;
  //     this.httpClient.get(cdn).subscribe((data) => {
  //       resolve(data);
  //     })
  //   })
  // }

  autoPlay(trackId): void {
    let currentTrackIndex;
    if (trackId && this.musicQueue.length > 0) {
      currentTrackIndex = this.musicQueue.findIndex(track => track.id === trackId);
    }
    if (currentTrackIndex >= 0) {
      // pass next music info while checking if there is any track after the current playing track
      this.musicInfo.next(currentTrackIndex < this.musicQueue?.length - 1 ? this.musicQueue[currentTrackIndex + 1] : undefined);
    }
  }

}
