import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser'; 
import { GtmAnalyticsService } from '../services/gtm-analytics.service';
declare var window: any;

@Component({
 selector: 'app-copyright',
 templateUrl: './copyright.component.html',
 styleUrls: ['./copyright.component.scss'],
})
export class CopyrightComponent implements OnInit {
 constructor(
  private titleService: Title,
  private metaTagService: Meta,
  private gtmAnalytics: GtmAnalyticsService
 ) {}

 ngOnInit(): void {
  this.setMetaTags();
 }

pushAnalytics() {
  this.gtmAnalytics.pushCtaAnalytics({
   event:'ga4 form_interaction',
   event_name:'form_interaction',
   artist_name: '',
   instrument_type: '',
   link_name: 'form',
   link_header: '',
   link_section:'claim_clearance',
   link_text:'Submit form',
   link_url: (window.location?.pathname.length > 125 ? window.location?.pathname.substring(0, 125) : window.location?.pathname) || null,
   login_status: JSON.parse(localStorage.getItem('userData'))?.id
    ? 'login'
    : 'guest',
   page_type: window.location?.pathname.split('/')[1] || null,
   search_link: '',
   search_term: '',
   track_name: '',
   user_id: JSON.parse(localStorage.getItem('userData'))?.id || null,
  });
 }

 setMetaTags(): void {
  this.titleService.setTitle(
    'Copyright Claim Clearance Support | Hoopr',
  );
  this.metaTagService.updateTag({
   name: 'description',
   content:
   'Get copyright claim clearance in 24 hours! If you are a hoopr subscriber & still worried about claims then fill claim submission form or contact us.',
  });
  this.metaTagService.updateTag({
    property: 'og:title',
    content:
    'Copyright Claim Clearance Support | Hoopr',
  });
  this.metaTagService.updateTag({
    property: 'og:description',
    content:
    'Get copyright claim clearance in 24 hours! If you are a hoopr subscriber & still worried about claims then fill claim submission form or contact us.',
  });
 }
}

					
