// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'hoopr-dev',
    appId: '1:972579908208:web:ad1b97a83f8b6acda162b5',
    storageBucket: 'hoopr-dev.appspot.com',
    locationId: 'asia-south1',
    apiKey: 'AIzaSyCalHKa9ZEH-2XWbBvldFDYftNuNGyki7w',
    authDomain: 'hoopr-dev.firebaseapp.com',
    messagingSenderId: '972579908208',
  },
  BASE_PATH: 'https://api.staging.select.hoopr.ai',
  //BASE_PATH: 'http://localhost:3000',
  production: false,
   //baseUrlBackend: "http://localhost:3000",
  baseUrlBackend: 'https://api.staging.select.hoopr.ai', // connect with dev server backend
   key: 'rzp_test_xl5uA8LKejOLe8',
  baseUrlFrontend: "http://localhost:4200",
  BASE_CDN_URL: "https://cdn.staging.select.hoopr.ai",
  cognitoUserPoolId: "ap-south-1_s6JINVRla",
  cognitoAppClientId: '74dulorqfh144d0u47sdq8omsr',
  cognitoDomain: 'https://hoopr.auth.ap-south-1.amazoncognito.com',
  imageKitBaseUrl: "https://ik.imagekit.io/hooprai/hooprdev",
  chargebeeSite: "hoopr-test",
  IMAGE_CDN_URL: 'https://d61a2ihidj3bb.cloudfront.net',
  IPAPI_API_KEY: 'a11cb23980e99138aacb9e94a38bbd4e'
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
