import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { countries } from '../../country-data-store';
import { map } from 'rxjs/operators';
declare var window: any;
const pincodeApiBasePath = 'https://api.worldpostallocations.com/pincode';

@Injectable()
export class BackendApiService {
  private BaseUrl = environment.baseUrlBackend;
  public filterRemove$ = new BehaviorSubject<any>(undefined);
  public avatorImg = new BehaviorSubject<string>(undefined);
  public $sharePopup = new BehaviorSubject<any>(undefined);
  public cartItemCount = new BehaviorSubject<number>(localStorage.getItem('userData.cartItems') ? JSON.parse(localStorage.getItem('userData.cartItems')).length : 0);

  constructor(private http: HttpClient) { }

  verifyTrackPurchase(track) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .post<Response>(`${this.BaseUrl}/lifetimeLicense/verify/`, track, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  getLicenses(type: any) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/consumer/license?type=${type}`, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }
  incCartCount() {
    this.cartItemCount.next(this.cartItemCount.value + 1);
  }

  decCartCount() {
    this.cartItemCount.next(this.cartItemCount.value - 1);
  }

  getIp() {
    return this.http.get<Response>(`https://api.ipify.org/?format=json`).pipe(
      map((res: Response) => {
        return this.extractData(res);
      })
    );
  }

  ipApi(ip: string) {
    return this.http
      .get<Response>(
        `https://api.ipapi.com/api/${ip}?access_key=${environment.IPAPI_API_KEY}`
      )
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  logEvent(event: any) {
    const headers = {
      "Content-Type": "application/json",
    };
    return this.http
      .post<Response>(
        `${this.BaseUrl}/log/event?event_Type=${event.event_type || 'null'}?track_name=${event.event_type === 'song_downloaded' ? event.track_name : ''}`,
        event
        ,{ headers: headers }
      )
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  getConsumerById(consumerId: string) {
    return this.http
      .get<Response>(`${this.BaseUrl}/getConsumerProfile/${consumerId}`)
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  askPromoterScore() {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/consumer/askPromoterScore`, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  setPromoterScore(data: any) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .post<Response>(`${this.BaseUrl}/consumer/setPromoterScore`, data, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  setToken(token) {
    if (token == '') {
      for (let key of Object.keys(localStorage)) {
        if (key.startsWith('CognitoIdentityServiceProvider')) {
          localStorage.removeItem(key);
        }
      }
    }
    localStorage.setItem('authorizationToken', token);
  }

  getToken() {
    const token = localStorage.getItem('authorizationToken');
    return token ? token : '';
  }

  setUserData(userData) {
    localStorage.setItem('userData', JSON.stringify(userData));
  }

  getUserData() {
    const userData = localStorage.getItem('userData');
    if (userData == 'undefined' || !userData) {
      return '';
    } else {
      return JSON.parse(userData);
    }
  }

  private extractData(res: Response) {
    return res || {};
  }

  logOut() {
    this.setToken('');
    this.setUserData({});
    window.location.pathname = '/auth/get-started';
  }

  isUserOnboarded() {
    const userData = this.getUserData();
    return  !!userData?.isOnboarded || false
  }

  setUserDetails() {
    const userData = this.getUserData();
    if (!userData || userData == null) {
      return of(undefined);
    }
    const profileData = {
      countryCode: userData.countryCode, 
      mobile: userData.mobile,
    };
    return this.http
      .post<Response>(`${this.BaseUrl}/auth/consumer/getUserDetails`, profileData)
      .pipe(
        map((res: Response) => {
          this.setUserData(res['data']['userData']);
          return this.extractData(res);
        })
      );
  }

  refreshUserToken() {
    let accesstoken = this.getToken();

    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .post<Response>(`${this.BaseUrl}/auth/consumer/refresh-user-data`, '', {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  consumerRegister(body: Object) {
    return this.http
      .post<Response>(`${this.BaseUrl}/auth/consumer/register`, body)
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  consumerResendOtp(body: Object) {
    let accesstoken = this.getToken();

    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .post<Response>(`${this.BaseUrl}/auth/consumer/resend-otp`, body, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  consumerVerifyEmail(body: Object) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .post<Response>(`${this.BaseUrl}/auth/consumer/resend-email-otp`, body, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  validateEmailOtp(body: Object) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .post<Response>(`${this.BaseUrl}/auth/consumer/validate-email-otp`, body, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  consumerVerifyOtp(body: Object) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .post<Response>(`${this.BaseUrl}/auth/consumer/activate`, body, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  addSocialMediaLinks(body: Object) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .post<Response>(`${this.BaseUrl}/consumer/social-media`, body, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  consumerSetPassword(body: Object, JwtToken) {
    //let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + JwtToken,
    };
    return this.http
      .post<Response>(`${this.BaseUrl}/auth/consumer/set-password`, body, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  consumerChangePassword(body: Object) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .post<Response>(`${this.BaseUrl}/auth/consumer/reset-password`, body, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  apiCreateProfile(body: Object) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .post<Response>(`${this.BaseUrl}/consumer/createProfile`, body, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  apiGetProfile() {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/consumer/`, { headers: headers })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }
  apiUpdateProfile(reqObj) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .patch<Response>(`${this.BaseUrl}/consumer/updateProfile`, reqObj, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  uploadUserImageApi(fileToUpload, userId) {
    let formdata = new FormData();
    formdata.append('userId', userId);
    formdata.append('file', fileToUpload);
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .post<Response>(`${this.BaseUrl}/file`, formdata, { headers: headers })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  apiGetDownloadTracks() {
    let accesstoken = this.getToken();
    var headers = {
      // 'Authorization': EvtAuthStr,
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/consumer/owned-tracks?pageLimit=50`, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  apiAddtoCart(reqObj) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .post<Response>(`${this.BaseUrl}/consumer/cart`, reqObj, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  consumerContactUs(body: Object) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .post<Response>(`${this.BaseUrl}/contact-us`, body, { headers: headers })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  consumerRequestQuote(body: Object) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .post<Response>(`${this.BaseUrl}/request-quote`, body, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  consumerlikeTrack(body: Object) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .post<Response>(`${this.BaseUrl}/consumer/like-track`, body, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  apiCreatePlaysits(reqObj: any) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .post<Response>(`${this.BaseUrl}/consumer/playlist`, reqObj, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  apiRenamePlaylist(reqObj: any) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .put<Response>(`${this.BaseUrl}/consumer/playlist`, reqObj, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  deleteConsumerPlaylist(playlistId: any) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .delete<Response>(`${this.BaseUrl}/consumer/playlist/${playlistId}`, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  consumerlikePlaylist(body: Object) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .post<Response>(`${this.BaseUrl}/consumer/like-playlist`, body, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  updateLikedTracks({ id, status }): void {
    const userData = JSON.parse(localStorage.getItem('userData')) || {};
    if (!userData.likedTracks) {
      userData.likedTracks = [];
    }
    if (status) {
      if (!userData.likedTracks.includes(id)) {
        userData.likedTracks.push(id);
      }
    } else {
      userData.likedTracks = userData.likedTracks.filter((trackId: string) => trackId !== id);
    }
    localStorage.setItem('userData', JSON.stringify(userData));
  }

  apiGetUserPlaylists() {
    console.log('---called')
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/consumer/playlist`, { headers: headers })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  addTrackToPlaylist(playlsitid, reqObj) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .post<Response>(
        `${this.BaseUrl}/consumer/playlist/` + playlsitid + `/addTrack`,
        reqObj,
        { headers: headers }
      )
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  getDiscoverFeed() {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/feed/discover`, { headers: headers })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  getGenreListFeed() {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/feed/genres`, { headers: headers })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  getMoodListFeed() {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/feed/moods`, { headers: headers })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  getLanguageListFeed() {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/feed/languages`, { headers: headers })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  getTrendingFeed() {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/feed/trending`, { headers: headers })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  getPopularFeed() {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/feed/popular`, { headers: headers })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  getMoviesListFeed() {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/feed/movies`, { headers: headers })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  getArtistListFeed() {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/feed/artists`, { headers: headers })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  getGenreFeed(genre: string , limit: number, offset: number) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/feed/genres/${genre.toLowerCase()}?limit=${limit}&offset=${offset}`, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  getPlaylistTrackFeed(playlistNameSlug: string, limit: number, offset: number) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/feed/playlist/${playlistNameSlug.toLowerCase()}?limit=${limit}&offset=${offset}`, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  getLanguageFeed(language: string, limit: number, offset: number) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/feed/languages/${language.toLowerCase()}?limit=${limit}&offset=${offset}`, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  getMoodFeed(mood: string, limit: number, offset: number) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/feed/moods/${mood.toLowerCase()}?limit=${limit}&offset=${offset}`, { headers: headers })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  getPlaylistListFeed() {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/feed/playlistslist`, { headers: headers })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  getSingleTrack(id: string) {
    console.log(id)
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/feed/track/${id}`, { headers: headers })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  getSingleArtist(id: string) {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/feed/artist/${id}`, { headers: headers })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  getLikedSongs() {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/consumer/likedTrackList`, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  apiGetPlaylist() {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/consumer/getPlaylist`, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  validateToken(): any {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/auth/consumer/validateToken`, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  getMonthlyRefferals(): any {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .get<Response>(`${this.BaseUrl}/consumer/referral-monthly-data`, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }

  redeemReward(body: Object): any {
    let accesstoken = this.getToken();
    var headers = {
      Authorization: 'Bearer ' + accesstoken,
    };
    return this.http
      .post<Response>(`${this.BaseUrl}/consumer/redeem-reward`, body, {
        headers: headers,
      })
      .pipe(
        map((res: Response) => {
          return this.extractData(res);
        })
      );
  }
 
}
