<div class="contactus_page landing__other_sections bg-page">
    <app-header></app-header>
    <div class="contactus_page_container">
        <!-- <div class="p-relative">
            <img src="assets/images/banners/static-banner.webp" alt="" class="fixed-banner">
            <div class="banner-text">
                <h1>Copyright Claim Submission</h1>
                <p>Everything else you want to know</p>
            </div>
        </div> -->

        <div class="section-container">
            <div class="container policy_contents_layout mt-5 text-content copyright">
                <h1 class="title text-center">Claim Submission Form</h1>
            </div>
            
            <div class="container pb-4">
                <div class="contactus__page_fields form-section mt-5">
                    <div class="row m-0 justify-content-center">
                        <div class="col-lg-10">
                            <!-- <p class="sub-title text-center"> Claim Submission <span class="brand-color">Form</span></p> -->
                            <p class="text-small text-center text-mute">
                                Feel confident, as a Hoopr subscriber, we're here to support you in addressing the copyright claim. Additionally, consider disputing the claim on your video using the track license from your downloads section as a proactive measure to prevent any revenue losses.
                            </p>
                        </div>
                    </div>
                    <form ngNoForm
                        action="https://forms.zohopublic.in/hello90/form/ClaimSubmissionForm/formperma/K_xTMI_csErEERY1CEq_cQgdrFq86IrUtKnGygyD8xk/htmlRecords/submit"
                        name='form' id='form' method='POST' accept-charset='UTF-8' enctype='multipart/form-data' target="_blank">
                        <div class="d-flex flex-wrap justify-content-between">
                            <input type="hidden" name="zf_referrer_name" value="">
                            <!-- To Track referrals , place the referrer name within the " " in the above hidden input field -->
                            <input type="hidden" name="zf_redirect_url" value="">
                            <!-- To redirect to a specific page after record submission , place the respective url within the " " in the above hidden input field -->
                            <input type="hidden" name="zc_gad" value="">
                            <!-- If GCLID is enabled in Zoho CRM Integration, click details of AdWords Ads will be pushed to Zoho CRM -->
    
                            <!-- <div class="hoopr_form_field_group field_width_50">
                                <div class="hoopr_form_fields">
                                    <input type="text" inputmode="text" class="inputText" maxlength="255" name="Name_First"
                                        fieldType=7 placeholder="" required />
                                    <span class="floating-label">First Name*</span>
                                    <p><i class="glyphicon glyphicon-exclamation-sign error_info_highlight"> </i></p>
                                </div>    
                            </div> -->
                            <!-- <p class="form_errors text-left" *ngIf="formErrors.firstName">{{formErrors.firstName}}</p> -->
                            <!-- <div class="hoopr_form_field_group field_width_50">
                                <div class="hoopr_form_fields">
                                    <input type="text" inputmode="text" class="inputText" maxlength="255" name="Name_Last"
                                        fieldType=7 placeholder="" required />
                                    <span class="floating-label">Last Name</span>
                                    <p><i class="glyphicon glyphicon-exclamation-sign error_info_highlight"> </i></p>
                                </div>
                            </div> -->
                            <!-- <span class="tooltiptext">tooltip error</span> *ngIf="em.value == ''" -->
                            <!-- <p class="form_errors text-left" *ngIf="formErrors.lastName">{{formErrors.lastName}}</p> -->
                            <div class="hoopr_form_field_group field_width_100">
                                <div class="hoopr_form_fields">
                                    <input type="email" inputmode="email" class="inputText" name="Email" value="" fieldType=9
                                        placeholder="" required />
                                    <span class="floating-label">Email*</span>
                                    <p><i class="glyphicon glyphicon-exclamation-sign error_info_highlight"> </i></p>
                                    <!-- <span class="tooltiptext">tooltip error</span> *ngIf="em.value == ''" -->
                                </div>
                                <!-- <p class="form_errors text-left" *ngIf="formErrors.email">{{formErrors.email}}</p> -->    
                            </div>
                            <div class="hoopr_form_field_group field_width_100">
                                <div class="hoopr_form_fields">
                                    <input type="text" inputmode="tel" class="inputText" compname="PhoneNumber"
                                        name="PhoneNumber_countrycode" phoneFormat="1" isCountryCodeEnabled=false
                                        maxlength="20" value="" fieldType=11 id="international_PhoneNumber_countrycode"
                                        placeholder="" required />
                                    <span class="floating-label">Phone Number*</span>
                                    <p><i class="glyphicon glyphicon-exclamation-sign error_info_highlight"> </i></p>
                                    <!-- <span class="tooltiptext">tooltip error</span> *ngIf="em.value == ''" -->
                                </div>
                                <!-- <p class="form_errors text-left" *ngIf="formErrors.mobile">{{formErrors.mobile}}</p> -->
                            </div>
                            <div class="hoopr_form_field_group field_width_100">
                                <div class="hoopr_form_fields">
                                    <input type="url" inputmode="text" class="inputText" maxlength="2083" name="Website"
                                        value="" placeholder="" required />
                                    <span class="floating-label">Video URL*</span>
                                    <p><i class="glyphicon glyphicon-exclamation-sign error_info_highlight"> </i></p>
                                    <!-- <span class="tooltiptext">tooltip error</span> *ngIf="em.value == ''" -->
                                </div>
                            </div>
                            <!-- <div class="hoopr_form_field_group field_width_100">
                                <div class="hoopr_form_fields">
                                    <input type="text" inputmode="text" class="inputText" maxlength="2083" name="Website1"
                                        value="" placeholder="" required />
                                    <span class="floating-label">Channel URL</span>
                                    <p><i class="glyphicon glyphicon-exclamation-sign error_info_highlight"> </i></p>
                                </div>
                            </div> -->
                            <!-- <span class="tooltiptext">tooltip error</span> *ngIf="em.value == ''" -->
                            <!-- <p class="form_errors text-left" *ngIf="formErrors.lastName">{{formErrors.lastName}}</p> -->
    
                            <!-- <div class="hoopr_form_field_group field_width_100">
                                <div class="hoopr_form_fields hoopr_form_fields_text_area">
                                    <textarea name="MultiLine" maxlength="65535" rows="8" cols="80"
                                        placeholder="Message"></textarea>
                                </div>
                            </div> -->
                        </div>
                        <div class="mt-4 d-flex justify-content-center">
                            <button type="submit" class="CTA_button" (click)="this.pushAnalytics()">Submit Form</button>
                        </div>
                    </form>
                </div>
            </div>
        </div><!-- section container -->


        <section class="steps-to-claim container px-0 mb-4">
            <p class="sub-title mb-3 px-3 text-center"> You can <span class="brand-color">Refute The Claim Directly</span> On
                Youtube</p>

            <div class="row m-0">
                <div class="col-md-6 d-flex align-items-center">
                    <ul class="nav nav-pills d-flex flex-column" id="pills-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="pills-step1-tab" data-toggle="pill" href="#copyright-step-1"
                                role="tab" aria-controls="pills-step1" aria-selected="true">
                                <div class="row m-0">
                                    <div class="col-auto d-flex align-items-center px-0">
                                        <h2 class="text-active text-number">1.</h2>
                                    </div>
                                    <div class="col">
                                        <p class="text-active">On the ‘Claims’ page, go to “Actions” and select
                                            “Dispute” from the drop-down
                                            menu</p>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pills-step2-tab" data-toggle="pill" href="#copyright-step-2"
                                role="tab" aria-controls="pills-step2" aria-selected="false">
                                <div class="row m-0">
                                    <div class="col-auto d-flex align-items-center px-0">
                                        <h2 class="text-active text-number">2.</h2>
                                    </div>
                                    <div class="col">
                                        <p class="text-active">Select the “License” option. By doing so you tell YouTube
                                            that you have a valid license to use the music in your video
                                        </p>
                                    </div>
                                </div><!-- row -->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pills-step3-tab" data-toggle="pill" href="#copyright-step-3"
                                role="tab" aria-controls="pills-step3" aria-selected="false">
                                <div class="row m-0">
                                    <div class="col-auto d-flex align-items-center px-0">
                                        <h2 class="text-active text-number">3.</h2>
                                    </div>
                                    <div class="col">
                                        <p class="text-active">
                                            Select the Checkbox and acknowledge that you have the permission to use the
                                            content from the Copyright Owner.
                                        </p>
                                    </div>
                                </div><!-- row -->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pills-step4-tab" data-toggle="pill" href="#copyright-step-4"
                                role="tab" aria-controls="pills-step4" aria-selected="false">
                                <div class="row m-0">
                                    <div class="col-auto d-flex align-items-center px-0">
                                        <h2 class="text-active text-number">4.</h2>
                                    </div>
                                    <div class="col">
                                        <p class="text-active">
                                            Copy and paste your license certificate here. (You can download the license
                                            certificate from Hoopr by going to My Account -> History -> Download License
                                            for the track in question.
                                        </p>
                                    </div>
                                </div><!-- row -->
                            </a>
                        </li>
                    </ul>
                </div><!-- col-md-6 -->
                <div class="col-md-6 d-flex align-items-center">
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="copyright-step-1" role="tabpanel"
                            aria-labelledby="pills-step1-tab">
                            <img src="/assets/images/copyright-step-1.webp" alt="" class="w-100">
                        </div>
                        <div class="tab-pane fade" id="copyright-step-2" role="tabpanel"
                            aria-labelledby="pills-step2-tab">
                            <img src="/assets/images/copyright-step-2.webp" alt="" class="w-100">
                        </div>
                        <div class="tab-pane fade" id="copyright-step-3" role="tabpanel"
                            aria-labelledby="pills-step3-tab">
                            <img src="/assets/images/copyright-step-3.webp" alt="" class="w-100">
                        </div>
                        <div class="tab-pane fade" id="copyright-step-4" role="tabpanel"
                            aria-labelledby="pills-step4-tab">
                            <img src="/assets/images/copyright-step-4.webp" alt="" class="w-100">
                        </div>
                    </div>
                </div>
            </div><!-- row -->

        </section>

        <section class="contact mt-5">
            <div class="pageContainer p-3 px-md-4 px-xl-5">
                <div class="row justify-content-center">
                    <div class="col-md-6 col-lg-5">
                        <h3 class="text-normal">We’re happy to answer questions and get you acquainted with Hoopr, 
                            Still worried about claims?</h3>
                        <div class="divider"></div>
                        <a routerLink="/contactus" id="copyright_contact_us" class="btn btn-brand">Contact us</a>
                    </div><!-- col-6 -->
                </div><!-- row -->
            </div><!-- page container -->
        </section><!-- contact -->

    </div>
</div>
<footer></footer>