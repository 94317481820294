import { FiltersSchema } from './../../swagger/model/filtersSchema';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchHelperService } from './../services/search-helper.service';
import { Component, OnInit, OnDestroy, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import SwiperCore, { Pagination, Navigation, FreeMode, Autoplay, SwiperOptions } from "swiper";
import { DeviceDetectorService } from 'ngx-device-detector';
import {Location} from '@angular/common';

SwiperCore.use([Pagination, Navigation, Autoplay, FreeMode]);

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit, OnDestroy {

  public isMobile :boolean;
  public isTablet :boolean;
  public isDesktopDevice :boolean;

  public searchText = "";
  public searchPageSection: string = "all";
  public searchResults = undefined;
  public prefillSearchText = "";
  public preDefinedFilters: FiltersSchema = {
    vocals: undefined,
    asset_type: undefined,
    playlist_type: undefined,
    genres: [],
    moods: [],
    usecases: [],
    languages: [],
    artists: [],
    playlists: [],
    subgenres: [],
    sfxcategories: [],
    sfxsubcategories: [],
    contextMeta: undefined,
    bpm: {
      min: undefined,
      max: undefined,
    },
    bpm_bucket: undefined
  }

  public filterTabItems = {
    'track_type': false,
    'genres': false,
    'moods': false,
    'usecases': false,
    'bpm': false,
    'languages': false,
    'playlist_type': false,
    'PRO_type':true,
  }

  // pagination
  public currentPageNumber: number = 1;
  public endOfResults = false;
  private loadingMoreData = false;

  constructor(
    private searchHelper: SearchHelperService,
    private activatedRoute: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private router: Router,
    private location: Location,
  ) {
    this.deviceDetect();
    this.searchHelper.resetFilters(false);
   }

   backClicked() {
    this.location.back();
  }

   deviceDetect() {
    // this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  async ngOnInit() {
    // first fetch the filter master list
    await this.searchHelper.getFilterMasterList();

    // subscribe to new search results
    this.searchHelper.$searchResults.subscribe((apiResponse) => {
      this.searchResults = apiResponse;
    })

    // get query params from url
    // q - search query
    // t - search tab
    this.activatedRoute.queryParams.subscribe(async (paramMap) => {

      this.currentPageNumber = 1;
      // prefill the searched text
      this.prefillSearchText = paramMap.q ? paramMap.q : "";

      // set the search keyword in search helper
      this.searchHelper.$searchKeyword.next(this.prefillSearchText);

      // reset filters if the search tab is changed
      if(this.searchPageSection && this.searchPageSection !== paramMap.t) {
        this.searchHelper.resetFilters(false);
      }

      // set the search tab
      this.searchPageSection = paramMap.t ? paramMap.t : "all";

      //  set the search type in search helper
      if(this.searchPageSection === 'all') {
        this.searchHelper.$searchType.next("global")
      } else {
        this.preDefinedFilters.asset_type = this.searchPageSection
        await this.searchHelper.processPredefinedFilters(this.preDefinedFilters)
        this.searchHelper.$searchType.next("faceted")
      }

      // set the filter tab items
      if(this.searchPageSection === 'music') {
        this.filterTabItems = {
          'track_type': true,
          'genres': true,
          'moods': true,
          'usecases': true,
          'bpm': true,
          'languages': true,
          'playlist_type': false,
          'PRO_type':true
        }
      } else if(this.searchPageSection === 'playlist') {
        this.filterTabItems = {
          'track_type': false,
          'genres': true,
          'moods': true,
          'usecases': true,
          'bpm': false,
          'languages': false,
          'playlist_type': true,
          'PRO_type':false,
        }
      }
    })
  }

  ngOnDestroy(): void {   
    this.searchHelper.resetFilters(false);
  }

  public async redirectToSection(sectionType, searchQuery) {
    if(this.searchPageSection === sectionType) {
      return;
    } 
    this.searchHelper.resetFilters(false);
    this.router.navigate([], {relativeTo: this.activatedRoute, queryParams: {"q": searchQuery, "t": sectionType}})
  }

  public async searchPillClick(searchQuery) {
    this.router.navigate([], {relativeTo: this.activatedRoute, queryParams: {"q": searchQuery, "t": 'all'}})
  }
  
  async loadMoreData() {
    if (this.searchPageSection !== 'all' && !this.endOfResults && !this.loadingMoreData) {
      this.currentPageNumber = this.currentPageNumber + 1;
      this.loadingMoreData = true;
      const newResults: any = await this.searchHelper.fetchNewPageResults(this.currentPageNumber);
      if (newResults && newResults.data && newResults.data.length === 0) {
        this.endOfResults = true;
      }
      this.searchResults[0].data.push(...newResults);
      this.loadingMoreData = false;
    }
  }
}
