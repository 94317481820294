<div class="filter align-items-center mt-4 mt-md-0">
  <div class="d-flex justify-content-between align-items-center">
    <span class="item filter-by pr-4">Filter By</span>
  </div>

  <!-- Genres -->
  <ng-container *ngIf="this.filterTabItems.genres">
    <div class="filter-title" [attr.aria-expanded]="expandedDropdown === 'genres'" data-toggle="collapse" data-target="#genresCollapse" aria-controls="genresCollapse" role="button">
      <button class="titleContainer btn" id="genres-heading" [ngClass]="['cursor', 'item', 'd-flex']" (click)="toggleDropdown('genres')">
        <lengend class="mr-1">Genres</lengend>
        <div class="arrow" [ngClass]="['ml-auto']">
          <i class="ph ph-caret-down"></i>
        </div>
      </button>
    </div>
  </ng-container>
  <!-- Genres end -->

  <!-- Moods -->
  <ng-container *ngIf="this.filterTabItems.moods">
    <div class="filter-title" [attr.aria-expanded]="expandedDropdown === 'moods'" data-toggle="collapse" data-target="#moodsCollapse" aria-controls="moodsCollapse" role="button">
      <button class="titleContainer btn" id="moods-heading" [ngClass]="['cursor', 'item', 'd-flex']" (click)="toggleDropdown('moods')">
        <lengend class="">Moods</lengend>
        <div class="arrow" [ngClass]="['ml-auto']">
          <i class="ph ph-caret-down"></i>
        </div>
      </button>
    </div>
  </ng-container>
  <!-- Moods end -->

  <!-- Bpm type -->
  <ng-container *ngIf="this.filterTabItems.bpm && this.filterTabItems.track_type">
    <div class="filter-title" [attr.aria-expanded]="expandedDropdown === 'bpm'" data-toggle="collapse" data-target="#bpmCollapse" aria-controls="bpmCollapse" role="button">
      <button class="titleContainer btn" id="bpm-heading" [ngClass]="['cursor', 'item', 'd-flex']" (click)="toggleDropdown('bpm')">
        <lengend class="mr-1">BPM</lengend>
        <div class="arrow" [ngClass]="['ml-auto']">
          <i class="ph ph-caret-down"></i>
        </div>
      </button>
    </div>
  </ng-container>
  <!-- Bpm end -->

  <!-- Languages -->
  <ng-container *ngIf="this.filterTabItems.languages && this.filterTabItems.track_type">
    <div class="filter-title" [attr.aria-expanded]="expandedDropdown === 'languages'" data-toggle="collapse" data-target="#languagesCollapse" aria-controls="languagesCollapse" role="button">
      <button class="titleContainer btn" id="languages-heading" [ngClass]="['cursor', 'item', 'd-flex']" (click)="toggleDropdown('languages')">
        <lengend class="mr-1">Languages</lengend>
        <div class="arrow" [ngClass]="['ml-auto']">
          <i class="ph ph-caret-down"></i>
        </div>
      </button>
    </div>
  </ng-container>
  <!-- Languages end -->

  <!-- Usecases -->
  <ng-container *ngIf="this.filterTabItems.usecases">
    <div class="filter-title" [attr.aria-expanded]="expandedDropdown === 'usecases'" data-toggle="collapse" data-target="#usecaseCollapse" aria-controls="usecaseCollapse" role="button">
      <button class="titleContainer btn active" id="usecase-heading" [ngClass]="['cursor', 'item', 'd-flex']" (click)="toggleDropdown('usecases')">
        <lengend class="mr-1">Usecases</lengend>
        <div class="arrow" [ngClass]="['ml-auto']">
          <i class="ph ph-caret-down parent-collapsed"></i>
        </div>
      </button>
    </div>
  </ng-container>
  <!-- Usecases end -->

  <div class="px-2 py-2 pointer pt-0 clear-text" (click)="this.clearAllFilters()"><u>Clear All</u></div>
</div>

<div id="accordion">
    <div id="genresCollapse" class="collapse" aria-labelledby="genres-heading" data-parent="#accordion"
  [ngClass]="['in  cursor',' filter-box-container']">
  <div class=" px-3 py-3  m-1 filter-box "
    *ngFor="let item of this.searchHelper.filterMasterList['genres']; index as i">
    <ng-container>
      <div class=" d-flex align-items-center list-item"
        (click)="!item.disabled ? this.searchHelper.selectFilter('genre', i): ''"
        [ngClass]="item.disabled ? 'filter-disabled': ''">
          <app-checkbox class="" [state]="item.excluded ?'excluded':item.selected===true?'checked':'default'">
          </app-checkbox>
          <span [ngClass]="['ml-2','cursor' ,'filter-option-label']">
            {{item.name}}
          </span>
      </div>
    </ng-container>
  </div>
</div>

<div id="moodsCollapse" class="collapse" aria-labelledby="moods-heading" data-parent="#accordion"
  [ngClass]="['in cursor', 'filter-box-container']">
  <div class="px-3 py-3 m-1 filter-box" 
    *ngFor="let item of this.searchHelper.filterMasterList['moods']; index as i">
    <ng-container>
      <div class="d-flex align-items-center list-item"
        (click)="!item.disabled ? this.searchHelper.selectFilter('mood', i): ''"
        [ngClass]="item.disabled ? 'filter-disabled': ''">
          <app-checkbox class="" [state]="item.excluded ? 'excluded' : item.selected === true ? 'checked' : 'default'">
          </app-checkbox>
          <span [ngClass]="['ml-2', 'cursor', 'filter-option-label']">
            {{item.name}}
          </span>
      </div>
    </ng-container>
  </div>
</div>

<div id="bpmCollapse" class="collapse" aria-labelledby="bpm-heading" data-parent="#accordion"
  [ngClass]="['in  cursor',' filter-box-container']">
  <div class="px-3 py-3  m-1 filter-box"
    (click)="this.searchHelper.selectFilter('bpm_bucket', undefined,  'very-slow')">
    <div class="d-flex align-items-center list-item">
        <app-checkbox class=""
          [state]="this.searchHelper.selectedFilters.bpm_bucket === 'very-slow' ?'checked':'default'">
        </app-checkbox>
        <span [ngClass]="['ml-2','cursor' ,'filter-option-label']">
          Very Slow
        </span>
    </div>
  </div>

  <div class="px-3 py-3  m-1 filter-box" (click)="this.searchHelper.selectFilter('bpm_bucket', undefined,  'slow')">
    <div class="d-flex align-items-center list-item">
        <app-checkbox class="" [state]="this.searchHelper.selectedFilters.bpm_bucket === 'slow' ?'checked':'default'">
        </app-checkbox>
        <span [ngClass]="['ml-2','cursor' ,'filter-option-label']">
          Slow
        </span>
    </div>
  </div>

  <div class="px-3 py-3  m-1 filter-box" (click)="this.searchHelper.selectFilter('bpm_bucket', undefined,  'medium')">
    <div class="d-flex align-items-center list-item">
        <app-checkbox class="" [state]="this.searchHelper.selectedFilters.bpm_bucket === 'medium' ?'checked':'default'">
        </app-checkbox>
        <span [ngClass]="['ml-2','cursor' ,'filter-option-label']">
          Medium
        </span>
    </div>
  </div>

  <div class="px-3 py-3  m-1 filter-box" (click)="this.searchHelper.selectFilter('bpm_bucket', undefined,  'fast')">
    <div class="d-flex align-items-center list-item">
        <app-checkbox class="" [state]="this.searchHelper.selectedFilters.bpm_bucket === 'fast' ?'checked':'default'">
        </app-checkbox>
        <span [ngClass]="['ml-2','cursor' ,'filter-option-label']">
          Fast
        </span>
    </div>
  </div>

  <div class="px-3 py-3  m-1 filter-box"
    (click)="this.searchHelper.selectFilter('bpm_bucket', undefined,  'very-fast')">
    <div class="d-flex align-items-center list-item">
        <app-checkbox class=""
          [state]="this.searchHelper.selectedFilters.bpm_bucket === 'very-fast' ?'checked':'default'">
        </app-checkbox>
        <span [ngClass]="['ml-2','cursor' ,'filter-option-label']">
          Very Fast
        </span>
    </div>
  </div>

</div>

<div id="languagesCollapse" class="collapse" aria-labelledby="languages-heading" data-parent="#accordion"
  [ngClass]="['in  cursor',' filter-box-container']">
  <div class="px-3 py-3  m-1 filter-box " *ngFor="let item of this.searchHelper.filterMasterList['languages']; index as i">
    <ng-container>
      <div class="d-flex align-items-center list-item"  
        (click)="!item.disabled ? this.searchHelper.selectFilter('language', i): ''"
        [ngClass]="item.disabled ? 'filter-disabled': ''">
          <app-checkbox class="mx-2" [state]="item.excluded ?'excluded':item.selected===true?'checked':'default'">
          </app-checkbox>
          <span [ngClass]="['ml-2','cursor','filter-option-label']">
            {{item.name}}
          </span>
      </div>
    </ng-container>
  </div>
</div>

<div id="usecaseCollapse"  class="collapse" aria-labelledby="usecase-heading" data-parent="#accordion"
  [ngClass]="['in cursor',' filter-box-container']">
  <div class="px-3 py-3 m-1 filter-box "
    *ngFor="let item of this.searchHelper.filterMasterList['usecases']; index as i">
    <ng-container>
      <div class="d-flex align-items-center list-item"
        (click)="!item.disabled ? this.searchHelper.selectFilter('usecase', i): ''"
        [ngClass]="item.disabled ? 'filter-disabled': ''">
          <app-checkbox class="mx-2" [state]="item.excluded ?'excluded':item.selected===true?'checked':'default'">
          </app-checkbox>
          <span [ngClass]="['ml-2','cursor','filter-option-label']">
            {{item.name}}
          </span>
      </div>
    </ng-container>
  </div>
</div>

</div>



