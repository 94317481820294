import { Component } from '@angular/core';
import { PlayerService } from './services/player.service';
import { PopupService } from './services/popup.service';
import { RouterOutlet, ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { fadeAnimation } from "./animation";
import { BackendApiService } from './services/hoopr.services';
import { UserInfoService } from './services/user-info.service';
import * as uuid from 'uuid';
import { environment } from 'src/environments/environment';

declare var window: any;
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    fadeAnimation,
  ]
})
export class AppComponent {

  public previousUrl: string;
  public userData: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    public playerService: PlayerService,
    public popUpService: PopupService,
    public router: Router,
    public apiService: BackendApiService,
    private userInfoService: UserInfoService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {

        if (event.url === '/') {
          const rpScript = document.createElement('script');
          rpScript.type = 'application/ld+json'
          rpScript.innerHTML = `
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "Hoopr",
            "url": "https://hoopr.ai/",
            "logo": "https://hoopr.ai/assets/images/hoopr-logo.webp",
            "potentialAction": [
                {
                    "@type": "SearchAction",
                    "target": {
                        "@type": "EntryPoint",
                        "urlTemplate": "https://hoopr.ai/search?q={search_term_string}"
                    },
                    "query-input": "required name=search_term_string"
                }
            ]
          }`

          $('head').append(rpScript);

        }

        // update canonical url
        this.updateCanonicalUrl(`${environment.baseUrlFrontend}${window.location.pathname}`);
        this.logToAmplitude();
      }

    })
    localStorage.setItem("diwaliNotification", JSON.stringify(true));
  }

  ngOnInit(): void {

    this.userData = this.apiService.getUserData();

    this.apiService.setUserDetails().subscribe((userDetails) => {
    });

    this.activatedRoute.queryParamMap.subscribe(params => {

      const urlSearchParams = new URLSearchParams(window.location.search);
      for (const [key, value] of urlSearchParams.entries()) {
        localStorage.setItem(key, value);
      }

      if (params.get('referral_link')) {
        this.checkReferralLinkValidity(params.get('referral_link'));
      }

    })

    this.checkTokenValidity();
    this.assignAnonId();
    this.setUserIp();
  }

  checkTokenValidity() {
    // check path to avoid looping
    if (window.location.pathname !== '/auth/get-started') {
      if (localStorage.authorizationToken) {
        this.apiService.validateToken().subscribe(() => {
        }, (err: any) => {
          if (err.status == 401) {
            this.apiService.logOut();
          }
        })
      }
    }
  }

  assignAnonId() {
    // if the user is not registered them we make anonymous id for analytics.
    if (!localStorage.getItem('authorizationToken')) {
      if (!localStorage.getItem('anon_id')) {
        const myId = uuid.v4();
        localStorage.setItem('anon_id', myId);
      }
    }
  }

  setUserIp() {
    // checking the ip of user
    this.apiService.getIp().subscribe((response: any) => {
      let ip = response.ip;
      // check whether the user has current address from ip-api
      if (localStorage.getItem('current_address')) {
        let isp_ip = JSON.parse(localStorage.getItem('current_address'))?.isp_ip
        // check whether the saved address of the ip has still the same ip or not
        if (ip !== isp_ip) {
          // if saved ip and current ip are different then remove address and call ip-api again
          localStorage.removeItem('current_address');
          this.ipApi(ip);
        }
      } else {
        // if current address is not already there we call ip-api
        this.ipApi(ip);
      }
    })
  }

  ipApi(ip: string) {
    // api to get city, state and country of consumers
    this.apiService.ipApi(ip).subscribe((response: any) => {
      let current_address = {
        city: response.city,
        state: response.region_name,
        country: response.country_name,
        isp_ip: ip
      };
      localStorage.setItem('current_address', JSON.stringify(current_address));
    })
  }

  logToAmplitude() {
    let params
    this.activatedRoute.queryParams.subscribe(pararms => {
      params = pararms;
    })

    let utm_medium = params?.utm_medium ? this.assignValue(params.utm_medium) : null;
    let utm_source = params?.utm_source ? this.assignValue(params.utm_source) : null;
    let utm_campaign = params?.utm_campaign ? this.assignValue(params.utm_campaign) : null;

    const eventMetaData = {
      event_type: 'page_viewed',
      page_name: window.location?.pathname?.substring(0, 125) || null,
      page_type: window.location?.pathname?.split("/")[1]?.substring(0, 125) || null,
      page_url: window.location?.href?.substring(0, 125) || null,
      referral_page: this.previousUrl || null,
      referral_pagetype: this.previousUrl?.split("/")[1] || null,
      referral_button: null,
      referral_utm_source: utm_source,
      referral_utm_medium: utm_medium,
      referral_utm_campaign: utm_campaign,
    }
    this.previousUrl = window.location?.pathname?.substring(0, 125) || null;
    this.userInfoService.logData(eventMetaData);
  }

  assignValue(param) {
    return typeof param === 'object' ? param[0].substring(0, 125) : param.substring(0, 125);
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }

  updateCanonicalUrl(url: string) {
    let canonicalLink = document.querySelector('link[rel="canonical"]') as HTMLLinkElement;

    if (!canonicalLink) {
      const head = document.getElementsByTagName('head')[0];
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      head.appendChild(canonicalLink);
    }
    canonicalLink.setAttribute('href', url);
  }

  checkReferralLinkValidity(referralLink: string) {
    if (
      this.apiService.getToken() === "" ||
      this.apiService.getUserData() === ""
    ) {
      let referrerId = referralLink.slice(6)
      this.apiService.getConsumerById(referrerId).subscribe((data: any) => {
        localStorage.setItem('referral_link', referralLink);
        this.popUpService.showPopUp('REFERRAL_POPUP', data);
      }, (err) => {
        this.popUpService.showPopUp('REFERRAL_POPUP_ERROR');
      })
    }
  }

}
