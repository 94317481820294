import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { BackendApiService } from '../services/hoopr.services';
import { PopupService } from '../services/popup.service';

@Component({
  selector: 'app-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.scss']
})

// **************************************************************
// component used for INDIVIDUAL PLAYLIST/MOVIE/GENRE/LANGUAGES detail page
// **************************************************************

export class IndividualComponent implements OnInit {

  type: string;
  nameslug: string;
  feedData: any;
  tracks: any = [];
  showEditIcon: boolean = false;

  // for pagination
  currentPageNumber: number = 0;
  endOfResults: boolean = false;
  loadingMoreData: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: BackendApiService,
    private popupService: PopupService,
  ) { }

  ngOnInit(): void {

    this.feedData = null;
    const data$ = this.activatedRoute.data;
    const params$ = this.activatedRoute.paramMap;

    combineLatest([data$, params$]).subscribe(([data, params]) => {
      this.type = data['type'];
      this.nameslug = params.get('value');
      this.feedData = null;
      this.fetchData(this.type, this.nameslug, 0);
    })
  }

  async fetchData(assetType: string, value: string, pageNumber: number): Promise<void> {
    try {
      switch (assetType) {
        case 'genre':
          this.feedData = await this.apiService.getGenreFeed(value, 15, pageNumber).toPromise();
          this.tracks.push(...this.feedData.tracks);
          break;
  
        case 'playlist':
          this.feedData = await this.apiService.getPlaylistTrackFeed(value, 15, pageNumber).toPromise();
          this.tracks.push(...this.feedData.tracks);
          this.showEditIcon = !!this.feedData.banner.showEditIcon;
          break;
  
        case 'movie':
          this.feedData = await this.apiService.getPlaylistTrackFeed(value, 15, pageNumber).toPromise();
          this.tracks.push(...this.feedData.tracks);
          break;
  
        case 'language':
          this.feedData = await this.apiService.getLanguageFeed(value, 15, pageNumber).toPromise();
          this.tracks.push(...this.feedData.tracks);
          break;

        case 'mood':
          this.feedData = await this.apiService.getMoodFeed(value, 15, pageNumber).toPromise();
          this.tracks.push(...this.feedData.tracks);
          break;
  
        default:
          break;
      }
    } catch (err) {
      console.log('--err in getting feed data for asset', assetType, 'Error:' ,err.message);
    }
  }

  public async loadMoreData() {
    if (!this.endOfResults && !this.loadingMoreData) {
      this.currentPageNumber = this.currentPageNumber + 1;
      this.loadingMoreData = true;
      await this.fetchData(this.type, this.nameslug, this.currentPageNumber);
      if(this.feedData.tracks.length === 0) {
        this.endOfResults = true;
      }
      this.loadingMoreData = false;
    }
  }

  renamePlaylist(): void {
    const data = {
      playlistId : this.nameslug,
      playlistName: this.feedData.banner.title,  
    };
    this.popupService.showPopUp("RENAME_PLAYLIST", data);
  }

  deleteUserPlaylist(): void {
    const data = {
      playlistId : this.nameslug,
      playlistName: this.feedData.banner.title,  
    };
    this.popupService.showPopUp("DELETE_PLAYLIST", data);
  }
  share():void{
    const data = {
      type: this.type,
      name: this.feedData.banner.title,
      description: this.feedData.banner.description,
      imageUrl:this.feedData.banner.image,
      name_slug: this.nameslug
    }
    this.popupService.showPopUp("SHARE_SONG",data );
  }
}
