import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BackendApiService } from '../services/hoopr.services';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {
  constructor(
    private apiService: BackendApiService,
    private router: Router,
  ) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canProceed();
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canProceed();
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canProceed();
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canProceed();
  }

  private canProceed() {
    const userData = this.apiService.getUserData();
    // TODO: ADDED ISONBOARDED FLAG
    if (userData && userData['activated']) {
      return true;
    }
    if (userData === undefined || Object.keys(userData).length == 0) {
      this.router.navigate(['/auth/get-started'])
    }
    // else if (userData['activated'] == false) {
    //   this.router.navigate(['/auth/get-started'])
    // }
    // else if (userData['isOnboarded'] == false) {
    //   this.router.navigate(['/auth/get-started'])
    // }
    return false;
  }
}
