import { SearchHelperService } from "./../services/search-helper.service";
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { BackendApiService } from '../services/hoopr.services';
interface sliderData {
  minValue: number,
  maxValue: number
}

@Component({
  selector: "app-global-filter",
  templateUrl: "./global-filter.component.html",
  styleUrls: ["./global-filter.component.scss"],
})
export class GlobalFilterComponent implements OnInit, OnDestroy {

  @Input()
  public filterTabItems = {
    'track_type': false,
    'PRO_type': true,
    'genres': false,
    'moods': false,
    'usecases': false,
    'languages': false,
    'bpm': false,
    'playlist_type': false,
  };

  public sliderValue: sliderData = {
    minValue: 0,
    maxValue: 300,
  }
  public userData:any;
  public isEnterpriseAccessible:boolean =false;

  public hasVocals;
  public isPRO;
  public playlistType = undefined;
  public expandedDropdown: string | null = null;

  

  constructor(
    public searchHelper: SearchHelperService,
    public apiService: BackendApiService
  ) {}

  async ngOnInit() {

    this.userData = this.apiService.getUserData();
    if(this.userData && this.userData.isEnterpriseAccessible){
      this.isEnterpriseAccessible = true;
    }
  }

  ngOnDestroy(): void {
  }

  public clearAllFilters() {
    this.hasVocals = undefined;
    this.isPRO = undefined;
    this.playlistType = undefined;
    this.searchHelper.resetFilters(true);
    this.sliderValue.maxValue = 300;
    this.sliderValue.minValue = 0;
  }

  public bpmLabelSelected(low, high) {
    this.sliderValue.minValue = low;
    this.sliderValue.maxValue = high;
  }
  toggleDropdown(dropdown: string) {
    this.expandedDropdown = this.expandedDropdown === dropdown ? null : dropdown;
  }
}
