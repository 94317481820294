import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-movie-frame',
  templateUrl: './movie-frame.component.html',
  styleUrls: ['./movie-frame.component.scss']
})
export class MovieFrameComponent {

  @Input() data: any;
  @Input() showLabel: boolean = false;
  @Input() labelColor:string;
  constructor() { }

}
