<a class=" " [routerLink]="'/playlist/' + this.data?.name_slug">
  <div class="hoopr-artist-card">
    <div class="position-relative">
      <img [src]="this.data.coverImage || this.data.cover_image" onerror="this.src='/assets/images/default/track.webp'"
      alt="song-artist-default" class="song-artist-image ">
      <div class="badge badge-bottom-playlist">
        <img src="/assets/images/popularPlaylist.webp" alt="popular-badge"  class="frame-badge" *ngIf="this.data?.payload == 'popular'">
        <img src="/assets/images/trendingPlaylist.webp" alt="popular-badge"  class="frame-badge" *ngIf="this.data?.payload == 'trending'">
        <img src="/assets/images/bothPlaylist.webp" alt="popular-badge"  class="frame-badge-large" *ngIf="this.data?.payload == 'popular-trending'">
    </div>
    </div>
    <ng-container *ngIf="showLabel">
      <p class="text-left py-2 name-text" [ngClass]="labelColor === 'black' ? 'text-black' : 'text-white'">
        {{this.data?.name || ''}}</p>
    </ng-container>
  </div>
</a>