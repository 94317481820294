import { Injectable } from '@angular/core';
declare var window: any;

@Injectable({
    providedIn: 'root',
})
export class GtmAnalyticsService {
    constructor() { }

    pushAnalytics(eventObject: any) {
        window.dataLayer.push(eventObject);
    }

    pushCtaAnalytics(eventObject: CtaTracking) {
        // TODO: fix the below breaking code
        // window.dataLayer.push(eventObject);
    }
}

export interface ProductImpression {
    event: string;
    ecommerce: {
        item_list_id: string; // id associated with the collection
        item_list_name: string; //
        currency: string;
        items: {
            item_id: string; // product id
            item_name: string; //product name
            index: number; //The index/position of the item in a list
            item_brand: string; //The brand of the item
            item_category: string; //The category of the item
            item_list_id: string; // id associated with the collection
            item_list_name: string; //
            user_id: string; //user id of logged in user
        }[];
    };
}

export interface SelectProduct {
    event: string;
    ecommerce: {
        item_list_id: string; // id associated with the collection
        item_list_name: string;
        currency: string;
        items: {
            item_id: string; // product id
            item_name: string; //product name
            index: number; //The index/position of the item in a list
            item_brand: string; //The brand of the item
            item_category: string; //The category of the item
            item_list_id: string; // id associated with the collection
            item_list_name: string; //
            user_id: string; //user id of logged in user
        }[];
    };
}

export interface AddToCart {
    event: string;
    ecommerce: {
        currency: string;
        value: number;
        items: {
            item_id: string; // product id
            item_name: string; //product name
            index: number; //The index/position of the item in a listt
            coupon: string; //The coupon name/code associated with the item if applied
            discount: number; //if any discount is applied
            price: string; //price before discount
            item_brand: string; //The brand of the item
            item_category: string; //The category of the item
            item_category2: string;
            item_list_id: string; // id associated with the collection
            item_list_name: string; //
            item_variant: string; //based on model selected
            user_id: string; //user id of logged in user
        }[];
    };
}

export interface BeginToCheckout {
    event: string;
    ecommerce: {
        currency: string;
        value: number;
        items: {
            item_id: string; // product id
            item_name: string; //product name
            index: number; //The index/position of the item in a listt
            coupon: string; //The coupon name/code associated with the item if applied
            discount: number; //if any discount is applied
            price: string; //price before discount
            item_brand: string; //The brand of the item
            item_category: string; //The category of the item
            item_category2: string;
            item_category3: string;
            item_category4: string; //track added
            item_list_id: string; // id associated with the collection
            item_list_name: string; //
            item_variant: string; //based on model selected
            user_id: string; //user id of logged in user
        }[];
    };
}

export interface Purchase {
    event: string;
    ecommerce: {
        transaction_id: string;
        affiliation: string;
        value: number;
        tax: number;
        shipping: number;
        currency: string;
        coupon: string;
        items: {
            item_id: string; // product id
            item_name: string; //product name
            index: number; //The index/position of the item in a listt
            coupon: string; //The coupon name/code associated with the item if applied
            discount: number; //if any discount is applied
            price: string; //price before discount
            item_brand: string; //The brand of the item
            item_category: string; //The category of the item
            item_category2: string;
            item_category3: string;
            item_category4: string; //track added
            item_list_id: string; // id associated with the collection
            item_list_name: string; //
            item_variant: string; //based on model selected
            user_id: string; //user id of logged in user
        }[];
    };
}
export interface CtaTracking {
    event: string;
    event_name: string;
    link_name: string;
    link_section: string;
    link_header: string;
    link_text: string;
    link_url: string;
    user_id: string;
    artist_name: string;
    track_name: string;
    search_term: string;
    search_link: string;
    instrument_type: string;
    login_status: 'guest' | 'login';
    page_type: string;
}
