/**
 * Hoopr
 * Hoopr Apis
 *
 * OpenAPI spec version: 2.0.1
 * Contact: sanket@blackcurrantlabs.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FiltersSchemaBpm { 
    min?: number;
    max?: number;
}