import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-language-frame',
  templateUrl: './language-frame.component.html',
  styleUrls: ['./language-frame.component.scss']
})
export class LanguageFrameComponent {

  @Input() data: any;
  @Input() showLabel: boolean;
  @Input() labelColor:string;

  constructor() { }

}
