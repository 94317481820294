import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-genre-frame',
  templateUrl: './genre-frame.component.html',
  styleUrls: ['./genre-frame.component.scss']
})
export class GenreFrameComponent {

  @Input() data: any;
  @Input() showLabel: boolean;

  constructor() { }
}
