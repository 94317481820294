import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {

  @Input() assetType: string;
  @Input() items: number;
  @Input() mobileItems: number;
  @Input() labelColor: string;
  @Input() data: any = [];

  constructor() { }

  ngOnInit(): void {
  }

}
