import { Component, OnInit } from '@angular/core';
import { BackendApiService } from '../services/hoopr.services';

@Component({
  selector: 'app-artists',
  templateUrl: './artists.component.html',
  styleUrls: ['./artists.component.scss']
})
export class ArtistsComponent implements OnInit {
 
  public feedData: any;
  public featuredArtistBanner: any;
  public featuredArtistTracks: any;
  public trending: any = [];
  public popularArtists: any;
  public allArtists: any;

  constructor(
    private apiService: BackendApiService,
  ) { }

  ngOnInit(): void {
    this.apiService.getArtistListFeed().subscribe(
      (response) => {
        this.feedData = response;
        this.featuredArtistBanner = this.feedData['featured-artist-banner'];
        this.featuredArtistTracks = this.feedData['featured-artist-tracks'].data;
        this.trending.push(this.feedData['trending-1']);
        this.trending.push(this.feedData['trending-2']);
        this.trending.push(this.feedData['trending-3']);
        this.popularArtists = this.feedData['popular'].data;
        this.allArtists = this.feedData['all-artists'].data;    
      },
      (err) => {
        console.log('Error while loading feed data', err);
      }
    )
  }

}
