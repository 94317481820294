import { Component, Input, OnInit } from '@angular/core';
import 'swiper/scss/pagination';
import 'swiper/scss';
import SwiperCore, { Pagination, Navigation, FreeMode, Autoplay, SwiperOptions } from "swiper";

SwiperCore.use([Pagination, Navigation, Autoplay, FreeMode]);


@Component({
  selector: 'app-trending',
  templateUrl: './trending.component.html',
  styleUrls: ['./trending.component.scss']
})
export class TrendingComponent implements OnInit {

  @Input() data:any;
  @Input() items: number;
  @Input() mobileItems: number;
  @Input() showLabel:boolean;

  constructor() { 
  }

  public trending: SwiperOptions = {
    slidesPerView: 3,
    spaceBetween: 20,
    // loop: true,
    // autoplay: true,
    // freeMode: true,
    direction:'horizontal',
    mousewheel: true,
  
    scrollbar: {
      draggable: true
    },
    breakpoints: {
      320: {
        slidesPerView: 2.2,
        spaceBetween: 10
      },
      425: {
        slidesPerView: 2.2,
        spaceBetween: 10
      },
      768: {
        slidesPerView: 2.5,
        spaceBetween: 15
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 20
      },
      1440: {
        slidesPerView: 3,
        spaceBetween: 20
      }
    }
  };
  
  ngOnInit(): void {
    this.trending.breakpoints[1440].slidesPerView = this.items ? this.items : 3;
    this.trending.breakpoints[1024].slidesPerView = this.items ? this.items : 3;
    this.trending.breakpoints[425].slidesPerView = this.mobileItems ? this.mobileItems : 2.2;
    this.trending.breakpoints[320].slidesPerView = this.mobileItems ? this.mobileItems : 2.2;
    console.log(this.data)
  }

}
