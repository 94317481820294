import { Component, OnInit } from '@angular/core';
import { BackendApiService } from '../services/hoopr.services';
import { Router } from '@angular/router';


@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {

  public feedData: any;
  public popularLanguages : any;
  public trendingLanguages: any = [];
  public featuredCategory1: any;
  public searchQuery: string = '';


  constructor(
    private apiService: BackendApiService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.apiService.getLanguageListFeed().subscribe(
      (response) => {
        this.feedData = response;
        this.popularLanguages = this.feedData['popular'];
        this.trendingLanguages = this.feedData['trending'].data;
        this.featuredCategory1 = this.feedData['featured-1'].data;
      },
      (err) => {
        console.log('Error while loading feed data', err);
      }
    )

  }
  searchNavigate(){
    this.router.navigate(['/search'], { queryParams: { q: this.searchQuery, t : "language" } });
  }


}
