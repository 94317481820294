<div class="popular pt-5">
	<div class="container">
		<ul class="nav nav-tabs justify-content-md-center mb-5 flex-nowrap mx-3 mx-md-0" role="tablist">
			<li class="nav-item">
				<a class="nav-link active " data-toggle="tab" href="#movies" role="tab">Movies 
					  
					<span class="ml-2" >
						<svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M10.5 0L12.8574 7.25532H20.4861L14.3143 11.7394L16.6717 18.9947L10.5 14.5106L4.32825 18.9947L6.68565 11.7394L0.513906 7.25532H8.1426L10.5 0Z"
								fill="#2E3E6E" />
						</svg>
					</span>
				</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" data-toggle="tab" href="#artists" role="tab">Artists 
					
					<span class="ml-2" >
						<svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M10.5 0L12.8574 7.25532H20.4861L14.3143 11.7394L16.6717 18.9947L10.5 14.5106L4.32825 18.9947L6.68565 11.7394L0.513906 7.25532H8.1426L10.5 0Z"
								fill="#2E3E6E" />
						</svg>
					</span>
				</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" data-toggle="tab" href="#playlists" role="tab">Playlists 
					
					<span class="ml-2" >
						<svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M10.5 0L12.8574 7.25532H20.4861L14.3143 11.7394L16.6717 18.9947L10.5 14.5106L4.32825 18.9947L6.68565 11.7394L0.513906 7.25532H8.1426L10.5 0Z"
								fill="#2E3E6E" />
						</svg>
					</span>
				</a>
			</li>
			<li class="nav-item">
				<a class="nav-link border-0" data-toggle="tab" href="#tracks" role="tab">Tracks 
					
					<span class="ml-2" >
						<svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M10.5 0L12.8574 7.25532H20.4861L14.3143 11.7394L16.6717 18.9947L10.5 14.5106L4.32825 18.9947L6.68565 11.7394L0.513906 7.25532H8.1426L10.5 0Z"
								fill="#2E3E6E" />
						</svg>
					</span>
				</a>
			</li>
		</ul>
		<!-- Tab panes -->
		<div class="tab-content">
			<div class="tab-pane active" id="movies" role="tabpanel">
				<app-grid [data]="this.popularData.movies" [assetType]="'Movies'" [items]="this.popularData?.movies?.length"
					[mobileItems]="'2'" [labelColor]="'white'"></app-grid>
			</div>
			<div class="tab-pane" id="artists" role="tabpanel">
				<app-grid [data]="this.popularData.artists  " [assetType]="'Artist'" [items]="this.popularData?.artists?.length"
					[mobileItems]="'2'" [labelColor]="'white'"></app-grid>
			</div>
			<div class="tab-pane" id="playlists" role="tabpanel">
				<app-grid [data]="this.popularData.playlists" [assetType]="'Playlists'" [items]="this.popularData?.playlists?.length"
					[mobileItems]="'2'" [labelColor]="'white'"></app-grid>
			</div>
			<div class="tab-pane" id="tracks" role="tabpanel">
				<div class="row py-md-5 py-2">
					<div class="col-md-4 col-12 py-md-3 py-2" *ngFor="let track of this.popularData.tracks | slice:0:6">
						<app-track-block [size]="'small'" [musicInfo]="track" [labelColor]="'white'"></app-track-block>
					</div>
				</div>
			</div>
		</div>
	</div><!-- container -->
</div><!-- popular -->