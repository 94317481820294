import { LoggerServiceService } from "src/app/services/logger-service.service";
import { BackendApiService } from "./../../services/hoopr.services";
import { PopupService } from "./../../services/popup.service";
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, Renderer2, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SwalHelper } from "../../_helpers/swal-helpers"
import { environment } from "src/environments/environment";
import { UserInfoService } from "src/app/services/user-info.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UtilsService } from "../../services/utils.service";
import { GtmAnalyticsService } from 'src/app/services/gtm-analytics.service';
import { PlayerService } from "src/app/services/player.service";
import SwiperCore, { Pagination, Navigation, FreeMode, Autoplay, SwiperOptions } from "swiper";
SwiperCore.use([Pagination, Navigation, Autoplay, FreeMode]);

declare var window: any;
declare var $: any;
@Component({
  selector: "app-logged-in",
  templateUrl: "./logged-in.component.html",
  styleUrls: ["./logged-in.component.scss"],
})
export class LoggedInComponent implements OnInit, OnDestroy {
  @ViewChild('viewMoreContainer') viewMoreContainer: ElementRef;

  public popUpType = undefined;
  public userDetails = undefined;
  public playlistName: string;
  public playlistDesc: string;
  public presentPlaylists: any;
  public formErrors = {};
  public trackInfo: any = {};
  public primaryArtist: any;
  public hasVocals: boolean
  public licenseTypeForm: FormGroup;
  public lifetimeLicenseAbout: boolean;
  private takeUntil$ = new Subject();
  public openCollapse: boolean = false;
  public hasVerticalScrollbar;
  private BaseUrl = environment.baseUrlBackend;
  token: string;
  public trackUrl: string;
  public showSkip = true;
  public artistString: string;

  // onboarding form variables
  public onboardingForm: FormGroup;
  public isUserEmailVerfied: boolean = false;
  public showEmailOtpBox: boolean = false;
  public emailToBeVerfied: string;
  public onboardingFromErrors = {
    "name": '',
    "email": '',
    "companyName": '',
    "companyType": '',
  }

  public validationMessages = {
    name: {
      "required": 'Name is required',
    },
    email: {
      required: 'Email is required',
      pattern: 'Please enter a valid email id',
    },
    companyName: {
      required: 'Company name is required',
    },
    companyType: {
      required: 'Company type is required',
    },
  }

  brands: SwiperOptions = {
    slidesPerView: 3.5,
    spaceBetween: 10,
    loop: false,
    autoplay: true,

    scrollbar: {
      draggable: true
    },
    breakpoints: {
      320: {
        slidesPerView: 3.5,
        spaceBetween: 10
      },
      425: {
        slidesPerView: 3.5,
        spaceBetween: 10
      },
      1024: {
        slidesPerView: 7,
        spaceBetween: 10
      }
    }
  };

  constructor(
    private popupService: PopupService,
    public router: Router,
    private apiService: BackendApiService,
    private logger: LoggerServiceService,
    private userInfoService: UserInfoService,
    private formBuilder: FormBuilder,
    public utilsService: UtilsService,
    private playerService: PlayerService,
    private gtmAnalytics: GtmAnalyticsService
  ) { }

  ngOnInit(): void {
    this.popupService.popUpType.subscribe((popUpType) => {
      this.userDetails = this.apiService.getUserData();
      this.popUpType = popUpType;

      switch (this.popUpType) {
        case 'ADD_TRACK_TO_CART':
          this.initTrackForm();
          break;
        case 'COMPLETE_PROFILE':
          this.initOnboardingForm();
          break;
        case 'RENAME_PLAYLIST':
          this.playlistName = this.trackInfo.playlistName;
        case 'NEW_PLAYLIST':
          this.playlistName = '';
        case 'ADD_TO_PLAYLIST':
          this.getPlaylistData();
        default:
          break;
      }
    });
    this.popupService.trackInfo.pipe(takeUntil(this.takeUntil$)).subscribe(trackInfo => {
      this.trackInfo = trackInfo;
      this.trackUrl = environment.baseUrlFrontend + "/" + this.trackInfo?.type.toLowerCase() + "/" + this.trackInfo?.name_slug
      this.primaryArtist = this.trackInfo?.tracksArtistRole?.filter(i => i.isPrimary == true) || this.trackInfo?.tracks_artist_role?.filter(i => i.isPrimary == true)
      this.hasVocals = this.trackInfo?.hasVocals || this.trackInfo?.has_vocals === 'true' ? true : false;
      this.artistString = this.primaryArtist?.map((artist) => artist.artist.name ).join(', ') || '';
    })
  }

  initOnboardingForm(): void {
    this.onboardingForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      emailOtp: this.formBuilder.group({
        otp1: ['', [Validators.required, Validators.pattern(/^\d{1}$/)]],
        otp2: ['', [Validators.required, Validators.pattern(/^\d{1}$/)]],
        otp3: ['', [Validators.required, Validators.pattern(/^\d{1}$/)]],
        otp4: ['', [Validators.required, Validators.pattern(/^\d{1}$/)]],
        otp5: ['', [Validators.required, Validators.pattern(/^\d{1}$/)]],
        otp6: ['', [Validators.required, Validators.pattern(/^\d{1}$/)]],
      }),
      companyName: ['', Validators.required],
      companyType: ['', Validators.required],
    })
  }

  onOnboardingFormSubmit(): void {

    if (!this.onboardingForm.valid) {
      if (!this.onboardingForm) { return; }
      const form = this.onboardingForm;
      for (const field in this.onboardingFromErrors) {
        this.onboardingFromErrors[field] = '';
        const control = form.get(field);
        if (control && !control.valid) {
          const messages: { [key: string]: any } = this.validationMessages[field];
          for (const key in control.errors) {
            this.onboardingFromErrors[field] += messages[key] + ' ';
          }
        }
      }
    }

    if (this.onboardingForm.valid) {
      const submittedData = {
        name: this.onboardingForm.value.name,
        email: this.onboardingForm.value.email,
        companyName: this.onboardingForm.value.companyName,
        companyType: this.onboardingForm.value.companyType,
      }

      this.apiService.apiCreateProfile(submittedData).subscribe((response: any) => {
        if (response.info.status == "200") {
          this.closePopUp();
          // mark user as onboarded
          const userData = this.apiService.getUserData();
          if (userData && Object.keys(userData).length > 0) {
            userData.isOnboarded = true;
            this.apiService.setUserData(userData);
          }
          if (!this.trackInfo?.isAllowedToSkipOnboard) {
            this.requestQuote();
          }
        }
      }, (err) => {
        console.log(err);
      })
    }
  } 

  verifyOnboardingEmail():void {
    console.log('user trying to verify')
    if(!this.onboardingForm || !this.onboardingForm?.value?.email) { return; }
    const reqObj = {
      email: this.onboardingForm?.value?.email,
    }

    if (!this.onboardingForm.controls['email'].valid) {
      this.onboardingFromErrors.email = 'Please enter a valid email address';
      return
    }
    this.apiService.consumerVerifyEmail(reqObj).subscribe(
      (response) => {
        this.onboardingFromErrors.email = '';
        this.emailToBeVerfied = reqObj.email;
        this.showEmailOtpBox = true;
      },
      (err) => {
        if (err.error.data.additionalInfo.type == 'DUPLICATE_KEY') {
          this.onboardingFromErrors.email = 'Email already exists';
        }
      }
    )
  }

  onOtpInput(event: any, nextInput?: HTMLInputElement): void {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    if (!allowedKeys.includes(event.key)) return

    const input = event.target;
    if (input.value) {
      input.classList.add('filled'); // Add 'filled' class when input has value
    } else if (nextInput === undefined && this.onboardingForm.valid) {
      input.classList.remove('filled');
    }
    if (nextInput && input.value) {
      nextInput.focus(); // Move focus to next input
    } else {
      this.onOtpSubmit();
    }
  }

  onKeyDown(event: KeyboardEvent, previousInput?: HTMLInputElement, currentInput?: HTMLInputElement): void {
    const target = event.target as HTMLInputElement;
    if (event.key === 'Backspace' && previousInput) {
      if (target.value.length === 0) {
        previousInput.focus();
        previousInput.value = '';
      } else {
        target.value = '';
      }
    }
  }

  private onOtpSubmit(): void {

    const { otp1, otp2, otp3, otp4, otp5, otp6 } = this.onboardingForm?.value?.emailOtp || null;
    const reqObj = {
      otp: `${otp1}${otp2}${otp3}${otp4}${otp5}${otp6}`,
    }
    this.apiService.validateEmailOtp(reqObj).subscribe(
      (response: any) => {
        if (response.info.status == 200) {
          this.showEmailOtpBox = false;
          this.isUserEmailVerfied = true;
        }
      }, (err) => {
        console.log(err);
      }
    )
  }

  public copyEmbedCode(): void {
    navigator.clipboard.writeText(this.trackUrl);
    SwalHelper.showToast('<p class="swal2-title link">Link copied to clipboard</p>',
   '<i class="ph ph-fill ph-check-circle" style="color:#2C96FF"></i>',
   '<a href="/user/my-collection/my-likes" class="swal-text-link text-normal"></a>');
  }

  closePopUp() {
    this.popupService.popUpType.next(undefined);
  }
  aboutpopupshow() {
    this.lifetimeLicenseAbout = true;
    $('#lifetimeLicenseAbout').modal('show');
  }
  aboutpopupClose() {
    $('#lifetimeLicenseAbout').modal('hide');
    this.lifetimeLicenseAbout = false;
  }
  initTrackForm(): void {
    this.licenseTypeForm = this.formBuilder.group({
      product: ['digital']
    })

    // by default select brand for buisness user type
    if (this.userDetails.role === 'business') {
      setTimeout(() => {
        let universalEl: HTMLElement = document.getElementById("universal") as HTMLElement
        universalEl.click();
        let creatorAccordian: HTMLElement = document.getElementById("individualCollapse") as HTMLElement;
        creatorAccordian.classList.remove('show');
        let brandAccordian: HTMLElement = document.getElementById("brandCollapse") as HTMLElement
        brandAccordian.classList.add('show');
      }, 100)
    } else {
      setTimeout(() => {
        let digitalEl: HTMLElement = document.getElementById("digital") as HTMLElement
        digitalEl.click();
        let brandAccordian: HTMLElement = document.getElementById("individualCollapse") as HTMLElement
        brandAccordian.classList.add('show');
      }, 100)
    }
  }

  createNewPlaylist() {
    this.closePopUp();
    this.popupService.showPopUp("NEW_PLAYLIST");
  }

  requestQuote() {
    this.closePopUp();
    let userData = JSON.parse(localStorage.getItem('userData'));
    userData.cartItems = userData.cartItems || [];
    const userDetails = this.apiService.getUserData();
    const isOnboarded = userDetails?.isOnboarded;
    
    if (isOnboarded) {
      const trackExists = userData.cartItems.some(item => item.id === this.trackInfo?.id);
      if (!trackExists) {
          userData.cartItems.push({
              id: this.trackInfo?.id,
              name: this.trackInfo?.name,
              imageUrl: this.trackInfo?.imageUrl,
              artists: this.artistString || '',
          });
      }
          this.showSkip = true;
        this.router.navigate(['/user/request-license']);
        this.apiService.setUserData(userData);
        this.apiService.incCartCount();
    } else {
        this.showSkip = false;
        this.popupService.showPopUp("COMPLETE_PROFILE");
    }
}
 navigate(){
  this.closePopUp();
  let userData = JSON.parse(localStorage.getItem('userData'));
  userData.cartItems = userData.cartItems || [];
  const userDetails = this.apiService.getUserData();
  const isOnboarded = userDetails?.isOnboarded;
  if (isOnboarded) {
        userData.cartItems.push({
        id: this.trackInfo?.id,
        name: this.trackInfo?.name,
        imageUrl: this.trackInfo?.imageUrl,
        artists : this.artistString || '',
    });
  }
  this.apiService.setUserData(userData);
  this.apiService.incCartCount();
  const redirectUrl = localStorage.getItem('redirectUrl') || '/';
  this.router.navigateByUrl(redirectUrl);

}
  continuePlaying(): void {
    this.playerService.autoPlay(this.trackInfo?.id || null);
    this.closePopUp();
  }

  onPlaylistSubmit(event: any) {
    if (this.playlistName === undefined || this.playlistName.trim() === "") {
      return;
    }

    if (
      this.presentPlaylists.find(
        (p) =>
          p.name.toLowerCase().trim() ===
          this.playlistName?.toLowerCase().trim()
      )
    ) {
      this.formErrors["name"] = "Playlist with same name already exists";
      this.closePopUp()
      SwalHelper.showToast("Playlist not created  " + this.playlistName + "Playlist with same name already exists", "", "");
      
      return;
    }
    this.logger.logPlaylistEvent("Playlist created", this.playlistName);
    this.apiService
      .apiCreatePlaysits({
        name: this.playlistName,
        description: this.playlistDesc,
      })
      .subscribe(
        (response: any) => {
          this.getPlaylistData();
          this.closePopUp();
          SwalHelper.showToast(
            '<p>Playlist successfully created</p>',
            '<i class="ph ph-fill ph-check-circle" style="color:#2C96FF"></i>',
            `<a href='/playlist/${response.data.playlist.id}' class='swal-text-link text-normal'>VIEW ${this.playlistName}</a>`
          );    
          this.popupService.showPopUp("ADD_TO_PLAYLIST", this.trackInfo);
          // log data to backend
          const playlist = response.data.playlist;
          const playListMeta = {
            event_type: 'create_playlist',
            playlist_id: playlist?.id || null,
            playlist_name: playlist?.name || null,
            playlist_meta: playlist?.description || null,
          }
          this.userInfoService.logData(playListMeta);
        },
        (error) => {
          console.log("Error found while creating playlist", error);
        }
      );
    this.closePopUp()
    return
  }

  getPlaylistData() {
    this.apiService.apiGetUserPlaylists().pipe(takeUntil(this.takeUntil$)).subscribe(
      (response) => {
        this.presentPlaylists = response["data"].playlist;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  renamePlaylistSubmit(): void {
    const playlistData = {
      id: this.trackInfo.playlistId,
      name: this.playlistName
    }
    this.apiService.apiRenamePlaylist(playlistData).subscribe(
      (response) => {
        this.closePopUp();
        location.reload();
      },
      (error) => {
        console.log(error);
      }
    )
  }

  deletePlaylistSubmit(): void {
    this.apiService.deleteConsumerPlaylist(this.trackInfo.playlistId,).subscribe(
      (response) => {
        this.closePopUp();
        history.back();
      },
      (error) => {
        console.log(error);
      }
    )
  }

  public imageNotFound(event, assetType) {
    if (assetType === "genre" || assetType === "subgenre") {
      event.target.src = `assets/images/default/genre.webp`;
    } else if (assetType === "artist") {
      event.target.src = `assets/images/default/track.webp`;
    } else {
      event.target.src = `assets/images/default/track.webp`;
    }
  }

  addTrackToplaylist(playlistId) {

    let reqObj = {
      tracks: [this.trackInfo?.id],
    };
    this.apiService.addTrackToPlaylist(playlistId, reqObj).subscribe(
      (response: any) => {
        this.closePopUp();
        SwalHelper.showToast(
          '<p>Successfully added to your playlist</p>',
          '<i class="ph ph-fill ph-check-circle" style="color:#2C96FF"></i>',
          `<a href='/playlist/${response.data.playlistId}' class='swal-text-link text-normal'>VIEW ${response.data.playlistName} </a>`
        )
        if (this.trackInfo.asset_type) {
          if (this.trackInfo.type === "music") {
            this.trackInfo.sub_genres = this.trackInfo.sub_filters
          } else {
            this.trackInfo.sfx_subcategory = this.trackInfo.sub_filters
            this.trackInfo.sfx_category = this.trackInfo.filters
          }
        }
        else if (this.trackInfo.filters) {
          const genres = [];
          const moods = [];
          const usecases = [];
          const sub_genres = [];
          const sfx_subcategory = [];
          const sfx_category = [];
          this.trackInfo?.filters?.forEach(filter => {
            if (filter.type === "genre") {
              genres.push(filter.name)
            } else if (filter.type === "mood") {
              moods.push(filter.name)
            } else if (filter.type === "usecase") {
              usecases.push(filter.name)
            } else if (filter.type === "sfxcategory") {
              sfx_category.push(filter.name)
            }
          })
          this.trackInfo?.subFilters?.forEach(subfilter => {
            if (subfilter.type === "subgenre") {
              sub_genres.push(subfilter.name)
            } else if (subfilter.type === "subsfxcategory") {
              sfx_subcategory.push(subfilter.name)
            }
          })
          this.trackInfo.genres = genres;
          this.trackInfo.moods = moods;
          this.trackInfo.usecases = usecases;
          this.trackInfo.sfx_category = sfx_category;
          this.trackInfo.sub_genres = sub_genres;
          this.trackInfo.sfx_subcategory = sfx_subcategory;
        }

        if (this.trackInfo?.keywords && this.trackInfo?.keywords[0] && typeof this.trackInfo?.keywords[0] !== "string") {
          let keywords = []
          this.trackInfo?.keywords?.forEach(keyword => {
            keywords.push(keyword.keyword)
          })
          this.trackInfo.keywords = keywords
        }

        const playListMeta = {
          event_type: 'add_to_playlist',
          track_id: this.trackInfo?.id || null,
          track_name: this.trackInfo?.name || null,
          track_genre: this.trackInfo?.genres ? this.trackInfo?.genres : null,
          track_moods: this.trackInfo?.moods ? this.trackInfo?.moods : null,
          track_usecases: this.trackInfo?.usecases ? this.trackInfo?.usecases : null,
          track_keywords: this.trackInfo?.keywords ? this.trackInfo?.keywords : null,
          track_bpm: this.trackInfo?.bpm,
          primary_artist: this.trackInfo?.tracksArtistRole?.find(i => i.isPrimary == true).artist?.name || this.trackInfo?.tracks_artist_role?.find(i => i.isPrimary == true).artist?.name || this.trackInfo?.artists.find((artist) => artist.ArtistTrackRoleMappingModel.isPrimary == true)?.name || this.trackInfo?.artists[0].name,
          // track_meta: { ...this.trackInfo },
          is_vocal: this.trackInfo?.hasVocals === true || this.trackInfo?.has_vocals === "true" ? true : false,
          sfx_category: this.trackInfo?.sfx_category || null,
          sfx_subcategory: this.trackInfo?.sfx_subcategory || null,
          sub_genres: this.trackInfo?.sub_genres || null,
          track_origin_region: this.trackInfo?.region || null,
          track_release_region: this.trackInfo?.release_region || this.trackInfo?.releaseRegion || null,
          is_explicit: this.trackInfo?.isExplicit === true || this.trackInfo?.is_explicit === "true" ? true : false,
          track_type: this.trackInfo?.type || null,
          duration: this.trackInfo?.duration || null,
          display_tags: this.trackInfo?.displayTags || this.trackInfo?.display_tags || null,
          playlist_id: playlistId || null,
          playlist_name: response.data?.playlistName || null,
          playlist_meta: {},
        }
        this.userInfoService.logData(playListMeta);
      },
      (error) => {
        console.log("Error found while adding popup to playlist", error);
      }
    );
  }
  addLifetimeLictoCart() {

    let reqObj = {
      itemType: "license",
      licenseType: "standard lifetime",
      trackId: this.trackInfo?.id,
    };
    this.apiService.apiAddtoCart(reqObj).subscribe(
      (res: any) => {
        if (res?.info.status == "201") {
          this.closePopUp();
          this.router.navigateByUrl("/user/mycart");
        }
      },
      (err) => {
        console.log("Error found while adding item to cart", err);
      }
    );
  }

  urlRoute(action) {
    let url = this.router.url
    localStorage.setItem('action', action)
    localStorage.setItem('redirectUrl', url)

  }
  redirectRoute() {
    const redirectlocation = localStorage.getItem('redirectUrl') ? localStorage.getItem('redirectUrl') : '/'
    this.router.navigate([redirectlocation]);
    localStorage.removeItem('redirectUrl');
  }


  ngOnDestroy(): void {
    this.takeUntil$.next();
    this.takeUntil$.complete();
  }

  signUpASBusiness() {
    let token = "";
    let userData = {};
    this.logger.logEvent('Logout')
    this.apiService.setToken(token);
    this.apiService.setUserData(userData);
    this.closePopUp();
    this.router.navigate(['/auth/get-started', { select: 'business' }]);
  }

  // one month extra free subscription offer - temporary
  // add monthly individual plan to cart
  // redirect to cart page
  addToCart(): void {
    const reqBody = {
      "itemType": "plan",
      "planId": "3cd4eb4d-8d8a-4d33-90d0-e32a5a413609"
    }

    this.apiService.apiAddtoCart(reqBody).subscribe(res => {
      this.router.navigateByUrl("/user/mycart");

    }, err => {
    })

  }

  redirectToSubscription() {
    this.router.navigate(['/user/my-account/purchases/subscription']);
  }

  redirectToCartTab(tab: string): void {
    this.router.navigateByUrl(`/user/mycart?activeTab=${tab}`);
  }

  redirectToPricing() {
    this.router.navigateByUrl('/pricing');
  }

  redirectToAccountDetails(): void {
    this.closePopUp();
    this.router.navigateByUrl('/user/my-account/profile;section=socialLinksSection');
  }

  async addTrackToCart(redirect: boolean) {
    const reqObj = {
      itemType: 'license',
      licenseTypeId: this.licenseTypeForm?.value?.product || null,
      trackId: this.trackInfo?.id || null,
    }

    if (this.userDetails.role === 'business' && (reqObj.licenseTypeId.includes('individual') || reqObj.licenseTypeId.includes('web3'))) {
      this.popUpType = 'BUY_INDIVIDUAL_TRACK';
      return
    }

    this.apiService.apiAddtoCart(reqObj).subscribe((res: any) => {
      this.closePopUp();
      if (res?.data?.Cart) {
        this.apiService.incCartCount();
      }
      if (redirect) {
        this.router.navigateByUrl('/user/mycart?activeTab=license');
      }
      SwalHelper.showToast("Track Successfully added to cart", "", "");
    }, err => {
      console.log(err)
    })
  }

  // skipOnboarding(): void 

  pushAnalytics() {
    this.gtmAnalytics.pushCtaAnalytics({
      event: 'ga4 click_interaction	',
      event_name: 'click_interaction',
      artist_name: '',
      instrument_type: '',
      link_name: 'pop_up',
      link_header: '',
      link_section: '',
      link_text: 'Subscribe Now',
      link_url: (window.location?.pathname.length > 125 ? window.location?.pathname.substring(0, 125) : window.location?.pathname) || null,
      login_status: JSON.parse(localStorage.getItem('userData'))?.id
        ? 'login'
        : 'guest',
      page_type: window.location?.pathname.split('/')[1] || null,
      search_link: '',
      search_term: '',
      track_name: '',
      user_id: JSON.parse(localStorage.getItem('userData'))?.id || null,
    });
  }
}
