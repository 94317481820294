import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  templateUrl: './privacy_policy.component.html',
  styleUrls: ['./privacy_policy.component.scss'],
})
export class PrivacyPolicyComponent {



  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }


  ngOnInit() {
    this.setMetaTags();

  }

  setMetaTags(): void {
    this.titleService.setTitle(
      'Privacy Policy | Hoopr - India ka Sound'
    );
    this.metaTagService.updateTag({
      name: 'description',
      content:
        'Policy discloses the privacy practices for the Company’s applications and/or Websites, how personal & other information that we receive or collect about you is treated.',
    });
    this.metaTagService.updateTag({
      property: 'og:title',
      content:
        'Privacy Policy | Hoopr - India ka Sound'
    });
    this.metaTagService.updateTag({
      property: 'og:description',
      content:
        'Policy discloses the privacy practices for the Company’s applications and/or Websites, how personal & other information that we receive or collect about you is treated.',
    });
  }

}
