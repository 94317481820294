import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendApiService } from './hoopr.services';
import { environment } from "src/environments/environment";
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class LoggerServiceService {
  public deviceInfo: any
  constructor(private _http: HttpClient,
    private apiService: BackendApiService,
    private deviceDetectorService: DeviceDetectorService) {
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
  }



  public logTrackEvent(eventType, trackId, trackName) {
    let logObject: any = {}
    let user = this.apiService.getUserData() 
    logObject.userEmail = Object.keys(user).length !==0 ? this.apiService.getUserData().email : ""
    logObject.eventType = eventType;
    logObject.metadata = {
      trackId: trackId,
      ...this.deviceInfo
    }

    this.sendLogToServer(logObject)
  }


  public logArtistEvent(eventType, artistId) {
    let logObject: any = {}
    let user = this.apiService.getUserData() 
    logObject.userEmail = Object.keys(user).length !==0 ? this.apiService.getUserData().email : ""
    logObject.eventType = eventType;
    logObject.metadata = {
      artistId: artistId,
      ...this.deviceInfo
    }
    this.sendLogToServer(logObject)
  }


  public logSearchEvent(eventType, keyword) {
    let logObject: any = {}
    let user = this.apiService.getUserData() 
    logObject.userEmail = Object.keys(user).length !==0 ? this.apiService.getUserData().email : ""
    logObject.eventType = eventType;
    logObject.metadata = {
      keyword: keyword,
      ...this.deviceInfo
    }
    this.sendLogToServer(logObject)
  }

  public logPlaylistEvent(eventType, playlistName) {
    let logObject: any = {}
    let user = this.apiService.getUserData() 
    logObject.userEmail = Object.keys(user).length !==0 ? this.apiService.getUserData().email : ""
    logObject.eventType = eventType;
    logObject.metadata = {
      playlistName: playlistName,
      ...this.deviceInfo
    }
    this.sendLogToServer(logObject)
  }

  public logEvent(eventType) {
    let logObject: any = {}
    let user = this.apiService.getUserData() 
    logObject.userEmail = Object.keys(user).length !==0 ? this.apiService.getUserData().email : ""
    logObject.eventType = eventType;

    logObject.metadata = {
      ...this.deviceInfo
    }
    this.sendLogToServer(logObject)
  }


  public sendLogToServer(logObject: any) {
    this._http.post(`${environment.baseUrlBackend}/logging/`, logObject).subscribe(
      (data: any) => {
      },
      (error) => {
        console.log(error)
      }
    );
  }


}
