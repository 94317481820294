import { NgModule } from "@angular/core";
import { CommonComponentsModule } from "./common-components/common-components.module";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FooterComponent } from "./_nav_footer/footer.component";
import { TrackComponent } from './track/track.component';
import { LandingComponent } from "./_page_landing/landing.component";
import { ContactUsComponent } from "./_page_contactus/contactus.component";
import { ContactSalesComponent } from "./_page_contact_sales/contact-sales.component";
import { PrivacyPolicyComponent } from "./_page_privacy_policy/privacy_policy.component";
import { TermsConditionsComponent } from "./_page_terms_conditions/terms_conditions.component";
import { BackendApiService } from "./services/hoopr.services";
import { WindowRefService } from "./services/window-ref.service";
import { environment } from "src/environments/environment";
import { PlayerService } from "./services/player.service";
import { GlobalPlayerComponent } from "./global-player/global-player.component";
import { CommonModule } from "@angular/common";
import { SwiperModule } from 'swiper/angular';
import { TrackAuthorizationService } from "./services/track-authorization.service";
import { PopupModule } from './popup/popup.module';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AboutusComponent } from './aboutus/aboutus.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { RouterModule } from '@angular/router';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { MaintenancePageComponent } from './maintenance-page/maintenance-page.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { GlobalFilterComponent } from './global-filter/global-filter.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider'
import { BASE_PATH } from 'src/swagger';
import { DataDeletionComponent } from './data-deletion/data-deletion.component';
import { pageNotFoundComponent } from './_page_error_state_404/pageNotFound.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { PlaylistsComponent } from './playlists/playlists.component';
import { PopularPlaylistsComponent } from './popular-playlists/popular-playlists.component';
import { TrendingComponent } from './trending/trending.component';
import { FeatureCategoryGridComponent } from './feature-category-grid/feature-category-grid.component';
import { PopularComponent } from './popular/popular.component';
import { GenresComponent } from './genres/genres.component';
import { MoviesComponent } from './movies/movies.component';
import { IndividualComponent } from './individual/individual.component';
import { ArtistsComponent } from './artists/artists.component';
import { SliderComponent } from './slider/slider.component';
import { GridComponent } from './grid/grid.component';
import { HomePopularComponent } from './home-popular/home-popular.component';
import { ArtistComponent } from './artist/artist.component';
import { TrendingsComponent } from './trendings/trendings.component';
import { PopularsComponent } from './populars/populars.component';
import { MoodsComponent } from './moods/moods.component';
import { LanguagesComponent } from './languages/languages.component';
import { FaqComponent } from './faq/faq.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    TrackComponent,
    FooterComponent,
    ContactUsComponent,
    ContactSalesComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    GlobalPlayerComponent,
    AboutusComponent,
    CopyrightComponent,
    pageNotFoundComponent,
    MaintenancePageComponent,
    GlobalFilterComponent,
    GlobalSearchComponent,
    DataDeletionComponent,
    BreadcrumbComponent,
    PlaylistsComponent,
    PopularPlaylistsComponent,
    TrendingComponent,
    FeatureCategoryGridComponent,
    PopularComponent,
    GenresComponent,
    MoviesComponent,
    IndividualComponent,
    ArtistsComponent,
    SliderComponent,
    GridComponent,
    HomePopularComponent,
    ArtistComponent,
    TrendingsComponent,
    PopularsComponent,
    MoodsComponent,
    LanguagesComponent,
    FaqComponent,
   
  ],
  exports: [
    CommonModule,
    SwiperModule,
    NgxSliderModule,
    
  ],
  imports: [
    InfiniteScrollModule,
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    CommonComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    PopupModule,
    NgxSliderModule,

    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
  ],
  providers: [
    PlayerService,
    TrackAuthorizationService,
    BackendApiService,
    WindowRefService,
    // ElasticHelperService,
    { provide: BASE_PATH, useValue: environment.BASE_PATH },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
