import { Component,Input } from '@angular/core';

@Component({
  selector: 'app-popular',
  templateUrl: './popular.component.html',
  styleUrls: ['./popular.component.scss']
})
export class PopularComponent {
  @Input() popular: string;
  @Input() popularItems: any;
  @Input() items: number;
  @Input() mobileItems: number;

  constructor() { }
}
