<app-header></app-header>
<div>
    <div class="row">
        <div class="col-xl-4 col-md-5 text-container">
            <div class="mt-3 ml-3">
                <app-breadcrumb></app-breadcrumb>
            </div>
            <div class="pt-5 text-center">
                <h1 class="py-2">{{this.artistBanner?.title}}</h1>
                <h5 class="py-2 px-5 text-center">{{this.artistBanner?.description}}</h5>
                <div><a routerLink="/" class="btn btn-white btn-ic"><img
                            src="/assets/images/share-black-ic.svg" /></a><!-- btn --></div>
                <div><img src="/assets/images/artist.png" alt="artist" class="pt-5 artist-image"></div>
            </div>

        </div><!--col-->
        <div class="col-xl-8 col-md-7 py-5 py-md-0">
            <div class="artist-padded-section ">
                <div class="d-flex justify-content-start">
                    <h3 class="text-left head-text">Top Tracks</h3>
                </div>
                <app-tracks-list [tracks]="this.topTracks" [size]="'medium'"></app-tracks-list>
            </div>
        </div><!--col-->
    </div><!--row-->
    <div class="container py-5">
        <div class="py-5">
            <h2 class="head-text text-left">Top Playlists</h2>
            <app-slider [sliderItems]="this.topPlaylists" [popular]="'Playlists'" [items]="6" [mobileItems]="1.2"
                [showLabel]="true" [labelColor]="'black'"></app-slider>

        </div>
        <div class="py-5">
            <h2 class="head-text text-left">Featured In</h2>
            <app-slider [sliderItems]="this.featuredIn" [popular]="'Movies'" [items]="6" [mobileItems]="1.2"
                [showLabel]="true" [labelColor]="'black'"></app-slider>

        </div>
    </div>
</div>