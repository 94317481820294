import { Router } from '@angular/router';
import { SearchHelperService } from './../../services/search-helper.service';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GtmAnalyticsService } from 'src/app/services/gtm-analytics.service';
declare var window: any;
@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit, OnChanges {

  public searchText = ""
  @Input()
  prefillSearchText;

  @Input()
  searchType: string;

  constructor(
    public searchHelper: SearchHelperService,
    private router :Router,
    private gtmAnalytics:GtmAnalyticsService
  ) { }

  ngOnInit(): void {
    this.searchText = this.prefillSearchText;
  }

  public getSearchResults() {
    this.pushAnalytics(this.searchText)
    this.router.navigate(['/search'], {queryParams: {"q": this.searchText, "t": this.searchType}})
    // this.searchHelper.$searchKeyword.next(this.searchText)
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes.prefillSearchText) {
        this.searchText = changes.prefillSearchText.currentValue;
      }
  }

  /**
   * Remove the clicked filter chip
   * @param index index of clicked chip
   */
  public removeFilter(index: number) {
    const filterToRemove = this.searchHelper.searchChips[index];
    if(filterToRemove.type === 'genre') {
      const filterIndex = this.searchHelper.filterMasterList['genres'].findIndex(genre => genre.slug === filterToRemove.slug)
      this.searchHelper.selectFilter('genre', filterIndex)
    } else if(filterToRemove.type === 'mood') {
      const filterIndex = this.searchHelper.filterMasterList['moods'].findIndex(mood => mood.slug === filterToRemove.slug)
      this.searchHelper.selectFilter('mood', filterIndex)
    } else if(filterToRemove.type === 'usecase') {
      const filterIndex = this.searchHelper.filterMasterList['usecases'].findIndex(usecase => usecase.slug === filterToRemove.slug)
      this.searchHelper.selectFilter('usecase', filterIndex)
    } 
    // this.searchHelper.searchChips.splice(index, 1)
  }


  pushAnalytics(searchQuery) {
    this.gtmAnalytics.pushCtaAnalytics({
      event: 'ga4 search_interaction',
      event_name: 'search_interaction',
      artist_name: '',
      instrument_type: '',
      link_name: '',
      link_header: 'search',
      link_section: '',
      link_text: 'search',
      link_url: (window.location?.pathname.length > 125 ? window.location?.pathname.substring(0, 125) : window.location?.pathname) || null,
      login_status: JSON.parse(localStorage.getItem('userData'))?.id
       ? 'login'
       : 'guest',
      page_type: window.location?.pathname.split('/')[1] || null,
      search_link: searchQuery || null,
      search_term: searchQuery || null,
      track_name: '',
      user_id: JSON.parse(localStorage.getItem('userData'))?.id || null,
});
  }
}
